import { useMutation } from "@apollo/client";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, IconButton, LinearProgress, Modal, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import {
  CreateAssetInputFormFields,
  getDefaultCreateAssetInputFormFields,
  parseToInput,
  parseToOutOfContractInput,
} from "./CreateAssetInput";
import { idfyTheme } from "../../../util/IdfyTheme";
import { MAX_CONTENT_WIDTH } from "../../pageFragments/BasePage";
import { AgriLogo } from "../../pageFragments/util/AgriLogo";
import { CreateAssetCustomer } from "./CreateAssetCustomer";
import { CreateAssetDueDate } from "./CreateAssetDueDate";
import { AssetSidebarFields, CreateAssetInfo } from "./CreateAssetInfo";
import { CreateAssetSubAssets } from "./CreateAssetSubAssets";
import { StepIndicator } from "./StepIndicator";
import { SubAssetConfirmDialog } from "./SubAssetConfirmDialog";
import { ArrowLeftIcon } from "@material-ui/pickers/_shared/icons/ArrowLeftIcon";
import { ArrowRightIcon } from "@material-ui/pickers/_shared/icons/ArrowRightIcon";
import { createSupplierMutation } from "../../suppliers/api/__generated__/createSupplierMutation";
import { CREATE_SUPPLIER_MUTATION } from "../../suppliers/api/suppplierQueriesAndMutations";
import { SupplierData } from "../../suppliers/api/__generated__/SupplierData";
import { isBlankOrEmpty } from "../../../util/stringUtil";
import { ADD_ASSET_MUTATION, USE_OUT_OF_CONTRACT_ASSET_MUTATION } from "../api/assetQueriesAndMutations";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalWrapper: {
      overflow: "scroll",
      width: "100%",
      height: "100%",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        overflow: "hidden auto",
      },
    },
    modalBody: {
      maxWidth: MAX_CONTENT_WIDTH,
      position: "absolute",
      top: "50%",
      left: "50%",
      height: "90%",
      width: "90%",
      transform: "translate(-50%,-50%)",
      boxSizing: "border-box",
      padding: theme.spacing(4),
      borderRadius: "10px",
      outline: "none",
      display: "flex",
      flexDirection: "column",
      background: idfyTheme.palette.background.default,
    },
    grid: {
      maxHeight: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    header: {
      display: "grid",
      width: "100%",
      flexWrap: "nowrap",
      alignItems: "center",
      gridTemplateColumns: "1fr 1fr 1fr",
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        gridTemplateRows: "1fr 1fr 1fr",
        gridTemplateColumns: "auto",
        justifyContent: "center",
      },
    },
    headerButtons: {
      display: "flex",
      justifyContent: "flex-end",
      "& > *:nth-child(n)": {
        marginLeft: theme.spacing(2),
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    featureCounter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "nowrap",
    },
    content: {
      display: "grid",
      gridTemplateColumns: "30% 70%",
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingLeft: 0,
    },
    menu: {
      gridColumn: "1 /span 1",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      borderRight: "1px solid #333",
    },
    logoArea: {
      width: "100%",
      textAlign: "center",
    },
    stepIndicatorContainer: {
      width: "100%",
      padding: idfyTheme.spacing(2),
      boxSizing: "border-box",
      justifyContent: "center",
      display: "flex",
    },
    currentStep: {
      gridColumn: "2 /span 1",
      paddingLeft: theme.spacing(3),
    },
    accountCircle: {
      width: "35%",
      height: "auto",
      fill: "grey",
    },
  })
);

export const CreateAssetModal: React.FunctionComponent<{
  newModalOpen: boolean;
  handleClose: (success: boolean) => void;
  customerId?: string;
}> = (props) => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);
  const { t } = useTranslation();

  const [addAsset] = useMutation(ADD_ASSET_MUTATION);
  const [useOutOfContractAsset] = useMutation(USE_OUT_OF_CONTRACT_ASSET_MUTATION);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [lastSubAssetFieldIsDirty, setLastSubAssetFieldIsDirty] = useState(false);
  const [subAssetConfirmDialogOpen, setSubAssetConfirmDialogOpen] = useState(false);
  const [manufacturerAndSerialInUse, setManufacturerAndSerialInUse] = useState(false);
  // Only used to display manufacturer and name in the sidebar
  const [manufacturerAndName, setManufacturerAndName] = useState("");
  // Used to create a supplier for an asset.
  const [createSupplier] = useMutation<createSupplierMutation>(CREATE_SUPPLIER_MUTATION);

  const reset = () => {
    setNewAsset(initialAssetInput);
    setCurrentStep(0);
    setSubmit(false);
  };

  function handleNextStep() {
    // On 'sub assets' step, we validate, if the user has entered changes, which have not been saved yet
    if (currentStep === 1 && lastSubAssetFieldIsDirty) {
      setSubAssetConfirmDialogOpen(true);
      return;
    }
    // On 'information' step, we validate, if the combination of manufacturer and serial number is already in use
    if (currentStep === 0 && manufacturerAndSerialInUse) {
      return;
    }
    handleNextStepConfirm();
  }

  function handleStepBack() {
    if (currentStep >= 1) setCurrentStep(currentStep - 1);
  }

  function handleNextStepConfirm() {
    const submitted = formSubmit();
    if (currentStep < 3 && submitted) {
      setFormSubmit(() => () => false);
      setCurrentStep(currentStep + 1);
    }
    if (currentStep === 3 && submitted) {
      setSubmit(true);
    }
  }

  // If a new asset contains a new supplier then we add it here.
  async function addSupplier(newAsset: CreateAssetInputFormFields): Promise<SupplierData> {
    const newSupplier = newAsset.newSupplier;
    const response = await createSupplier({
      variables: {
        input: newSupplier,
      },
    });
    return response.data?.addSupplier?.supplier || Promise.reject();
  }

  async function createAsset(newAsset: CreateAssetInputFormFields): Promise<void> {
    if (newAsset.useOutOfContractAsset) {
      const outOfContractAssetForSubmit = parseToOutOfContractInput(newAsset);
      await useOutOfContractAsset({ variables: { input: outOfContractAssetForSubmit } }).then((res) => {
        if (!res.errors) {
          setSubmit(false);
          setIsSubmitting(false);
          handleClose(true);
          setNewAsset(initialAssetInput);
          setCurrentStep(0);
        }
      });
    } else {
      const newAssetForSubmit = parseToInput(newAsset);

      await addAsset({ variables: { input: newAssetForSubmit } }).then((res) => {
        if (!res.errors) {
          setSubmit(false);
          setIsSubmitting(false);
          handleClose(true);
          setNewAsset(initialAssetInput);
          setCurrentStep(0);
        }
      });
    }
    setSubmit(false);
  }

  const [submit, setSubmit] = useState(false);

  const initialAssetInput = getDefaultCreateAssetInputFormFields(props.customerId);

  const [newAsset, setNewAsset] = useState<CreateAssetInputFormFields>(initialAssetInput);
  const handleClose = props.handleClose;

  // Monitor submit and dueDate until they're true/not-null respectively
  // and the asset can then be created. This function is run lots of times
  // during asset creation and there might be a race condition error. When
  // a new asset is created the function might run again before submit can
  // be set back to false.
  useEffect(() => {
    if (submit && newAsset.inspection.dueDate !== null) {
      setIsSubmitting(true);

      if (newAsset.showSupplierFields) {
        addSupplier(newAsset).then((result: SupplierData) => {
          newAsset.asset.supplierId = result.id;
          return createAsset(newAsset);
        });
      } else {
        createAsset(newAsset).then();
      }
    }
  }, [addAsset, newAsset, handleClose, submit]);

  const [formSubmit, setFormSubmit] = useState<() => boolean>(() => () => false);

  function setSidebarValues(fields: AssetSidebarFields) {
    setManufacturerAndName(fields.name);
    setNewAsset((prevState) => ({
      ...prevState,
      asset: {
        ...prevState.asset,
        name: fields.name,
        serialNumber: fields.serialNumber,
        contractNumber: fields.contractNumber,
      },
      isSerialNumberUnknown: fields.isSerialNumberUnknown,
    }));
  }

  return (
    <React.Fragment>
      <Modal
        open={props.newModalOpen}
        onClose={() => {
          props.handleClose(false);
          reset();
        }}
      >
        <Paper className={classes.modalBody} elevation={2}>
          <div className={classes.modalWrapper}>
            <div className={classes.grid}>
              <div className={classes.header}>
                <Typography variant={"h2"}>{t("pages.assets.modals.create-asset.header")}</Typography>
                <div className={classes.featureCounter}>
                  <IconButton size="small" disabled={currentStep < 1} onClick={handleStepBack}>
                    <ArrowLeftIcon />
                  </IconButton>
                  <span>{`${t("pages.assets.modals.create-asset.step")} ${currentStep + 1}/4`}</span>
                  <IconButton size="small" disabled={currentStep === 3} onClick={handleNextStep}>
                    <ArrowRightIcon />
                  </IconButton>
                </div>{" "}
                <div className={classes.headerButtons}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    disabled={isSubmitting}
                    onClick={() => {
                      props.handleClose(false);
                      reset();
                    }}
                  >
                    Abbrechen
                  </Button>
                  <Button color={"primary"} onClick={handleNextStep} disabled={isSubmitting}>
                    {currentStep === 3
                      ? t("pages.assets.modals.create-asset.buttons.create")
                      : t("pages.assets.modals.create-asset.buttons.next-step")}
                  </Button>
                </div>
              </div>
              <LinearProgress variant={"determinate"} value={currentStep / 0.04} />
              <div className={classes.content}>
                <div className={classes.menu}>
                  <div className={classes.logoArea}>
                    <AgriLogo className={classes.accountCircle} />
                    <Typography variant="h3">
                      {manufacturerAndName !== ""
                        ? manufacturerAndName
                        : t("pages.assets.modals.create-asset.details-screen.new-object")}
                    </Typography>
                    <Typography variant="body1">
                      {!isBlankOrEmpty(newAsset.asset.serialNumber)
                        ? `${t("pages.assets.modals.create-asset.details-screen.serial-number")}: ${newAsset.asset.serialNumber
                        }`
                        : newAsset.isSerialNumberUnknown
                          ? t("pages.assets.modals.create-asset.details-screen.serial-number-unknown")
                          : t("pages.assets.modals.create-asset.details-screen.serial-number")}
                    </Typography>
                    <Typography variant="body1">
                      {newAsset.asset.contractNumber !== ""
                        ? `${t("pages.assets.modals.create-asset.details-screen.contract-number")}: ${newAsset.asset.contractNumber
                        }`
                        : t("pages.assets.modals.create-asset.details-screen.contract-number")}
                    </Typography>
                    {newAsset.asset.subAssets.length !== 0 && (
                      <Typography variant="h6">
                        {t("pages.assets.modals.create-asset.details-screen.sub-assets")}:
                      </Typography>
                    )}
                    {newAsset.asset.subAssets.map((subAsset, i) => (
                      <Typography variant="body1" key={i}>
                        {subAsset.name} -{" "}
                        {subAsset.serialNumber || t("pages.assets.modals.create-asset.details-screen.no-serial-number")}
                      </Typography>
                    ))}
                  </div>
                  <div className={classes.stepIndicatorContainer}>
                    <StepIndicator currentStep={currentStep} />
                  </div>
                </div>
                <div className={classes.currentStep}>
                  {[
                    <CreateAssetInfo
                      key={0}
                      assetInput={newAsset}
                      setAssetInput={setNewAsset}
                      setFormSubmit={setFormSubmit}
                      onSidebarFieldsChange={(fields) => setSidebarValues(fields)}
                      onManufacturerAndSerialInUseStateChange={(inUse) => setManufacturerAndSerialInUse(inUse)}
                      customerId={props.customerId}
                    />,
                    <CreateAssetSubAssets
                      key={1}
                      assetInput={newAsset}
                      setAssetInput={setNewAsset}
                      setFormSubmit={setFormSubmit}
                      onDirtyStateChange={(isDirty) => setLastSubAssetFieldIsDirty(isDirty)}
                    />,
                    <CreateAssetCustomer
                      key={2}
                      assetInput={newAsset}
                      setAssetInput={setNewAsset}
                      setFormSubmit={setFormSubmit}
                      customerId={props.customerId}
                    />,
                    <CreateAssetDueDate
                      key={3}
                      assetInput={newAsset}
                      setAssetInput={setNewAsset}
                      setFormSubmit={setFormSubmit}
                    />,
                  ][currentStep] || <h1>Error</h1>}
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </Modal>
      <SubAssetConfirmDialog
        open={subAssetConfirmDialogOpen}
        onClose={() => setSubAssetConfirmDialogOpen(false)}
        onConfirm={() => {
          setSubAssetConfirmDialogOpen(false);
          handleNextStepConfirm();
        }}
        onDeny={() => setSubAssetConfirmDialogOpen(false)}
      />
    </React.Fragment>
  );
};
