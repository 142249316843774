import React from "react";
import { useTranslation } from "react-i18next";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import HistoryIcon from "@material-ui/icons/History";
import InfoIcon from "@material-ui/icons/Info";

import { InspectionStatus } from "../../../__generated__/globalTypes";
import { DetailsMenu, DetailsMenuSubPage } from "../../../components/DetailsMenu";
import { routes } from "../../../routing/routes";
import { AgriLogo } from "../../pageFragments/util/AgriLogo";
import { assetDetailQuery_asset_image } from "../api/__generated__/assetDetailQuery";
import { StatusChip } from "../../pageFragments/util/StatusChip";
import { Typography } from "@material-ui/core";

export const useStyles = makeStyles((_: Theme) =>
  createStyles({
    assetImage: {
      width: "80%",
      height: "auto",
      fill: "red",
      borderRadius: "50%",
    },
    subtitle: {
      paddingTop: 5,
      paddingBottom: 5,
    },
  })
);

export const AssetMenu: React.FunctionComponent<{
  manufacturer: string | undefined;
  name: string;
  serialNumber: string | null;
  id: string;
  blueprint: string | undefined;
  status?: InspectionStatus;
  subAssetsCount: number;
  image: assetDetailQuery_asset_image | null;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const menuButtons: DetailsMenuSubPage[] = [
    {
      label: t("pages.asset-details.menu.information"),
      icon: InfoIcon,
      path: routes.infoSuffix,
    },
    {
      label: t("pages.asset-details.menu.inspections"),
      icon: AssignmentTurnedInIcon,
      path: routes.inspectionSuffix,
      badge: props.status === InspectionStatus.WAITING_FOR_APPROVAL,
    },
    {
      label: t("pages.asset-details.menu.sub-assets"),
      icon: AddToPhotosIcon,
      path: routes.subAssetSuffix,
      extra: props.subAssetsCount.toString(),
    },
    {
      label: t("pages.asset-details.menu.protocol"),
      icon: HistoryIcon,
      path: routes.protocolSuffix,
    },
  ];

  return (
    <DetailsMenu
      title={
        <div>
          {props.manufacturer && (
            <div>
              {props.manufacturer}
              <br />
            </div>
          )}
          {props.name}
        </div>
      }
      subtitle={
        <>
          <Typography className={classes.subtitle} variant={"body1"}>
            {props.serialNumber}
          </Typography>
          <Typography className={classes.subtitle} variant={"body2"}>
            {props.blueprint && props.blueprint}
          </Typography>
          {props.status && <StatusChip status={props.status} />}
        </>
      }
      icon={(className) =>
        props.image === null ? (
          <AgriLogo className={className} />
        ) : (
          <img
            alt={"Asset"}
            className={classes.assetImage}
            src={`data:${props.image.type};base64,${props.image.data}`}
          />
        )
      }
      subPages={menuButtons}
      subPageActiveCheck={(button) =>
        window.location.pathname === routes.assets + routes.details(props.id) + button.path
      }
      routeFunction={(s: string) => routes.assets + routes.details(s)}
    />
  );
};
