import React, { ReactElement, useEffect, useState } from "react";
import { PopUpDialog } from "../../pageFragments/util/PopUpDialog";
import { FormGridContainer, FormGridItem } from "../../../components/forms/FormGrid";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { getDateFnsLocale } from "../../../i18nInit";
import { useInfoBoxStyles } from "../../../util/IdfyTheme";
import { Alert } from "@material-ui/lab";

export function ExtendContractDialog(props: {
  onConfirm: (extensionPeriod: string | undefined, extendedEndDate: Date | undefined) => void;
  onClose: () => void;
  open: boolean;
  contractEndDate: Date;
}): ReactElement {
  const infoBoxClasses = useInfoBoxStyles();
  const { t, i18n } = useTranslation();
  const [extendedEndDate, setExtendedEndDate] = useState<Date | null>(null);
  const [extensionPeriod, setExtensionPeriod] = useState<string | undefined>("P1M");

  useEffect(() => {
    if (extensionPeriod) {
      setExtendedEndDate(null);
    }
  }, [extensionPeriod]);

  return (
    <PopUpDialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      handleConfirm={() => {
        props.onConfirm(extensionPeriod, extendedEndDate || undefined);
      }}
      header={t("pages.contracts.extend-contract-dialog.header")}
      confirm={t("pages.contracts.extend-contract-dialog.confirm")}
      deny={t("pages.contracts.extend-contract-dialog.cancel")}
      handleDeny={() => {
        props.onClose();
      }}
    >
      <Alert severity="info" color={"warning"} className={infoBoxClasses.infoBox}>
        <Trans t={t} i18nKey="pages.contracts.extend-contract-dialog.body" />
      </Alert>
      <FormGridContainer>
        <FormGridItem>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getDateFnsLocale(i18n.language)}>
            <DatePicker
              value={extendedEndDate}
              label={t("pages.contracts.extend-contract-dialog.extensionPeriod")}
              minDate={props.contractEndDate}
              onChange={(date) => setExtendedEndDate(date)}
              fullWidth
              format={t("dates.date-long")}
              inputVariant="outlined"
              color="secondary"
              disabled={!!extensionPeriod}
            />
          </MuiPickersUtilsProvider>
        </FormGridItem>
        <FormGridItem>
          <FormControl variant="outlined" color="secondary" fullWidth>
            <Select
              labelId="fixed-date-select"
              value={extensionPeriod || 0}
              onChange={(e) => {
                if (e.target.value === 0) {
                  setExtensionPeriod(undefined);
                } else {
                  setExtensionPeriod(e.target.value as string);
                }
              }}
            >
              <MenuItem value={0}>{t("dates.self-select-date")}</MenuItem>
              <MenuItem value={"P1M"}>{"1 " + t("dates.month")}</MenuItem>
              <MenuItem value={"P2M"}>{"2 " + t("dates.months")}</MenuItem>
              <MenuItem value={"P6M"}>{"6 " + t("dates.months")}</MenuItem>
              <MenuItem value={"P12M"}>{"12 " + t("dates.months")}</MenuItem>
              <MenuItem value={"P24M"}>{"24 " + t("dates.months")}</MenuItem>
            </Select>
          </FormControl>
        </FormGridItem>
      </FormGridContainer>
    </PopUpDialog>
  );
}
