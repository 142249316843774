import * as Sentry from "@sentry/browser";

import * as React from "react";
import { ErrorInfo, ReactNode } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  errorText: string | null;
  showDialog: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      errorText: null,
      showDialog: false,
    };
  }

  public componentDidMount(): void {
    window.addEventListener("error", (event) => {
      this.setState({
        hasError: true,
        errorText: event.message,
        showDialog: true,
      });
      console.error(event);
    });

    window.addEventListener("unhandledrejection", (event) => {
      this.setState({
        hasError: true,
        errorText: null,
        showDialog: true,
      });
      console.error(event);
    });
  }

  public componentDidCatch(error: Error, info: ErrorInfo): void {
    this.setState({ hasError: true, showDialog: true });
    if (process.env.NODE_ENV === "production") {
      // Send the error to sentry
      Sentry.withScope((scope) => {
        scope.setExtra("errorInfo", info);
        Sentry.captureException(error);
      });
    } else {
      console.error(error);
    }
  }

  public render(): ReactNode {
    const handleOnReportFeedbackClick = () => {
      Sentry.showReportDialog({
        labelClose: "Reload",
        // See https://github.com/getsentry/sentry-javascript/issues/758#issuecomment-688904129
        onLoad: () => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          document.querySelector(".sentry-error-embed .close").addEventListener("click", () => {
            window.location.reload();
          });
        },
      });
      this.setState({ showDialog: false });
    };

    if (this.state.hasError) {
      return (
        <Dialog open={this.state.showDialog}>
          <DialogTitle>Fehler</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ein unerwarteter Fehler ist aufgetreten!
              <br />
              Bitte laden Sie die Seite neu und versuchen es erneut.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => window.location.reload()}>Neu Laden</Button>
            <Button color="primary" onClick={handleOnReportFeedbackClick}>
              Feedback geben
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return this.props.children;
  }
}
