import React from "react";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import InfoIcon from "@material-ui/icons/Info";
import { Skeleton } from "@material-ui/lab";

import { DetailsMenu, DetailsMenuSubPage } from "../../../components/DetailsMenu";
import { routes } from "../../../routing/routes";
import { userInfoQuery_user } from "./__generated__/userInfoQuery";

export interface UserMenuProps {
  id: string;
  user?: userInfoQuery_user | null;
}

export const UserMenu: React.FunctionComponent<UserMenuProps> = (props) => {
  const menuButtons: DetailsMenuSubPage[] = [
    {
      label: "Information",
      icon: InfoIcon,
      path: routes.infoSuffix,
    },
  ];

  return (
    <DetailsMenu
      subPages={menuButtons}
      icon={(className) => <AccountCircleIcon className={className} />}
      title={props.user ? `${props.user.lastName}, ${props.user.firstName}` : <Skeleton variant="text" />}
      subPageActiveCheck={(button) =>
        window.location.pathname === routes.administration + routes.details(props.id) + button.path
      }
      routeFunction={(s: string) => routes.administration + routes.details(s)}
    />
  );
};
