import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import {
  AssetOrSubAssetAlertReason,
  DuplicateAssetOrSubAssetAlert,
} from "../../../../../components/DuplicateAssetOrSubAssetAlert";
import { usePrefixedTranslation } from "../../../../../util/usePrefixedTranslation";
import { Alert } from "@material-ui/lab";
import { FormTextField } from "../../../../../components/forms/FormTextField";
import { FormGridContainer, FormGridItem } from "../../../../../components/forms/FormGrid";
import { ManufacturerSelectField } from "./ManufacturerSelectField";

export const AssetInfoBaseFields: React.FunctionComponent<{
  manufacturerAndSerialInUse: { inUse: boolean; assetId: string };
  disabled: boolean;
  isSerialNumberDisabled: boolean;
  isContractEndOfTermDisabled: boolean;
  duplicateReason: AssetOrSubAssetAlertReason | undefined;
}> = (props) => {
  const { disabled } = props;
  const { t } = useTranslation();
  const { pt } = usePrefixedTranslation("pages.asset-details.information.");
  const form = useFormContext();

  return (
    <React.Fragment>
      <FormGridContainer>
        <FormGridItem>
          <ManufacturerSelectField
            name={"manufacturerId"}
            label={t("pages.assets.modals.create-asset.manufacturer")}
            disabled={props.disabled}
          />
        </FormGridItem>
        <FormGridItem>
          <FormTextField
            name="serialNumber"
            label={pt("fields.serial-number")}
            disabled={props.disabled || props.isSerialNumberDisabled}
            helperText={form.errors.serialNumber?.message}
            error={!!form.errors.serialNumber}
            trimOnBlur
            required
          />
          <Controller
            control={form.control}
            defaultValue={false}
            variant={"outlined"}
            size={"small"}
            color={"secondary"}
            name={"isSerialNumberUnknown"}
            required
            render={({ onChange, value, ref }) => (
              <>
                {!props.disabled && (
                  <FormControlLabel
                    disabled={props.disabled}
                    control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
                    label={pt("fields.serial-number-unknown")}
                    inputRef={ref}
                  />
                )}
              </>
            )}
          />
        </FormGridItem>
        <FormGridItem>
          <FormTextField disabled={disabled} name="name" label={pt("fields.name")} trimOnBlur required />
        </FormGridItem>
        {props.isSerialNumberDisabled && (
          <FormGridItem>
            <Alert severity="warning">
              <Trans t={pt} i18nKey="fields.serial-number-warning" />
            </Alert>
          </FormGridItem>
        )}
      </FormGridContainer>
      {props.manufacturerAndSerialInUse.inUse && !props.disabled && (
        <Grid item xs={12}>
          {props.duplicateReason && (
            <DuplicateAssetOrSubAssetAlert
              assetId={props.manufacturerAndSerialInUse.assetId}
              reason={props.duplicateReason}
            />
          )}
        </Grid>
      )}
    </React.Fragment>
  );
};
