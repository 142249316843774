import { makeStyles } from "@material-ui/core/styles";
import { createStyles, FormGroup, InputBase, MenuItem, Select, withStyles } from "@material-ui/core";
import React, { ReactElement, useState } from "react";

export const BootstrapInput = withStyles((theme) => ({
  root: {
    fontWeight: "bolder",
    marginLeft: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0.5),
    },
  },
  input: {
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    fontSize: 14,
    marginTop: "3px",
    padding: "9px 26px 9px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontWeight: "bold",
    "&:focus": {
      borderRadius: 8,
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
    textTransform: "capitalize",
  },
}))(InputBase);

const useStyles = makeStyles(() =>
  createStyles({
    menuItem: {
      fontWeight: "bold",
      fontSize: "14px",
      textTransform: "capitalize",
    },
  })
);

export type SearchSelectOption = {
  value?: string;
  label: string;
};

type SearchSelectFieldProps = {
  options: SearchSelectOption[];
  onSelectChange: (value?: string) => void;
  initValue?: string | undefined;
};

export function SearchSelectField(props: SearchSelectFieldProps): ReactElement {
  const EMPTY_VALUE = "_EMPTY_";
  const classes = useStyles();
  const [value, setValue] = useState<string>(
    props.initValue !== undefined ? props.initValue : props.options[0]?.value || EMPTY_VALUE
  );

  const handleChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const newValue = event.target.value as string;
    setValue(newValue);
    if (newValue === EMPTY_VALUE) {
      props.onSelectChange(undefined);
    } else {
      props.onSelectChange(newValue);
    }
  };

  return (
    <FormGroup>
      <Select value={value} onChange={handleChange} input={<BootstrapInput />}>
        {props.options.map((option) => (
          <MenuItem key={option.value || EMPTY_VALUE} value={option.value || EMPTY_VALUE} className={classes.menuItem}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormGroup>
  );
}
