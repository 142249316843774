import { gql, useQuery } from "@apollo/client";
import { UserManager, WebStorageStateStore } from "oidc-client";
import { AuthProvider, useAuth } from "oidc-react";

import React, { ReactElement, useEffect, useState } from "react";

import { LoadingPage } from "../../components/LoadingPage";
import { routes } from "../../routing/routes";
import { authConfigQuery } from "./__generated__/authConfigQuery";

export const AUTH_REDIRECT_PATH = "/auth/redirect";
export const AUTH_SILENT_REDIRECT_PATH = "/auth/silent_redirect";

export interface ConfiguredAuthProviderProps {
  children: ReactElement;
}

export const ConfiguredAuthProvider: React.FunctionComponent<ConfiguredAuthProviderProps> = (props) => {
  const { data: { authConfig } = {} } = useQuery<authConfigQuery>(gql`
    query authConfigQuery {
      authConfig {
        authUrl
        clientId
      }
    }
  `);

  const [userManager, setUserManager] = useState<UserManager>();
  useEffect(() => {
    if (!authConfig) {
      return;
    }
    setUserManager(
      new UserManager({
        authority: authConfig.authUrl,
        client_id: authConfig.clientId,
        redirect_uri: `${window.location.origin}${routes.successfulLogin}`,
        silent_redirect_uri: `${window.location.origin}${AUTH_SILENT_REDIRECT_PATH}`,
        post_logout_redirect_uri: window.location.origin,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        scope: "openid profile email",
        response_type: "code",
        accessTokenExpiringNotificationTime: 20,
        automaticSilentRenew: true,
        loadUserInfo: true,
        monitorSession: false,
      })
    );
  }, [authConfig]);

  if (!userManager) {
    return <LoadingPage />;
  }

  return (
    <AuthProvider autoSignIn userManager={userManager}>
      <AuthGuard>{props.children}</AuthGuard>
    </AuthProvider>
  );
};

// Only renders its children when authenticated
const AuthGuard: React.FunctionComponent<{ children: ReactElement }> = (props) => {
  const auth = useAuth();
  if (!auth?.userData || auth.userData.expired) {
    return null;
  }
  return props.children;
};
