import { TFunction } from "i18next";
import * as yup from "yup";
import { FIELD_VALIDATION_TYPE_DATE, FIELD_VALIDATION_TYPE_STRING } from "../../../../util/constants";
import { date, string } from "yup";

export const assetInfoValidationSchema = (
  t: TFunction,
  pt: TFunction,
  isAlreadyInStock: boolean | null
): yup.AnySchema => {
  return yup.object({
    manufacturerId: FIELD_VALIDATION_TYPE_STRING(
      t,
      pt("validations.select-manufacturer"),
      pt("validations.select-manufacturer")
    ),
    name: FIELD_VALIDATION_TYPE_STRING(t),
    isSerialNumberUnknown: yup.boolean().required(),
    serialNumber: string().when("isSerialNumberUnknown", {
      is: false,
      then: string().required(t("validations.required")),
      otherwise: string().optional(),
    }),
    contractNumber: FIELD_VALIDATION_TYPE_STRING(t),
    contractDate: FIELD_VALIDATION_TYPE_DATE(t),
    contractEndOfTerm: yup.date().when("isContractEndOfTermUnknown", {
      is: false,
      then: FIELD_VALIDATION_TYPE_DATE(t),
      otherwise: date()
        .transform(() => {
          return null;
        })
        .notRequired()
        .nullable(),
    }),
    supplierId: string().when([], {
      is: () => isAlreadyInStock === true,
      then: string().optional().nullable(),
      otherwise: string().required(pt("validations.select-supplier")).typeError(pt("validations.select-supplier")),
    }),
    isOffsiteLocation: yup.boolean().required(),
    offsiteLocation: yup.object().when("isOffsiteLocation", {
      is: true,
      then: yup.object({
        name: FIELD_VALIDATION_TYPE_STRING(t),
        streetAddress: FIELD_VALIDATION_TYPE_STRING(t),
        zip: FIELD_VALIDATION_TYPE_STRING(t),
        city: FIELD_VALIDATION_TYPE_STRING(t),
      }),
      otherwise: yup.object().notRequired(),
    }),
    supplierInvoiceNumber: string().when([], {
      is: () => isAlreadyInStock === true,
      then: string().optional().nullable(),
      otherwise: FIELD_VALIDATION_TYPE_STRING(t).typeError(t("validations.required")),
    }),
    invoiceDate: date().when([], {
      is: () => isAlreadyInStock === true,
      then: date()
        .transform((value, originalValue) => {
          if (originalValue === null) return null;
          return value;
        })
        .nullable(),
      otherwise: FIELD_VALIDATION_TYPE_DATE(t),
    }),
  });
};
