import React from "react";

import { createStyles, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles(() =>
  createStyles({
    userInfoContainer: {
      display: "grid",
      gridTemplateRows: "auto",
      justifyItems: "center",
    },
    accountCircle: {
      fill: "#999",
      fontSize: 56,
    },
    manageAccountButton: {
      marginTop: 16,
      minWidth: 200,
      borderRadius: 100,
    },
  })
);

export interface UserPopupUserInfoProps {
  userName: string;
  email: string;
  onManageAccountClick: () => void;
}

export const UserPopupUserInfo: React.FunctionComponent<UserPopupUserInfoProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.userInfoContainer}>
      <AccountCircleIcon className={classes.accountCircle} />
      <Typography variant="h3">{props.userName}</Typography>
      <Typography variant="subtitle2">{props.email}</Typography>
    </div>
  );
};
