import React, { ReactNode, useState } from "react";

import { Button, IconButton, Modal, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";

import { idfyTheme } from "../util/IdfyTheme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      position: "absolute",
      height: "90%",
      width: "90%",
      top: "5%",
      left: "5%",
      background: idfyTheme.palette.background.paper,
      outline: "none",
      boxSizing: "border-box",
      padding: theme.spacing(5),
      borderRadius: "10px",
    },
    contentWrapper: {
      height: "100%",
      width: "100%",
      overflowY: "auto",
      overflowX: "hidden",
    },
    header: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      boxSizing: "border-box",
      marginBottom: theme.spacing(3),
    },
    buttonWrapper: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      paddingTop: theme.spacing(3),
      "& button": {
        marginLeft: theme.spacing(2),
      },
    },
  })
);

export interface FormModalProps {
  open: boolean;
  onClose: () => void;
  cancelButtonText: string;
  submitButtonText: string;
  header: string;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void | Promise<unknown>;
  children: ReactNode;
}

export const FormModal: React.FunctionComponent<FormModalProps> = (props) => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    await props?.onSubmit?.(event);
    setIsSubmitting(false);
  };

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <div className={classes.modal}>
        <div className={classes.contentWrapper}>
          <form onSubmit={handleSubmit}>
            <div className={classes.header}>
              <Typography variant="h3">{props.header}</Typography>
              <IconButton onClick={props.onClose} disabled={isSubmitting}>
                <ClearIcon />
              </IconButton>
            </div>
            {props.children}
            <div className={classes.buttonWrapper}>
              <Button color="secondary" variant="outlined" onClick={props.onClose} disabled={isSubmitting}>
                {props.cancelButtonText}
              </Button>
              <Button color="primary" type="submit" disabled={isSubmitting}>
                {props.submitButtonText}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
