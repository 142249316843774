import React, { ReactElement, ReactNode, Ref } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { idfyTheme, palette } from "../../util/IdfyTheme";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from "@material-ui/core";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      overflow: "auto",
      "-webkit-overflow-scrolling": "touch",
    },
    tableHeadRow: {
      "& th": {
        background: theme.palette.background.paper,
        color: palette.mediumGrey,
      },
    },
    tableRow: {
      padding: `${theme.spacing(4)}px 0`,
    },
    tableColumn: {},
  })
);

export type IDFyColumn<T> = {
  name: string;
  title?: string;
  titleKey?: string;
  render: (rowData: T, t: TFunction) => ReactNode;
  renderMobile?: (rowData: T, t: TFunction) => ReactNode;
};

export function IDFyTable<T>(props: {
  columns: IDFyColumn<T>[];
  data: T[];
  onRowClick?: (rowData: T) => void;
  loadingRowRef?: Ref<HTMLTableRowElement>;
}): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader>
        {!mobile && (
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              {props.columns.map((column, index) => (
                <TableCell key={index}>
                  {column.title?.toUpperCase() || (column.titleKey ? t(column.titleKey) : "")}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {props.data.map((rowData, index) => (
            <TableRow
              key={index}
              className={classes.tableRow}
              onClick={() => props.onRowClick && props.onRowClick(rowData)}
              hover={props.onRowClick != null}
            >
              {!mobile && (
                <>
                  {props.columns.map((column, index) => (
                    <TableCell key={index} className={classes.tableColumn}>
                      {column.render(rowData, t)}
                    </TableCell>
                  ))}
                </>
              )}
              {mobile && (
                <TableCell>
                  {props.columns.map((column, index) => {
                    const element: ReactNode = column.renderMobile
                      ? column.renderMobile(rowData, t)
                      : column.render(rowData, t);
                    if (typeof element === "string") {
                      return <p key={index}>{element}</p>;
                    } else {
                      return <div key={index}>{element}</div>;
                    }
                  })}
                </TableCell>
              )}
            </TableRow>
          ))}
          {props.loadingRowRef && (
            <TableRow key={"sentry"} className={classes.tableRow} ref={props.loadingRowRef}>
              <TableCell colSpan={6}>Loading</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
