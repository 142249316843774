import { gql, useQuery } from "@apollo/client";

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Route } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";

import { BreadcrumbContext } from "../../../routing/idfyRouter";
import { routes } from "../../../routing/routes";
import { DetailsPage } from "../../pageFragments/DetailsPage";
import { blueprintInfoQuery } from "./__generated__/blueprintInfoQuery";
import { BlueprintAssets } from "./BlueprintAssets";
import { BlueprintInfo } from "./BlueprintInfo";
import { BlueprintMenu } from "./BlueprintMenu";

export const BLUEPRINT_INFO_QUERY = gql`
  query blueprintInfoQuery($id: ID!) {
    blueprint(id: $id) {
      id
      name
      status
      featureBlueprints {
        id
        type
        title
        description
        images {
          data
          type
        }
      }
    }
  }
`;

export const BlueprintDetails: React.FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();

  const utilContext = useContext(BreadcrumbContext);

  const { t } = useTranslation();

  if (utilContext.breadcrumbs.length === 0) {
    utilContext.breadcrumbs.push({
      url: routes.blueprints,
      title: t("pages.blueprint-details.breadcrumb"),
    });
  }

  const { data } = useQuery<blueprintInfoQuery>(BLUEPRINT_INFO_QUERY, {
    variables: { id: id },
  });

  return (
    <DetailsPage
      headerText={t("pages.blueprint-details.blueprint-details")}
      menu={<BlueprintMenu name={data?.blueprint?.name || "Loading... "} />}
    >
      <Route path={routes.blueprints + routes.details(":id") + routes.infoSuffix}>
        {data && data.blueprint ? <BlueprintInfo blueprint={data.blueprint} /> : <CircularProgress color={"primary"} />}
      </Route>
      <Route path={routes.blueprints + routes.details(":id") + routes.assetsSuffix}>
        {data && data.blueprint ? (
          <BlueprintAssets blueprintId={data.blueprint?.id} blueprintName={data.blueprint.name} />
        ) : (
          <CircularProgress color={"primary"} />
        )}
      </Route>
    </DetailsPage>
  );
};
