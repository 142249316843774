import {
  NewAssetInspectionInput,
  OffsiteLocationInput,
  SubAssetInput,
  SupplierInput,
  UseOutOfContractAssetInput,
} from "../../../__generated__/globalTypes";
import { subAssetQuery_asset_subAssets } from "../api/__generated__/subAssetQuery";

/* The create asset pages now use a CreateAssetInput type instead of AddAssetInput since the latter is
automatically generated from graphql and contains a mandatory isAlreadyInStock property when it needs
to be nullable during asset creation. */

export interface CreateAsset {
  name: string;
  serialNumber: string | null;
  customerId: string;
  offsiteLocation: OffsiteLocationInput | null;
  manufacturerId: string;
  supplierId: string | null;
  blueprintId?: string | null;
  contractNumber: string;
  contractDate: string;
  contractEndOfTerm: string | null;
  isNew: boolean;
  isAlreadyInStock?: boolean;
  subAssets: SubAssetInput[];
  supplierInvoiceNumber: string | null;
  invoiceDate: string | null;
}

export interface CreateAssetInput {
  asset: CreateAsset;
  inspection: NewAssetInspectionInput;
}

/**
 * Used to hold the state when creating an asset
 */
export interface CreateAssetInputFormFields extends CreateAssetInput {
  showSupplierFields: boolean;
  isOffsiteLocation: boolean;
  isSerialNumberUnknown: boolean;
  isContractEndOfTermUnknown: boolean;
  newSupplier: SupplierInput | null;
  useExistingContract: boolean;
  useOutOfContractAsset: boolean;
  assetId: string | null;
  existingSubAssets: subAssetQuery_asset_subAssets[];
  subAssetsToAdd: SubAssetInput[];
  subAssetsToDelete: string[];
}

export function getDefaultCreateAssetInputFormFields(customerId: string | undefined): CreateAssetInputFormFields {
  return {
    asset: {
      name: "",
      serialNumber: "",
      contractNumber: "",
      customerId: customerId || "",
      subAssets: Array<{ name: string; serialnumber?: string; manufacturerId: string }>(),
      isNew: true,
      blueprintId: "",
      manufacturerId: "",
      contractDate: "",
      contractEndOfTerm: "",
      supplierId: "",
      offsiteLocation: { name: "", streetAddress: "", zip: "", city: "" },
      supplierInvoiceNumber: "",
      invoiceDate: "",
    },
    inspection: {
      dueDate: "",
      messageToCustomer: null,
    },
    newSupplier: {
      name: "",
      streetAddress: "",
      zip: "",
      city: "",
    },
    showSupplierFields: false,
    isOffsiteLocation: false,
    isSerialNumberUnknown: false,
    isContractEndOfTermUnknown: false,
    useExistingContract: false,
    useOutOfContractAsset: false,
    assetId: "",
    existingSubAssets: Array<subAssetQuery_asset_subAssets>(),
    subAssetsToAdd: Array<SubAssetInput>(),
    subAssetsToDelete: Array<string>(),
  };
}

export function parseToInput(input: CreateAssetInputFormFields): CreateAssetInput {
  return {
    asset: input.asset,
    inspection: input.inspection,
  };
}

export function parseToOutOfContractInput(input: CreateAssetInputFormFields): UseOutOfContractAssetInput {
  return {
    asset: {
      assetId: input.assetId!,
      isAlreadyInStock: input.asset.isAlreadyInStock!,
      subAssetsToAdd: input.subAssetsToAdd,
      subAssetsToDelete: input.subAssetsToDelete,
      customerId: input.asset.customerId,
      offsiteLocation: input.asset.offsiteLocation,
      contractNumber: input.asset.contractNumber,
      contractDate: input.asset.contractDate,
      contractEndOfTerm: input.asset.contractEndOfTerm,
      supplierId: input.asset.supplierId,
      supplierInvoiceNumber: input.asset.supplierInvoiceNumber,
      invoiceDate: input.asset.invoiceDate,
    },
    inspection: input.inspection,
  };
}
