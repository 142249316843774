import { Map } from "immutable";
import { Role } from "../../__generated__/globalTypes";
import { AccountDto } from "../../util/auth/useCurrentAccount";


const actionRolesMap = Map<string, Role[]>(
    {
        //Main Menu
        "drawer.objects": [Role.ADMIN, Role.EMPLOYEE, Role.INSPECTOR],
        "drawer.customers": [Role.ADMIN, Role.EMPLOYEE, Role.INSPECTOR],
        "drawer.contracts": [Role.ADMIN, Role.EMPLOYEE, Role.INSPECTOR],
        "drawer.types": [Role.ADMIN, Role.EMPLOYEE],
        "drawer.manufacturers": [Role.ADMIN, Role.EMPLOYEE, Role.INSPECTOR],
        "drawer.suppliers": [Role.ADMIN, Role.EMPLOYEE, Role.INSPECTOR],
        "drawer.administration": [Role.ADMIN],

        //Assets & sub-assets
        "pages.asset-details.sub-assets.update-dialog": [Role.ADMIN, Role.EMPLOYEE],
        "pages.assets.buttons.new": [Role.ADMIN, Role.EMPLOYEE],

        //Customers
        "pages.customers.buttons.new": [Role.ADMIN, Role.EMPLOYEE],
        "pages.customer-details.information.edit": [Role.ADMIN, Role.EMPLOYEE],
        "pages.customer-details.assets.add-asset":[Role.ADMIN, Role.EMPLOYEE],

        //Manufacturers
        "pages.manufacturers.table.edit": [Role.ADMIN, Role.EMPLOYEE, Role.INSPECTOR],
        "pages.manufacturers.table.delete": [Role.ADMIN, Role.EMPLOYEE, Role.INSPECTOR],

        //Suppliers
        "pages.suppliers.table.delete": [Role.ADMIN, Role.EMPLOYEE],
        "pages.suppliers.buttons.new": [Role.ADMIN, Role.EMPLOYEE],
    }
);

export function isAllowed(action: string, currentAccount?: AccountDto): boolean {
    if(!currentAccount) {
        return false;
    }

    const allowedRoles = actionRolesMap.get(action);
    if (!allowedRoles) {
        return false;
    }

    return allowedRoles.some((r) => currentAccount?.roles?.includes(r))
}