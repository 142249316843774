import React, { PropsWithChildren, ReactElement } from "react";
import { createStyles, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DetailsSectionHeader } from "../DetailsSectionHeader";

const useStyles = makeStyles(() =>
  createStyles({
    formGrid: {
      boxSizing: "border-box",
      paddingTop: ".5em",
    },
    gridItem: {
      marginTop: ".2em",
    },
  })
);

export function FormGridContainer(props: PropsWithChildren<unknown>): ReactElement {
  const classes = useStyles();

  return (
    <Grid container spacing={4} justifyContent={"flex-start"} className={classes.formGrid}>
      {props.children}
    </Grid>
  );
}

export function FormGridItem(props: PropsWithChildren<{ fullSize?: boolean }>): ReactElement {
  const classes = useStyles();

  return (
    <Grid item xs={12} lg={12} xl={props.fullSize ? 12 : 6} className={classes.gridItem}>
      {props.children}
    </Grid>
  );
}

export function FormGridSectionHeader(props: { header: string }): ReactElement {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.gridItem}>
      <DetailsSectionHeader header={props.header} />
    </Grid>
  );
}
