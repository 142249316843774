import { gql } from "@apollo/client";
import { accountFragment, customerFragment } from "./customerFragment";

export const CUSTOMER_SELECT_QUERY = gql`
  query customerSelectQuery {
    customers {
      id
      firstName
      lastName
      company
      customerNumber
      streetAddress
      zip
      city
    }
  }
`;

export const CREATE_CUSTOMER_MUTATION = gql`
  mutation addCustomerMutation($input: AddCustomerInput!) {
    addCustomer(input: $input) {
      customer {
        id
      }
      emailAlreadyInUse
    }
  }
`;

export const CUSTOMER_REGISTRATION_QUERY = gql`
  query customerRegistrationQuery($input: CustomerRegistrationArguments!) {
    customerRegistration(arguments: $input) {
      registrationCode
      registrationLink
    }
  }
`;

export const UPDATE_CUSTOMER_MUTATION = gql`
  ${customerFragment}
  mutation updateCustomerMutation($id: ID!, $customer: CustomerInput!) {
    updateCustomer(input: { customerId: $id, customer: $customer }) {
      customer {
        ...CustomerData
      }
    }
  }
`;

export const CUSTOMERS_AFTER_UPDATE_QUERY = gql`
  query customersAfterUpdate {
    customers {
      id
    }
  }
`;

export const DELETE_CUSTOMER_MUTATION = gql`
  mutation deleteCustomerMutation($id: ID!) {
    deleteCustomer(input: { customerId: $id }) {
      customerId
    }
  }
`;

export const ASSET_NUMBER_PER_CUSTOMER_QUERY = gql`
  query assetNumberQuery($id: ID!) {
    assets(filter: { customerId: $id }) {
      id
    }
  }
`;

export const CUSTOMER_DETAIL_QUERY = gql`
  ${accountFragment}
  query customerByIdQuery($id: ID!) {
    customer(id: $id) {
      id
      email
      firstName
      lastName
      customerNumber
      streetAddress
      zip
      city
      company
      telephoneNumber
      accounts {
        ...Account
      }
      responsibleTeam
      registrationComplete
    }
  }
`;

export const CUSTOMERS_QUERY = gql`
  ${customerFragment}
  query customersInfoQuery {
    customers {
      ...CustomerData
    }
  }
`;
