import React, { useContext, useMemo, useState } from "react";
import { BasePage } from "../pageFragments/BasePage";
import { usePrefixedTranslation } from "../../util/usePrefixedTranslation";
import { TableWrapperPaper } from "../pageFragments/util/TableWrapperPaper";
import { manufacturersQuery } from "./api/__generated__/manufacturersQuery";
import { useMutation, useQuery } from "@apollo/client";
import { BasePageWithLoadingIndicator } from "../../components/BasePageWithLoadingIndicator";
import { ManufacturerTable } from "./ManufacturerTable";
import { TableHeader } from "../../components/TableHeader";
import { SearchField } from "../../components/SearchField";
import { CreateManufacturerModal } from "./createManufacturer/CreateManufacturerModal";
import { EditManufacturerModal } from "./editManufacturer/EditManufacturerModal";

import { DELETE_MANUFACTURER_MUTATION, MANUFACTURERS_QUERY } from "./api/manufacturerQueriesAndMutations";
import { SnackContext } from "../../routing/idfyRouter";
import {
  deleteManufacturerMutation,
  deleteManufacturerMutationVariables
} from "./api/__generated__/deleteManufacturerMutation";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    additionalContentContainer: {
      width: "33%",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginTop: theme.spacing(1),
      },
    },
  })
);

export const ManufacturersPage: React.FunctionComponent = () => {
  const classes = useStyles();
  const { pt } = usePrefixedTranslation("pages.manufacturers.");
  const { data } = useQuery<manufacturersQuery>(MANUFACTURERS_QUERY, { fetchPolicy: "network-only" });
  const snackContext = useContext(SnackContext);

  const [searchText, setSearchText] = useState("");
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState({
    "id": "",
    "isOpen": false,
    "manufacturerName": ""
  })

  const filteredManufacturers = useMemo(() => {
    if (searchText.length === 0) {
      return data?.manufacturers;
    }
    return data?.manufacturers.filter((asset) => {
      return asset.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
    });
  }, [searchText, data]);

  const [deleteManufacturer] = useMutation<deleteManufacturerMutation, deleteManufacturerMutationVariables>(
    DELETE_MANUFACTURER_MUTATION,
    { refetchQueries: [{ query: MANUFACTURERS_QUERY }] }
  );

  const handleManufacturerDelete = (id: string, name: string) => {
    void deleteManufacturer({
      variables: { id: id },
    }).then((result) => {
      snackContext.setSnackOpen(true);
      const snackText = result.data?.deleteManufacturer.deletionSuccessful
        ? pt("manufacturer-deleted", { manufacturer: name })
        : pt("manufacturer-could-not-be-deleted", { manufacturer: name });
      snackContext.setSnackText(snackText);
    });
  };

  if (!data) {
    return <BasePageWithLoadingIndicator headerText={pt("header")} />;
  }

  return (
    <>
      <BasePage headerText={pt("header")}>
        <TableHeader
          title={pt("header")}
          buttonText={pt("buttons.new")}
          enabled={true}
          onButtonClick={() => setCreateModalOpen(true)}
          additionalContent={
            <div className={classes.additionalContentContainer}>
              <SearchField placeholder={pt("search-manufacturers")} onChange={(e) => setSearchText(e.target.value)} />
            </div>
          }
        />
        <TableWrapperPaper fullHeight={filteredManufacturers?.length === 0}>
          <ManufacturerTable
            manufacturers={filteredManufacturers || []}
            onEditClick={(id: string, name: string) => setEditModalOpen({"id": id, "isOpen": true, "manufacturerName": name})}
            onDeleteClick={(id: string, name: string) => handleManufacturerDelete(id, name)}
          />
        </TableWrapperPaper>
      </BasePage>


      <CreateManufacturerModal
        open={createModalOpen}
        handleClose={(manufacturerCreated) => {
          setCreateModalOpen(false);
          snackContext.setSnackOpen(manufacturerCreated);
          snackContext.setSnackText(pt("manufacturer-created"));
        }}
      />

      <EditManufacturerModal
        id={editModalOpen.id}
        currentName={editModalOpen.manufacturerName}
        open={editModalOpen.isOpen}
        handleClose={(manufacturerUpdated) => {
          setEditModalOpen({
            "id": "",
            "isOpen": false,
            "manufacturerName": ""
          });
          snackContext.setSnackOpen(manufacturerUpdated);
          snackContext.setSnackText(pt("manufacturer-saved"));
        }}
      />
    </>
  );
};
