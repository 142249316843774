import React, { ReactElement } from "react";
import { useQuery } from "@apollo/client";
import { suppliersQuery } from "./api/__generated__/suppliersQuery";
import { SUPPLIERS_QUERY } from "./api/suppplierQueriesAndMutations";
import { Controller, useFormContext } from "react-hook-form";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";

export type SupplierFieldProps = {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
};

export function SupplierField(props: SupplierFieldProps): ReactElement {
  const { data: data } = useQuery<suppliersQuery>(SUPPLIERS_QUERY, {
    fetchPolicy: "no-cache",
  });
  const { control, errors } = useFormContext();

  if (!data) {
    return <></>;
  }

  return (
    <Controller
      control={control}
      name={props.name}
      render={
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ({ onChange, onBlur, value }) => (
          <FormControl
            size="small"
            fullWidth
            color={"secondary"}
            error={!!errors[props.name]}
            disabled={props.disabled}
          >
            <InputLabel variant={"outlined"} id={"supplier-select-label"} required={props.required}>
              {props.label}
            </InputLabel>
            <Select
              labelId="supplier-select-label"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              variant={"outlined"}
              label={props.label}
              required={props.required}
            >
              {data?.suppliers.map((supplier, i) => (
                <MenuItem value={supplier.id} key={i}>
                  <div>
                    <b>{supplier.name}</b>
                    <br />
                    <span>
                      {supplier.streetAddress} {supplier.city} {supplier.zip}
                    </span>
                  </div>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors[props.name]?.message}</FormHelperText>
          </FormControl>
        )
      }
    />
  );
}
