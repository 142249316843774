import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import InfoIcon from "@material-ui/icons/Info";
import StarsIcon from "@material-ui/icons/Stars";

import { DetailsMenu, DetailsMenuSubPage } from "../../../components/DetailsMenu";
import { routes } from "../../../routing/routes";
import BlueprintIcon from "./../../../components/icons/BlueprintIcon";

export const BlueprintMenu: React.FunctionComponent<{ name: string }> = (props) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const menuButtons: DetailsMenuSubPage[] = [
    {
      label: t("pages.blueprint-details.details-menu.information"),
      icon: InfoIcon,
      path: routes.infoSuffix,
    },
    {
      label: t("pages.blueprint-details.details-menu.assets"),
      icon: StarsIcon,
      path: routes.assetsSuffix,
    },
  ];

  return (
    <DetailsMenu
      title={props.name}
      icon={(className) => <BlueprintIcon className={className} />}
      subPages={menuButtons}
      subPageActiveCheck={(button) => window.location.pathname === routes.blueprints + routes.details(id) + button.path}
      routeFunction={(s: string) => routes.blueprints + routes.details(s)}
    />
  );
};
