import { format } from "date-fns";

import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import AddIcon from "@material-ui/icons/Add";
import PersonIcon from "@material-ui/icons/Person";

import PendingActionsIcon from "../../../../../components/icons/PendingActions";
import { usePrefixedTranslation } from "../../../../../util/usePrefixedTranslation";
import { AssetProtocolEntry, ProtocolEntryFieldProps } from "../AssetProtocolEntry";
import { EventTextBox } from "./EventTextBox";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import FolderIcon from "@material-ui/icons/Folder";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";

export interface AssetCreatedEventEntryProps {
  dueDate: Date;
  time: Date;
  messageToCustomer: string | null;
  user: {
    firstName: string;
    lastName: string;
  };
  isNewAsset: boolean;
  isAlreadyInStock: boolean | null;
}

export const AssetCreatedEventEntry: FC<AssetCreatedEventEntryProps> = (props) => {
  const { t } = useTranslation();
  const { pt } = usePrefixedTranslation("pages.asset-details.protocol.asset-created.");

  const protocolFields: Array<ProtocolEntryFieldProps> = [
    {
      text: pt("due-date", { dueDate: format(props.dueDate, t("dates.date")) }),
      icon: <PendingActionsIcon />,
    },
    {
      text: `${props.user.lastName}, ${props.user.firstName}`,
      tooltip: pt("tooltip-user"),
      icon: <PersonIcon />,
    },
    {
      text: props.isNewAsset ? pt("new-object") : pt("old-object"),
      icon: <HourglassEmptyIcon />,
    },
  ];
  if (props.isAlreadyInStock !== null) {
    protocolFields.push({
      text: props.isAlreadyInStock ? pt("in-stock") : pt("added-to-stock"),
      icon: props.isAlreadyInStock ? <FolderIcon /> : <CreateNewFolderIcon />,
    });
  }

  return (
    <AssetProtocolEntry
      icon={<AddIcon />}
      title={pt("title")}
      time={props.time}
      fields={protocolFields}
      body={
        props.messageToCustomer ? (
          <EventTextBox title={pt("message-to-customer")} text={props.messageToCustomer} />
        ) : undefined
      }
    />
  );
};
