import { TFunction } from "i18next";
import * as yup from "yup";

import { idfyTheme } from "./IdfyTheme";
import { RequiredStringSchema } from "yup/lib/string";

export const DRAWER_WIDTH = "14em";
export const COLLAPSED_DRAWER_WIDTH = idfyTheme.spacing(11);
export const APPBAR_HEIGHT = idfyTheme.spacing(8);
export const MENU_WIDTH = 250;

// TODO: Move validations into validations.ts file

export const FIELD_VALIDATION_TYPE_STRING = (
  t: TFunction,
  minMessage?: string,
  requiredMessage?: string
): RequiredStringSchema<string | undefined> => {
  return yup
    .string()
    .trim(t("validations.trim"))
    .min(1, minMessage ? minMessage : t("validations.min"))
    .max(255, t("validations.max"))
    .required(requiredMessage ? requiredMessage : t("validations.required"));
};

export const FIELD_VALIDATION_TYPE_EMAIL = (t: TFunction): yup.StringSchema =>
  yup
    .string()
    .strict(true)
    .trim(t("validations.trim"))
    .min(1, t("validations.min"))
    .required(t("validations.required"))
    .email(t("validations.email"));

export const FIELD_VALIDATION_TYPE_BOOLEAN = (t: TFunction, requiredMessage?: string): yup.BooleanSchema => {
  return yup
    .boolean()
    .strict(true)
    .required(requiredMessage ? requiredMessage : t("validations.required"));
};

export const FIELD_VALIDATION_TYPE_DATE = (t: TFunction, requiredMessage?: string): yup.DateSchema => {
  return yup
    .date()
    .typeError(requiredMessage ? requiredMessage : t("validations.date"))
    .required(requiredMessage ? requiredMessage : t("validations.required"));
};
