import React from "react";
import { useHistory } from "react-router";

import { Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { idfyTheme } from "../../../util/IdfyTheme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badge: {
      width: "100%",
      margin: `${theme.spacing(1)}px 0`,
      [theme.breakpoints.down("sm")]: {
        width: "auto",
      },
    },
    subPageButton: {
      width: "100%",
      background: "#DDDBD7",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      padding: theme.spacing(2),
      color: idfyTheme.typography.button.color,
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        borderRadius: 0,
        borderLeft: "#bbb solid 2px",
      },
      "&:hover ": {
        backgroundColor: "#B8B6B3dd",
      },
    },
    subPageButtonActive: {
      background: idfyTheme.palette.secondary.main,
      color: idfyTheme.palette.background.default,
      "&:hover ": {
        backgroundColor: "#264653cc",
      },
    },
  })
);

export const SubPageButton: React.FunctionComponent<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  label: string;
  id: string;
  subPath: string;
  active: boolean;
  routeFunction: (id: string) => string;
  badge?: boolean;
  extra?: string;
}> = (props) => {
  const classes = useStyles();

  const history = useHistory();

  return (
    <Button
      startIcon={<props.icon />}
      endIcon={
        <span style={{ fontSize: "1em", display: "flex", alignItems: "center" }}>
          {props.badge && (
            <span
              style={{
                padding: `0 ${idfyTheme.spacing(1.5)}px`,
                borderRadius: "6px",
                background: idfyTheme.palette.primary.main,
                color: `${idfyTheme.palette.text.primary}`,
              }}
            >
              1
            </span>
          )}
          {props.extra}
          <ArrowForwardIosIcon style={{ fontSize: "1em" }} />
        </span>
      }
      className={
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        classes.subPageButton + " " + (props.active ? classes.subPageButtonActive : null)
      }
      onClick={() => history.push(props.routeFunction(props.id) + props.subPath)}
      style={{ justifyContent: "space-between", margin: "8px 0" }}
    >
      {props.label}
    </Button>
  );
};
