import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import UndoIcon from "@material-ui/icons/Undo";
import { useQuery } from "@apollo/client";
import { suppliersQuery, suppliersQuery_suppliers } from "../../../../suppliers/api/__generated__/suppliersQuery";
import { SUPPLIERS_QUERY } from "../../../../suppliers/api/suppplierQueriesAndMutations";
import { useTranslation } from "react-i18next";
import { assetDetailQuery_asset_supplier } from "../../../api/__generated__/assetDetailQuery";

export const FormattedSupplierInfo: React.FunctionComponent<{
  supplier: suppliersQuery_suppliers | assetDetailQuery_asset_supplier;
}> = (props) => {
  return (
    <div>
      <b> {props.supplier.name}</b>
      <br />
      <span>
        {props.supplier.streetAddress} {props.supplier.city} {props.supplier.zip}
      </span>
    </div>
  );
};

export const CreateAssetInfoSupplierFields: React.FunctionComponent<{
  isNewDisabled: boolean;
}> = (props) => {
  const { t } = useTranslation();
  const { control, errors, watch } = useFormContext();
  const suppliersData = useQuery<suppliersQuery>(SUPPLIERS_QUERY).data;
  const showSupplierFields = watch("showSupplierFields");

  useEffect(() => {
    // We have no customerId or useExistingContract field => we must register it manually
    control.register("showSupplierFields");
  }, []);

  return (
    <>
      {showSupplierFields ? (
        <>
          <Grid item xs={12}>
            <Controller
              control={control}
              as={TextField}
              fullWidth
              variant="outlined"
              size="small"
              name="newSupplier.name"
              label={t("pages.assets.modals.create-asset.fields.supplier.name")}
              required
              color="secondary"
              helperText={errors.newSupplier?.name?.message}
              error={!!errors.newSupplier?.name}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              as={TextField}
              fullWidth
              variant="outlined"
              size="small"
              name="newSupplier.streetAddress"
              label={t("pages.assets.modals.create-asset.fields.supplier.address")}
              required
              color="secondary"
              helperText={errors.newSupplier?.streetAddress?.message}
              error={!!errors.newSupplier?.streetAddress}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              as={TextField}
              fullWidth
              variant="outlined"
              size="small"
              name="newSupplier.zip"
              label={t("pages.assets.modals.create-asset.fields.supplier.zip")}
              required
              color="secondary"
              helperText={errors.newSupplier?.zip?.message}
              error={!!errors.newSupplier?.zip}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              as={TextField}
              fullWidth
              variant="outlined"
              size="small"
              name="newSupplier.city"
              label={t("pages.assets.modals.create-asset.fields.supplier.city")}
              required
              color="secondary"
              helperText={errors.newSupplier?.city?.message}
              error={!!errors.newSupplier?.city}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <FormControl size="small" fullWidth variant={"outlined"} color={"secondary"} error={!!errors.supplierId}>
            <InputLabel required={!props.isNewDisabled}>{t("pages.assets.modals.create-asset.supplier")}</InputLabel>
            <Controller
              control={control}
              as={Select}
              fullWidth
              name={"supplierId"}
              label={t("pages.assets.modals.create-asset.fields.blueprint")}
            >
              {suppliersData?.suppliers.map((supplier, i) => (
                <MenuItem value={supplier.id} key={i}>
                  <FormattedSupplierInfo supplier={supplier} />
                </MenuItem>
              ))}
            </Controller>
            <FormHelperText>{errors.supplierId?.message}</FormHelperText>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12} container justifyContent={"flex-end"} style={{ display: !showSupplierFields ? "none" : "" }}>
        <Controller
          name="showSupplierFields"
          control={control}
          render={({ onChange }) => {
            return (
              <Button
                startIcon={<UndoIcon />}
                variant={"outlined"}
                onClick={() => {
                  onChange(false);
                }}
              >
                {t("pages.assets.modals.create-asset.buttons.rollback-to-existing-supplier")}
              </Button>
            );
          }}
        />
      </Grid>
      <Grid item xs={12} container justifyContent={"flex-end"} style={{ display: showSupplierFields ? "none" : "" }}>
        <Controller
          name="showSupplierFields"
          control={control}
          render={({ onChange }) => {
            return (
              <Button
                variant={"outlined"}
                onClick={() => {
                  onChange(true);
                }}
              >
                {t("pages.assets.modals.create-asset.buttons.add-new-supplier")}
              </Button>
            );
          }}
        />
      </Grid>
    </>
  );
};
