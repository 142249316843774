import { formatDistance, formatISO } from "date-fns";

export function formatDateString(date: string | undefined | null, defaultString?: string): string {
  if (!date) {
    return defaultString || "";
  }
  return formatDate(new Date(date));
}

export function formatDateShortString(date: string | undefined | null, defaultString?: string): string {
  if (!date) {
    return defaultString || "";
  }
  return formatDateShort(new Date(date));
}

export function formatDateShort(date: Date, locale?: Locale): string {
  return date.toLocaleString(locale?.code || "de-DE", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

export function formatDate(date: Date): string {
  return date.toLocaleString("de-DE", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function formatDateToIsoDate(date: Date): string {
  return formatISO(date, { representation: "date" });
}

export function formatDatePeriod(fromDate: string, toDate: string, locale: Locale): string {
  return formatDistance(new Date(fromDate), new Date(toDate), { locale: locale });
}
