import React, { ReactElement, useState } from "react";
import { PopUpDialog } from "../../../pageFragments/util/PopUpDialog";
import { usePrefixedTranslation } from "../../../../util/usePrefixedTranslation";
import { TextField } from "@material-ui/core";
import { FormGridContainer, FormGridItem } from "../../../../components/forms/FormGrid";
import addDays from "date-fns/addDays";
import { DateWithFixedDatePeriodCombo } from "../../../../components/inputs/DateWithFixedDatePeriodCombo";

export function ExtendDueDateDialog(props: {
  onConfirm: (dueDate: Date | undefined, messageToCustomer: string | undefined) => void;
  onClose: () => void;
  open: boolean;
}): ReactElement {
  const { pt } = usePrefixedTranslation("pages.asset-details.inspection-overview.extend-due-date-dialog.");
  const [messageToCustomer, setMessageToCustomer] = useState<string>();
  const [dueDate, setDueDate] = useState<Date | null>(addDays(new Date(), 28));

  return (
    <PopUpDialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      handleConfirm={() => {
        props.onConfirm(dueDate || undefined, messageToCustomer);
      }}
      header={pt("header")}
      confirm={pt("confirm")}
      deny={pt("deny")}
      handleDeny={() => {
        props.onClose();
      }}
    >
      <FormGridContainer>
        <DateWithFixedDatePeriodCombo
          value={dueDate}
          setValue={setDueDate}
          fixedDateSelectionLabel={pt("new-due-date")}
          fixedDateValue={28}
          fixedDateLabel={pt("28-days")}
        />
        <FormGridItem fullSize={true}>
          <TextField
            variant={"outlined"}
            multiline
            fullWidth={true}
            value={messageToCustomer}
            onChange={(text) => setMessageToCustomer(text.target.value)}
            minRows={4}
            maxRows={4}
            aria-label="message to customer"
            placeholder={pt("message-to-customer-label")}
          />
        </FormGridItem>
      </FormGridContainer>
    </PopUpDialog>
  );
}
