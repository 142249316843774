import classNames from "classnames";

import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { createStyles, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { InfoOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    container: {
      margin: theme.spacing(2),
    },
    icon: {
      fontSize: 64,
      fill: theme.palette.secondary.main,
    },
    title: {
      fontSize: 24,
    },
    subTitle: {
      marginTop: 8,
      fontSize: 16,
    },
  })
);

export interface NoDataPlaceholderProps {
  title?: string;
  subTitle?: string;
  className?: string;
  children?: ReactNode;
}

export const NoDataPlaceholder: React.FunctionComponent<NoDataPlaceholderProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const title = props.title ?? t("no-data-placeholder.title");
  const subTitle = props.subTitle ?? t("no-data-placeholder.subtitle");

  return (
    <div className={classNames(classes.root, props.className)}>
      <div className={classes.container}>
        <InfoOutlined className={classes.icon} />
        <div>
          <Typography className={classes.title} variant="h1">
            {title}
          </Typography>
        </div>
        <div>
          <Typography className={classes.subTitle} variant="body2">
            {subTitle}
          </Typography>
        </div>
        <div>{props.children}</div>
      </div>
    </div>
  );
};
