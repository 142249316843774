import { gql } from "@apollo/client";
import { acceptanceDeclarationFragment } from "../../../api/acceptanceDeclarationFragment";
import {
  assetFragment,
  offsiteLocationFragment,
  smallSubAssetFragment,
  subAssetFragment,
} from "../../../api/assetFragments";
import { manufacturerFragment } from "../../../../manufacturers/api/manufacturerFragment";
import { featureInspectionFragment, inspectionFragment } from "./inspectionFragments";
import { contractFragment } from "../../../../contracts/api/contractFragments";
import { bluePrintFragment, featureBlueprintWithImagesFragment } from "../../../../blueprints/api/blueprintFragments";

export const INSPECTION_QUERY = gql`
  ${assetFragment}
  ${inspectionFragment}
  ${manufacturerFragment}
  ${offsiteLocationFragment}
  ${subAssetFragment}
  ${bluePrintFragment}
  ${featureBlueprintWithImagesFragment}
  ${featureInspectionFragment}
  ${smallSubAssetFragment}
  ${acceptanceDeclarationFragment}
  ${contractFragment}
  query inspectionQuery($id: ID!) {
    asset(id: $id) {
      ...AssetData
      manufacturer {
        ...Manufacturer
      }
      offsiteLocation {
        ...OffsiteLocation
      }
      blueprint {
        ...Blueprint
        featureBlueprints {
          ...FeatureBlueprintWithImages
        }
      }
      subAssets {
        ...SubAsset
      }
      inspections {
        ...Inspection
        featureInspections {
          ...FeatureInspection
        }
        subInspections {
          subAsset {
            ...SmallSubAsset
          }
          featureInspections {
            ...FeatureInspection
          }
        }
        contract {
          ...Contract
        }
      }
      acceptanceDeclarations {
        ...AcceptanceDeclaration
      }
    }
  }
`;

export const INITIATE_INSPECTION_MUTATION = gql`
  mutation initiateInspectionMutation($assetId: ID!, $messageToCustomer: String, $date: String!) {
    setInspection(
      input: { assetId: $assetId, inspectorName: null, dueDate: $date, messageToCustomer: $messageToCustomer }
    ) {
      inspection {
        id
        status
      }
    }
  }
`;

export const UPDATE_INSPECTION_MUTATION = gql`
  mutation updateInspectionMutation($input: UpdateInspectionInput!) {
    updateInspection(input: $input) {
      inspection {
        id
      }
    }
  }
`;

export const SET_INSPECTION_MANDATORY_ASSET_FIELDS_MUTATION = gql`
  mutation setInspectionMandatoryFieldsMutation($input: InspectionMandatoryAssetFieldsInput!) {
    setInspectionMandatoryFields(input: $input) {
      assetId
      serialNumber
      contractEndOfTerm
    }
  }
`;

export const GET_MATCHING_FEATURES_QUERY = gql`
  query getMatchingFeaturesQuery($featureId: ID!, $assetId: ID!) {
    getMatchingFeatureInspections(input: { featureInspectionId: $featureId, assetId: $assetId }) {
      featureBlueprint {
        id
        title
        description
        type
        images {
          type
          data
          creationDate
          location {
            latitude
            longitude
          }
          appCreationDateTime
        }
      }
      featureInspections {
        featureInspection {
          id
          tag
          images {
            type
            data
            creationDate
            location {
              latitude
              longitude
            }
            appCreationDateTime
          }
          meter {
            hours
            kilometers
          }
          location {
            latitude
            longitude
          }
        }
        date
      }
    }
  }
`;
