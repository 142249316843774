import classNames from "classnames";
import { TFunction } from "i18next";

import React from "react";
import { useTranslation } from "react-i18next";

import { Chip, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";

import { AssetStatus, BlueprintStatus, InspectionStatus, InspectionType } from "../../../__generated__/globalTypes";
import { formatDateString } from "../../../util/dateTime/formatDate";
import { idfyTheme } from "../../../util/IdfyTheme";
import { getDaysTillDue } from "../../../util/dateTime/dateCalculations";

export enum DueDateStatus {
  OK,
  WARNING,
  ERROR,
}

export const getDueDateStatus = (status: InspectionStatus | BlueprintStatus, daysTillDue: number): DueDateStatus => {
  if (status === InspectionStatus.REJECTED || (status === InspectionStatus.PENDING && daysTillDue && daysTillDue < 0)) {
    return DueDateStatus.ERROR;
  }
  if (status === InspectionStatus.WAITING_FOR_APPROVAL || (status === InspectionStatus.PENDING && daysTillDue <= 3)) {
    return DueDateStatus.WARNING;
  }
  return DueDateStatus.OK;
};

export const inspectionStatusText = (
  inspection: {
    status: InspectionStatus;
    dueDate: string | null;
  },
  t: TFunction
): string => {
  const date = formatDateString(inspection.dueDate || "");

  if (
    inspection.status === InspectionStatus.WAITING_FOR_APPROVAL ||
    inspection.status === InspectionStatus.REJECTED ||
    inspection.status === InspectionStatus.APPROVED
  ) {
    return `${t("pages.asset-details.inspection-status.originDueDate")} ${date} `;
  }

  const daysTillDue = getDaysTillDue(inspection.dueDate);
  const options = { absoluteDaysTillDue: Math.abs(daysTillDue) };
  let statusText = "";

  if (daysTillDue >= 2 || daysTillDue <= -2) {
    statusText += t("pages.asset-details.inspection-status.deadlinePlural", options);
  } else if (daysTillDue === 1 || daysTillDue === -1) {
    statusText += t("pages.asset-details.inspection-status.deadlineSingular", options);
  } else {
    // Deadline is today (daysTillDue === 0)
    statusText += t("pages.asset-details.inspection-status.deadlineToday", options);
  }

  if (daysTillDue < 0) {
    statusText += ` ${t("pages.asset-details.inspection-status.overdue")}`;
  }

  statusText += ` (${date})`;
  return statusText;
};

export interface InspectionProperty {
  dueDate: string | null;
  status: InspectionStatus;
  latestSubmissionDate: string | null;
  creationDate: string;
  inspectionType: InspectionType;
}

function getApprovedInspection(inspections: InspectionProperty[]): InspectionProperty {
  const approvedInspections = inspections.filter((inspection) => inspection.status === InspectionStatus.APPROVED);
  return approvedInspections.length > 0 ? approvedInspections.slice().pop()! : inspections.slice().pop()!;
}

export const AssetStatusCell: React.FunctionComponent<{
  asset: {
    assetStatus: AssetStatus;
    inspections: InspectionProperty[];
  };
  mobile: boolean;
}> = (props) => {
  const { t } = useTranslation();

  switch (props.asset.assetStatus) {
    case AssetStatus.BLUEPRINT_PENDING:
      return (
        <AssetStatusBlock
          mobile={props.mobile}
          chipColor={"default"}
          chipLabel={t("pages.asset-details.asset-status.blueprint-pending-label")}
          text={t("pages.asset-details.asset-status.blueprint-pending-text")}
        />
      );
    case AssetStatus.BLUEPRINT_WAITING_FOR_APPROVAL:
      return (
        <AssetStatusBlock
          mobile={props.mobile}
          chipColor={"primary"}
          chipLabel={t("pages.asset-details.asset-status.bp-waiting-for-approval-label")}
          text={t("pages.asset-details.asset-status.bp-waiting-for-approval-text", {
            date: formatDateString(props.asset.inspections.slice().reverse().pop()?.latestSubmissionDate || ""),
          })}
        />
      );
    case AssetStatus.BLUEPRINT_REJECTED:
      return (
        <AssetStatusBlock
          mobile={props.mobile}
          chipColor={"default"}
          chipLabel={t("pages.asset-details.asset-status.blueprint-rejected-label")}
          text={t("pages.asset-details.asset-status.blueprint-rejected-text")}
          dueDateStatus={DueDateStatus.ERROR}
        />
      );
    case AssetStatus.INSPECTION_PENDING:
      return (
        <AssetStatusBlock
          mobile={props.mobile}
          chipColor={"default"}
          chipLabel={t(
            `pages.asset-details.asset-status.inspection-pending-${props.asset.inspections
              .slice()
              .pop()!
              .inspectionType.toLowerCase()}`
          )}
          text={inspectionStatusText(props.asset.inspections.slice().pop()!, t)}
          dueDateStatus={getDueDateStatus(
            props.asset.inspections.slice().pop()!.status,
            getDaysTillDue(props.asset.inspections.slice().pop()!.dueDate)
          )}
        />
      );
    case AssetStatus.INSPECTION_WAITING_FOR_APPROVAL:
      return (
        <AssetStatusBlock
          mobile={props.mobile}
          chipColor={"primary"}
          chipLabel={t("pages.asset-details.asset-status.inspection-waiting-for-approval-label")}
          text={t(
            `pages.asset-details.asset-status.${props.asset.inspections
              .slice()
              .pop()!
              .inspectionType.toLowerCase()}-waiting-for-approval-text`,
            { date: formatDateString(props.asset.inspections.slice().pop()!.latestSubmissionDate || "") } || ""
          )}
        />
      );
    case AssetStatus.INSPECTION_REJECTED:
      return (
        <AssetStatusBlock
          mobile={props.mobile}
          chipColor={"secondary"}
          chipLabel={t("pages.asset-details.asset-status.inspection-rejected-label")}
          text={inspectionStatusText(props.asset.inspections.slice().pop()!, t)}
          dueDateStatus={getDueDateStatus(
            props.asset.inspections.slice().pop()!.status,
            getDaysTillDue(props.asset.inspections.slice().pop()!.dueDate)
          )}
        />
      );
    case AssetStatus.APPROVED:
      return (
        <AssetStatusBlock
          mobile={props.mobile}
          chipColor={"default"}
          chipLabel={`${formatDateString(getApprovedInspection(props.asset.inspections).latestSubmissionDate || "")}`}
          variant={"outlined"}
          text={t(
            `pages.asset-details.asset-status.${getApprovedInspection(
              props.asset.inspections
            ).inspectionType.toLowerCase()}-approved-text`
          )}
          dueDateStatus={getDueDateStatus(
            getApprovedInspection(props.asset.inspections).status,
            getDaysTillDue(getApprovedInspection(props.asset.inspections).dueDate)
          )}
        />
      );
    case AssetStatus.OUT_OF_CONTRACT:
      return (
        <AssetStatusBlock
          mobile={props.mobile}
          chipColor={"default"}
          chipLabel={t("pages.asset-details.asset-status.out-of-contract-label")}
        />
      );
    case AssetStatus.UNCHECKED:
      return (
        <AssetStatusBlock
          mobile={props.mobile}
          chipColor={"default"}
          chipLabel={t("pages.asset-details.asset-status.unchecked-label")}
        />
      );
    default:
      return <h1>Error</h1>;
  }
};

export const StatusChip: React.FunctionComponent<{
  status: BlueprintStatus | InspectionStatus;
  disabled?: boolean;
}> = (props) => {
  const { t } = useTranslation();

  switch (props.status) {
    case BlueprintStatus.APPROVED || InspectionStatus.APPROVED:
      return (
        <AssetStatusBlock
          chipColor={"secondary"}
          variant={"outlined"}
          chipLabel={t("status.approved")}
          disabled={props.disabled}
          mobile
        />
      );
    case BlueprintStatus.PENDING || InspectionStatus.PENDING:
      return (
        <AssetStatusBlock chipColor={"default"} chipLabel={t("status.pending")} disabled={props.disabled} mobile />
      );
    case BlueprintStatus.REJECTED || InspectionStatus.REJECTED:
      return (
        <AssetStatusBlock chipColor={"default"} chipLabel={t("status.rejected")} disabled={props.disabled} mobile />
      );
    case BlueprintStatus.WAITING_FOR_APPROVAL || InspectionStatus.WAITING_FOR_APPROVAL:
      return (
        <AssetStatusBlock
          chipColor={"primary"}
          chipLabel={t("status.waiting-for-approval")}
          disabled={props.disabled}
          mobile
        />
      );
    case InspectionStatus.REVOKED:
      return (
        <AssetStatusBlock chipColor={"default"} chipLabel={t("status.revoked")} disabled={props.disabled} mobile />
      );
    default:
      return <h1>Error</h1>;
  }
};

const useAssetStatusBlockStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      verticalAlign: "middle",
      display: "inline-flex",
      marginTop: idfyTheme.spacing(1),
      fontSize: "0.9em",
      alignItems: "center",
    },
    icon: {
      marginRight: "5px",
    },
    iconWarning: {
      color: theme.palette.warning.main,
    },
    iconError: {
      color: theme.palette.error.main,
    },
  })
);

const AssetStatusBlock: React.FunctionComponent<{
  mobile: boolean;
  chipColor: "primary" | "secondary" | "default" | undefined;
  variant?: "outlined";
  dueDateStatus?: DueDateStatus;
  chipLabel: string;
  text?: string;
  disabled?: boolean;
}> = (props) => {
  const classes = useAssetStatusBlockStyles();
  const iconClassNames = classNames(
    classes.icon,
    props.dueDateStatus === DueDateStatus.WARNING && classes.iconWarning,
    props.dueDateStatus === DueDateStatus.ERROR && classes.iconError
  );

  return props.mobile ? (
    <Chip
      disabled={props.disabled}
      color={props.chipColor}
      label={props.chipLabel}
      size="small"
      variant={props.variant || "default"}
      style={{ fontSize: "0.9em", fontWeight: 300 }}
    />
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Chip
        disabled={props.disabled}
        color={props.chipColor}
        label={props.chipLabel}
        size="small"
        variant={props.variant || "default"}
        style={{ fontSize: "0.9em", fontWeight: 300 }}
      />
      <Typography variant={"body2"} color={"secondary"} className={classes.typography}>
        {(props.dueDateStatus === DueDateStatus.WARNING || props.dueDateStatus === DueDateStatus.ERROR) && (
          <ErrorIcon fontSize={"small"} className={iconClassNames} />
        )}
        {props.text}
      </Typography>
    </div>
  );
};
