import React, { useEffect, useState } from "react";

import { Fab, IconButton, Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { ArrowLeftIcon } from "@material-ui/pickers/_shared/icons/ArrowLeftIcon";
import { ArrowRightIcon } from "@material-ui/pickers/_shared/icons/ArrowRightIcon";

import { idfyTheme } from "../../../util/IdfyTheme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      outline: 0,
      boxSizing: "border-box",
      padding: theme.spacing(5),
      borderRadius: "10px",
      background: idfyTheme.palette.background.paper,
      "& .CarouselItem": {
        height: "100%",
        "& div": {
          transitionDuration: 0,
        },
      },
    },
    imageWrapper: {
      display: "flex",
      justifyContent: "center",
    },
    image: {
      objectFit: "contain",
      width: "80vw",
      maxHeight: "80vh",
    },
    formHeader: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      boxSizing: "border-box",
      marginBottom: theme.spacing(3),
    },
    arrowRight: {
      position: "absolute",
      top: "50%",
      left: "100%",
      transform: "translate(-120%,-50%)",
    },
    arrowLeft: {
      position: "absolute",
      top: "50%",
      transform: "translate(20%,-50%)",
    },
  })
);

export const ImageModal: React.FunctionComponent<{
  images: { type: string; data: string }[];
  open: boolean;
  currentIndex: number;
  handleClose: (newIndex: number) => void;
  header: string;
}> = (props) => {
  const classes = useStyles();

  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    setCurrentIndex(props.currentIndex);
  }, [props.currentIndex]);

  /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  return (
    <Modal open={props.open} onClose={() => props.handleClose(currentIndex)}>
      <div className={classes.modal}>
        <div className={classes.formHeader}>
          <Typography variant={"h2"}>{props.header}</Typography>
          <IconButton onClick={() => props.handleClose(currentIndex)}>
            <ClearIcon />
          </IconButton>
        </div>
        <div style={{ position: "relative" }}>
          {props.images.length > 1 ? (
            <React.Fragment>
              <Fab
                className={classes.arrowLeft}
                size={"small"}
                onClick={() => setCurrentIndex((currentIndex - 1 + props.images.length) % props.images.length)}
              >
                <ArrowLeftIcon />
              </Fab>
              <Fab
                className={classes.arrowRight}
                size={"small"}
                onClick={() => setCurrentIndex((currentIndex + 1) % props.images.length)}
              >
                <ArrowRightIcon />
              </Fab>
            </React.Fragment>
          ) : null}
          <img
            alt=""
            className={classes.image}
            src={`data:${props.images[currentIndex]?.type};base64,${props.images[currentIndex]?.data}`}
          />
        </div>
      </div>
    </Modal>
  );
};
