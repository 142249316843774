import { gql, useQuery } from "@apollo/client";

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  createStyles,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { NoDataPlaceholder } from "../../components/NoDataPlaceholder";
import { TableHeader } from "../../components/TableHeader";
import { BreadcrumbContext } from "../../routing/idfyRouter";
import { routes } from "../../routing/routes";
import { idfyTheme, palette } from "../../util/IdfyTheme";
import { BasePage } from "../pageFragments/BasePage";
import { StatusChip } from "../pageFragments/util/StatusChip";
import {
  blueprintsQuery,
  blueprintsQuery_blueprints_assetBlueprints,
  blueprintsQuery_blueprints_subAssetBlueprints,
} from "./__generated__/blueprintsQuery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tablesWrapper: {
      width: "100%",
    },
    paper: {
      flexGrow: 1,
      overflow: "auto",
    },
    tableContainer: {
      overflow: "auto",
      "-webkit-overflow-scrolling": "touch",
      height: "100%",
    },
    tableHeadRow: {
      "& th": {
        background: theme.palette.background.paper,
        color: palette.mediumGrey,
      },
    },
  })
);

const BLUEPRINTS_QUERY = gql`
  query blueprintsQuery {
    blueprints {
      assetBlueprints {
        id
        name
        status
      }
      subAssetBlueprints {
        id
        name
        status
      }
    }
  }
`;

export const BlueprintsPage: React.FunctionComponent = () => {
  const classes = useStyles();
  const header = "Objekttypen";
  const { data } = useQuery<blueprintsQuery>(BLUEPRINTS_QUERY, {
    fetchPolicy: "network-only",
  });
  const utilContext = useContext(BreadcrumbContext);
  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));

  utilContext.breadcrumbs = [];

  return (
    <BasePage headerText={header}>
      <Grid container className={classes.tablesWrapper} spacing={mobile ? 0 : 2}>
        <Grid item xs={12} md={6}>
          <TableHeader title="Hauptobjekte" enabled={true}/>
          <Paper className={classes.paper} elevation={2}>
            {data && <BlueprintTable blueprints={data.blueprints.assetBlueprints} />}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <TableHeader title="Zubehörteile" enabled={true}/>
          <Paper className={classes.paper} elevation={2}>
            {data && <BlueprintTable blueprints={data.blueprints.subAssetBlueprints} />}
          </Paper>
        </Grid>
      </Grid>
    </BasePage>
  );
};

const BlueprintTable = (props: {
  blueprints: blueprintsQuery_blueprints_assetBlueprints[] | blueprintsQuery_blueprints_subAssetBlueprints[];
}) => {
  const classes = useStyles();
  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"), { noSsr: true });
  const history = useHistory();
  const { t } = useTranslation();

  if (props.blueprints.length <= 0) {
    return (
      <NoDataPlaceholder
        title={t("pages.blueprints.no-data-placeholder.title")}
        subTitle={t("pages.blueprints.no-data-placeholder.subtitle")}
      />
    );
  }

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader>
        {mobile ? null : (
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell>{t("pages.blueprints.headers.name").toUpperCase()}</TableCell>
              <TableCell>{t("pages.blueprints.headers.status").toUpperCase()}</TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {props.blueprints.map((blueprint, i) => (
            <TableRow
              key={i}
              hover
              onClick={() => history.push(routes.blueprints + routes.details(blueprint.id) + routes.infoSuffix)}
            >
              <TableCell>{blueprint.name}</TableCell>
              <TableCell>
                <StatusChip status={blueprint.status} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
