import { gql } from "@apollo/client";

export const pageInfoFragment = gql`
  fragment PageInfo on PageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`;
