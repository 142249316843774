import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { createStyles, debounce, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TableHeader } from "../../components/TableHeader";
import { BreadcrumbContext, SnackContext } from "../../routing/idfyRouter";
import { BasePage } from "../pageFragments/BasePage";
import { TableWrapperPaper } from "../pageFragments/util/TableWrapperPaper";
import { CreateAssetModal } from "./createAsset/CreateAssetModal";
import { SearchField } from "../../components/SearchField";
import { AssetTable } from "./table/AssetTable";
import { AssetFilterSelection, AssetFilterValue } from "./AssetFilterSelection";
import { useCurrentAccount } from '../../util/auth/useCurrentAccount';
import { getTeamFilterForUser, TeamFilter } from "../../components/team/TeamFilter";
import { ContractStatus } from "../../__generated__/globalTypes";
import { isAllowed } from "../../components/access/AccessManager";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
    },
    loadingIndicator: {
      margin: "auto",
    },
    additionalContentContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginTop: theme.spacing(1),
      },
    },
    searchFieldContainer: {
      width: "50%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    filterContainer: {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginLeft: "unset",
      },
    },
  })
);

export const AssetsPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useCurrentAccount();
  const [searchText, setSearchText] = useState("");
  const [assetStatusFilter, setAssetStatusFilter] = useState<AssetFilterValue>(AssetFilterValue.ALL);
  const [contractStatusFilter, setContractStatusFilter] = useState<ContractStatus>();
  const [teamFilter, setTeamFilter] = useState<TeamFilter>(getTeamFilterForUser(user));
  const [newModalOpen, setNewModalOpen] = useState(false);
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false);
  const utilContext = useContext(BreadcrumbContext);
  utilContext.breadcrumbs = [];
  const snackContext = useContext(SnackContext);

  const handleSearchTextChange = useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
      }, 300),
    []
  );

  return (
    <BasePage headerText={t("pages.assets.header")}>
      <TableHeader
        title={t("pages.assets.header")}
        buttonText={t("pages.assets.buttons.new")}
        onButtonClick={() => setNewModalOpen(true)}
        enabled={isAllowed("pages.assets.buttons.new", useCurrentAccount())}
        additionalContent={
          <div className={classes.additionalContentContainer}>
            <SearchField placeholder={t("pages.assets.search-objects")} onChange={handleSearchTextChange} />
            <AssetFilterSelection
              onAssetStatusFilterChange={(newValue) => setAssetStatusFilter(newValue)}
              onContractStatusFilterChange={(newValue) => setContractStatusFilter(newValue)}
              onFilterChangeForTeam={(newValue) => setTeamFilter(newValue)}
              initTeamValue={teamFilter}
            />
          </div>
        }
      />

      <TableWrapperPaper fullHeight={true}>
        <AssetTable
          searchAssetStatusQuery={assetStatusFilter}
          searchContractStatusQuery={contractStatusFilter}
          searchTeamFilter={teamFilter}
          searchText={searchText}
          toggleRefresh={toggleRefresh}
        />
      </TableWrapperPaper>

      <CreateAssetModal
        newModalOpen={newModalOpen}
        handleClose={(success: boolean) => {
          setNewModalOpen(false);
          setToggleRefresh(!toggleRefresh);
          snackContext.setSnackOpen(success);
          snackContext.setSnackText(t("pages.assets.alerts.object-created"));
        }}
      />
    </BasePage>
  );
};
