import React, { useRef, useState } from "react";

import { Button, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { idfyTheme } from "../../../util/IdfyTheme";
import { DLLogo } from "../util/Deutsche_Leasing_logo";
import { UserPopup } from "./UserPopup";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userButton: {
      border: "1px #aaa solid",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "&:hover": {
        backgroundColor: idfyTheme.palette.primary.main + "14",
      },
      [theme.breakpoints.down("sm")]: {
        border: "none",
        backgroundColor: theme.palette.background.default,
      },
    },
  })
);

export const UserButton: React.FunctionComponent = () => {
  const classes = useStyles();

  const buttonRef = useRef(null);
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <>
      <Button className={classes.userButton} onClick={() => setPopupOpen(true)} ref={buttonRef}>
        <DLLogo />
        <AccountCircleIcon style={{ fontSize: "2.3em", fill: "#999" }} />
      </Button>
      <UserPopup open={popupOpen} anchorEl={buttonRef.current} onClose={() => setPopupOpen(false)} />
    </>
  );
};
