import React from "react";

import { IconButton } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ArrowLeftIcon } from "@material-ui/pickers/_shared/icons/ArrowLeftIcon";
import { ArrowRightIcon } from "@material-ui/pickers/_shared/icons/ArrowRightIcon";

const useStyles = makeStyles(() =>
  createStyles({
    featureCounter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "nowrap",
    },
  })
);

export interface InspectionModalNavigationProps {
  hasPrevious: boolean;
  hasNext: boolean;
  text: string;
  onNext: () => void;
  onPrevious: () => void;
}

export const InspectionModalNavigation: React.FunctionComponent<InspectionModalNavigationProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.featureCounter}>
      <IconButton size="small" disabled={!props.hasPrevious} onClick={props.onPrevious}>
        <ArrowLeftIcon />
      </IconButton>
      <span>{props.text}</span>
      <IconButton size="small" disabled={!props.hasNext} onClick={props.onNext}>
        <ArrowRightIcon />
      </IconButton>
    </div>
  );
};
