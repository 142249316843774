import React, { ReactElement, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TableHeader } from "../../components/TableHeader";
import { BreadcrumbContext } from "../../routing/idfyRouter";
import { BasePage } from "../pageFragments/BasePage";
import { TableWrapperPaper } from "../pageFragments/util/TableWrapperPaper";
import { ContractTable } from "./ContractTable";
import { SearchSelectField } from "../../components/SearchSelectField";
import { getContractStatusFilterOptions } from "./ContractStatusFilter";
import { ContractFilter, ContractStatus } from "../../__generated__/globalTypes";
import {
  convertTeamFilterToTeam,
  getTeamFilterForUser,
  getTeamFilterOptions,
  TeamFilter,
} from "../../components/team/TeamFilter";
import { useCurrentAccount } from "../../util/auth/useCurrentAccount";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { SearchField } from "../../components/SearchField";
import { debounce } from "@material-ui/core";

const contractsPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    additionalContentContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginTop: theme.spacing(1),
      },
    },
  })
);

export function ContractsPage(): ReactElement {
  const { t } = useTranslation();
  const classes = contractsPageStyles();
  const contractStatusOptions = getContractStatusFilterOptions(t);
  const teamOptions = getTeamFilterOptions(t);
  const defaultTeam = getTeamFilterForUser(useCurrentAccount());
  const utilContext = useContext(BreadcrumbContext);
  utilContext.breadcrumbs = [];

  const [contractFilter, setContractFilter] = useState<ContractFilter>({
    team: convertTeamFilterToTeam(defaultTeam),
  });

  const handleSearchTextChange = useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        //setSearchText(e.target.value);
        setContractFilter((filter) => ({ ...filter, searchText: e.target.value }));
      }, 300),
    []
  );

  return (
    <BasePage headerText={t("pages.contracts.header")}>
      <TableHeader
        title={t("pages.contracts.header")}
        enabled={true}
        additionalContent={
          <div className={classes.additionalContentContainer}>
            <SearchField placeholder={t("pages.contracts.search-contracts")} onChange={handleSearchTextChange} />
            <SearchSelectField
              options={contractStatusOptions}
              onSelectChange={(value) =>
                setContractFilter((filter) => ({ ...filter, status: value as ContractStatus }))
              }
            />
            <SearchSelectField
              options={teamOptions}
              onSelectChange={(value) =>
                setContractFilter((filter) => ({ ...filter, team: convertTeamFilterToTeam(value as TeamFilter) }))
              }
              initValue={defaultTeam}
            />
          </div>
        }
      />
      <TableWrapperPaper fullHeight={true}>
        <ContractTable filter={contractFilter} />
      </TableWrapperPaper>
    </BasePage>
  );
}
