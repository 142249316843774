import React, { useState } from "react";

import { TextField } from "@material-ui/core";

import { PopUpDialog } from "../../../../pageFragments/util/PopUpDialog";
import { usePrefixedTranslation } from "../../../../../util/usePrefixedTranslation";
import { isBlankOrEmpty } from "../../../../../util/stringUtil";

export interface DenyFeatureDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (message: string) => void;
}

export const DenyFeatureDialog: React.FunctionComponent<DenyFeatureDialogProps> = (props) => {
  const { pt } = usePrefixedTranslation(
    "pages.asset-details.inspection-overview.modals.normal-inspection.deny-feature-dialog."
  );

  const [message, setMessage] = useState<string | undefined>(undefined);
  const [error, setError] = useState(false);

  const handleConfirm = () => {
    if (message === undefined || isBlankOrEmpty(message)) {
      setError(true);
    } else {
      props.onConfirm(message);
      setMessage(undefined);
    }
  };

  const handleCancel = () => {
    props.onClose();
    setError(false);
  };

  return (
    <PopUpDialog
      open={props.open}
      onClose={handleCancel}
      handleConfirm={handleConfirm}
      handleDeny={handleCancel}
      header={pt("header")}
      confirm={pt("confirm")}
      deny={pt("deny")}
      fullWidth
    >
      <TextField
        onChange={(e) => {
          setMessage(e.target.value);
          setError(false);
        }}
        value={message}
        color="secondary"
        variant="outlined"
        label={pt("text-field")}
        fullWidth
        multiline
        rows={8}
        error={error}
        helperText={error && pt("error-message")}
        required
      />
    </PopUpDialog>
  );
};
