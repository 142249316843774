import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { usePrefixedTranslation } from "../../util/usePrefixedTranslation";
import { suppliersQuery_suppliers } from "./api/__generated__/suppliersQuery";
import { NoDataPlaceholder } from "../../components/NoDataPlaceholder";
import { ContextModifyMenu } from "../pageFragments/util/ContextModifyMenu";
import { palette } from "../../util/IdfyTheme";
import { isAllowed } from "../../components/access/AccessManager";
import { useCurrentAccount } from "../../util/auth/useCurrentAccount";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      overflow: "auto",
      "-webkit-overflow-scrolling": "touch",
    },
    tableHeadRow: {
      "& th": {
        background: theme.palette.background.paper,
        color: palette.mediumGrey,
      },
    },
    tableRow: {
      padding: `${theme.spacing(4)}px 0`,
    },
    ButtonColumn: {
      MarginLeft: "200px",
    },
  })
);

export const SuppliersTable: React.FunctionComponent<{
  suppliers: suppliersQuery_suppliers[];
  onDeleteClick: (id: string, name: string) => void;
}> = (props) => {
  const classes = useStyles();
  const currentAccount = useCurrentAccount();
  const { pt } = usePrefixedTranslation("pages.suppliers.table.");

  if (props.suppliers.length <= 0) {
    return <NoDataPlaceholder title={pt("no-data-placeholder.title")} subTitle={pt("no-data-placeholder.subtitle")} />;
  }

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            <TableCell>{pt("supplier").toUpperCase()}</TableCell>
            <TableCell>{pt("streetAddress").toUpperCase()}</TableCell>
            <TableCell>{pt("zip").toUpperCase()}</TableCell>
            <TableCell>{pt("city").toUpperCase()}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.suppliers.map((supplier) => (
            <TableRow key={supplier.id} className={classes.tableRow}>
              <TableCell>
                <b>{supplier.name}</b>
              </TableCell>
              <TableCell>
                <b>{supplier.streetAddress}</b>
              </TableCell>
              <TableCell>
                <b>{supplier.zip}</b>
              </TableCell>
              <TableCell>
                <b>{supplier.city}</b>
              </TableCell>
              <TableCell align="right">
                <ContextModifyMenu
                  menuItems={[
                    {
                      enabled: isAllowed("pages.suppliers.table.delete", currentAccount),
                      buttonText: pt("delete"),
                      handleModification: () => props.onDeleteClick(supplier.id, supplier.name),
                    },
                  ]}
                ></ContextModifyMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
