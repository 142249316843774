import "./index.css";
import "./disabledTextFieldStyle.css";
import "./i18nInit";

import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import { ThemeProvider } from "@material-ui/core/styles";

import { LoadingPage } from "./components/LoadingPage";
import { ErrorBoundary } from "./ErrorBoundary";
import IdfyRouter from "./routing/idfyRouter";
import { initSentry } from "./sentry";
import { ConfiguredAuthProvider } from "./util/auth/ConfiguredAuthProvider";
import { AccountProvider } from "./util/auth/useCurrentAccount";
import { AuthenticatedApolloProvider, UnAuthenticatedApolloProvider } from "./util/graphql/AuthenticatedApolloProvider";
import { idfyTheme } from "./util/IdfyTheme";
import { RolesCheck } from "./RolesCheck";
import { extendYup } from "./util/yup/extensions";

initSentry();
extendYup();

const theme = idfyTheme;

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<LoadingPage />}>
          <UnAuthenticatedApolloProvider>
            <ConfiguredAuthProvider>
              <AuthenticatedApolloProvider>
                <AccountProvider>
                  <RolesCheck>
                    <IdfyRouter />
                  </RolesCheck>
                </AccountProvider>
              </AuthenticatedApolloProvider>
            </ConfiguredAuthProvider>
          </UnAuthenticatedApolloProvider>
        </Suspense>
      </ThemeProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
