import React from "react";
import { IconButton, Link, Typography } from "@material-ui/core";
import { downloadDeclaration } from "../../acceptanceDeclarationPdf/api/acceptanceDeclarationApi";
import { useApolloClient } from "@apollo/client";
import { usePrefixedTranslation } from "../../../../util/usePrefixedTranslation";
import DescriptionIcon from "@material-ui/icons/Description";
import { format } from "date-fns";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { palette } from "../../../../util/IdfyTheme";
import { AcceptanceDeclaration } from "../../api/__generated__/AcceptanceDeclaration";

const useStyles = makeStyles((theme) =>
  createStyles({
    downloadIcon: {
      color: palette.red,
      fill: palette.red,
      justifyContent: "left",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    downloadText: {
      color: palette.red,
    },
  })
);

export const AcceptanceDownloadLink: React.FunctionComponent<{
  assetId: string;
  acceptanceDeclarations: AcceptanceDeclaration[];
  companyName: string;
  styleClasses?: string;
}> = (props) => {
  const { pt } = usePrefixedTranslation("pages.asset-details.acceptance-download.");
  const classes = useStyles();

  const linkDate: string =
    props.acceptanceDeclarations.length > 0
      ? format(new Date(props.acceptanceDeclarations[0].submissionDateTime), "dd_MM_yy")
      : "";
  const companyName: string = props.companyName.replaceAll(" ", "_");
  const linkName: string = pt("link-text", { name: companyName, date: linkDate });
  const client = useApolloClient();
  return (
    <>
      {props.acceptanceDeclarations.length > 0 && (
        <>
          <IconButton
            component={Link}
            onClick={() => downloadDeclaration(props.assetId, client, linkName)}
            size={"small"}
            className={classes.downloadIcon}
          >
            <DescriptionIcon />
            <span>
              <Typography className={classes.downloadText}>{linkName}</Typography>
            </span>
          </IconButton>
        </>
      )}
    </>
  );
};
