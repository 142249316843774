import { AssetInfoFormData } from "./AssetInfoFormData";
import { FetchResult, gql, MutationFunctionOptions } from "@apollo/client";
import { assetFragment } from "../../api/assetFragments";
import { updateAssetMutation } from "./__generated__/updateAssetMutation";
import { deleteAssetMutation } from "./__generated__/deleteAssetMutation";
import { formatDateToIsoDate } from "../../../../util/dateTime/formatDate";

export const UPDATE_ASSET_MUTATION = gql`
  ${assetFragment}
  mutation updateAssetMutation($input: UpdateAssetInput!) {
    updateAsset(input: $input) {
      asset {
        ...AssetData
      }
    }
  }
`;

export function updateAsset(
  values: AssetInfoFormData,
  assetId: string,
  updateAssetMutationCall: (
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    options?: MutationFunctionOptions<updateAssetMutation, Record<string, any>> | undefined
  ) => Promise<FetchResult<updateAssetMutation>>
): Promise<FetchResult<updateAssetMutation>> {
  return updateAssetMutationCall({
    variables: {
      input: {
        assetId: assetId,
        manufacturerId: values.manufacturerId,
        name: values.name,
        serialNumber: values.isSerialNumberUnknown ? null : values.serialNumber,
        contractNumber: values.contractNumber,
        subAssetsToAdd: [],
        subAssetsToDelete: [],
        contractDate: formatDateToIsoDate(values.contractDate!), // Validated as non-null with yup
        contractEndOfTerm: values.contractEndOfTerm !== null ? formatDateToIsoDate(values.contractEndOfTerm) : null,
        supplierId: values.supplierId,
        offsiteLocation: values.offsiteLocation || null,
        supplierInvoiceNumber: values.supplierInvoiceNumber,
        invoiceDate: values.invoiceDate !== null ? formatDateToIsoDate(values.invoiceDate) : null,
      },
    },
  }).then((result: FetchResult<updateAssetMutation>) => {
    return result;
  });
}

export const DELETE_ASSET_MUTATION = gql`
  mutation deleteAssetMutation($input: DeleteAssetInput!) {
    deleteAsset(input: $input) {
      assetId
    }
  }
`;

export function deleteAsset(
  assetId: string,
  deleteAssetMutationCall: (
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    options?: MutationFunctionOptions<deleteAssetMutation, Record<string, any>> | undefined
  ) => Promise<FetchResult<deleteAssetMutation>>
): Promise<FetchResult<deleteAssetMutation>> {
  return deleteAssetMutationCall({
    variables: { input: { assetId: assetId } },
  }).then((result: FetchResult<deleteAssetMutation>) => {
    return result;
  });
}
