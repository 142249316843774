import React, { useContext } from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { DrawerContext } from "../../routing/idfyRouter";
import { APPBAR_HEIGHT, COLLAPSED_DRAWER_WIDTH, DRAWER_WIDTH } from "../../util/constants";
import { idfyTheme } from "../../util/IdfyTheme";
import { IdfyAppBar } from "./appBar/IdfyAppBar";

export const MAX_CONTENT_WIDTH = 1920;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },
    contentContainer: {
      maxWidth: MAX_CONTENT_WIDTH,
      width: "100%",
      height: "100%",
      maxHeight: "100%",
      display: "flex",
      flexDirection: "column",
    },
    content: {
      paddingLeft: `calc(${COLLAPSED_DRAWER_WIDTH}px + ${theme.spacing(7)}px)`,
      padding: theme.spacing(7),
      paddingTop: APPBAR_HEIGHT + 32,
      height: "100%",
      background: idfyTheme.palette.background.default,
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("xl")]: {
        paddingLeft: `calc(${DRAWER_WIDTH} + ${theme.spacing(7)}px)`,
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
  })
);

export const BasePage: React.FunctionComponent<{
  children: NonNullable<React.ReactNode>;
  headerText: string;
  backLink?: boolean;
}> = (props) => {
  const classes = useStyles();

  const drawerContext = useContext(DrawerContext);

  return (
    <>
      <IdfyAppBar
        header={props.headerText}
        link={props.backLink}
        toggleDrawer={() => drawerContext.setDrawerOpen(!drawerContext.drawerOpen)}
        drawerOpen={drawerContext.drawerOpen}
      />
      <main className={classes.content}>
        <div className={classes.contentContainer}>{props.children}</div>
      </main>
    </>
  );
};
