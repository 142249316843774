import React from "react";
import { Control, Controller } from "react-hook-form";

import { TextField } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";

export type ControllerTextFieldProps = {
  control: Control;
  name: string;
  trimOnBlur?: boolean;
} & TextFieldProps;

/**
 * A simple controller text field that hides the ugly render method (which is required for stuff like `onBlur()`) and
 * provides some useful utilities like `trimOnBlur`.
 */
export const ControllerTextField: React.FunctionComponent<ControllerTextFieldProps> = (props) => {
  const { control, name, trimOnBlur, ...rest } = props;

  return (
    <Controller
      control={control}
      render={
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ({ onChange, onBlur, value, name, ref }) => (
          <TextField
            onChange={onChange}
            onBlur={(event) => {
              onBlur();
              props?.onBlur?.(event);
              if (trimOnBlur) {
                control.setValue(name, event.target.value.trim(), {
                  shouldValidate: true,
                });
              }
            }}
            value={value as string}
            name={name}
            inputRef={ref as unknown as null}
            {...rest}
          />
        )
      }
      name={name}
    />
  );
};
