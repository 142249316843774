import React from "react";
import { useTranslation } from "react-i18next";

import { Step, StepLabel, Stepper } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    stepper: {
      backgroundColor: "transparent",
    },
  })
);

export const StepIndicator: React.FunctionComponent<{
  currentStep: number;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const I18NEXT_PREFIX = "pages.assets.modals.create-asset.steps.";

  const steps = [
    t(`${I18NEXT_PREFIX}information`),
    t(`${I18NEXT_PREFIX}sub-assets`),
    t(`${I18NEXT_PREFIX}customer`),
    t(`${I18NEXT_PREFIX}inspections`),
  ];

  return (
    <Stepper activeStep={props.currentStep} orientation={"vertical"} className={classes.stepper}>
      {steps.map((stepLabel, index) => (
        <Step key={index}>
          <StepLabel>{stepLabel}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
