import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const uaeAssetInfoOffsiteLocationStyles = makeStyles((_: Theme) =>
  createStyles({
    header: {
      marginBottom: "2rem",
    },
    radioGroup: {
      marginLeft: "1rem",
    },
    textField: {
      marginTop: "0.65rem",
      marginBottom: "0.65rem",
    },
    customerAddress: {
      marginLeft: "1rem",
      marginTop: "0.5rem",
      marginBottom: "0.5rem",
    },
  })
);
