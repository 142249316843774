import React from "react";
import { useTranslation } from "react-i18next";

import { Chip, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Role } from "../../__generated__/globalTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipRightAligned: {
      marginLeft: theme.spacing(1),
    },
  })
);

export interface RoleChipProps {
  role: Role;
}

export const RoleChip: React.FunctionComponent<RoleChipProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return <Chip className={classes.chipRightAligned} label={t(`role.${props.role}`)} color="secondary" size="small" />;
};
