import { gql } from "@apollo/client";
import { imageFragment } from "../../../../../util/graphql/commonFragments";
import { featureBlueprintWithImagesFragment } from "../../../../blueprints/api/blueprintFragments";

export const inspectionFragment = gql`
  fragment Inspection on Inspection {
    id
    dueDate
    creationDate
    latestSubmissionDate
    rejectionReason
    messageToCustomer
    inspectionType
    inspectorName
    serialNumber
    status
  }
`;

export const featureInspectionFragment = gql`
  ${imageFragment}
  ${featureBlueprintWithImagesFragment}
  fragment FeatureInspection on FeatureInspection {
    id
    tag
    meter {
      hours
      kilometers
    }
    location {
      ...ImageLocation
    }
    images(scale: { width: 1000, height: 1000, mode: FIT }) {
      ...Image
    }
    featureBlueprint {
      ...FeatureBlueprintWithImages
    }
    rejectionReason
  }
`;
