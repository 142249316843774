import { useAuth } from "oidc-react";

import React from "react";

import { ClickAwayListener, createStyles, Grow, Paper, Popper, PopperProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useCurrentAccount } from "../../../util/auth/useCurrentAccount";
import { UserPopupButtons } from "./UserPopupButtons";
import { UserPopupLinks } from "./UserPopupLinks";
import { UserPopupUserInfo } from "./UserPopupUserInfo";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: 4,
      padding: 32,
      minWidth: 250,
    },
  })
);

export interface UserPopupProps {
  open: boolean;
  anchorEl: PopperProps["anchorEl"];
  onClose: () => void;
}

export const UserPopup: React.FunctionComponent<UserPopupProps> = (props) => {
  const classes = useStyles();
  const auth = useAuth();
  const account = useCurrentAccount();

  return (
    <Popper open={props.open} anchorEl={props.anchorEl} placement="bottom-end" transition>
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={props.onClose}>
          <Grow {...TransitionProps} timeout={350} style={{ transformOrigin: "75% 0 0" }}>
            <Paper className={classes.container} elevation={3}>
              <UserPopupUserInfo
                email={account?.user?.email ?? ""}
                userName={`${account?.user?.lastName ?? ""}, ${account?.user?.firstName ?? ""}`}
                onManageAccountClick={() => null /* TODO */}
              />
              <UserPopupButtons onHelpClick={() => null} onLogoutClick={() => auth?.userManager?.signoutRedirect()} />
              <UserPopupLinks />
            </Paper>
          </Grow>
        </ClickAwayListener>
      )}
    </Popper>
  );
};
