import * as yup from "yup";
import { string } from "yup";
import { useTranslation } from "react-i18next";
import { FIELD_VALIDATION_TYPE_STRING } from "../../../../util/constants";

export const createAssetCustomerValidationSchema = (): yup.AnySchema => {
  const { t } = useTranslation();

  return yup.object().shape({
    customerId: yup.string().required(),
    isOffsiteLocation: yup.boolean().required(),
    name: string().when("isOffsiteLocation", {
      is: false,
      then: string().optional(),
      otherwise: FIELD_VALIDATION_TYPE_STRING(t),
    }),
    streetAddress: string().when("isOffsiteLocation", {
      is: false,
      then: string().optional(),
      otherwise: FIELD_VALIDATION_TYPE_STRING(t),
    }),
    zip: string().when("isOffsiteLocation", {
      is: false,
      then: string().optional(),
      otherwise: FIELD_VALIDATION_TYPE_STRING(t),
    }),
    city: string().when("isOffsiteLocation", {
      is: false,
      then: string().optional(),
      otherwise: FIELD_VALIDATION_TYPE_STRING(t),
    }),
  });
};
