import { gql, useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Checkbox, FormControlLabel, FormGroup, Grid, Typography, FormHelperText } from "@material-ui/core";

import { UserInput, Team } from "../../__generated__/globalTypes";
import { FormModal } from "../../components/FormModal";
import { ControllerTextField } from "../../components/inputs/ControllerTextField";
import { FIELD_VALIDATION_TYPE_EMAIL, FIELD_VALIDATION_TYPE_STRING } from "../../util/constants";
import { addUserMutation } from "./__generated__/addUserMutation";
import { SELECTABLE_ROLES } from "./userDetails/UserInfo";
import { TeamField } from "../../components/team/TeamField";
import { getTeamsSchema } from "../../components/team/TeamFilter";
import { RoleError } from "../../components/error/RoleError";

const ADD_USER_MUTATION = gql`
  mutation addUserMutation($input: AddUserInput!) {
    addUser(input: $input) {
      user {
        id
      }
      emailAlreadyInUse
    }
  }
`;

export interface CreateUserModalProps {
  open: boolean;
  onClose: () => void;
  onUserCreate: () => void;
}

export const CreateUserModal: React.FunctionComponent<CreateUserModalProps> = (props) => {
  const { t } = useTranslation();

  const [addUser] = useMutation<addUserMutation>(ADD_USER_MUTATION, {
    refetchQueries: [
      {
        query: gql`
          query usersAfterUpdate {
            users {
              id
            }
          }
        `,
      },
    ],
  });

  const schema = yup.object().shape({
    firstName: FIELD_VALIDATION_TYPE_STRING(t),
    lastName: FIELD_VALIDATION_TYPE_STRING(t),
    email: FIELD_VALIDATION_TYPE_EMAIL(t),
    telephoneNumber: FIELD_VALIDATION_TYPE_STRING(t),
    team: getTeamsSchema(true).default(Team.ASSET_MONITORING),
  });

  const form = useForm<UserInput>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, errors, control, setError } = form;

  const handleOnSubmit = async (values: UserInput) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    const roles = SELECTABLE_ROLES.filter((role) => (values as any)[role]);

    if (roles.length < 1) {
      setError("roles", {
        message: t("validations.required"),
      });
      return;
    }

    const response = await addUser({
      variables: {
        input: {
          user: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            telephoneNumber: values.telephoneNumber,
            roles,
            team: values.team,
          },
        },
      },
    });
    if (response.data) {
      if (response.data.addUser.user) {
        props.onUserCreate();
      }
      if (response.data.addUser.emailAlreadyInUse) {
        setError("email", {
          message: t("validations.email-already-in-use"),
        });
      }
    }
  };

  const i18nPrefix = "pages.administration.create-new-user-dialog";

  return (
    <FormProvider {...form}>
      <FormModal
        open={props.open}
        onClose={props.onClose}
        cancelButtonText={t(`${i18nPrefix}.buttons.cancel`)}
        submitButtonText={t(`${i18nPrefix}.buttons.create`)}
        header={t(`${i18nPrefix}.header`)}
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <Grid container spacing={4} justifyContent="flex-start">
          <Grid item xs={12}>
            <ControllerTextField
              control={control}
              defaultValue=""
              fullWidth
              variant="outlined"
              size="small"
              name="firstName"
              label={t(`${i18nPrefix}.field.first-name`)}
              color="secondary"
              helperText={errors.firstName?.message}
              error={!!errors.firstName}
              trimOnBlur
            />
          </Grid>
          <Grid item xs={12}>
            <ControllerTextField
              control={control}
              defaultValue=""
              fullWidth
              variant="outlined"
              size="small"
              name="lastName"
              label={t(`${i18nPrefix}.field.last-name`)}
              color="secondary"
              helperText={errors.lastName?.message}
              error={!!errors.lastName}
              trimOnBlur
            />
          </Grid>
          <Grid item xs={12}>
            <ControllerTextField
              control={control}
              defaultValue=""
              fullWidth
              variant="outlined"
              size="small"
              name="email"
              label={t(`${i18nPrefix}.field.email`)}
              color="secondary"
              helperText={errors.email?.message}
              error={!!errors.email}
              trimOnBlur
            />
          </Grid>
          <Grid item xs={12}>
            <ControllerTextField
              control={control}
              defaultValue=""
              fullWidth
              variant="outlined"
              size="small"
              name="telephoneNumber"
              label={t(`${i18nPrefix}.field.telephone`)}
              color="secondary"
              helperText={errors.telephoneNumber?.message}
              error={!!errors.telephoneNumber}
              trimOnBlur
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h3">{t(`${i18nPrefix}.field.roles`)}</Typography>
            <FormHelperText>{(errors.roles && errors.roles as RoleError)?.message}</FormHelperText>
            <FormGroup>
              {SELECTABLE_ROLES.map((role) => (
                <Controller
                  key={role}
                  name={role as never}
                  control={control}
                  render={
                    /* @ts-ignore */
                    ({ onChange, onBlur, value, _, ref }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={role}
                            onBlur={onBlur}
                            onChange={(e) => {
                              onChange(e.target.checked);
                            }}
                            checked={!!value}
                            inputRef={ref as unknown as null}
                          />
                        }
                        label={t(`role.${role}`)}
                      />
                    )
                  }
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <TeamField name={"team"} label={t("Team.title")} required={false} includeAllTeam={true} />
          </Grid>
        </Grid>
      </FormModal>
    </FormProvider>
  );
};
