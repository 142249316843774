import { Customer } from "./Customer";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { TableCell } from "@material-ui/core";

export function CustomerCell(props: { customer: Customer | undefined }): ReactElement {
  return <TableCell>{props.customer && <CustomerCellContent customer={props.customer} />}</TableCell>;
}

export function CustomerCellContent(props: { customer: Customer }): ReactElement {
  const { t } = useTranslation();
  return (
    <>
      {props.customer.company}
      <br />
      <span style={{ fontSize: "0.9em" }}>
        {t("pages.customers.table.customer-number")}: {props.customer.customerNumber}
      </span>
    </>
  );
}
