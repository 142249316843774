import "graphiql/graphiql.css";

import GraphiQL from "graphiql";
import { useAuth } from "oidc-react";

import React, { useCallback } from "react";

import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { COLLAPSED_DRAWER_WIDTH, DRAWER_WIDTH } from "../util/constants";

export const GraphiQLPage: React.FunctionComponent = () => {
  const auth = useAuth();
  const accessToken = auth?.userData?.access_token;

  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        height: "100vh",
        marginLeft: COLLAPSED_DRAWER_WIDTH,
        [theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
        [theme.breakpoints.up("xl")]: {
          marginLeft: DRAWER_WIDTH,
        },
      },
    })
  );

  const graphQLFetcher = useCallback(
    (graphQLParams: unknown) => {
      return fetch(window.location.origin + "/api/graphql", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
        },
        body: JSON.stringify(graphQLParams),
      }).then((response) => response.json());
    },
    [accessToken]
  );

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GraphiQL fetcher={graphQLFetcher} />
    </div>
  );
};
