import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { CustomerInput } from "../../../__generated__/globalTypes";
import { FormModal } from "../../../components/FormModal";
import { CREATE_CUSTOMER_MUTATION, CUSTOMERS_AFTER_UPDATE_QUERY } from "../api/customerQueriesAndMutations";
import { CustomerForm } from "../CustomerForm";
import { addCustomerMutation } from "../api/__generated__/addCustomerMutation";
import { customerSchema } from "../Customer";

export const CreateCustomerModal: React.FunctionComponent<{
  handleClose: (success: boolean) => void;
  newModalOpen: boolean;
}> = (props) => {
  const { t } = useTranslation();

  const [addCustomer] = useMutation<addCustomerMutation>(CREATE_CUSTOMER_MUTATION, {
    refetchQueries: [{ query: CUSTOMERS_AFTER_UPDATE_QUERY }],
  });

  const methods = useForm<CustomerInput>({
    resolver: yupResolver(customerSchema(t)),
  });

  async function onSubmit(values: CustomerInput) {
    const response = await addCustomer({
      variables: {
        input: {
          customer: values,
        },
      },
    });

    if (response.data?.addCustomer?.emailAlreadyInUse) {
      methods.setError("email", {
        message: t("validations.email-already-in-use"),
      });
    } else {
      props.handleClose(!response.errors);
    }
  }

  const i18nPrefix = "pages.customers.create-new-customer-dialog";

  return (
    <FormProvider {...methods}>
      <FormModal
        open={props.newModalOpen}
        onClose={() => props.handleClose(false)}
        cancelButtonText={t(`${i18nPrefix}.buttons.cancel`)}
        submitButtonText={t(`${i18nPrefix}.buttons.create-customer`)}
        header={t(`${i18nPrefix}.header`)}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <CustomerForm />
      </FormModal>
    </FormProvider>
  );
};
