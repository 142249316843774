import { gql } from "@apollo/client";
import { manufacturerFragment } from "./manufacturerFragment";

export const MANUFACTURERS_QUERY = gql`
  ${manufacturerFragment}
  query manufacturersQuery {
    manufacturers {
      ...Manufacturer
    }
  }
`;

export const CREATE_MANUFACTURER_MUTATION = gql`
  ${manufacturerFragment}
  mutation createManufacturerMutation($input: ManufacturerInput!) {
    addManufacturer(input: $input) {
      manufacturer {
        ...Manufacturer
      }
      manufacturerAlreadyExists
    }
  }
`;

export const UPDATE_MANUFACTURER_MUTATION = gql`
  ${manufacturerFragment}
  mutation updateManufacturerMutation($input: UpdateManufacturerInput!) {
    updateManufacturer(input: $input) {
      manufacturer {
        ...Manufacturer
      }
    }
  }
`;

export const DELETE_MANUFACTURER_MUTATION = gql`
  mutation deleteManufacturerMutation($id: ID!) {
    deleteManufacturer(id: $id) {
      id
      deletionSuccessful
    }
  }
`;
