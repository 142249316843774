import React, { useContext } from "react";

import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { SnackContext } from "../../../routing/idfyRouter";

export const IdfySnackbar: React.FunctionComponent = () => {
  const context = useContext(SnackContext);

  return (
    <Snackbar open={context.snackOpen} autoHideDuration={3000} onClose={() => context.setSnackOpen(false)}>
      <Alert variant={"filled"} severity={"success"}>
        {context.snackText}
      </Alert>
    </Snackbar>
  );
};
