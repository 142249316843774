/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enum with all possible asset events, used for the AssetEventsFilter.
 */
export enum AssetEventType {
  ASSET_CREATED = "ASSET_CREATED",
  INSPECTION_APPROVED = "INSPECTION_APPROVED",
  INSPECTION_EXTENDED_DUE_DATE = "INSPECTION_EXTENDED_DUE_DATE",
  INSPECTION_REJECTED = "INSPECTION_REJECTED",
  INSPECTION_REQUESTED = "INSPECTION_REQUESTED",
  INSPECTION_REVOKED = "INSPECTION_REVOKED",
  INSPECTION_SUBMITTED = "INSPECTION_SUBMITTED",
  NOTE_ADDED = "NOTE_ADDED",
}

export enum AssetStatus {
  APPROVED = "APPROVED",
  BLUEPRINT_PENDING = "BLUEPRINT_PENDING",
  BLUEPRINT_REJECTED = "BLUEPRINT_REJECTED",
  BLUEPRINT_WAITING_FOR_APPROVAL = "BLUEPRINT_WAITING_FOR_APPROVAL",
  INSPECTION_PENDING = "INSPECTION_PENDING",
  INSPECTION_REJECTED = "INSPECTION_REJECTED",
  INSPECTION_WAITING_FOR_APPROVAL = "INSPECTION_WAITING_FOR_APPROVAL",
  OUT_OF_CONTRACT = "OUT_OF_CONTRACT",
  UNCHECKED = "UNCHECKED",
}

export enum AssetStatusGroup {
  ALL = "ALL",
  APPROVED = "APPROVED",
  OUT_OF_CONTRACT = "OUT_OF_CONTRACT",
  PENDING = "PENDING",
  UNCHECKED = "UNCHECKED",
  WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL",
}

export enum BlueprintStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL",
}

export enum ContractStatus {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  EXTENDED = "EXTENDED",
  INACTIVE = "INACTIVE",
  NOT_YET_ACTIVE = "NOT_YET_ACTIVE",
  UNKNOWN = "UNKNOWN",
}

export enum FeatureType {
  IMAGE = "IMAGE",
  METER = "METER",
  TAG = "TAG",
}

export enum InspectionStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  REVOKED = "REVOKED",
  WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL",
}

export enum InspectionType {
  CORRECTION = "CORRECTION",
  INITIAL = "INITIAL",
  REGULAR = "REGULAR",
}

export enum Role {
  ADMIN = "ADMIN",
  CUSTOMER = "CUSTOMER",
  EMPLOYEE = "EMPLOYEE",
  INSPECTOR = "INSPECTOR",
  SUB_ACCOUNT = "SUB_ACCOUNT",
}

export enum Team {
  ALL = "ALL",
  ASSET_MONITORING = "ASSET_MONITORING",
  NORTH = "NORTH",
  SOUTH = "SOUTH",
}

export interface AddAssetInput {
  asset: AssetInput;
  inspection: NewAssetInspectionInput;
}

export interface AddCustomerInput {
  customer: CustomerInput;
}

export interface AddUserInput {
  user: UserInput;
}

export interface AssetFilter {
  actionRequired?: boolean | null;
  customerId?: string | null;
  contractId?: string | null;
  blueprintId?: string | null;
  statusGroup?: AssetStatusGroup | null;
  contractStatus?: ContractStatus | null;
  team?: Team | null;
  searchText?: string | null;
}

export interface AssetInput {
  name: string;
  serialNumber?: string | null;
  customerId: string;
  offsiteLocation?: OffsiteLocationInput | null;
  manufacturerId: string;
  supplierId?: string | null;
  blueprintId: string;
  contractNumber: string;
  contractDate: string;
  contractEndOfTerm?: string | null;
  isNew: boolean;
  isAlreadyInStock: boolean;
  subAssets: SubAssetInput[];
  supplierInvoiceNumber?: string | null;
  invoiceDate?: string | null;
}

export interface ContractFilter {
  status?: ContractStatus | null;
  customerId?: string | null;
  team?: Team | null;
  searchText?: string | null;
}

export interface ContractInput {
  contractNumber: string;
  customer: EntityRef;
  startDate?: string | null;
  endDate?: string | null;
  extensionPeriod?: string | null;
  extendedEndDate?: string | null;
  status: ContractStatus;
}

export interface CustomerInput {
  email?: string | null;
  firstName: string;
  lastName: string;
  customerNumber: string;
  company: string;
  streetAddress: string;
  zip: string;
  city: string;
  telephoneNumber?: string | null;
  responsibleTeam: Team;
}

export interface CustomerRegistrationArguments {
  customerId: string;
}

export interface DeclarationPdfArguments {
  assetId: string;
}

export interface DeleteAssetInput {
  assetId: string;
}

export interface EntityRef {
  id: string;
}

export interface InspectionMandatoryAssetFieldsInput {
  assetId: string;
  serialNumber?: string | null;
  contractEndOfTerm: string;
}

export interface ManufacturerInput {
  name: string;
}

export interface NewAssetInspectionInput {
  dueDate: string;
  messageToCustomer?: string | null;
}

export interface OffsiteLocationInput {
  name: string;
  streetAddress: string;
  zip: string;
  city: string;
}

export interface OutOfContractAssetInput {
  assetId: string;
  isAlreadyInStock: boolean;
  subAssetsToAdd?: SubAssetInput[] | null;
  subAssetsToDelete?: string[] | null;
  customerId: string;
  offsiteLocation?: OffsiteLocationInput | null;
  contractNumber: string;
  contractDate: string;
  contractEndOfTerm?: string | null;
  supplierId?: string | null;
  supplierInvoiceNumber?: string | null;
  invoiceDate?: string | null;
}

export interface RejectedFeatureInspectionInput {
  featureInspectionId: string;
  rejectionReason: string;
}

export interface SetMultipleInspectionsInput {
  customerId: string;
  assetIdList?: string[] | null;
  dueDate: string;
  messageToCustomer?: string | null;
}

export interface SubAssetInput {
  name: string;
  serialNumber?: string | null;
  manufacturerId: string;
}

export interface SupplierInput {
  name: string;
  streetAddress: string;
  zip: string;
  city: string;
}

export interface UpdateAssetInput {
  assetId: string;
  manufacturerId: string;
  name: string;
  serialNumber?: string | null;
  contractNumber: string;
  subAssetsToDelete?: string[] | null;
  subAssetsToAdd?: SubAssetInput[] | null;
  supplierId?: string | null;
  contractDate: string;
  contractEndOfTerm?: string | null;
  offsiteLocation?: OffsiteLocationInput | null;
  supplierInvoiceNumber?: string | null;
  invoiceDate?: string | null;
}

export interface UpdateInspectionInput {
  inspectionId: string;
  status: InspectionStatus;
  messageToCustomer?: string | null;
  dueDate?: string | null;
  rejectedFeatureInspections: RejectedFeatureInspectionInput[];
}

export interface UpdateManufacturerInput {
  manufacturerId: string;
  manufacturer: ManufacturerInput;
}

export interface UpdateSubAssetInput {
  subAssetId: string;
  subAsset: SubAssetInput;
}

export interface UseOutOfContractAssetInput {
  asset: OutOfContractAssetInput;
  inspection: NewAssetInspectionInput;
}

export interface UserInput {
  firstName: string;
  lastName: string;
  telephoneNumber: string;
  email: string;
  roles: Role[];
  team: Team;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
