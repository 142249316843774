import React from "react";
import { useTranslation } from "react-i18next";

import { Button, FormControl as MUIForm, IconButton, Modal, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { idfyTheme } from "../../../../util/IdfyTheme";
import { blueprintInfoQuery_blueprint } from "../__generated__/blueprintInfoQuery";
import { BlueprintModalFormRow } from "./BlueprintModalFormRow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inspectionModalForm: {
      width: "100%",
      flex: "1 1 0",
      overflow: "auto",
    },
    formHeader: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      boxSizing: "border-box",
      marginBottom: theme.spacing(1),
    },
    modalBody: {
      position: "absolute",
      top: "5%",
      left: "5%",
      width: "90%",
      height: "90%",
      boxSizing: "border-box",
      padding: theme.spacing(4),
      borderRadius: "10px",
      outline: "none",
      display: "flex",
      flexDirection: "column",
      background: idfyTheme.palette.background.default,
    },
    inspectionModalFeatureRowTitleArea: {
      "& div input": {
        fontWeight: "bold",
        color: idfyTheme.palette.text.primary,
      },
    },
    inspectionModalFormButtonWrapper: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      paddingTop: theme.spacing(2),
      "& button": {
        marginLeft: theme.spacing(2),
      },
    },
    denyTextArea: {
      width: "400px",
    },
    confirmDeleteButton: {
      color: idfyTheme.palette.background.paper,
      background: idfyTheme.palette.error.main,
      "&:hover": {
        background: "#FF7B59",
      },
    },
    typo: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

export const BlueprintModal: React.FunctionComponent<{
  modalOpen: boolean;
  handleClose: (triggerRefetch: boolean) => void;
  blueprint: blueprintInfoQuery_blueprint;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const I18NEXT_PREFIX = "pages.asset-details.inspection-overview.modals.blueprint.";

  /* eslint-disable @typescript-eslint/no-unused-vars */
  return (
    <React.Fragment>
      <Modal open={props.modalOpen} onClose={props.handleClose}>
        <div className={classes.modalBody}>
          <div className={classes.formHeader}>
            <Typography variant={"h5"}>{t(`${I18NEXT_PREFIX}blueprint-check`)}</Typography>
            <IconButton onClick={() => props.handleClose(false)}>
              <ClearIcon />
            </IconButton>
          </div>
          <MUIForm className={classes.inspectionModalForm}>
            <Typography variant={"h4"} className={classes.typo}>
              {props.blueprint.name} - {t(`${I18NEXT_PREFIX}blueprint`)}
            </Typography>
            {props.blueprint.featureBlueprints.map((f, i) => (
              <BlueprintModalFormRow feature={f} key={i} />
            ))}
          </MUIForm>
          <div className={classes.inspectionModalFormButtonWrapper}>
            <Button
              color="primary"
              onClick={() => {
                props.handleClose(false);
              }}
            >
              {t(`${I18NEXT_PREFIX}buttons.close`)}
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
