import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import VerifiedFilled from "../../../../../../../components/icons/VerifiedFilled";
import { InspectionOverviewElement, InspectionOverviewIconType } from "../InspectionOverviewElement";
import { InspectionType } from "../../../../../../../__generated__/globalTypes";
import { acceptanceDeclarationComplete } from "./AcceptanceDeclaration";
import classNames from "classnames";
import { AcceptanceDeclarationChecklist } from "./AcceptanceDeclarationChecklist";
import { AcceptanceDownloadLink } from "../../../../acceptanceDownload/AcceptanceDownloadLink";
import { inspectionQuery_asset, inspectionQuery_asset_inspections } from "../../../api/__generated__/inspectionQuery";

const useStyles = makeStyles((theme) =>
  createStyles({
    iconContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      fontSize: 128,
      color: "#2a9d8f",
    },
    image: {
      width: "12rem",
    },
    flexContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    noteContainer: {
      border: "1px solid",
      padding: "1rem",
      marginTop: "1rem",
    },
  })
);

export const InspectionOverviewAcceptanceDeclarationElement: React.FunctionComponent<{
  inspection: inspectionQuery_asset_inspections;
  asset: inspectionQuery_asset;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const I18N_PREFIX = "pages.asset-details.inspection-overview.modals.normal-inspection.overview.";

  const hasDeclaration: boolean = props.asset.acceptanceDeclarations[0] !== undefined;
  const displayDeclaration: boolean = props.inspection.inspectionType === InspectionType.INITIAL && hasDeclaration;
  const acceptanceDeclarationNote: string | null = displayDeclaration
    ? props.asset.acceptanceDeclarations[0].note
    : null;

  function getIconType(): InspectionOverviewIconType {
    if (props.inspection.inspectionType === InspectionType.INITIAL && hasDeclaration) {
      if (acceptanceDeclarationComplete(props.asset.acceptanceDeclarations[0], props.asset)) {
        return "check";
      } else {
        return "error";
      }
    }
    return "check";
  }

  return (
    <InspectionOverviewElement
      imageColumn={
        <div className={classes.iconContainer}>
          {displayDeclaration && (
            <img src={"/images/acceptance_protocol.png"} className={classes.image} alt={"Acceptance Declaration"} />
          )}
          {!displayDeclaration && <VerifiedFilled className={classes.icon} />}
        </div>
      }
      informationColumn={
        <div className={classes.flexContainer}>
          <Typography variant="h1" gutterBottom>
            {props.inspection.inspectionType === InspectionType.INITIAL
              ? t(`${I18N_PREFIX}acceptance-declaration`)
              : t(`${I18N_PREFIX}inspector-person`)}
          </Typography>
          {props.inspection.inspectionType === InspectionType.INITIAL && (
            <AcceptanceDownloadLink
              assetId={props.asset.id}
              acceptanceDeclarations={props.asset.acceptanceDeclarations}
              companyName={props.inspection.contract.customer.company}
            />
          )}
          <Typography variant="body2">
            {t(`${I18N_PREFIX}inspection-performed-by`)}
            <br />
            <b>{props.inspection.inspectorName || "-"}</b>
          </Typography>
        </div>
      }
      icon={getIconType()}
      acceptanceDeclaration={
        displayDeclaration
          ? {
              checklist: (
                <AcceptanceDeclarationChecklist
                  declaration={props.asset.acceptanceDeclarations[0]}
                  latestSubmissionDate={props.inspection.latestSubmissionDate}
                />
              ),
              note: acceptanceDeclarationNote && (
                <div className={classNames(classes.flexContainer)}>
                  <Typography variant="h2" gutterBottom>
                    {t(`${I18N_PREFIX}note`)}:
                  </Typography>
                  <div className={classes.noteContainer}>
                    <Typography variant="body2">{acceptanceDeclarationNote}</Typography>
                  </div>
                </div>
              ),
            }
          : undefined
      }
    />
  );
};
