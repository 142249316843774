import React, { ReactElement, ReactNode } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@material-ui/core";
import { DetailsSectionHeader } from "../DetailsSectionHeader";
import { idfyTheme, palette } from "../../util/IdfyTheme";
import { alignWithAccordionDividerStyle } from "../alignmentStyles/AlignWithAccordionDividerStyle";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobile: {
      padding: 15,
      background: theme.palette.background.paper,
      color: palette.mediumGrey,
    },
  })
);

export type IDFyFormColumn<T> = {
  name: string;
  title?: string;
  titleKey?: string;
  render: (rowData: T, t: TFunction) => ReactNode;
  renderMobile?: (rowData: T, t: TFunction) => ReactNode;
};

export function IDFyFormTable<T>(props: {
  title: string;
  columns: IDFyFormColumn<T>[];
  data?: T[];
  onRowClick: (rowData: T) => void;
}): ReactElement {
  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));
  const { title, columns, data } = props;
  const { t } = useTranslation();

  const tableStyle = useStyles();
  const alignStyle = alignWithAccordionDividerStyle();

  return (
    <React.Fragment>
      <DetailsSectionHeader header={title} />
      <TableContainer>
        <Table>
          {!mobile && (
            <TableHead>
              <TableRow>
                {columns.map((colum, index) => (
                  <TableCell key={index} className={index == 0 ? alignStyle.firstTableCell : ""}>
                    {colum.title?.toUpperCase() || (colum.titleKey ? t(colum.titleKey) : "")}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {data ? (
              data.map((rowData, index) => (
                <TableRow hover={!mobile} key={index} onClick={() => props.onRowClick && props.onRowClick(rowData)}>
                  {!mobile && (
                    <>
                      {columns.map((column, index) => (
                        <TableCell key={index} className={index == 0 ? alignStyle.firstTableCell : ""}>
                          {column.render(rowData, t)}
                        </TableCell>
                      ))}
                    </>
                  )}
                  {mobile && (
                    <TableCell>
                      <Paper elevation={2} className={tableStyle.mobile}>
                        {props.columns.map((column, index) => {
                          const element: ReactNode = column.renderMobile
                            ? column.renderMobile(rowData, t)
                            : column.render(rowData, t);
                          if (typeof element === "string") {
                            return <p key={index}>{element}</p>;
                          } else {
                            return <div key={index}>{element}</div>;
                          }
                        })}
                      </Paper>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                {columns.map((value, index) => (
                  <TableCell key={index}>{index ? " " : "-"}</TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
