import React from "react";
import { FormSelectField } from "../../../../../components/forms/FormSelectField";
import { useQuery } from "@apollo/client";
import { manufacturersQuery } from "../../../../manufacturers/api/__generated__/manufacturersQuery";
import { MANUFACTURERS_QUERY } from "../../../../manufacturers/api/manufacturerQueriesAndMutations";

export const ManufacturerSelectField: React.FunctionComponent<{
  label: string;
  name: string;
  disabled: boolean;
}> = (props) => {
  const { label, name, disabled } = props;
  const { loading: loadingManufacturers, data: manufacturersData } = useQuery<manufacturersQuery>(MANUFACTURERS_QUERY);

  return (
    <React.Fragment>
      {!loadingManufacturers && manufacturersData?.manufacturers && (
        <FormSelectField
          name={name}
          label={label}
          list={manufacturersData?.manufacturers}
          getListLabel={(manufacturer) => manufacturer.name}
          getListKey={(manufacturer) => manufacturer.id}
          disabled={disabled}
          required
        />
      )}
    </React.Fragment>
  );
};
