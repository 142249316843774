import { KeyboardDatePicker } from "@material-ui/pickers";
import { Controller, useFormContext } from "react-hook-form";
import React, { ReactElement } from "react";
import { KeyboardDatePickerProps } from "@material-ui/pickers/DatePicker/DatePicker";
import { useTranslation } from "react-i18next";

export type FormDateFieldProps = {
  name: string;
} & Omit<KeyboardDatePickerProps, "value" | "onChange">;

export function FormDateField(props: FormDateFieldProps): ReactElement {
  const { name, required, label, format, ...rest } = props;
  const { control, errors } = useFormContext();
  const { t } = useTranslation();

  const displayLabel = required ? label + " *" : label;
  const dateFormat = format ? format : t("dates.date");

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ onChange, value }) => {
        return (
          <KeyboardDatePicker
            value={value}
            onChange={onChange}
            variant="inline"
            fullWidth
            autoOk
            inputVariant="outlined"
            format={dateFormat}
            size={"small"}
            color="secondary"
            error={!!errors[name]}
            helperText={errors[name]?.message}
            label={displayLabel}
            {...rest}
          />
        );
      }}
    />
  );
}
