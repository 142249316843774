import React from "react";

import ImageIcon from "@material-ui/icons/Image";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import SpeedIcon from "@material-ui/icons/Speed";
import WarningIcon from "@material-ui/icons/Warning";

import { FeatureType } from "../__generated__/globalTypes";

export function getFeatureTypeIcon(type: FeatureType): React.ReactElement {
  if (type === FeatureType.IMAGE) return <ImageIcon />;
  if (type === FeatureType.TAG) return <LocalOfferIcon />;
  if (type === FeatureType.METER) return <SpeedIcon />;
  return <WarningIcon />;
}
