import React from "react";
import { usePrefixedTranslation } from "../../../util/usePrefixedTranslation";
import { FormModal } from "../../../components/FormModal";
import * as yup from "yup";
import { FIELD_VALIDATION_TYPE_STRING } from "../../../util/constants";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ManufacturerInput } from "../../../__generated__/globalTypes";
import { ControllerTextField } from "../../../components/inputs/ControllerTextField";
import { useMutation } from "@apollo/client";
import { CREATE_MANUFACTURER_MUTATION, MANUFACTURERS_QUERY } from "../api/manufacturerQueriesAndMutations";
import { createManufacturerMutation } from "../api/__generated__/createManufacturerMutation";

export const CreateManufacturerModal: React.FunctionComponent<{
  open: boolean;
  handleClose: (manufacturerCreated: boolean) => void;
}> = (props) => {
  const { pt } = usePrefixedTranslation("pages.manufacturers.modals.create-manufacturer.");
  const { t } = useTranslation();

  const schema = yup.object().shape({
    name: FIELD_VALIDATION_TYPE_STRING(t),
  });

  const { handleSubmit, errors, control, setError } = useForm<ManufacturerInput>({
    resolver: yupResolver(schema),
  });

  const [createManufacturer] = useMutation<createManufacturerMutation>(CREATE_MANUFACTURER_MUTATION, {
    refetchQueries: [{ query: MANUFACTURERS_QUERY }],
  });

  async function onSubmit(values: ManufacturerInput) {
    const response = await createManufacturer({
      variables: {
        input: {
          name: values.name,
        },
      },
    });

    if (response.data?.addManufacturer?.manufacturerAlreadyExists) {
      setError("name", {
        message: pt("manufacturerAlreadyExists"),
      });
    } else {
      props.handleClose(true);
    }
  }

  return (
    <FormModal
      open={props.open}
      onClose={() => props.handleClose(false)}
      cancelButtonText={pt("buttons.cancel")}
      submitButtonText={pt("buttons.create")}
      header={pt("header")}
      onSubmit={handleSubmit(onSubmit)}
    >
      <ControllerTextField
        control={control}
        fullWidth
        variant="outlined"
        size="small"
        name="name"
        label={pt("manufacturer")}
        color="secondary"
        helperText={errors.name?.message}
        error={!!errors.name}
        trimOnBlur
      />
    </FormModal>
  );
};
