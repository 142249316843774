import * as yup from "yup";
import {
  FIELD_VALIDATION_TYPE_BOOLEAN,
  FIELD_VALIDATION_TYPE_DATE,
  FIELD_VALIDATION_TYPE_STRING,
} from "../../../../util/constants";
import { date, string } from "yup";
import { useTranslation } from "react-i18next";
import { usePrefixedTranslation } from "../../../../util/usePrefixedTranslation";

export const createAssetInfoValidationSchema = (): yup.AnySchema => {
  const { t } = useTranslation();
  const { pt } = usePrefixedTranslation("pages.assets.modals.create-asset.");
  return yup.object({
    name: FIELD_VALIDATION_TYPE_STRING(t),
    isSerialNumberUnknown: yup.boolean().required(),
    serialNumber: string().when("isSerialNumberUnknown", {
      is: false,
      then: string().required(t("validations.required")),
      otherwise: string().optional(),
    }),
    contractNumber: FIELD_VALIDATION_TYPE_STRING(t),
    manufacturerId: FIELD_VALIDATION_TYPE_STRING(t, pt("validations.select-manufacturer")),
    blueprintId: FIELD_VALIDATION_TYPE_STRING(t),
    isAlreadyInStock: FIELD_VALIDATION_TYPE_BOOLEAN(t),
    showSupplierFields: yup.boolean().required(),
    supplierId: string().when("showSupplierFields", {
      is: false,
      then: string().when("isAlreadyInStock", {
        is: true,
        then: string().optional().nullable(),
        otherwise: string().required(pt("validations.select-supplier")).typeError(pt("validations.select-supplier")),
      }),
      otherwise: string().optional(),
    }),
    contractDate: FIELD_VALIDATION_TYPE_DATE(t, pt("validations.select-date")),
    contractEndOfTerm: date().when("isContractEndOfTermUnknown", {
      is: false,
      then: FIELD_VALIDATION_TYPE_DATE(t, pt("validations.select-date")),
      otherwise: date()
        .transform(() => {
          return null;
        })
        .notRequired()
        .nullable(),
    }),
    newSupplier: yup.object().when("showSupplierFields", {
      is: true,
      then: yup.object({
        name: string().required(t("validations.required")),
        streetAddress: string().required(t("validations.required")),
        zip: string().required(t("validations.required")),
        city: string().required(t("validations.required")),
      }),
    }),
    supplierInvoiceNumber: string().when("isAlreadyInStock", {
      is: true,
      then: string().optional().nullable(),
      otherwise: FIELD_VALIDATION_TYPE_STRING(t).typeError(t("validations.required")),
    }),
    invoiceDate: date().when("isAlreadyInStock", {
      is: true,
      then: date()
        .transform((value, originalValue) => {
          if (originalValue === null) return null;
          return value;
        })
        .nullable(),
      otherwise: date().typeError(t("validations.date")).required(t("validations.required")),
    }),
  });
};
