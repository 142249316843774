import React from "react";

import { PopUpDialog } from "../../../../pageFragments/util/PopUpDialog";
import * as yup from "yup";
import { FIELD_VALIDATION_TYPE_DATE } from "../../../../../util/constants";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Trans, useTranslation } from "react-i18next";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Alert } from "@material-ui/lab";
import { usePrefixedTranslation } from "../../../../../util/usePrefixedTranslation";
import { format } from "date-fns";
import deLocale from "date-fns/locale/de";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formGrid: {
      boxSizing: "border-box",
      paddingTop: theme.spacing(3),
    },
    deleteCell: {
      width: 60,
    },
    form: {
      maxWidth: 300,
    },
  })
);

export interface AddContractEndOfTermProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (contractEndOfTerm: Date) => void;
  contractNumber: string;
  contractDate: string | null;
}

export const AddContractEndOfTermDialog: React.FunctionComponent<AddContractEndOfTermProps> = (props) => {
  const { t } = useTranslation();
  const i18nPrefix =
    "pages.asset-details.inspection-overview.modals.normal-inspection.add-contract-end-of-term-dialog.";
  const { pt } = usePrefixedTranslation(i18nPrefix);

  const parsedDate: Date = new Date(props.contractDate!);
  const schema = yup.object().shape({
    contractEndOfTerm: FIELD_VALIDATION_TYPE_DATE(t),
  });

  const { control, errors, handleSubmit, reset } = useForm<{ contractEndOfTerm: Date }>({
    resolver: yupResolver(schema),
  });

  const classes = useStyles();

  return (
    <PopUpDialog
      open={props.open}
      onClose={props.handleClose}
      handleConfirm={handleSubmit((data) => props.handleSubmit(data.contractEndOfTerm))}
      header={t(`${i18nPrefix}header`)}
      confirm={t(`${i18nPrefix}save`)}
      deny={t(`${i18nPrefix}cancel`)}
      handleDeny={() => {
        reset();
        props.handleClose();
      }}
    >
      <form className={classes.form}>
        <Grid container spacing={2} alignItems={"flex-start"}>
          <Grid item xs={12}>
            <Alert severity="warning">
              <Trans t={pt} i18nKey="warning" />
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={true}
              value={props.contractNumber}
              label={t(`${i18nPrefix}contract-number`)}
              size={"small"}
              fullWidth
              variant={"outlined"}
              color={"secondary"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={true}
              value={format(parsedDate, "dd.MM.yyyy")}
              label={t(`${i18nPrefix}contract-date`)}
              size={"small"}
              fullWidth
              variant={"outlined"}
              color={"secondary"}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
              <Controller
                required
                name="contractEndOfTerm"
                control={control}
                defaultValue={null}
                render={({ onChange, value }) => {
                  return (
                    <KeyboardDatePicker
                      label={t(`${i18nPrefix}contract-end-of-term`)}
                      variant="inline"
                      fullWidth
                      autoOk
                      format="dd.MM.yyyy"
                      value={value}
                      onChange={onChange}
                      inputVariant="outlined"
                      size={"small"}
                      color="secondary"
                      required
                      error={!!errors.contractEndOfTerm}
                      helperText={errors.contractEndOfTerm?.message}
                    />
                  );
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </form>
    </PopUpDialog>
  );
};
