import { gql } from "@apollo/client";

export const accountFragment = gql`
  fragment Account on Account {
    firstName
    lastName
    email
    role
  }
`;

export const customerFragment = gql`
  ${accountFragment}
  fragment CustomerData on Customer {
    id
    firstName
    lastName
    company
    customerNumber
    telephoneNumber
    city
    streetAddress
    zip
    email
    accounts {
      ...Account
    }
    responsibleTeam
    registrationComplete
  }
`;
