import { yupResolver } from "@hookform/resolvers/yup";

import React, { useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InfoPageHeader } from "../../pageFragments/util/InfoPageHeader";
import { Contract } from "../api/__generated__/Contract";
import { ContractInfoFormFields, contractSchema, toContractInfoFormFields, toContractInput } from "../Contract";
import { FormGridContainer, FormGridItem } from "../../../components/forms/FormGrid";
import { FormTextField } from "../../../components/forms/FormTextField";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormDateField } from "../../../components/forms/FormDateField";
import { Alert } from "@material-ui/lab";
import { ContractInput, ContractStatus } from "../../../__generated__/globalTypes";
import { FetchResult, useMutation } from "@apollo/client";
import { updateContractMutation, updateContractMutationVariables } from "../api/__generated__/updateContractMutation";
import { UPDATE_CONTRACT_MUTATION } from "../api/contractQueriesAndMutations";
import { SnackContext } from "../../../routing/idfyRouter";
import { Box, Checkbox, FormControlLabel, MenuItem } from "@material-ui/core";
import { CustomerDetailsSectionTable } from "../../customers/CustomerDetailsSectionTable";
import { EndContractDialog } from "./EndContractDialog";
import { ContextMenu, useContextMenuStyles } from "../../../components/buttons/ContextMenu";
import { ExtendContractDialog } from "./ExtendContractDialog";
import { handleInvalidForm } from "../../../util/yup/handleInvalidForm";
import { isBlankOrEmpty } from "../../../util/stringUtil";
import { formatDateToIsoDate } from "../../../util/dateTime/formatDate";
import deLocale from "date-fns/locale/de";

export const ContractInfo: React.FunctionComponent<{
  contract: Contract;
  onUpdateContractDetails: () => void;
}> = (props) => {
  const classes = useContextMenuStyles();
  const { t } = useTranslation();
  const snackContext = useContext(SnackContext);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [contract, setContract] = useState<ContractInfoFormFields>(toContractInfoFormFields(props.contract));
  const [endContractDialogOpen, setEndContractDialogOpen] = React.useState<boolean>(false);
  const [extendContractDialogOpen, setExtendContractDialogOpen] = React.useState<boolean>(false);

  const [updateContract] = useMutation<updateContractMutation, updateContractMutationVariables>(
    UPDATE_CONTRACT_MUTATION
  );

  const methods = useForm<ContractInfoFormFields>({
    defaultValues: contract,
    resolver: yupResolver(contractSchema(t)),
  });
  const { handleSubmit, reset, register, setValue } = methods;

  useEffect(() => {
    // We have no customer or contract status field => we must register it manually
    register("customer");
    register("status");
    register("extendedEndDate");
    register("extensionPeriod");
  }, [props.contract]);

  const isContractEndOfTermUnknownWatch = methods.watch("isContractEndOfTermUnknown");
  useEffect(() => {
    if (isContractEndOfTermUnknownWatch) {
      methods.setValue("endDate", null);
    }
  }, [isContractEndOfTermUnknownWatch]);

  function submitUpdatedContract(id: string, values: ContractInput): Promise<FetchResult<updateContractMutation>> {
    return updateContract({
      variables: {
        id: id,
        input: values,
      },
    });
  }

  function notifyUser(result: FetchResult<updateContractMutation>) {
    snackContext.setSnackOpen(!result.errors);
    snackContext.setSnackText(t("pages.contracts.contractUpdated"));
    props.onUpdateContractDetails();
  }

  function onSubmit(values: ContractInfoFormFields) {
    console.log("onSubmit", values);
    submitUpdatedContract(props.contract.id, toContractInput(values)).then(handleContractUpdated);
  }

  const handleEndContract = (value: boolean) => {
    // console.log("handleEndContract:", value);
    setEndContractDialogOpen(false);
    if (value) {
      setValue("status", ContractStatus.INACTIVE);
      // console.log("handleEndContract: Updated status to inactive.");
      const updatedContract: ContractInput = {
        ...toContractInput(contract),
        status: ContractStatus.INACTIVE,
      };
      submitUpdatedContract(props.contract.id, updatedContract).then(handleContractUpdated);
    }
  };

  const displayEndContractDialog = () => {
    setEndContractDialogOpen(true);
  };

  function displayExtendContractDialog() {
    setExtendContractDialogOpen(true);
  }

  function closeExtendContractDialog() {
    setExtendContractDialogOpen(false);
  }

  // Extract the period from the string P1M or P12M.
  function getPeriod(period: string): number {
    return parseInt(period.substring(1, period.indexOf("M", 1)));
  }

  function addExtensionPeriods(currentPeriod: string | null | undefined, periodToAdd: string | undefined) {
    if (periodToAdd === undefined) {
      return currentPeriod;
    } else if (currentPeriod === undefined || currentPeriod === null) {
      return periodToAdd;
    }
    const newPeriod = getPeriod(currentPeriod) + getPeriod(periodToAdd);
    return "P" + newPeriod.toString() + "M";
  }

  function handleExtendContract(extensionPeriod: string | undefined, extendedEndDate: Date | undefined) {
    const combinedExtensionPeriod = addExtensionPeriods(contract.extensionPeriod, extensionPeriod);
    const updatedContract: ContractInput = {
      ...toContractInput(contract),
      extendedEndDate: extendedEndDate ? formatDateToIsoDate(extendedEndDate) : undefined,
      extensionPeriod: combinedExtensionPeriod,
    };
    submitUpdatedContract(props.contract.id, updatedContract).then(handleContractUpdated);
    closeExtendContractDialog();
  }

  function handleContractUpdated(result: FetchResult<updateContractMutation>) {
    setDisabled(true);
    const updatedContract = result.data?.updateContract;
    updatedContract && setContract(toContractInfoFormFields(updatedContract));
    reset(updatedContract);
    // console.info("updatedContract:", updatedContract);
    notifyUser(result);
  }

  const endContractOptionDisabled = contract.status === ContractStatus.INACTIVE;
  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data) => onSubmit(data), handleInvalidForm)}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
            <InfoPageHeader
              setDisabled={setDisabled}
              disabled={disabled}
              header={t("pages.contracts.information.header")}
              onCancel={() => reset(toContractInfoFormFields(props.contract))}
              additionalContent={
                <ContextMenu label={t("pages.contracts.change-status")}>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={displayEndContractDialog}
                    disabled={endContractOptionDisabled}
                  >
                    {t("pages.contracts.end-contract")}
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={displayExtendContractDialog}
                    disabled={isContractEndOfTermUnknownWatch || isBlankOrEmpty(contract.endDate)}
                  >
                    {t("pages.contracts.extend-contract")}
                  </MenuItem>
                </ContextMenu>
              }
            />
            <FormGridContainer>
              <FormGridItem>
                <FormTextField
                  disabled={disabled}
                  name="contractNumber"
                  label={t("pages.contracts.fields.contractNumber")}
                  trimOnBlur
                  required
                />
              </FormGridItem>
              <FormGridItem>
                <FormDateField
                  disabled={disabled}
                  name="startDate"
                  label={t("pages.contracts.fields.startDate")}
                  required
                />
              </FormGridItem>
              <FormGridItem>
                <FormDateField
                  disabled={disabled || isContractEndOfTermUnknownWatch}
                  name="endDate"
                  label={t("pages.contracts.fields.endDate")}
                  required={!isContractEndOfTermUnknownWatch}
                />
                <Box display="flex" justifyContent="flex-end">
                  <Controller
                    control={methods.control}
                    defaultValue={false}
                    variant={"outlined"}
                    size={"small"}
                    color={"secondary"}
                    name={"isContractEndOfTermUnknown"}
                    required
                    render={({ onChange, value, ref }) => (
                      <FormControlLabel
                        disabled={disabled || contract.isContractEndExtended}
                        control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
                        label={t("pages.assets.modals.create-asset.fields.isContractEndOfTermUnknown.label")}
                        inputRef={ref}
                      />
                    )}
                  />
                </Box>
              </FormGridItem>
              {!contract.endDate && (
                <FormGridItem>
                  <Alert severity="warning">{t("pages.contracts.endDateMissingWarning")}</Alert>
                </FormGridItem>
              )}
              {!isBlankOrEmpty(contract.extendedEndDate) && (
                <FormGridItem>
                  <FormDateField
                    disabled={true}
                    name="extendedEndDate"
                    label={t("pages.contracts.fields.extendedEndDate")}
                  />
                </FormGridItem>
              )}
            </FormGridContainer>
            <Box width={"100%"} height={"10vh"} />
            <CustomerDetailsSectionTable
              customer={contract.customer}
              breadcrumbTitle={t("pages.contracts.contractBreadcrumb") + contract.contractNumber}
            />
          </MuiPickersUtilsProvider>
        </form>
      </FormProvider>
      <EndContractDialog
        contractId={props.contract.id}
        open={endContractDialogOpen}
        endContractHandler={handleEndContract}
      />
      <ExtendContractDialog
        open={extendContractDialogOpen}
        onClose={closeExtendContractDialog}
        onConfirm={handleExtendContract}
        contractEndDate={contract.endDate ? new Date(contract.endDate) : new Date()}
      />
    </React.Fragment>
  );
};
