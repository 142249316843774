import { useTranslation } from "react-i18next";
import { Button, createStyles, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import React, { MutableRefObject, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row-reverse",
    },
    button: {
      backgroundColor: "white",
      marginTop: "9px",
      height: "35px",
      width: "34px",
    },
    searchFieldHidden: {
      backgroundColor: "white",
      visibility: "hidden",
      width: "0%",
      opacity: 0,
      transition: "300ms ease",
    },
    searchFieldVisible: {
      backgroundColor: "white",
      visibility: "visible",
      width: "100%",
      opacity: 1,
      transition: "300ms ease",
    },
    endAdornment: {
      marginRight: "-6px",
    },
  })
);

export const SearchField: React.FunctionComponent<{
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
  value?: unknown;
  placeholder?: string;
  className?: string;
}> = (props) => {
  // Used to set the cursor focus on the search bar after expanding it
  // eslint-disable-next-line
  const useFocus = (): [any, () => void] => {
    const htmlElRef: MutableRefObject<HTMLDivElement | undefined> = useRef<HTMLDivElement>();
    const setFocus = (): void => {
      if (!htmlElRef || !htmlElRef.current) return;
      const div = htmlElRef.current as HTMLDivElement;
      if (!div) return;
      const input = div.querySelector("input");
      if (input) input.focus();
    };
    return [htmlElRef, setFocus];
  };

  const { t } = useTranslation();
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  const [ref, setFocus] = useFocus();
  const [searchText, setSearchText] = useState<string>("");

  const searchFieldClassNames = classNames(
    props.className,
    !expanded && classes.searchFieldHidden,
    expanded && classes.searchFieldVisible
  );

  return (
    <div className={classes.container}>
      {!expanded && (
        <Button
          className={classes.button}
          onClick={() => {
            setExpanded(true);
            // Little hacky trick to set the focus after expanding the search bar
            setTimeout(() => {
              setFocus();
            }, 100);
          }}
        >
          <SearchIcon />
        </Button>
      )}
      <TextField
        color={"secondary"}
        ref={ref}
        margin="dense"
        className={searchFieldClassNames}
        variant="outlined"
        label={t("search-field.label")}
        placeholder={props.placeholder || ""}
        onChange={(e) => {
          props.onChange(e);
          setSearchText(e.target.value);
        }}
        onBlur={() => {
          if (searchText === "") {
            setExpanded(false);
          }
        }}
        value={props.value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon className={classes.endAdornment} />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
