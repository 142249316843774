import { useQuery } from "@apollo/client";

import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  createStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { NoDataPlaceholder } from "../../components/NoDataPlaceholder";
import { TableHeader } from "../../components/TableHeader";
import { BreadcrumbContext, SnackContext } from "../../routing/idfyRouter";
import { routes } from "../../routing/routes";
import { idfyTheme, palette } from "../../util/IdfyTheme";
import { BasePage } from "../pageFragments/BasePage";
import { TableWrapperPaper } from "../pageFragments/util/TableWrapperPaper";
import { assetsByCustomerIdQuery } from "../assets/api/__generated__/assetsByCustomerIdQuery";
import { CreateCustomerModal } from "./createCustomer/CreateCustomerModal";
import { SearchField } from "../../components/SearchField";
import { Team } from "../../__generated__/globalTypes";
import { useCurrentAccount } from "../../util/auth/useCurrentAccount";
import { CUSTOMERS_QUERY } from "./api/customerQueriesAndMutations";
import { customersInfoQuery, customersInfoQuery_customers } from "./api/__generated__/customersInfoQuery";
import { ASSETS_BY_CUSTOMER_ID_QUERY } from "../assets/api/assetQueriesAndMutations";
import { getTeamFilterForUser, getTeamFilterOptions, TeamFilter } from "../../components/team/TeamFilter";
import { SearchSelectField } from "../../components/SearchSelectField";
import { isAllowed } from "../../components/access/AccessManager";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      overflow: "auto",
      "-webkit-overflow-scrolling": "touch",
    },
    tableRow: {
      padding: `${theme.spacing(4)}px 0`,
    },
    tableHeadRow: {
      "& th": {
        background: theme.palette.background.paper,
        color: palette.mediumGrey,
      },
    },
    mobileTableRow: {},
    leasedObjectsTableOpen: {
      flexGrow: 1,
      marginLeft: theme.spacing(2),
      width: "auto !important",
    },
    leasedObjectsTableClosed: {
      width: 0,
      overflow: "hidden",
    },
    tablesWrapper: {
      flexGrow: 1,
      overflow: "hidden",
      padding: theme.spacing(1),
      margin: theme.spacing(-1),
    },
    leasedObjectsButton: {
      color: idfyTheme.palette.secondary.main,
      textDecoration: "underline",
    },
    additionalContentContainer: {
      display: "flex",
      width: "33%",
      alignItems: "center",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginTop: theme.spacing(1),
      },
    },
    menuItem: {
      fontWeight: "bold",
      fontSize: "14px",
    },
  })
);

export const CustomersPage: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"), { noSsr: true });
  const snackContext = useContext(SnackContext);
  const header = t("pages.customers.header");

  const { data } = useQuery<customersInfoQuery>(CUSTOMERS_QUERY, {
    fetchPolicy: "network-only",
  });

  const [newModalOpen, setNewModalOpen] = useState(false);
  const [leasedObjectsTableOpen, setLeasedObjectsTableOpen] = useState(false);
  const [currentLeasedObjectsCustomerId, setCurrentCustomerId] = useState("-1");
  const [currentLeasedObjectsCustomerName, setCurrentCustomerName] = useState("");
  const [searchText, setSearchText] = useState("");
  const defaultTeam = getTeamFilterForUser(useCurrentAccount());
  const [teamFilterValue, setTeamFilterValue] = useState<TeamFilter>(defaultTeam);

  const utilContext = useContext(BreadcrumbContext);

  utilContext.breadcrumbs = [];

  function toggleLeasedObjectsTable(customerId: string, customerName: string) {
    if (leasedObjectsTableOpen) {
      if (currentLeasedObjectsCustomerId === customerId) {
        setLeasedObjectsTableOpen(false);
      } else setCurrentCustomerId(customerId);
      setCurrentCustomerName(customerName);
    } else {
      setLeasedObjectsTableOpen(true);
      setCurrentCustomerId(customerId);
      setCurrentCustomerName(customerName);
    }
  }

  const filteredCustomers = useMemo(() => {
    if (searchText.length === 0 && teamFilterValue === TeamFilter.ALL) {
      return data?.customers;
    }
    let filteredList = data?.customers;
    if (teamFilterValue === TeamFilter.NORTH) {
      filteredList = filteredList?.filter((customer) => customer.responsibleTeam === Team.NORTH);
    }
    if (teamFilterValue === TeamFilter.SOUTH) {
      filteredList = filteredList?.filter((customer) => customer.responsibleTeam === Team.SOUTH);
    }
    if (teamFilterValue === TeamFilter.ASSET_MONITORING) {
      filteredList = filteredList?.filter((customer) => customer.responsibleTeam === Team.ASSET_MONITORING);
    }
    return filteredList?.filter((customer) => {
      const fullCustomerName = customer.lastName + ", " + customer.firstName;
      return (
        customer.company.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
        customer.customerNumber.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
        customer.firstName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
        customer.lastName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
        fullCustomerName.toLocaleLowerCase().indexOf(searchText.toLowerCase()) !== -1
      );
    });
  }, [searchText, data, teamFilterValue]);

  const teamOptions = getTeamFilterOptions(t);
  const handleChange = (newValue: TeamFilter) => {
    setTeamFilterValue(newValue);
  };

  return (
    <BasePage headerText={header}>
      <TableHeader
        title={header}
        buttonText={t("pages.customers.buttons.new")}
        onButtonClick={() => setNewModalOpen(true)}
        enabled={isAllowed("pages.customers.buttons.new", useCurrentAccount())}
        additionalContent={
          <div className={classes.additionalContentContainer}>
            <SearchField
              placeholder={t("pages.customers.search-customers")}
              onChange={(event) => setSearchText(event.target.value)}
            />
            <SearchSelectField
              options={teamOptions}
              onSelectChange={(newField) => handleChange(newField as TeamFilter)}
              initValue={defaultTeam}
            />
          </div>
        }
      />
      <div className={classes.tablesWrapper}>
        <TableWrapperPaper fullHeight={filteredCustomers?.length == 0}>
          {data && (
            <CustomerTable
              customerArray={filteredCustomers || []}
              mobileOpen={{
                customerId: currentLeasedObjectsCustomerId,
                open: leasedObjectsTableOpen,
              }}
              toggleLeasedObjectsTable={toggleLeasedObjectsTable}
            />
          )}
        </TableWrapperPaper>
        {!mobile ? (
          <LeasedObjectsTable
            open={leasedObjectsTableOpen}
            customerId={currentLeasedObjectsCustomerId}
            customerName={currentLeasedObjectsCustomerName}
          />
        ) : null}
      </div>
      <CreateCustomerModal
        handleClose={(success: boolean) => {
          setNewModalOpen(false);
          snackContext.setSnackOpen(success);
          snackContext.setSnackText(t("pages.customers.alerts.customer-created"));
        }}
        newModalOpen={newModalOpen}
      />
    </BasePage>
  );
};

export const CustomerTable: React.FunctionComponent<{
  customerArray: customersInfoQuery_customers[];
  toggleLeasedObjectsTable: (customerId: string, customerName: string) => void;
  mobileOpen: { customerId: string; open: boolean };
}> = (props) => {
  const classes = useStyles();
  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"), { noSsr: true });
  const { t } = useTranslation();

  if (props.customerArray.length <= 0) {
    return (
      <NoDataPlaceholder
        title={t("pages.customers.no-data-placeholder.title")}
        subTitle={t("pages.customers.no-data-placeholder.subtitle")}
      />
    );
  }

  return (
    <React.Fragment>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          {mobile ? null : (
            <TableHead>
              <TableRow className={classes.tableHeadRow}>
                <TableCell>{t("pages.customers.table.customer").toUpperCase()}</TableCell>
                <TableCell>{t("pages.customers.table.customer-number").toUpperCase()}</TableCell>
                <TableCell>{t("pages.customers.table.contactPerson").toUpperCase()}</TableCell>
                <TableCell>{t("pages.customers.table.objects").toUpperCase()}</TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {props.customerArray
              .slice()
              .sort((c1, c2) => c1.company.localeCompare(c2.company))
              .map((data, i) =>
                mobile ? (
                  <MobileCustomerRow
                    key={i}
                    customer={data}
                    toggleLeasedObjectsTable={props.toggleLeasedObjectsTable}
                    open={props.mobileOpen}
                  />
                ) : (
                  <CustomerRow key={i} customer={data} toggleLeasedObjectsTable={props.toggleLeasedObjectsTable} />
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

function CustomerRow(props: {
  customer: customersInfoQuery_customers;
  toggleLeasedObjectsTable: (customerId: string, customerName: string) => void;
}) {
  const classes = useStyles();
  const history = useHistory();

  const { data } = useQuery<assetsByCustomerIdQuery>(ASSETS_BY_CUSTOMER_ID_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      id: props.customer.id,
    },
  });

  const { t } = useTranslation();
  const name = props.customer.lastName + ", " + props.customer.firstName;
  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));

  return data?.assets ? (
    <TableRow
      onClick={() => history.push(routes.customers + routes.details(props.customer.id) + routes.infoSuffix)}
      className={classes.tableRow}
      hover={!mobile}
    >
      <TableCell>
        <b>{props.customer.company}</b>
      </TableCell>
      <TableCell>
        <b>{props.customer.customerNumber}</b>
      </TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>
        <span
          onClick={(e) => {
            props.toggleLeasedObjectsTable(props.customer.id, name);
            e.stopPropagation();
          }}
          className={classes.leasedObjectsButton}
        >
          {data.assets.length.toString() + " " + t("pages.customers.table.objects")}
        </span>
      </TableCell>
    </TableRow>
  ) : (
    <React.Fragment>{null}</React.Fragment>
  );
}

function MobileCustomerRow(props: {
  customer: customersInfoQuery_customers;
  toggleLeasedObjectsTable: (customerId: string, customerName: string) => void;
  open: { customerId: string; open: boolean };
}) {
  const history = useHistory();
  const classes = useStyles();
  const { data } = useQuery<assetsByCustomerIdQuery>(ASSETS_BY_CUSTOMER_ID_QUERY, {
    variables: { id: props.customer.id },
    fetchPolicy: "network-only",
  });

  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));
  const utilContext = useContext(BreadcrumbContext);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <TableRow
        onClick={() => history.push(routes.customers + routes.details(props.customer.id) + routes.infoSuffix)}
        className={classes.mobileTableRow}
        hover={!mobile}
      >
        <TableCell>
          <b>{props.customer.company}</b>
          <br />
          <b>{t("pages.customers.table.customer-number") + ": " + props.customer.customerNumber}</b>
          <br />
          {t("pages.customers.table.contactPerson") + ": " + props.customer.lastName + ", " + props.customer.firstName}
          <br />
          <span
            onClick={(e) => {
              props.toggleLeasedObjectsTable(props.customer.id, "Error");
              e.stopPropagation();
            }}
            className={classes.leasedObjectsButton}
          >
            {(data?.assets?.length.toString() || "0") + " " + t("pages.customers.table.objects")}
          </span>
        </TableCell>
      </TableRow>
      {props.open.customerId === props.customer.id && props.open.open
        ? data?.assets?.map((asset, i) => (
          <TableRow
            key={i}
            onClick={() => {
              utilContext.breadcrumbs.push({
                url: history.location.key || "errorPage",
                title: "Kunden",
              });
              history.push(routes.customers + routes.details(props.customer.id) + routes.infoSuffix);
            }}
            className={classes.mobileTableRow}
            hover
          >
            <TableCell>
              <b>{asset.name}</b>
            </TableCell>
          </TableRow>
        ))
        : null}
    </React.Fragment>
  );
}

const LeasedObjectsTable = (props: { open: boolean; customerId: string; customerName: string }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data } = useQuery<assetsByCustomerIdQuery>(ASSETS_BY_CUSTOMER_ID_QUERY, {
    variables: { id: props.customerId },
    fetchPolicy: "network-only",
  });

  return (
    <TableContainer
      component={Paper}
      className={props.open ? classes.leasedObjectsTableOpen : classes.leasedObjectsTableClosed}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("pages.customers.table.objects-of") + props.customerName}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.assets?.map((asset, i) => (
            <TableRow key={i}>
              <TableCell>{asset.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
