import React, { FC } from "react";

import { Skeleton } from "@material-ui/lab";

import { AssetProtocolEntry } from "./AssetProtocolEntry";

export interface AssetProtocolEntrySkeletonProps {
  // The seed is used to give the skeletons some variance in length
  seed: number;
}

/**
 * A simple skeleton class that can be rendered while the real events are loading.
 */
export const AssetProtocolEntrySkeleton: FC<AssetProtocolEntrySkeletonProps> = (props) => (
  <AssetProtocolEntry
    title={<Skeleton variant="text" style={{ width: 300 + ((props.seed * 1234) % 71) }} />}
    time={<Skeleton variant="text" style={{ width: 130 }} />}
    fields={[
      {
        text: <Skeleton variant="text" style={{ width: 100 + ((props.seed * 1234) % 43) }} />,
        icon: <Skeleton variant="rect" style={{ width: 20, height: 20 }} />,
      },
      {
        text: <Skeleton variant="text" style={{ width: 90 + ((props.seed * 4321) % 23) }} />,
        icon: <Skeleton variant="rect" style={{ width: 20, height: 20 }} />,
      },
    ]}
    icon={<Skeleton variant="rect" style={{ width: 25, height: 25 }} />}
  />
);
