import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { CreateAssetInputFormFields } from "./CreateAssetInput";
import { manufacturersQuery } from "../../manufacturers/api/__generated__/manufacturersQuery";
import { MANUFACTURERS_QUERY } from "../../manufacturers/api/manufacturerQueriesAndMutations";
import {
  ASSET_DETAIL_QUERY,
  ASSETS_AND_SUB_ASSETS_BY_MANUFACTURER_AND_SERIAL_NUMBER_QUERY,
  SUB_ASSET_QUERY,
} from "../api/assetQueriesAndMutations";
import { AssetOrSubAssetAlertReason } from "../../../components/DuplicateAssetOrSubAssetAlert";
import { formatDateToIsoDate } from "../../../util/dateTime/formatDate";
import { AssetStatus, SubAssetInput, SupplierInput } from "../../../__generated__/globalTypes";
import { createAssetInfoValidationSchema } from "./validation/CreateAssetInfoValidationSchema";
import { isBlankOrEmpty } from "../../../util/stringUtil";
import {
  assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBoth,
  assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBothVariables,
} from "../api/__generated__/assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBoth";
import { CreateAssetInfoCreationFields } from "./form-fields/asset-info/CreateAssetInfoCreationFields";
import { CreateAssetInfoObjectFields, MatchedAsset } from "./form-fields/asset-info/CreateAssetInfoObjectFields";
import { Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { CreateAssetInfoContractFields } from "./form-fields/asset-info/CreateAssetInfoContractFields";
import { assetDetailQuery } from "../api/__generated__/assetDetailQuery";
import { subAssetQuery } from "../api/__generated__/subAssetQuery";
import { blueprintNamesQuery } from "../../blueprints/api/__generated__/blueprintNamesQuery";
import { BLUEPRINT_NAMES_QUERY } from "../../blueprints/api/blueprintQueriesAndMutations";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formGrid: {
      boxSizing: "border-box",
      paddingTop: theme.spacing(3),
    },
  })
);

export type AssetSidebarFields = {
  name: string;
  serialNumber: string | null;
  contractNumber: string;
  isSerialNumberUnknown: boolean;
};

export const CreateAssetInfo: React.FunctionComponent<{
  assetInput: CreateAssetInputFormFields;
  setAssetInput: React.Dispatch<React.SetStateAction<CreateAssetInputFormFields>>;
  setFormSubmit: (fun: () => () => boolean) => void;
  onSidebarFieldsChange: (fields: AssetSidebarFields) => void;
  onManufacturerAndSerialInUseStateChange: (inUser: boolean) => void;
  customerId?: string;
}> = (props) => {
  const classes = useStyles();
  // Indicates whether another asset with same manufacturer and ID exists
  const [manufacturerAndSerialInUse, setManufacturerAndSerialInUse] = useState<MatchedAsset>({
    inUse: props.assetInput.useOutOfContractAsset,
    assetId: props.assetInput.assetId || "-1",
    outOfContract: props.assetInput.useOutOfContractAsset,
  });
  const [duplicateReason, setDuplicateReason] = useState<AssetOrSubAssetAlertReason | undefined>(undefined);
  const [isNewDisabled, setIsNewDisabled] = useState(false);
  const [isSerialNumberDisabled, setIsSerialNumberDisabled] = useState(false);
  const [displayUseExistingContractPrompt, setDisplayUseExistingContractPrompt] = useState<boolean>(false);
  const { data: manufacturersData } = useQuery<manufacturersQuery>(MANUFACTURERS_QUERY);
  const { data: blueprintData } = useQuery<blueprintNamesQuery>(BLUEPRINT_NAMES_QUERY);

  const client = useApolloClient();
  const assetsAndSubAssetsByManufacturerAndSerialNumber = async (manufacturerId: string, serialNumber: string) => {
    return client.query<
      assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBoth,
      assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBothVariables
    >({
      query: ASSETS_AND_SUB_ASSETS_BY_MANUFACTURER_AND_SERIAL_NUMBER_QUERY,
      variables: { manufacturerId: manufacturerId, serialNumber: serialNumber },
    });
  };

  const methods = useForm<{
    name: string;
    isSerialNumberUnknown: boolean;
    serialNumber: string | null;
    contractNumber: string;
    manufacturerId: string;
    blueprintId: string;
    isNew: boolean;
    contractDate: Date;
    isContractEndOfTermUnknown: boolean;
    contractEndOfTerm: Date | null;
    supplierId: string;
    isAlreadyInStock: boolean;
    newSupplier: SupplierInput | undefined;
    showSupplierFields: boolean;
    supplierInvoiceNumber: string | null;
    invoiceDate: Date | null;
    useExistingContract: boolean;
    customerId: string;
    useOutOfContractAsset: boolean;
    assetId: string | null;
  }>({
    resolver: yupResolver(createAssetInfoValidationSchema()),
    mode: "onChange",
    defaultValues: {
      name: props.assetInput.asset.name || "",
      isSerialNumberUnknown: props.assetInput.isSerialNumberUnknown || false,
      serialNumber: props.assetInput.asset.serialNumber || "",
      contractNumber: props.assetInput.asset.contractNumber || "",
      manufacturerId: props.assetInput.asset.manufacturerId || "",
      blueprintId: props.assetInput.asset.blueprintId || "",
      contractDate: new Date(props.assetInput.asset.contractDate) || undefined,
      isContractEndOfTermUnknown: props.assetInput.isContractEndOfTermUnknown || false,
      contractEndOfTerm: isBlankOrEmpty(props.assetInput.asset.contractEndOfTerm)
        ? undefined
        : new Date(props.assetInput.asset.contractEndOfTerm!),
      supplierId: props.assetInput.asset.supplierId || "",
      showSupplierFields: props.assetInput.showSupplierFields || false,
      isAlreadyInStock: props.assetInput.asset.isAlreadyInStock,
      isNew: props.assetInput.asset.isNew,
      newSupplier: {
        name: props.assetInput.newSupplier?.name || "",
        streetAddress: props.assetInput.newSupplier?.streetAddress || "",
        zip: props.assetInput.newSupplier?.zip || "",
        city: props.assetInput.newSupplier?.city || "",
      },
      supplierInvoiceNumber: props.assetInput.asset.supplierInvoiceNumber || "",
      invoiceDate: isBlankOrEmpty(props.assetInput.asset.invoiceDate)
        ? undefined
        : new Date(props.assetInput.asset.invoiceDate!),
      useExistingContract: props.assetInput.useExistingContract || false,
      customerId: props.assetInput.asset.customerId || "",
      useOutOfContractAsset: props.assetInput.useOutOfContractAsset,
      assetId: props.assetInput.assetId,
    },
  });

  const name = methods.watch("name");
  const serialNumber = methods.watch("serialNumber");
  const contractNumber = methods.watch("contractNumber");
  const manufacturerId = methods.watch("manufacturerId");
  const isSerialNumberUnknownWatch = methods.watch("isSerialNumberUnknown");
  const isAlreadyInStockWatch = methods.watch("isAlreadyInStock");
  const useOutOfContractAssetWatch = methods.watch("useOutOfContractAsset");

  useEffect(() => {
    const manufacturer = manufacturersData?.manufacturers.filter((m) => m.id === manufacturerId)[0];
    const manufacturerName = manufacturer?.name || "";
    const displayName = manufacturerName === "" && name === "" ? "" : manufacturerName + " " + name;
    const changedFields = {
      name: displayName,
      serialNumber: serialNumber,
      contractNumber: contractNumber,
      isSerialNumberUnknown: isSerialNumberUnknownWatch,
    } as AssetSidebarFields;
    props.onSidebarFieldsChange(changedFields);
    // Including props causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, serialNumber, contractNumber, manufacturerId, isSerialNumberUnknownWatch]);

  const [getAssetDetails, { data: assetDetails, loading: getAssetDetailsLoading }] =
    useLazyQuery<assetDetailQuery>(ASSET_DETAIL_QUERY);
  const [getSubAssets, { data: subAssets, loading: getSubAssetsLoading }] =
    useLazyQuery<subAssetQuery>(SUB_ASSET_QUERY);

  useEffect(() => {
    if (manufacturerId && serialNumber) {
      console.log("Running use effect for manufacturerId/serialnumber", manufacturerId, serialNumber);
      assetsAndSubAssetsByManufacturerAndSerialNumber(manufacturerId, serialNumber).then((result) => {
        if (result.data.assetsByManufacturerAndSerialNumber.length > 0 && !useOutOfContractAssetWatch) {
          setManufacturerAndSerialInUse({
            inUse: true,
            assetId: result.data.assetsByManufacturerAndSerialNumber[0].id,
            outOfContract: false,
          });

          methods.setError("manufacturerId", {});
          methods.setError("serialNumber", {});

          getAssetDetails({
            variables: {
              id: result.data.assetsByManufacturerAndSerialNumber[0].id,
            },
          });
        } else if (result.data.subAssetsByManufacturerAndSerialNumber.length > 0) {
          setManufacturerAndSerialInUse({
            inUse: true,
            assetId: result.data.subAssetsByManufacturerAndSerialNumber[0].assetId,
            outOfContract: false,
          });

          methods.setError("manufacturerId", {});
          methods.setError("serialNumber", {});
          setDuplicateReason("SerialNumberUsedInExistingSubAsset");
          props.onManufacturerAndSerialInUseStateChange(true);
        } else {
          setManufacturerAndSerialInUse({ inUse: false, assetId: "-1", outOfContract: false });
          methods.clearErrors("manufacturerId");
          methods.clearErrors("serialNumber");
          props.onManufacturerAndSerialInUseStateChange(false);
          setDuplicateReason(undefined);
        }
      });
    }
  }, [manufacturerId, serialNumber, useOutOfContractAssetWatch]);

  useEffect(() => {
    if (!getAssetDetailsLoading) {
      console.log("useEffect::assetDetails?.asset", assetDetails?.asset);
      if (assetDetails?.asset.assetStatus == AssetStatus.OUT_OF_CONTRACT) {
        setManufacturerAndSerialInUse((prevState) => ({
          ...prevState,
          outOfContract: true,
        }));
        setDuplicateReason(undefined);
        props.onManufacturerAndSerialInUseStateChange(true);
      } else if (manufacturerAndSerialInUse.inUse) {
        setDuplicateReason("SerialNumberUsedInExistingAsset");
        props.onManufacturerAndSerialInUseStateChange(true);
      }
      console.log("setManufacturerAndSerialInUse", manufacturerAndSerialInUse);
    }
  }, [assetDetails, getAssetDetailsLoading]);

  useEffect(() => {
    if (!getSubAssetsLoading) {
      console.log("subAssets?.asset.subAssets", subAssets?.asset.subAssets);
      if (subAssets) {
        const subAssetInputs: SubAssetInput[] = subAssets?.asset.subAssets.map((sa) => {
          return {
            name: sa.name,
            serialNumber: sa.serialNumber,
            manufacturerId: sa.manufacturer?.id || "",
          };
        });
        props.setAssetInput((prevState) => ({
          ...prevState,
          asset: {
            ...prevState.asset,
            subAssets: subAssetInputs,
          },
          existingSubAssets: subAssets.asset.subAssets,
        }));
      }
    }
  }, [subAssets, getSubAssetsLoading]);

  const useExistingAssetHandler = (useAsset: boolean) => {
    if (useAsset && assetDetails) {
      console.log("Calling sub assets query for ", assetDetails?.asset.id);
      getSubAssets({ variables: { id: assetDetails.asset.id } });
    } else {
      props.setAssetInput((prevState) => ({
        ...prevState,
        asset: {
          ...prevState.asset,
          subAssets: Array<SubAssetInput>(),
        },
      }));
    }
  };

  useEffect(() => {
    console.log("useEffect::manufacturerAndSerialInUse", manufacturerAndSerialInUse);
    console.log("useEffect::duplicateReason", duplicateReason);
  }, [manufacturerAndSerialInUse, duplicateReason]);

  useEffect(() => {
    if (isAlreadyInStockWatch) {
      methods.setValue("isNew", false);
      setIsNewDisabled(true);
    } else {
      setIsNewDisabled(false);
    }
  }, [isAlreadyInStockWatch]);

  useEffect(() => {
    if (isSerialNumberUnknownWatch) {
      methods.setValue("serialNumber", "");
      setIsSerialNumberDisabled(true);
    } else {
      setIsSerialNumberDisabled(false);
    }
  }, [isSerialNumberUnknownWatch]);

  useEffect(() => {
    if (useOutOfContractAssetWatch) {
      methods.setValue("isNew", false);
    }
  }, [useOutOfContractAssetWatch]);

  function onSubmit(data: {
    name: string;
    serialNumber: string | null;
    contractNumber: string;
    manufacturerId: string;
    blueprintId: string;
    isNew: boolean;
    contractDate: Date;
    contractEndOfTerm: Date | null;
    supplierId: string;
    isAlreadyInStock: boolean;
    newSupplier: SupplierInput | undefined;
    supplierInvoiceNumber: string | null;
    invoiceDate: Date | null;
    isContractEndOfTermUnknown: boolean;
    useExistingContract: boolean;
    customerId: string;
    showSupplierFields: boolean;
    useOutOfContractAsset: boolean;
    assetId: string | null;
  }) {
    console.log("Started submit", data);
    let supplierInput: SupplierInput | null;

    if (data?.newSupplier?.name && data?.newSupplier.streetAddress && data?.newSupplier.zip && data?.newSupplier.city) {
      supplierInput = {
        name: data.newSupplier!.name,
        streetAddress: data.newSupplier!.streetAddress,
        zip: data.newSupplier!.zip,
        city: data.newSupplier!.city,
      };
    } else {
      supplierInput = null;
    }
    props.setAssetInput((prevState) => ({
      ...prevState,
      asset: {
        ...prevState.asset,
        name: data.name,
        serialNumber: isBlankOrEmpty(data.serialNumber) ? null : data.serialNumber,
        contractNumber: data.contractNumber,
        isNew: data.isNew,
        isAlreadyInStock: data.isAlreadyInStock,
        manufacturerId: data.manufacturerId,
        blueprintId: data.blueprintId !== "-1" ? data.blueprintId : null,
        contractDate: formatDateToIsoDate(data.contractDate),
        contractEndOfTerm: data.contractEndOfTerm !== null ? formatDateToIsoDate(data.contractEndOfTerm) : null,
        supplierId: isBlankOrEmpty(data.supplierId) ? null : data.supplierId,
        supplierInvoiceNumber: data.supplierInvoiceNumber,
        invoiceDate: data.invoiceDate !== null ? formatDateToIsoDate(data.invoiceDate) : null,
        customerId: data.customerId,
      },
      newSupplier: supplierInput,
      showSupplierFields: data.showSupplierFields,
      isSerialNumberUnknown: isSerialNumberUnknownWatch,
      isContractEndOfTermUnknown: data.isContractEndOfTermUnknown,
      useExistingContract: data.useExistingContract,
      useOutOfContractAsset: data.useOutOfContractAsset,
      assetId: isBlankOrEmpty(data.assetId) ? null : data.assetId,
    }));
    console.log("Finished submit");
  }

  const displayUseExistingContractPromptHandler = (display: boolean) => {
    setDisplayUseExistingContractPrompt(display);
  };

  const formSubmit = () => {
    console.log("formSubmit::displayUseExistingContractPrompt", displayUseExistingContractPrompt);
    if (displayUseExistingContractPrompt) {
      return false;
    }

    void methods.handleSubmit((data) => onSubmit(data))();
    console.log("formSubmit.isValid", methods.formState.isValid);
    console.log("formSubmit.errors", methods.formState.errors);
    return methods.formState.isValid;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => props.setFormSubmit(() => formSubmit), [methods.formState.isValid]);

  if (!manufacturersData) return <></>;
  if (!blueprintData) return <></>;

  return (
    <FormProvider {...methods}>
      <form onSubmit={formSubmit}>
        <CreateAssetInfoCreationFields isNewDisabled={isNewDisabled} />
        <Grid container spacing={3} justifyContent={"space-around"} className={classes.formGrid}>
          <Grid item xs={12} md={12} lg={6}>
            <CreateAssetInfoObjectFields
              manufacturerAndSerialInUse={manufacturerAndSerialInUse}
              duplicateReason={duplicateReason}
              blueprintData={blueprintData}
              manufacturersData={manufacturersData}
              isSerialNumberUnknown={isSerialNumberUnknownWatch}
              isSerialNumberDisabled={isSerialNumberDisabled}
              foundAsset={assetDetails?.asset}
              useExistingAsset={useExistingAssetHandler}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <CreateAssetInfoContractFields
              isNewDisabled={isNewDisabled}
              displayUseExistingContractPromptCallback={displayUseExistingContractPromptHandler}
              customerId={props.customerId}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
