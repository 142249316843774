import { gql } from "@apollo/client";

export const acceptanceDeclarationFragment = gql`
  fragment AcceptanceDeclaration on AcceptanceDeclaration {
    hasInstructionManual
    id
    isBrandNewAndWorking
    isCheckedAndAccepted
    isDeliveryComplete
    isFreeOfDefects
    isInvoiceCorrect
    note
    submissionDateTime
  }
`;
