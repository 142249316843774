import React from "react";
import { useTranslation } from "react-i18next";
import { FormTextField } from "../../components/forms/FormTextField";
import { TeamField } from "../../components/team/TeamField";
import { FormGridContainer, FormGridItem, FormGridSectionHeader } from "../../components/forms/FormGrid";

export function CustomerForm(props: { disabled?: boolean; emailChangeDisabled?: boolean }): React.ReactElement {
  const { disabled, emailChangeDisabled } = props;
  const { t } = useTranslation();

  return (
    <>
      <FormGridContainer>
        <FormGridItem>
          <FormTextField
            name="company"
            label={t("pages.customers.fields.company")}
            disabled={disabled}
            required={true}
          />
        </FormGridItem>
        <FormGridItem>
          <FormTextField
            name="customerNumber"
            label={t("pages.customers.fields.customer-number")}
            disabled={disabled}
            required={true}
          />
        </FormGridItem>
        <FormGridItem>
          <FormTextField
            name="streetAddress"
            label={t("pages.customers.fields.streetAddress")}
            disabled={disabled}
            required={true}
          />
        </FormGridItem>
        <FormGridItem />
        <FormGridItem>
          <FormTextField name="zip" label={t("pages.customers.fields.zip")} disabled={disabled} required={true} />
        </FormGridItem>
        <FormGridItem>
          <FormTextField name="city" label={t("pages.customers.fields.city")} disabled={disabled} required={true} />
        </FormGridItem>
      </FormGridContainer>
      <FormGridContainer>
        <FormGridSectionHeader header={t("pages.customers.fields.contact-person")} />
        <FormGridItem>
          <FormTextField
            name="firstName"
            label={t("pages.customers.fields.first-name")}
            disabled={disabled}
            required={true}
          />
        </FormGridItem>
        <FormGridItem>
          <FormTextField
            name="lastName"
            label={t("pages.customers.fields.last-name")}
            disabled={disabled}
            required={true}
          />
        </FormGridItem>
        <FormGridItem>
          <FormTextField
            name="email"
            label={t("pages.customers.fields.email")}
            disabled={emailChangeDisabled || disabled}
          />
        </FormGridItem>
        <FormGridItem>
          <FormTextField
            name="telephoneNumber"
            label={t("pages.customers.fields.telephoneNumber")}
            disabled={disabled}
          />
        </FormGridItem>
        <FormGridItem>
          <TeamField
            name={"responsibleTeam"}
            label={t("pages.customers.fields.responsibleTeam")}
            disabled={disabled}
          />
        </FormGridItem>
      </FormGridContainer>
    </>
  );
}
