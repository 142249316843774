import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import InfoIcon from "@material-ui/icons/Info";
import StarsIcon from "@material-ui/icons/Stars";

import { DetailsMenu, DetailsMenuSubPage } from "../../../components/DetailsMenu";
import { routes } from "../../../routing/routes";
import { Contract } from "../api/__generated__/Contract";
import { ContractStatusChip } from "../ContractStatusChip";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

export function ContractMenu(props: { contract: Contract }): ReactElement {
  const { contract } = props;
  const { t } = useTranslation();

  const subPages: DetailsMenuSubPage[] = [
    {
      label: t("pages.contracts.buttons.information"),
      icon: InfoIcon,
      path: routes.infoSuffix,
    },
    {
      label: t("pages.contracts.buttons.objects"),
      icon: StarsIcon,
      path: routes.assetsSuffix,
    },
    // {
    //   label: t("pages.contracts.buttons.protocol"),
    //   icon: HistoryIcon,
    //   path: routes.protocolSuffix,
    // },
  ];

  return (
    <DetailsMenu
      title={props.contract.contractNumber}
      subtitle={<ContractStatusChip contract={contract} />}
      icon={(className) => <DescriptionOutlinedIcon className={className} />}
      subPages={subPages}
      subPageActiveCheck={(button) =>
        window.location.pathname === routes.contracts + routes.details(props.contract.id) + button.path
      }
      routeFunction={(id) => routes.contracts + routes.details(id)}
    />
  );
}
