import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Badge, Chip, Grid, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";

import { idfyTheme } from "../../../../util/IdfyTheme";
import { getTranslatedFeatureType } from "../../../../util/stringUtil";
import { ImageModal } from "../../../pageFragments/util/ImageModal";
import { blueprintInfoQuery_blueprint_featureBlueprints } from "../__generated__/blueprintInfoQuery";
import { getFeatureTypeIcon } from "../../../../util/iconUtil";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editButton: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        marginLeft: 0,
      },
    },
    inspectionModalFeatureRowTitleArea: {
      "& div input": {
        fontWeight: "bold",
        color: idfyTheme.palette.text.primary,
      },
    },
    inspectionModalTag: {
      // Padding of title text area
      paddingLeft: "14px",
    },
    noOutline: {
      "& fieldset": {
        border: 0,
      },
    },
    image: {
      width: "100%",
      height: "100%",
      "&:hover ": {
        cursor: "pointer",
      },
    },
    descriptionWrapper: {
      width: "100%",
      height: "100%",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    paper: {
      padding: theme.spacing(3),
      margin: `${theme.spacing(1)}px 0`,
    },
    description: {
      height: "100%",
      "& > div": {
        height: "100%",
        alignItems: "flex-start",
      },
    },
    typeChip: {
      marginTop: theme.spacing(1),
    },
  })
);

export const BlueprintModalFormRow: React.FunctionComponent<{
  feature: blueprintInfoQuery_blueprint_featureBlueprints;
}> = (props) => {
  const classes = useStyles();

  const [imageModalOpen, setImageModalOpen] = useState(false);

  const imgSource = "data:image/jpg;base64," + props.feature.images[0].data;
  const { t } = useTranslation();

  return (
    <Paper elevation={1} className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={3} xl={2}>
          <Badge
            color={"primary"}
            badgeContent={
              <React.Fragment>
                <PhotoLibraryIcon fontSize={"inherit"} style={{ marginRight: idfyTheme.spacing(0.5) }} />
                {props.feature.images.length}
              </React.Fragment>
            }
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          >
            <img src={imgSource} onClick={() => setImageModalOpen(true)} alt="" className={classes.image} />
          </Badge>
          <ImageModal
            images={props.feature.images}
            handleClose={() => setImageModalOpen(false)}
            open={imageModalOpen}
            header={props.feature.title}
            currentIndex={0}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3} xl={3}>
          <Typography variant={"h2"}>{props.feature.title}</Typography>
          <Chip
            size="small"
            label={getTranslatedFeatureType(props.feature.type, t)}
            icon={getFeatureTypeIcon(props.feature.type)}
            className={classes.typeChip}
          />
        </Grid>
        <Grid item xs={12} md={5} lg={6} xl={7}>
          <div className={classes.descriptionWrapper}>
            <Typography variant={"body1"}>{props.feature.description}</Typography>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};
