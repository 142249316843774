import { gql, useMutation } from "@apollo/client";

import React, { FC, useEffect, useRef, useState } from "react";

import { Button, TextField } from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

import { usePrefixedTranslation } from "../../../../util/usePrefixedTranslation";
import {
  addAssetNoteMutation,
  addAssetNoteMutation_addAssetNote,
  addAssetNoteMutationVariables,
} from "./__generated__/addAssetNoteMutation";
import { AssetProtocolEntry } from "./AssetProtocolEntry";

export interface NoteDraftEntryProps {
  assetId: string;
  onSave: (noteAddedEvent: addAssetNoteMutation_addAssetNote) => void;
}

const ADD_ASSET_NOTE_MUTATION = gql`
  mutation addAssetNoteMutation($assetId: ID!, $note: String!) {
    addAssetNote(assetId: $assetId, note: $note) {
      __typename
      timestamp
      note
      user {
        firstName
        lastName
      }
    }
  }
`;

export const NoteDraftEntry: FC<NoteDraftEntryProps> = (props) => {
  const { pt } = usePrefixedTranslation("pages.asset-details.protocol.note-draft.");

  const [value, setValue] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const [addAssetNote] = useMutation<addAssetNoteMutation, addAssetNoteMutationVariables>(ADD_ASSET_NOTE_MUTATION);

  const handleSaveClick = async () => {
    setIsSaving(true);
    const response = await addAssetNote({ variables: { assetId: props.assetId, note: value } });
    if (response.data?.addAssetNote) {
      props.onSave(response.data?.addAssetNote);
    }
    setIsSaving(false);
  };

  const inputEl = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // Focus the input element when the component gets mounted
    inputEl?.current?.focus();
  }, []);

  return (
    <AssetProtocolEntry
      icon={<CreateOutlinedIcon />}
      title={
        <span style={{ display: "inline-flex", alignItems: "center", marginTop: -1000 }}>
          {pt("title")}
          <Button
            disabled={isSaving || value.trim().length == 0}
            color="primary"
            size="small"
            variant="text"
            style={{ marginLeft: 8 }}
            onClick={handleSaveClick}
          >
            {pt("save")}
          </Button>
        </span>
      }
      time={null}
      fields={[]}
      body={
        <div style={{ width: "100%" }}>
          <TextField
            inputRef={inputEl}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            disabled={isSaving}
            multiline
            style={{ width: "100%" }}
            placeholder={pt("placeholder")}
            variant="outlined"
          />
        </div>
      }
    />
  );
};
