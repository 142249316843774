import { gql, useQuery } from "@apollo/client";

import React, { ReactNode, useContext } from "react";

import { LoadingPage } from "../../components/LoadingPage";
import { customerFragment } from "../../pages/customers/api/customerFragment";
import { userFragment } from "../graphql/userFragment";
import { getMeQuery, getMeQuery_getMe, getMeQuery_getMe_user } from "./__generated__/getMeQuery";

const CurrentAccountContext = React.createContext<{
  account?: AccountDto;
  refresh: () => void;
}>({
  refresh: () => {
    /*comment*/
  },
});

/**
 * Gets current connected account.
 */
export const useCurrentAccount = (): AccountDto | undefined => useContext(CurrentAccountContext).account;

export const refreshCurrentAccount = (): (() => void) => useContext(CurrentAccountContext).refresh;

export type AccountDto = Omit<getMeQuery_getMe, "__typename">;
export type User = Omit<getMeQuery_getMe_user, "__typename">;

export interface AccountProviderProps {
  children: ReactNode;
}

export const AccountProvider: React.FunctionComponent<AccountProviderProps> = (props) => {
  const { data: { getMe: account } = {}, refetch } = useQuery<getMeQuery>(gql`
    ${userFragment}
    ${customerFragment}
    query getMeQuery {
      getMe {
        keycloakId
        roles
        user {
          ...UserData
        }
        customer {
          ...CustomerData
        }
      }
    }
  `);

  if (account === undefined || account === null) {
    return <LoadingPage />;
  }
  const refresh = () => {
    refetch().then();
  };
  return <CurrentAccountContext.Provider value={{ account, refresh }}>{props.children}</CurrentAccountContext.Provider>;
};
