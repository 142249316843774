import { useQuery } from "@apollo/client";
import classNames from "classnames";
import { format } from "date-fns";

import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Chip, Table, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { FeatureType } from "../../../../../__generated__/globalTypes";
import { ChipDataType, IdfyDataChip, IdfyTagChip } from "../../../../../components/IdfyTagChip";
import { LocationChip } from "../../../../../components/LocationChip";
import { LocationIFrame } from "../../../../../components/LocationIFrame";
import { DenyFeatureDialog } from "./DenyFeatureDialog";
import { FeatureComparison } from "./FeatureComparison";
import { FeatureImageSlider } from "./FeatureImageSlider";
import { formatThousands, isBlankOrEmpty } from "../../../../../util/stringUtil";
import { AssetLocationModal } from "./AssetLocationModal";
import { House } from "@material-ui/icons";
import { AddSerialNumberDialog } from "./AddSerialNumberDialog";
import { FeatureInspection } from "../api/__generated__/FeatureInspection";
import { inspectionQuery_asset } from "../api/__generated__/inspectionQuery";
import { GET_MATCHING_FEATURES_QUERY } from "../api/inspectionQueriesAndMutations";
import { getMatchingFeaturesQuery } from "../api/__generated__/getMatchingFeaturesQuery";
import { Contract } from "../../../../contracts/api/__generated__/Contract";
import { Inspection } from "../api/__generated__/Inspection";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      flexGrow: 1,
      display: "grid",
      gridTemplateColumns: "7fr 3fr",
      gridTemplateRows: "auto minmax(0,1fr)",
      gridColumnGap: theme.spacing(3),
      minHeight: 0,
      [theme.breakpoints.down("sm")]: {
        gridTemplateRows: "auto auto auto auto",
        gridTemplateColumns: "100%",
      },
      height: "90%",
    },
    featureHeader: {
      width: "100%",
      display: "flex",
      gridColumn: "1 /span 1",
      gridRow: "1 /span 1",
      [theme.breakpoints.down("sm")]: {
        gridColumn: "1 /span 1",
        gridRow: "1 /span 1",
      },
      padding: theme.spacing(2),
      boxSizing: "border-box",
      marginTop: theme.spacing(2),
      backgroundColor: "#eeeeee",
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "auto",
        alignItems: "center",
        gridColumn: "1",
        "& > *:last-child": {
          marginTop: theme.spacing(2),
        },
      },
      "& td": {
        borderBottom: "none",
      },
    },
    assetInfo: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      },
    },
    assetInfoElement: {
      [theme.breakpoints.down("sm")]: {
        margin: `${theme.spacing(1)}px 0`,
      },
      padding: 0,
      flexGrow: 1,
    },
    assetInfoSNElement: {
      textAlign: "center",
      flexGrow: 2,
    },
    featureImageArea: {
      gridColumn: "1 /span 1",
      gridRow: "2 /span 1",
      [theme.breakpoints.down("sm")]: {
        gridColumn: "1 /span 1",
        gridRow: "3 /span 1",
      },
      position: "relative",
    },
    popOver: {
      padding: theme.spacing(2),
    },
    chipRightAligned: {
      float: "right",
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    chipLeftAligned: {
      float: "left",
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    condensed: {
      fontFamily: "Roboto Condensed",
      fontSize: "0.9em",
    },
    serialNumberAlertHeader: {
      fontFamily: "Roboto",
      fontSize: "0.9em",
      color: "red",
    },
    serialNrButton: {
      animation: "$shake 1s cubic-bezier(.36, .07, .19, .97) both",
      transform: "translate3d(0, 0, 0)",
      backfaceVisibility: "hidden",
      perspective: "1000px",
      border: "2px solid",
      borderColor: "red",
    },
    "@keyframes shake": {
      "10%": {
        transform: "translate3d(-1px, 0, 0)",
      },
      "90%": {
        transform: "translate3d(-1px, 0, 0)",
      },
      "20%": {
        transform: "translate3d(2px, 0, 0)",
      },
      "80%": {
        transform: "translate3d(2px, 0, 0)",
      },
      "30%": {
        transform: "translate3d(-4px, 0, 0)",
      },
      "50%": {
        transform: "translate3d(-4px, 0, 0)",
      },
      "70%": {
        transform: "translate3d(-4px, 0, 0)",
      },
      "40%": {
        transform: "translate3d(4px, 0, 0)",
      },
      "60%": {
        transform: "translate3d(4px, 0, 0)",
      },
    },
  })
);

export interface FeatureInspectionData {
  assetName: string;
  manufacturerName: string | undefined;
  featureInspection: FeatureInspection;
  assetSerialNumber: string | null | undefined;
  type?: string;
  isSubAsset: boolean;
}

export interface FeatureInspectionProps {
  active: boolean;
  data: FeatureInspectionData;
  asset: inspectionQuery_asset;
  inspection: Inspection;
  onDeny: (reason: string) => void;
  onTagsDoNotMatch: () => void;
  denyMessage?: string;
  denyFeatureOpen: boolean;
  onDenyFeatureClose: () => void;
  displayAddSerialNumberButton: boolean;
  onAddSerialNumber: (serialNumber: string) => void;
  shakeTheSerialNrButton?: boolean;
  contract: Contract;
}

export const FeatureInspectionComponent: React.FunctionComponent<FeatureInspectionProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const I18NEXT_PREFIX = "pages.asset-details.inspection-overview.modals.normal-inspection.";
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [addSerialNumberOpen, setAddSerialNumberOpen] = useState(false);

  const { data: matchingFeatureInspections, loading } = useQuery<getMatchingFeaturesQuery>(
    GET_MATCHING_FEATURES_QUERY,
    {
      variables: { featureId: props.data.featureInspection.id, assetId: props.asset.id },
    }
  );

  const displaySerialNumberAddedMsg = useMemo(
    () =>
      !props.data.isSubAsset &&
      props.data.featureInspection.featureBlueprint.type == FeatureType.TAG &&
      props.inspection.serialNumber,
    [props.data, props.inspection]
  );

  const currentTag = props.data.featureInspection.tag;
  const lastTagMatchesCurrentTag = checkLastTagMatchesCurrent(currentTag, matchingFeatureInspections);

  // Horrible idea. See InspectionModal for more information.
  if (!lastTagMatchesCurrentTag) {
    props.onTagsDoNotMatch();
  }

  const handleDenyFeatureSubmit = (reason: string) => {
    props.onDenyFeatureClose();
    props.onDeny(reason);
  };

  const handleSubmitSerialNumber = (serialNumber: string) => {
    console.log("New serial number " + serialNumber);
    props.onAddSerialNumber(serialNumber);
    setAddSerialNumberOpen(false);
  };

  const serialNumberString = isBlankOrEmpty(props.data.assetSerialNumber)
    ? t(`${I18NEXT_PREFIX}serialNumberUndefined`)
    : props.data.assetSerialNumber;
  return (
    <div className={classes.body}>
      <Table className={classes.featureHeader}>
        <TableRow className={classes.assetInfo}>
          <TableCell className={classes.assetInfoElement}>
            <Typography variant="h5">
              {props.data.manufacturerName} {props.data.assetName}
            </Typography>
            <Typography>{props.data.type}</Typography>
          </TableCell>
          {props.displayAddSerialNumberButton ? (
            <TableCell className={classNames(classes.assetInfoElement, classes.assetInfoSNElement)}>
              <Button
                color="primary"
                size="small"
                onClick={() => setAddSerialNumberOpen(true)}
                className={props.shakeTheSerialNrButton === true ? classes.serialNrButton : ""}
              >
                {t(`${I18NEXT_PREFIX}add-serial-number`)}
              </Button>
              <br />
              <span className={classes.serialNumberAlertHeader}>{t(`${I18NEXT_PREFIX}serialNumberUndefined`)}</span>
            </TableCell>
          ) : (
            <TableCell className={classNames(classes.assetInfoElement, classes.condensed)}>
              {props.data.assetSerialNumber}
            </TableCell>
          )}
          {displaySerialNumberAddedMsg && (
            <TableCell className={classNames(classes.assetInfoElement, classes.assetInfoSNElement)}>
              <span className={classes.serialNumberAlertHeader}>{t(`${I18NEXT_PREFIX}serialNumberAdded`)}</span>
            </TableCell>
          )}
          <TableCell className={classes.assetInfoElement}>
            <span>
              {props.contract.customer.lastName}, {props.contract.customer.firstName}
            </span>
            <Typography>{props.contract.customer.customerNumber}</Typography>
          </TableCell>
        </TableRow>
      </Table>
      <div className={classes.featureImageArea}>
        {/*Location of the inspection. Due to a new requirement (IDL-802) and to provide backwards compatibility, there is another location iFrame
        for each image placed on top of this iFrame, which is ok because the location of the image is more important than the image of the feature.*/}
        <LocationIFrame location={props.data.featureInspection.location} />
        <FeatureImageSlider
          images={props.data.featureInspection.images}
          displayLocation
          modalTitle={`${props.data.assetName} - ${serialNumberString} - 
                      ${props.data.featureInspection.featureBlueprint.title}`}
          footer={(image) => (
            <div>
              <Chip
                size="small"
                label={format(
                  new Date(image.appCreationDateTime ? image.appCreationDateTime : image.creationDate),
                  t("dates.date-time-with-seconds")
                )}
                className={classes.chipLeftAligned}
              />
              <LocationChip
                location={image.location ? image.location : props.data.featureInspection.location}
                className={classes.chipLeftAligned}
              />
              <Tooltip title={t("customerLocation-chip.tool-tip").toString()}>
                <Chip
                  icon={<House />}
                  size="small"
                  label={t("customerLocation-chip.display-location")}
                  className={classes.chipLeftAligned}
                  onClick={() => setCustomerModalOpen(true)}
                />
              </Tooltip>
              {currentTag && (
                <IdfyTagChip
                  tag={currentTag}
                  showWarning={!lastTagMatchesCurrentTag}
                  className={classes.chipRightAligned}
                />
              )}
              {props.data.featureInspection.featureBlueprint.type === FeatureType.METER && (
                <>
                  <IdfyDataChip
                    tag={formatThousands(props.data.featureInspection.meter?.kilometers)}
                    className={classes.chipRightAligned}
                    type={ChipDataType.KILOMETERS}
                  />
                  <IdfyDataChip
                    tag={formatThousands(props.data.featureInspection.meter?.hours)}
                    className={classes.chipRightAligned}
                    type={ChipDataType.HOURS}
                  />
                </>
              )}
            </div>
          )}
        />
      </div>
      {!loading && (
        <FeatureComparison
          featureId={props.data.featureInspection.id}
          title={props.data.featureInspection.featureBlueprint.title}
          tag={props.data.featureInspection.tag}
          type={props.data.featureInspection.featureBlueprint.type}
          matchingFeatureInspections={matchingFeatureInspections}
        />
      )}
      <DenyFeatureDialog
        open={props.denyFeatureOpen}
        onClose={() => props.onDenyFeatureClose()}
        onConfirm={(reason: string) => handleDenyFeatureSubmit(reason)}
      />
      <AssetLocationModal
        open={customerModalOpen}
        handleClose={() => setCustomerModalOpen(false)}
        name={props.asset.offsiteLocation?.name || props.contract.customer.company}
        address={props.asset.offsiteLocation?.streetAddress || props.contract.customer.streetAddress}
        zip={props.asset.offsiteLocation?.zip || props.contract.customer.zip}
        city={props.asset.offsiteLocation?.city || props.contract.customer.city}
      />
      <AddSerialNumberDialog
        open={addSerialNumberOpen}
        handleClose={() => setAddSerialNumberOpen(false)}
        handleSubmit={(serialNumber: string) => handleSubmitSerialNumber(serialNumber)}
        name={props.asset.name}
        manufacturer={props.asset.manufacturer}
        contractNumber={props.asset.contractNumber}
      />
    </div>
  );
};

/**
 * Checks if the current tag matches the last tag.
 */
function checkLastTagMatchesCurrent(currentTag: string | null, matchingFeatureInspections?: getMatchingFeaturesQuery) {
  const lastTag = getLastTag(matchingFeatureInspections);
  return !lastTag || currentTag === lastTag;
}

/**
 * Gets the last tag of the feature inspection.
 */
function getLastTag(matchingFeatureInspections?: getMatchingFeaturesQuery): string | null | undefined {
  return matchingFeatureInspections?.getMatchingFeatureInspections?.featureInspections
    ?.slice()
    .sort((a, b) => Date.parse(b.date) - Date.parse(a.date))[0]?.featureInspection?.tag;
}
