import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { CreateAssetInputFormFields } from "./CreateAssetInput";
import { SubAssetInput } from "../../../__generated__/globalTypes";
import { AddSubAssetDialog } from "./AddSubAssetDialog";
import { useQuery } from "@apollo/client";
import { manufacturersQuery } from "../../manufacturers/api/__generated__/manufacturersQuery";
import { MANUFACTURERS_QUERY } from "../../manufacturers/api/manufacturerQueriesAndMutations";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formGrid: {
      boxSizing: "border-box",
      paddingTop: theme.spacing(3),
    },
    deleteCell: {
      width: 60,
    },
  })
);

interface SubAssetProp {
  id?: string;
  name: string;
  serialNumber?: string | null;
  manufacturerId: string;
}

function convertSubAssetsToAddToProps(assetInput: CreateAssetInputFormFields): SubAssetProp[] {
  return assetInput.subAssetsToAdd;
}

function convertExistingSubAssetsToProps(assetInput: CreateAssetInputFormFields): SubAssetProp[] {
  return assetInput.existingSubAssets.map((data) => {
    return {
      id: data.id,
      name: data.name,
      serialNumber: data.serialNumber,
      manufacturerId: data.manufacturer?.id || "",
    };
  });
}
function convertSubAssetsToProps(assetInput: CreateAssetInputFormFields): SubAssetProp[] {
  if (assetInput.useOutOfContractAsset) {
    return convertExistingSubAssetsToProps(assetInput)
      .concat(convertSubAssetsToAddToProps(assetInput))
      .filter((data) => {
        return data.id === undefined || !assetInput.subAssetsToDelete.includes(data.id);
      });
  }
  return assetInput.asset.subAssets;
}

function getSubAssetsToAdd(assetInput: CreateAssetInputFormFields, subAssets: SubAssetProp[]): SubAssetInput[] {
  if (!assetInput.useOutOfContractAsset) {
    return [];
  }
  return subAssets.filter((data) => {
    return data.id === undefined;
  });
}

function getSubAssetsToRemove(assetInput: CreateAssetInputFormFields, subAssets: SubAssetProp[]): string[] {
  if (!assetInput.useOutOfContractAsset) {
    return [];
  }
  return assetInput.existingSubAssets
    .filter((data) => {
      return (
        subAssets.some((element) => {
          return element.id == data.id;
        }) == false
      );
    })
    .map((data) => {
      return data.id;
    });
}

export const CreateAssetSubAssets: React.FunctionComponent<{
  assetInput: CreateAssetInputFormFields;
  setAssetInput: React.Dispatch<React.SetStateAction<CreateAssetInputFormFields>>;
  setFormSubmit: (fun: () => () => boolean) => void;
  onDirtyStateChange: (isDirty: boolean) => void;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [subAssets, setSubAssets] = useState<SubAssetProp[]>(convertSubAssetsToProps(props.assetInput));
  const [addSubAssetDialogOpen, setAddSubAssetDialogOpen] = useState(false);

  useEffect(
    () =>
      props.setFormSubmit(() => () => {
        console.log("subAssets", subAssets);
        const subAssetsToAdd = getSubAssetsToAdd(props.assetInput, subAssets);
        console.log("subAssetsToAdd", subAssetsToAdd);
        const subAssetsToRemove = getSubAssetsToRemove(props.assetInput, subAssets);
        console.log("subAssetsToDelete", subAssetsToRemove);
        props.setAssetInput((prevState) => ({
          ...prevState,
          asset: {
            ...prevState.asset,
            subAssets: subAssets,
          },
          subAssetsToAdd: subAssetsToAdd,
          subAssetsToDelete: subAssetsToRemove,
        }));
        return true;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subAssets]
  );

  function addSubAsset(name: string, serialNumber: string, manufacturerId: string) {
    const temp = subAssets.slice();
    temp.push({ name: name, serialNumber: serialNumber, manufacturerId: manufacturerId });
    setSubAssets(temp);
  }

  function deleteSubAsset(index: number) {
    let temp = subAssets.slice();
    temp = temp.filter((obj) => obj !== temp[index]);
    setSubAssets(temp);
  }

  return (
    <>
      <Grid container spacing={2} justifyContent={"space-around"} className={classes.formGrid}>
        <Grid item xs={12}>
          <Typography variant={"h3"}>{t("pages.assets.modals.create-asset.sub-assets.sub-assets")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("pages.assets.modals.create-asset.sub-assets.name")}</TableCell>
                <TableCell>{t("pages.assets.modals.create-asset.sub-assets.serial-number")}</TableCell>
                <TableCell>{t("pages.assets.modals.create-asset.sub-assets.manufacturer")}</TableCell>
                <TableCell className={classes.deleteCell} />
              </TableRow>
            </TableHead>
            <TableBody>
              {subAssets.map((subAsset, index) => (
                <SubAssetRow key={index} subAsset={subAsset} deleteSubAsset={() => deleteSubAsset(index)} />
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <Button color={"primary"} onClick={() => setAddSubAssetDialogOpen(true)}>
            + {t("pages.assets.modals.create-asset.sub-assets.buttons.add")}
          </Button>
        </Grid>
        <AddSubAssetDialog
          addSubAssetDialogOpen={addSubAssetDialogOpen}
          handleClose={() => setAddSubAssetDialogOpen(false)}
          handleSubmit={(name: string, serialNumber: string, manufacturerId: string) => {
            addSubAsset(name, serialNumber, manufacturerId);
            setAddSubAssetDialogOpen(false);
          }}
          initialAssetManufacturerId={props.assetInput.asset.manufacturerId}
          serialNumberOfAsset={props.assetInput.asset.serialNumber || ""}
          previousSubAssets={subAssets}
        />
      </Grid>
    </>
  );
};

const SubAssetRow: React.FunctionComponent<{
  subAsset?: SubAssetInput;
  deleteSubAsset: () => void;
}> = (props) => {
  const classes = useStyles();
  const manufacturerData = useQuery<manufacturersQuery>(MANUFACTURERS_QUERY).data;
  const manufacturer = manufacturerData?.manufacturers.filter(
    (manufacturer) => manufacturer.id === props.subAsset?.manufacturerId
  );
  return (
    <TableRow>
      <TableCell>{props.subAsset?.name}</TableCell>
      <TableCell>{props.subAsset?.serialNumber}</TableCell>
      {manufacturer && <TableCell>{manufacturer![0].name}</TableCell>}
      <TableCell className={classes.deleteCell}>
        <IconButton onClick={() => props.deleteSubAsset()}>
          <Delete color={"error"} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
