import React, { useContext } from "react";
import { Contract } from "../../../../contracts/api/__generated__/Contract";
import { useTranslation } from "react-i18next";
import { ContractStatusChip } from "../../../../contracts/ContractStatusChip";
import { IDFyFormColumn, IDFyFormTable } from "../../../../../components/table/IDFyFormTable";
import { routes } from "../../../../../routing/routes";
import { BreadcrumbContext } from "../../../../../routing/idfyRouter";
import { useHistory } from "react-router-dom";
import { formatDateShortString } from "../../../../../util/dateTime/formatDate";

export const AssetInfoContractTable: React.FunctionComponent<{
  contract: Contract | null;
  breadcrumbTitle: string;
}> = (props) => {
  const { contract } = props;
  const { t } = useTranslation();

  const utilContext = useContext(BreadcrumbContext);
  const history = useHistory();

  const allColumns = [
    {
      name: "contractNumber",
      titleKey: `pages.contracts.fields.contractNumber`,
      render: (rowData) => rowData.contractNumber,
      renderMobile: (rowData) => t(`pages.contracts.fields.contractNumber`) + ": " + rowData.contractNumber,
    },
    {
      name: "startDate",
      titleKey: `pages.contracts.fields.startDate`,
      render: (rowData) => formatDateShortString(rowData.startDate),
      renderMobile: (rowData) =>
        t(`pages.contracts.fields.startDate`) + ": " + formatDateShortString(rowData.startDate),
    },
    {
      name: "endDate",
      titleKey: `pages.contracts.fields.endDate`,
      render: (rowData) => formatDateShortString(rowData.endDate),
      renderMobile: (rowData) => t(`pages.contracts.fields.endDate`) + ": " + formatDateShortString(rowData.endDate),
    },
    {
      name: "status",
      titleKey: `pages.contracts.fields.status`,
      // eslint-disable-next-line react/display-name
      render: (rowData) => <ContractStatusChip contract={rowData} />,
    },
  ] as IDFyFormColumn<Contract>[];

  function onRowClick(rowData: Contract) {
    utilContext.breadcrumbs.push({
      url: history.location.pathname || "errorPage",
      title: props.breadcrumbTitle,
    });
    history.push(routes.contracts + routes.details(rowData.id) + routes.infoSuffix);
  }

  return (
    <IDFyFormTable
      title={t("pages.asset-details.information.contract")}
      columns={allColumns}
      data={contract ? [contract] : undefined}
      onRowClick={onRowClick}
    />
  );
};
