import { useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { CreateAssetInputFormFields } from "./CreateAssetInput";
import { OffsiteLocationInput } from "../../../__generated__/globalTypes";
import { createAssetCustomerValidationSchema } from "./validation/CreateAssetCustomerValidationSchema";
import { CUSTOMER_SELECT_QUERY } from "../../customers/api/customerQueriesAndMutations";
import { customerSelectQuery } from "../../customers/api/__generated__/customerSelectQuery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formGrid: {
      boxSizing: "border-box",
      paddingTop: theme.spacing(3),
    },
    address: {
      marginTop: theme.spacing(2),
      minHeight: "2rem",
    },
    radioGroup: {
      marginLeft: "1rem",
    },
  })
);

export const CreateAssetCustomer: React.FunctionComponent<{
  assetInput: CreateAssetInputFormFields;
  setAssetInput: React.Dispatch<React.SetStateAction<CreateAssetInputFormFields>>;
  setFormSubmit: (fun: () => () => boolean) => void;
  customerId?: string;
}> = (props) => {
  const classes = useStyles();
  const { data } = useQuery<customerSelectQuery>(CUSTOMER_SELECT_QUERY);
  const { t } = useTranslation();

  const [currentSelectedCustomerAddress, setCurrentSelectedCustomerAddress] = useState<string | undefined>(undefined);

  interface CustomerFormData {
    customerId: string;
    name: string | null;
    streetAddress: string | null;
    zip: string | null;
    city: string | null;
    // Although we do not send the 'isOffsiteLocation' field to the backend, we need it in the form data to validate the form.
    isOffsiteLocation: boolean;
  }

  const { handleSubmit, control, errors, formState, watch } = useForm<CustomerFormData>({
    resolver: yupResolver(createAssetCustomerValidationSchema()),
    mode: "onChange",
    defaultValues: {
      customerId: props.customerId || props.assetInput.asset.customerId || "",
      isOffsiteLocation: props.assetInput.isOffsiteLocation,
      name: props.assetInput.asset.offsiteLocation?.name || "",
      streetAddress: props.assetInput.asset.offsiteLocation?.streetAddress || "",
      zip: props.assetInput.asset.offsiteLocation?.zip || "",
      city: props.assetInput.asset.offsiteLocation?.city || "",
    },
  });

  const onSubmit = (data: CustomerFormData) => {
    let offsiteLocationInput: OffsiteLocationInput | null;
    if (data.name && data.streetAddress && data.zip && data.city) {
      offsiteLocationInput = {
        name: data.name,
        streetAddress: data.streetAddress,
        zip: data.zip,
        city: data.city,
      };
    } else {
      offsiteLocationInput = null;
    }

    props.setAssetInput((prevState) => ({
      ...prevState,
      asset: {
        ...prevState.asset,
        customerId: data.customerId,
        offsiteLocation: offsiteLocationInput,
      },
      isOffsiteLocation: data.isOffsiteLocation,
    }));
  };

  const formSubmit = () => {
    void handleSubmit((data) => onSubmit(data))();
    return formState.isValid;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => props.setFormSubmit(() => formSubmit), [formState.isValid]);

  const customerId = watch("customerId");
  const isOffsiteLocation = watch("isOffsiteLocation");

  useEffect(() => {
    if (customerId) {
      const selectedCustomer = data?.customers.find((customer) => customer.id === customerId);
      if (selectedCustomer) {
        setCurrentSelectedCustomerAddress(
          `${selectedCustomer.streetAddress}, ${selectedCustomer.zip} ${selectedCustomer.city}`
        );
      }
    }
  }, [customerId, data]);

  if (!data) {
    return <></>;
  }

  return (
    <>
      <Grid container spacing={3} justifyContent={"space-around"} className={classes.formGrid}>
        <Grid item xs={12}>
          <Typography variant={"h3"}>{t("pages.assets.modals.create-asset.fields.customer")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <form style={{ width: "100%" }} onSubmit={formSubmit}>
            <FormControl fullWidth variant={"outlined"} color={"secondary"} error={!!errors.customerId}>
              <InputLabel required>{t("pages.assets.modals.create-asset.fields.customer")}</InputLabel>
              <Controller
                control={control}
                as={Select}
                fullWidth
                name={"customerId"}
                label={t("pages.assets.modals.create-asset.fields.customer")}
                disabled={!!props.customerId || props.assetInput.useExistingContract}
              >
                {data &&
                  data.customers &&
                  data.customers
                    .slice()
                    .sort((c1, c2) => c1.company.localeCompare(c2.company))
                    .map((customer, i) => (
                      <MenuItem value={customer.id} key={i}>
                        <div>
                          <b>{customer.company}</b>
                          <br />
                          Ansprechpartner: {customer.firstName} {customer.lastName},{" "}
                          {t("pages.assets.modals.create-asset.fields.customer-number")}: {customer.customerNumber}
                        </div>
                      </MenuItem>
                    ))}
              </Controller>
              <FormHelperText>
                {!!errors.customerId && t("pages.assets.modals.create-asset.validations.customer-must-be-selected")}
              </FormHelperText>
            </FormControl>
          </form>
        </Grid>
      </Grid>
      <div className={classes.address}>
        {currentSelectedCustomerAddress && (
          <Typography variant={"body1"}>
            {t("pages.assets.modals.create-asset.address")}: {currentSelectedCustomerAddress}
          </Typography>
        )}
      </div>
      <Grid container spacing={3} className={classes.formGrid}>
        <Grid item xs={12}>
          <Typography variant={"h3"}>{t("pages.assets.modals.create-asset.object-location")}</Typography>
        </Grid>
        <Controller
          control={control}
          name={"isOffsiteLocation"}
          required
          defaultValue={false}
          render={({ onChange, value, ref }) => (
            <FormControl
              component="fieldset"
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              error={!!errors.isOffsiteLocation}
            >
              <RadioGroup row className={classes.radioGroup}>
                <FormControlLabel
                  value="false"
                  control={<Radio checked={value === false} onChange={() => onChange(false)} />}
                  label={t("pages.assets.modals.create-asset.customer-address")}
                  inputRef={ref}
                />
                <FormControlLabel
                  value="true"
                  control={<Radio checked={value} onChange={() => onChange(true)} />}
                  label={t("pages.assets.modals.create-asset.offsite-location")}
                  inputRef={ref}
                />
              </RadioGroup>
              <FormHelperText>{errors.isOffsiteLocation?.message}</FormHelperText>
            </FormControl>
          )}
        />
        {isOffsiteLocation && (
          <>
            <Grid item xs={12}>
              <Controller
                control={control}
                as={TextField}
                fullWidth
                variant="outlined"
                name="name"
                label={t("pages.assets.modals.create-asset.fields.offsite-location.name")}
                required
                color="secondary"
                helperText={errors.name?.message}
                error={!!errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                as={TextField}
                fullWidth
                variant="outlined"
                name="streetAddress"
                label={t("pages.assets.modals.create-asset.fields.offsite-location.street-address")}
                required
                color="secondary"
                helperText={errors.streetAddress?.message}
                error={!!errors.streetAddress}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                as={TextField}
                fullWidth
                variant="outlined"
                name="zip"
                label={t("pages.assets.modals.create-asset.fields.offsite-location.zip")}
                required
                color="secondary"
                helperText={errors.zip?.message}
                error={!!errors.zip}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                as={TextField}
                fullWidth
                variant="outlined"
                name="city"
                label={t("pages.assets.modals.create-asset.fields.offsite-location.city")}
                required
                color="secondary"
                helperText={errors.city?.message}
                error={!!errors.city}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
