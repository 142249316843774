import React from "react";
import { useTranslation } from "react-i18next";

import {
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { TableWrapperPaper } from "../pageFragments/util/TableWrapperPaper";
import { usersQuery_users } from "./__generated__/usersQuery";
import { RoleChip } from "./RoleChip";
import { palette } from "../../util/IdfyTheme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headRow: {
      "& th": {
        background: theme.palette.background.paper,
        color: palette.mediumGrey,
        textTransform: "uppercase",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    row: {
      padding: `${theme.spacing(4)}px 0`,
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1),
        "& td": {
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          paddingBottom: 0,
          paddingTop: 0,
          borderBottom: "none",
        },
      },
    },
  })
);

export interface UserTableProps {
  users: usersQuery_users[];
  onUserClick: (user: usersQuery_users) => void;
}

export const UserTable: React.FunctionComponent<UserTableProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <TableWrapperPaper>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow className={classes.headRow}>
              <TableCell>{t("pages.administration.table.header-row.name")}</TableCell>
              <TableCell>{t("pages.administration.table.header-row.email")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.users
              .slice()
              .sort((u1, u2) => u1.lastName.localeCompare(u2.lastName))
              .map((user) => (
                <TableRow key={user.id} className={classes.row} hover onClick={() => props.onUserClick(user)}>
                  <TableCell>
                    <b>
                      {user.lastName}, {user.firstName}
                    </b>
                    {user.roles.map((role) => (
                      <RoleChip key={role} role={role} />
                    ))}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapperPaper>
  );
};
