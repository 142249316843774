import React, { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getTeams, TeamFilter } from "./TeamFilter";
import { Team } from "../../__generated__/globalTypes";

export type TeamFieldProps = {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  includeAllTeam?: boolean;
};

export function TeamField(props: TeamFieldProps): ReactElement {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();

  const teams: TeamFilter[] = getTeams(props.includeAllTeam || false);

  return (
    <Controller
      control={control}
      name={props.name}
      render={
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ({ onChange, onBlur, value }) => (
          <FormControl
            size="small"
            fullWidth
            color={"secondary"}
            error={!!errors[props.name]}
            disabled={props.disabled}
          >
            <InputLabel variant={"outlined"} id={"team-select-label"} required={props.required}>
              {props.label}
            </InputLabel>
            <Select
              labelId="team-select-label"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              variant={"outlined"}
              label={props.label}
              defaultValue={Team.ASSET_MONITORING}
            >
              {teams.map((team, i) => (
                <MenuItem value={team} key={i}>
                  {t("Team." + team)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors[props.name]?.message}</FormHelperText>
          </FormControl>
        )
      }
    />
  );
}
