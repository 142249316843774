import clsx from "clsx";

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  createStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { makeStyles } from "@material-ui/core/styles";
import transitions from "@material-ui/core/styles/transitions";
import PeopleIcon from "@material-ui/icons/People";
import StarsIcon from "@material-ui/icons/Stars";
import BuildIcon from "@material-ui/icons/Build";

import AdminPanelSettingsIcon from "../../components/icons/AdminPanelSettings";
import BlueprintIcon from "../../components/icons/BlueprintIcon";
import { routes } from "../../routing/routes";
import { useCurrentAccount } from "../../util/auth/useCurrentAccount";
import { APPBAR_HEIGHT, COLLAPSED_DRAWER_WIDTH, DRAWER_WIDTH } from "../../util/constants";
import { idfyTheme, palette } from "../../util/IdfyTheme";
import logo from "./IDfy - Logo Yellow.png";
import { useTranslation } from "react-i18next";
import { Storefront } from "@material-ui/icons";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { isAllowed } from "../../components/access/AccessManager";

const drawerWidth = DRAWER_WIDTH;
const collapsedDrawer = COLLAPSED_DRAWER_WIDTH;

type MenuEntry = {
  entryId: string,
  label: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  path: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      flexShrink: 0,
      whiteSpace: "nowrap",
      alignContent: "center",
      display: "flex",
      background: idfyTheme.palette.primary.main,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: transitions.create("width", {
        easing: transitions.easing.sharp,
        duration: 200,
      }),
      background: idfyTheme.palette.primary.main,
      border: "none",
      boxShadow: "2px 0px 3px 0px rgba(51,51,51,0.28)",
      [theme.breakpoints.down("sm")]: {
        width: "100vw",
        zIndex: 9,
      },
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: 200,
      }),
      overflowX: "hidden",
      width: collapsedDrawer,
      background: idfyTheme.palette.primary.main,
      border: "none",
      boxShadow: "2px 0px 3px 0px rgba(51,51,51,0.28)",
      [theme.breakpoints.down("sm")]: {
        width: 0,
      },
    },
    drawerMenu: {
      marginTop: "12em",
    },
    drawerMenuSectionTitle: {
      color: palette.mediumGrey,
      textTransform: "uppercase",
      fontSize: "small",
    },
    drawerIcon: {
      fontSize: theme.spacing(3.4),
      padding: theme.spacing(2),
      marginRight: theme.spacing(3),
      background: idfyTheme.palette.background.default + "a0",
      borderRadius: "12px",
      color: theme.palette.secondary.main,
      fill: theme.palette.secondary.main,
    },
    drawerIconActive: {
      color: idfyTheme.palette.background.default,
      fill: idfyTheme.palette.background.default,
      background: theme.palette.secondary.main,
    },
    idfyLogo: {
      width: "2em",
      padding: idfyTheme.spacing(2),
      transition: theme.transitions.create("padding-bottom", {
        easing: theme.transitions.easing.sharp,
        duration: 200,
      }),
    },
    logoWrapper: {
      position: "absolute",
      display: "flex",
      background: "#fff",
      height: "fit-content",
      flexDirection: "column",
      alignItems: "center",
      width: "80%",
      margin: "10%",
      borderRadius: idfyTheme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        marginTop: "calc(5% + " + APPBAR_HEIGHT.toString() + "px)",
      },
    },
    logoTagOpen: {
      fontSize: "0.7em",
      transition: theme.transitions.create("font-size", {
        easing: theme.transitions.easing.sharp,
        duration: 200,
      }),
    },
    logoTagClosed: {
      fontSize: "0",
      transition: theme.transitions.create("font-size", {
        easing: theme.transitions.easing.sharp,
        duration: 200,
      }),
    },
  })
);

export const IdfyDrawer: React.FunctionComponent<{
  drawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
}> = (props) => {
  const classes = useStyles();
  const currentAccount = useCurrentAccount();
  const { t } = useTranslation();

  const history = useHistory();

  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));
  const large = useMediaQuery(idfyTheme.breakpoints.up("xl"));

  const handleDrawerOpen = () => {
    props.setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    props.setDrawerOpen(false);
  };

  const transactionDataMenu: MenuEntry[] = [
    {
      entryId: "drawer.objects",
      label: t("drawer.objects"),
      icon: StarsIcon,
      path: routes.assets
    },
    {
      entryId: "drawer.customers",
      label: t("drawer.customers"),
      icon: PeopleIcon,
      path: routes.customers
    },
    {
      entryId: "drawer.contracts",
      label: t("drawer.contracts"),
      icon: DescriptionOutlinedIcon,
      path: routes.contracts
    },
  ];

  const masterDataMenu: MenuEntry[] = [
    {
      entryId: "drawer.types",
      label: t("drawer.types"),
      icon: BlueprintIcon,
      path: routes.blueprints
    },
    {
      entryId: "drawer.manufacturers",
      label: t("drawer.manufacturers"),
      icon: BuildIcon,
      path: routes.manufacturers
    },
    {
      entryId: "drawer.suppliers",
      label: t("drawer.suppliers"),
      icon: Storefront,
      path: routes.suppliers
    },
    {
      entryId: "drawer.administration",
      label: t("drawer.administration"),
      icon: AdminPanelSettingsIcon,
      path: routes.administration
    },
  ];

  const drawerOpen = props.drawerOpen || large;

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: drawerOpen,
        [classes.drawerClose]: !drawerOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        }),
      }}
      onMouseEnter={mobile ? () => null : handleDrawerOpen}
      onMouseLeave={mobile ? () => null : handleDrawerClose}
      color="inherit"
    >
      <div
        className={classes.logoWrapper}
        onClick={() => {
          history.push(routes.assets);
          if (mobile) props.setDrawerOpen(false);
        }}
        style={{ cursor: "pointer" }}
      >
        <img
          src={logo}
          className={classes.idfyLogo}
          style={
            drawerOpen
              ? {
                paddingBottom: 0,
              }
              : mobile
                ? {
                  visibility: "hidden",
                }
                : undefined
          }
          alt={"idfy logo"}
        />
        <p className={drawerOpen ? classes.logoTagOpen : classes.logoTagClosed}>{t("logo-label")}</p>
      </div>
      <List className={classes.drawerMenu}>
        {transactionDataMenu
          .filter((drawerOption) => isAllowed(drawerOption.entryId, currentAccount))
          .map((drawerOption, i) => (
            <DrawerMenuItem key={i} drawerOption={drawerOption} setDrawerOpen={props.setDrawerOpen} />
          ))}
        <ListItem key={"placeholder-menu"} style={{ height: 50 }}></ListItem>
        <ListItem key={"config-menu"} className={classes.drawerMenuSectionTitle}>
          {t("drawer.configurationSectionTitle")}
        </ListItem>
        {masterDataMenu
          .filter((drawerOption) => isAllowed(drawerOption.entryId, currentAccount))
          .map((drawerOption, i) => (
            <DrawerMenuItem key={i} drawerOption={drawerOption} setDrawerOpen={props.setDrawerOpen} />
          ))}
      </List>
    </Drawer>
  );
};

const DrawerMenuItem: React.FunctionComponent<{
  drawerOption: {
    label: string;
    icon: OverridableComponent<SvgIconTypeMap>;
    path: string;
  };
  setDrawerOpen: (b: boolean) => void;
}> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [active, setActive] = useState(false);

  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));

  useEffect(() => {
    setActive(location.pathname.startsWith(props.drawerOption.path));
  }, [location.pathname, props.drawerOption.path]);

  return (
    <span
      key={props.drawerOption.path}
      onClick={() => {
        history.push(props.drawerOption.path);
        if (mobile) props.setDrawerOpen(false);
      }}
    >
      <ListItem button key={props.drawerOption.path}>
        <ListItemIcon>
          <props.drawerOption.icon className={`${classes.drawerIcon} ${active ? classes.drawerIconActive : ""}`} />
        </ListItemIcon>
        <ListItemText>
          <Typography style={active ? undefined : { fontWeight: 400 }} variant={"h5"}>
            {props.drawerOption.label}
          </Typography>
        </ListItemText>
      </ListItem>
    </span>
  );
};
