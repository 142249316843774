import { makeStyles } from "@material-ui/core/styles";
import { Chip, createStyles } from "@material-ui/core";
import { palette } from "../../util/IdfyTheme";
import { Contract } from "./api/__generated__/Contract";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { getStatusColorClassName } from "./GetStatusColorClassName";

const useStatusColorStyles = makeStyles(() =>
  createStyles({
    active: {
      color: palette.green,
      borderColor: palette.green,
    },
    extended: {
      color: palette.green,
      borderColor: palette.green,
    },
    not_yet_active: {
      color: palette.mediumGrey,
      borderColor: palette.mediumGrey,
    },
    expired: {
      color: palette.red,
      borderColor: palette.red,
    },
    inactive: {
      color: palette.mediumGrey,
      borderColor: palette.mediumGrey,
    },
    unknown: {
      color: palette.black,
      backgroundColor: palette.yellow,
      borderColor: palette.yellow,
    },
  })
);

export function ContractStatusChip(props: { contract: Contract }): ReactElement {
  const { contract } = props;
  const { t } = useTranslation();
  const statusColorStyles = useStatusColorStyles();
  const statusLabel = t("pages.contracts.ContractStatus." + contract.status);
  const statusColorClassName = getStatusColorClassName(contract, statusColorStyles);
  return (
    <Chip
      label={statusLabel}
      size="small"
      style={{ fontSize: "0.9em", fontWeight: 300 }}
      variant={"outlined"}
      className={statusColorClassName}
    />
  );
}
