import React from "react";
import { useTranslation } from "react-i18next";

import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      marginTop: 16,
    },
    legalLinksContainer: {
      marginTop: 16,
      marginBottom: -8,
      display: "flex",
      justifyContent: "space-around",
    },
    legalLink: {
      color: "#999",
      fontSize: 12,
      textDecoration: "none",
    },
  })
);

export const UserPopupLinks: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.legalLinksContainer}>
      <a className={classes.legalLink} href="/">
        {t("appbar.user-button.popup.imprint")}
      </a>
      <a className={classes.legalLink} href="/">
        {t("appbar.user-button.popup.privacy-policy")}
      </a>
    </div>
  );
};
