import { Button, Modal, Typography } from "@material-ui/core";
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { idfyTheme } from "../../../../../util/IdfyTheme";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      top: "50%",
      left: "50%",
      width: "30%",
      transform: "translate(-50%,-50%)",
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      outline: 0,
      boxSizing: "border-box",
      padding: theme.spacing(5),
      borderRadius: "10px",
      background: idfyTheme.palette.background.paper,
      "& .CarouselItem": {
        height: "100%",
        "& div": {
          transitionDuration: 0,
        },
      },
    },
    buttonDiv: {
      display: "flex",
      justifyContent: "space-between",
    },
    button: {
      width: "30%",
      marginLeft: "10px",
    },
  })
);

export const AssetLocationModal: React.FunctionComponent<{
  open: boolean;
  handleClose: () => void;
  name: string;
  address: string;
  zip: string;
  city: string;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Modal open={props.open} onClose={() => props.handleClose()}>
      <div className={classes.modal}>
        <Typography variant="h2" component="h2">
          {t("customerLocation-modal.header")}
        </Typography>
        <p />
        <div>
          <Typography variant={"caption"}>{t("customerLocation-modal.name")}</Typography>
          <Typography>{props.name}</Typography>
        </div>
        <div>
          <Typography variant={"caption"}>{t("customerLocation-modal.streetAndHouseNumber")}</Typography>
          <Typography>{props.address}</Typography>
        </div>
        <div>
          <Typography variant={"caption"}>{t("customerLocation-modal.zip")}</Typography>
          <Typography>{props.zip}</Typography>
        </div>
        <div>
          <Typography variant={"caption"}>{t("customerLocation-modal.city")}</Typography>
          <Typography>{props.city}</Typography>
        </div>
        <p />
        <div className={classes.buttonDiv}>
          <Button
            variant={"outlined"}
            onClick={(e) => {
              e.preventDefault();
              window.open(
                /*`https://www.openstreetmap.org/search?query=${props.address} ${props.city}`*/ // openstreetmap works without marker or highlight
                `https://nominatim.openstreetmap.org/ui/search.html?q=${props.address} ${props.zip} ${props.city}`, // nominatim is showing markers or highlighting properties
                "_blank"
              );
            }}
          >
            {t("customerLocation-modal.locationButton")}
          </Button>
          <Button className={classes.button} variant={"outlined"} onClick={() => props.handleClose()}>
            {t("customerLocation-modal.done")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
