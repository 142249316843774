import React from "react";
import { useTranslation } from "react-i18next";
import { SearchSelectField, SearchSelectOption } from "../../components/SearchSelectField";
import { TFunction } from "i18next";
import { getTeamFilterOptions, TeamFilter } from "../../components/team/TeamFilter";
import { ContractStatus } from "../../__generated__/globalTypes";
import { getContractStatusFilterOptions } from "../contracts/ContractStatusFilter";

export enum AssetFilterValue {
  ALL = "ALL",
  PENDING = "PENDING",
  WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL",
  APPROVED = "APPROVED",
  OUT_OF_CONTRACT = "OUT_OF_CONTRACT",
  UNCHECKED = "UNCHECKED",
}

function getAssetOptionsLabel(key: string, t: TFunction): string {
  if (key == AssetFilterValue.ALL)
    return (
      t("pages.assets.filterSelection.status") +
      " : " +
      t("pages.assets.filterSelection." + AssetFilterValue[key as AssetFilterValue])
    );
  return t("pages.assets.filterSelection." + AssetFilterValue[key as AssetFilterValue]);
}

export function getAssetFilterOptions(t: TFunction): SearchSelectOption[] {
  return Object.keys(AssetFilterValue).map((key) => ({
    value: AssetFilterValue[key as AssetFilterValue],
    label: getAssetOptionsLabel(key, t),
  }));
}

function getContractStatusFilterOptionsForAssetPage(t: TFunction): SearchSelectOption[] {
  const contractStatusOptions = getContractStatusFilterOptions(t);
  return contractStatusOptions.filter((value: SearchSelectOption) => value.value !== ContractStatus.INACTIVE);
}

export const AssetFilterSelection: React.FunctionComponent<{
  onAssetStatusFilterChange: (filterValue: AssetFilterValue) => void;
  onContractStatusFilterChange: (filterValue: ContractStatus) => void;
  onFilterChangeForTeam: (filterValue: TeamFilter) => void;
  initTeamValue: TeamFilter;
}> = (props) => {
  const { t } = useTranslation();
  const assetStatusOptions = getAssetFilterOptions(t);
  const handleAssetStatusChange = (newValue: AssetFilterValue) => {
    props.onAssetStatusFilterChange(newValue);
  };
  const contractStatusOptions = getContractStatusFilterOptionsForAssetPage(t);
  const handleContractStatusChange = (newValue: ContractStatus) => {
    props.onContractStatusFilterChange(newValue);
  };
  const teamOptions = getTeamFilterOptions(t);
  const handleChangeForTeam = (newValue: TeamFilter) => {
    props.onFilterChangeForTeam(newValue);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <SearchSelectField
          options={contractStatusOptions}
          onSelectChange={(newField) => handleContractStatusChange(newField as ContractStatus)}
        />
        <SearchSelectField
          options={assetStatusOptions}
          onSelectChange={(newField) => handleAssetStatusChange(newField as AssetFilterValue)}
        />
        <SearchSelectField
          options={teamOptions}
          onSelectChange={(newField) => handleChangeForTeam(newField as TeamFilter)}
          initValue={props.initTeamValue}
        />
      </div>
    </React.Fragment>
  );
};
