import React, { useContext, useMemo, useState } from "react";
import { BasePage } from "../pageFragments/BasePage";
import { usePrefixedTranslation } from "../../util/usePrefixedTranslation";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Theme } from "@material-ui/core";
import { SearchField } from "../../components/SearchField";
import { TableHeader } from "../../components/TableHeader";
import { TableWrapperPaper } from "../pageFragments/util/TableWrapperPaper";
import { SuppliersTable } from "./SuppliersTable";
import { useMutation, useQuery } from "@apollo/client";
import { suppliersQuery } from "./api/__generated__/suppliersQuery";
import { DELETE_SUPPLIER_MUTATION, SUPPLIERS_QUERY } from "./api/suppplierQueriesAndMutations";
import { SnackContext } from "../../routing/idfyRouter";
import { BasePageWithLoadingIndicator } from "../../components/BasePageWithLoadingIndicator";
import { CreateSupplierModal } from "./CreateSupplierModal";
import { deleteSupplierMutation, deleteSupplierMutationVariables } from "./api/__generated__/deleteSupplierMutation";
import { isAllowed } from "../../components/access/AccessManager";
import { useCurrentAccount } from "../../util/auth/useCurrentAccount";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    additionalContentContainer: {
      width: "33%",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginTop: theme.spacing(1),
      },
    },
  })
);

export const SuppliersPage: React.FunctionComponent = () => {
  const classes = useStyles();
  const { pt } = usePrefixedTranslation("pages.suppliers.");
  const { data } = useQuery<suppliersQuery>(SUPPLIERS_QUERY, { fetchPolicy: "network-only" });
  const snackContext = useContext(SnackContext);
  const currentAccount = useCurrentAccount();

  const [searchText, setSearchText] = useState("");
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const filteredSuppliers = useMemo(() => {
    if (searchText.length === 0) {
      return data?.suppliers;
    }
    return data?.suppliers.filter((asset) => {
      return asset.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
    });
  }, [searchText, data]);

  const [deleteSupplier] = useMutation<deleteSupplierMutation, deleteSupplierMutationVariables>(
    DELETE_SUPPLIER_MUTATION,
    { refetchQueries: [{ query: SUPPLIERS_QUERY }] }
  );

  const handleSupplierDelete = (id: string, name: string) => {
    void deleteSupplier({ variables: { id: id } }).then((result) => {
      snackContext.setSnackOpen(true);
      const snackText = result.data?.deleteSupplier.deletionSuccessful
        ? pt("supplier-deleted", { manufacturer: name })
        : pt("supplier-could-not-be-deleted", { manufacturer: name });
      snackContext.setSnackText(snackText);
    });
  };

  if (!data) {
    return <BasePageWithLoadingIndicator headerText={pt("header")} />;
  }

  return (
    <>
      <BasePage headerText={pt("header")}>
        <TableHeader
          title={pt("header")}
          buttonText={pt("buttons.new")}
          onButtonClick={() => setCreateModalOpen(true)}
          enabled={isAllowed("pages.suppliers.buttons.new", currentAccount)}
          additionalContent={
            <div className={classes.additionalContentContainer}>
              <SearchField placeholder={pt("search-suppliers")} onChange={(e) => setSearchText(e.target.value)} />
            </div>
          }
        />
        <TableWrapperPaper fullHeight={true}>
          <SuppliersTable
            suppliers={filteredSuppliers || []}
            onDeleteClick={(id: string, name: string) => handleSupplierDelete(id, name)}
          />
        </TableWrapperPaper>
      </BasePage>
      <CreateSupplierModal
        open={createModalOpen}
        handleClose={(supplierCreated) => {
          setCreateModalOpen(false);
          snackContext.setSnackOpen(supplierCreated);
          snackContext.setSnackText(pt("supplier-created"));
        }}
      />
    </>
  );
};
