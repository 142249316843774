import { format } from "date-fns";

import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Paper, Tooltip, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { usePrefixedTranslation } from "../../../../util/usePrefixedTranslation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      boxSizing: "border-box",
      display: "grid",
      gridTemplateColumns: "64px auto",
    },
    iconWrapper: {
      backgroundColor: theme.palette.grey["100"],
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    titleWrapper: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      margin: theme.spacing(3),
      marginBottom: 0,
    },
    title: {
      marginTop: 0,
      marginRight: theme.spacing(1),
    },
    bodyContainer: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    informationContainer: {
      display: "flex",
      flexWrap: "wrap",
      margin: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginTop: 0,
    },
    informationField: {
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    informationFieldText: {
      marginLeft: theme.spacing(1),
    },
  })
);

export interface ProtocolEntryFieldProps {
  text: ReactNode;
  tooltip?: string;
  icon: ReactNode;
}

export interface ProtocolEntryProps {
  icon: ReactNode;
  title: string | ReactNode;
  time: Date | ReactNode;
  fields: Array<ProtocolEntryFieldProps>;
  body?: ReactNode;
}

export const AssetProtocolEntry: FC<ProtocolEntryProps> = (props) => {
  const { t } = useTranslation();
  const { pt } = usePrefixedTranslation("pages.asset-details.protocol.");
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div className={classes.iconWrapper}>{props.icon}</div>
      <div>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="h3">
            {props.title}
          </Typography>
          <Tooltip arrow title={pt("tooltip-time").toString()}>
            <Typography variant="caption">
              {props.time instanceof Date ? format(props.time, t("dates.full")) : props.time}
            </Typography>
          </Tooltip>
        </div>
        {props.body && <div className={classes.bodyContainer}>{props.body}</div>}
        <div className={classes.informationContainer}>
          {props.fields.map((field, index) => (
            <Tooltip key={index} arrow title={field.tooltip ?? ""}>
              <div className={classes.informationField}>
                {field.icon}
                <Typography className={classes.informationFieldText} variant="body2">
                  {field.text}
                </Typography>
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
    </Paper>
  );
};
