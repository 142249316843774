import { gql } from "@apollo/client";
import { assetFragment, offsiteLocationFragment, smallAssetFragment, smallSubAssetFragment } from "./assetFragments";
import { acceptanceDeclarationFragment } from "./acceptanceDeclarationFragment";
import { manufacturerFragment } from "../../manufacturers/api/manufacturerFragment";
import { pageInfoFragment } from "../../../util/graphql/pagingFragments";

export const ASSETS_BY_MANUFACTURER_AND_SERIAL_NUMBER_QUERY = gql`
  query assetsByManufacturerAndSerialNumberQuery($manufacturerId: String!, $serialNumber: String!) {
    assetsByManufacturerAndSerialNumber(manufacturerId: $manufacturerId, serialNumber: $serialNumber) {
      id
    }
  }
`;

export const ASSETS_AND_SUB_ASSETS_BY_MANUFACTURER_AND_SERIAL_NUMBER_QUERY = gql`
  query assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBoth($manufacturerId: String!, $serialNumber: String!) {
    assetsByManufacturerAndSerialNumber(manufacturerId: $manufacturerId, serialNumber: $serialNumber) {
      id
    }
    subAssetsByManufacturerAndSerialNumber(manufacturerId: $manufacturerId, serialNumber: $serialNumber) {
      id
      assetId
    }
  }
`;

export const ADD_ASSET_MUTATION = gql`
  ${assetFragment}
  mutation addAssetMutation($input: AddAssetInput!) {
    addAsset(input: $input) {
      asset {
        ...AssetData
      }
    }
  }
`;

export const ASSET_DETAIL_QUERY = gql`
  ${assetFragment}
  ${manufacturerFragment}
  ${offsiteLocationFragment}
  ${acceptanceDeclarationFragment}
  query assetDetailQuery($id: ID!) {
    asset(id: $id) {
      ...AssetData
      manufacturer {
        ...Manufacturer
      }
      offsiteLocation {
        ...OffsiteLocation
      }
      supplierInvoiceNumber
      invoiceDate
      acceptanceDeclarations {
        ...AcceptanceDeclaration
      }
    }
  }
`;

export const ASSETS_QUERY = gql`
  ${assetFragment}
  ${manufacturerFragment}
  ${pageInfoFragment}
  query assetsPagedQuery($first: Int, $after: String, $filter: AssetFilter) {
    assetsPaged(first: $first, after: $after, filter: $filter, defaultSort: WEB) {
      edges {
        node {
          ...AssetData
          manufacturer {
            ...Manufacturer
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
`;

export const ASSETS_BY_CUSTOMER_ID_QUERY = gql`
  ${smallAssetFragment}
  query assetsByCustomerIdQuery($id: ID!) {
    assets(sort: WEB, filter: { customerId: $id }) {
      ...SmallAsset
    }
  }
`;

export const ASSETS_BY_CONTRACT_ID_QUERY = gql`
  ${smallAssetFragment}
  query assetsByContractIdQuery($id: ID!) {
    assets(sort: WEB, filter: { contractId: $id }) {
      ...SmallAsset
    }
  }
`;

export const SUB_ASSET_IMAGE_QUERY = gql`
  ${smallSubAssetFragment}
  query subAssetImageQuery($id: ID!) {
    asset(id: $id) {
      id
      subAssets {
        ...SmallSubAsset
        image {
          data
          type
        }
      }
    }
  }
`;

export const SUB_ASSET_QUERY = gql`
  ${smallSubAssetFragment}
  query subAssetQuery($id: ID!) {
    asset(id: $id) {
      id
      subAssets {
        ...SmallSubAsset
      }
    }
  }
`;

export const USE_OUT_OF_CONTRACT_ASSET_MUTATION = gql`
  ${assetFragment}
  mutation useOutOfContractAssetMutation($input: UseOutOfContractAssetInput!) {
    useOutOfContractAsset(input: $input) {
      asset {
        ...AssetData
      }
    }
  }
`;
