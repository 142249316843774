import React from "react";
import { usePrefixedTranslation } from "../../util/usePrefixedTranslation";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { manufacturersQuery_manufacturers } from "./api/__generated__/manufacturersQuery";
import { NoDataPlaceholder } from "../../components/NoDataPlaceholder";
import { palette } from "../../util/IdfyTheme";
import { ContextModifyMenu } from '../pageFragments/util/ContextModifyMenu';
import { isAllowed } from "../../components/access/AccessManager";
import { useCurrentAccount } from "../../util/auth/useCurrentAccount";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      overflow: "auto",
      "-webkit-overflow-scrolling": "touch",
    },
    tableHeadRow: {
      "& th": {
        background: theme.palette.background.paper,
        color: palette.mediumGrey,
      },
    },
    tableRow: {
      padding: `${theme.spacing(4)}px 0`,
    },
    nameColumn: {
      width: "100%",
    },
  })
);

export const ManufacturerTable: React.FunctionComponent<{
  manufacturers: manufacturersQuery_manufacturers[];
  onDeleteClick: (id: string, name: string) => void;
  onEditClick: (id: string, name: string) => void;

}> = (props) => {
  const currentAccount = useCurrentAccount();
  const classes = useStyles();
  const { pt } = usePrefixedTranslation("pages.manufacturers.table.");

  if (props.manufacturers.length <= 0) {
    return <NoDataPlaceholder title={pt("no-data-placeholder.title")} subTitle={pt("no-data-placeholder.subtitle")} />;
  }

  return (

    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            <TableCell>{pt("manufacturer").toUpperCase()}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.manufacturers.map((manufacturer) => (
            <TableRow key={manufacturer.id} className={classes.tableRow}>
              <TableCell className={classes.nameColumn}>
                <b>{manufacturer.name}</b>
              </TableCell>
              <TableCell>
                <ContextModifyMenu
                  menuItems={[
                    {
                      buttonText: pt("delete"), 
                      handleModification: () => props.onDeleteClick(manufacturer.id, manufacturer.name),
                      enabled: isAllowed("pages.manufacturers.table.delete", currentAccount)
                  }, 
                    {
                      buttonText: pt("edit"), 
                      handleModification: () => props.onEditClick(manufacturer.id, manufacturer.name),
                      enabled: isAllowed("pages.manufacturers.table.edit", currentAccount)
                    }
                  ]}
                  >
                </ContextModifyMenu>
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
