import React, { ReactElement, useEffect } from "react";
import { PopUpDialog } from "../../pageFragments/util/PopUpDialog";
import { usePrefixedTranslation } from "../../../util/usePrefixedTranslation";
import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { Trans } from "react-i18next";
import { useQuery } from "@apollo/client";
import { ASSETS_BY_CONTRACT_ID_QUERY } from "../../assets/api/assetQueriesAndMutations";
import { assetsByContractIdQuery } from "../../assets/api/__generated__/assetsByContractIdQuery";

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      maxWidth: 500,
    },
  })
);

export function EndContractDialog(props: {
  contractId: string;
  endContractHandler: (value: boolean) => void;
  open: boolean;
}): ReactElement {
  const { pt } = usePrefixedTranslation("pages.contracts.change-status-dialog.");
  const classes = useStyles();
  const { data } = useQuery<assetsByContractIdQuery>(ASSETS_BY_CONTRACT_ID_QUERY, {
    variables: { id: props.contractId },
  });
  useEffect(() => {
    if (data) {
    }
  }, [data]);
  return (
    <PopUpDialog
      open={props.open}
      onClose={() => {
        props.endContractHandler(false);
      }}
      handleConfirm={() => {
        props.endContractHandler(true);
      }}
      header={pt("end-contract-header")}
      confirm={pt("end-contract-confirm")}
      deny={pt("end-contract-cancel")}
      handleDeny={() => {
        props.endContractHandler(false);
      }}
    >
      <Grid container spacing={2} alignItems={"flex-start"} direction={"column"}>
        <Grid spacing={1} container direction={"row"} item>
          <Alert severity="warning" className={classes.dialog}>
            <Grid spacing={1} container direction={"row"}>
              <Grid item>{pt("end-contract-body-1")}</Grid>
              <Grid item>{pt("end-contract-body-2")}</Grid>
              <Grid item>{pt("end-contract-body-3")}</Grid>
            </Grid>
          </Alert>
        </Grid>
        <Grid item>
          <Typography variant={"body1"}>
            {data && (
              <Trans
                t={pt}
                i18nKey="end-contract-asset-quantity"
                values={{
                  numberOfAssets: data.assets.length,
                }}
              />
            )}
          </Typography>
        </Grid>
      </Grid>
    </PopUpDialog>
  );
}
