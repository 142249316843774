import { Contract } from "./api/__generated__/Contract";
import { TFunction } from "i18next";
import * as yup from "yup";
import { FIELD_VALIDATION_TYPE_STRING } from "../../util/constants";
import { ContractInput, ContractStatus } from "../../__generated__/globalTypes";
import {
  fieldValidationDateString,
  fieldValidationEntityRef,
  fieldValidationOptionalDateString,
  fieldValidationOptionalString,
} from "../../util/yup/validations";
import { string } from "yup";
import { isBlankOrEmpty } from "../../util/stringUtil";

export const CONTRACT_STATUS_LIST: ContractStatus[] = Object.values(ContractStatus).map((k) => ContractStatus[k]);

export const contractStatusSchema: yup.SchemaOf<ContractStatus> = yup.mixed().oneOf(CONTRACT_STATUS_LIST);

export function contractSchema(t: TFunction): yup.SchemaOf<ContractInput> {
  return yup.object({
    contractNumber: FIELD_VALIDATION_TYPE_STRING(t),
    customer: fieldValidationEntityRef(t),
    startDate: fieldValidationDateString(t),
    endDate: string().when("isContractEndOfTermUnknown", {
      is: false,
      then: fieldValidationDateString(t),
      otherwise: string().notRequired().nullable(),
    }),
    extendedEndDate: fieldValidationOptionalDateString(t),
    extensionPeriod: fieldValidationOptionalString(t).nullable(),
    status: contractStatusSchema,
  });
}

export interface ContractInfoFormFields extends Contract {
  isContractEndOfTermUnknown: boolean;
  isContractEndExtended: boolean;
}

export function toContractInput(contract: ContractInfoFormFields): ContractInput {
  return {
    contractNumber: contract.contractNumber,
    customer: {
      id: contract.customer.id,
    },
    startDate: contract.startDate,
    endDate: contract.endDate,
    status: contract.status,
    extensionPeriod: contract.extensionPeriod,
    extendedEndDate: contract.extendedEndDate,
  };
}

export function toContractInfoFormFields(contract: Contract): ContractInfoFormFields {
  return {
    ...contract,
    isContractEndOfTermUnknown: contract.endDate === null,
    isContractEndExtended: !isBlankOrEmpty(contract.extendedEndDate) || !isBlankOrEmpty(contract.extensionPeriod),
  };
}
