import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Grid, makeStyles, TextField, Typography } from "@material-ui/core";

import { idfyTheme } from "../../../util/IdfyTheme";
import { InspectionPreview } from "../../assets/assetDetails/inspection/InspectionPreview";
import { StatusChip } from "../../pageFragments/util/StatusChip";
import { blueprintInfoQuery_blueprint } from "./__generated__/blueprintInfoQuery";
import { createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  })
);

export const BlueprintInfo: React.FunctionComponent<{
  blueprint: blueprintInfoQuery_blueprint;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { control, errors } = useForm<{ name: string }>({
    defaultValues: { name: props.blueprint.name },
  });

  return (
    <React.Fragment>
      <form onSubmit={() => null}>
        <div className={classes.header}>
          <Typography variant={"h3"}>{t("pages.blueprint-details.information.information")}</Typography>
        </div>
        <Grid container spacing={4} justifyContent={"flex-start"}>
          <Grid item xs={12}>
            <Controller
              control={control}
              as={TextField}
              disabled={true}
              fullWidth
              variant="outlined"
              size="small"
              name="name"
              label={t("pages.blueprint-details.information.name")}
              color="secondary"
              helperText={errors.name?.message}
              error={!!errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h3"} align={"left"} style={{ display: "flex", flexWrap: "nowrap" }}>
              <span style={{ marginRight: idfyTheme.spacing(1) }}>
                {t("pages.blueprint-details.information.blueprint")}
              </span>
              <StatusChip status={props.blueprint.status} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InspectionPreview
              inspection={props.blueprint}
              isInitial={true}
              startOpen={true}
              onInspectionSubmit={() => null}
            />
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};
