import "typeface-roboto";
import "typeface-montserrat";
import "typeface-roboto-condensed";

import { createTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import type {} from "@material-ui/lab/themeAugmentation";

export const palette = {
  yellow: "#ffcb47",
  blue: "#809fb0",
  darkBlue: "#264653",
  black: "#3C3C3C",
  lightBlue: "rgba(31,122,140,0.32)",
  lightYellow: "#ffec59",
  lightBackground: "#F4F2F0",
  whiteBlue: "rgba(31,122,140,0.04)",
  red: "#E76F51",
  green: "#2c988b",
  lightGrey: "#ccc",
  mediumGrey: "#999",
};

const robotoMedium = {
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontWeight: 700,
};

const robotoBase = {
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
};

export const idfyTheme = createTheme({
  palette: {
    primary: {
      main: palette.yellow,
      light: palette.lightYellow,
    },
    secondary: {
      main: palette.darkBlue,
    },
    background: {
      default: palette.lightBackground,
    },
    text: {
      primary: palette.black,
    },
    success: {
      main: palette.darkBlue,
      dark: palette.green,
    },
    warning: {
      main: palette.yellow,
    },
    error: {
      main: palette.red,
    },
    info: {
      main: palette.lightBlue,
    },
    action: {
      active: palette.blue,
    },
  },
  typography: {
    fontSize: 14,
    h1: {
      ...robotoMedium,
      fontSize: 22,
      color: palette.darkBlue,
    },
    h2: {
      ...robotoMedium,
      fontSize: 18,
      color: palette.darkBlue,
    },
    h3: {
      ...robotoBase,
      fontWeight: 500,
      fontSize: 18,
      letterSpacing: "-0.035em",
      color: palette.darkBlue,
      margin: "8px 0",
    },
    h4: {
      ...robotoBase,
      fontSize: 16,
      fontWeight: 500,
      color: palette.darkBlue,
    },
    h5: {
      ...robotoBase,
      fontSize: 16,
      fontWeight: 600,
      color: palette.darkBlue,
    },
    h6: {
      ...robotoBase,
      fontSize: 14,
      fontWeight: 600,
      color: palette.darkBlue,
    },
    body1: {
      ...robotoBase,
      fontSize: 14,
      fontWeight: 300,
      color: palette.black,
    },
  },
  overrides: {
    MuiFormHelperText: {
        root: {
            color: palette.red
      }
    },
    MuiButton: {
      root: {
        borderRadius: "8px",
        textTransform: "none",
        minWidth: "max-content",
        minHeight: "fit-content",
      },
      text: {
        fontSize: 14,
      },
      textPrimary: {
        color: palette.darkBlue,
        background: palette.yellow,
        "&:hover": {
          backgroundColor: `${palette.lightYellow} !important`,
        },
      },
      textSecondary: {
        "@media (hover: none)": {
          color: palette.darkBlue,
          backgroundColor: palette.blue,
        },
        color: palette.darkBlue,
        background: palette.lightBlue,
        "&:hover": {
          backgroundColor: palette.blue,
          "@media (hover: none)": {
            backgroundColor: palette.blue,
          },
        },
      },
    },
    MuiIconButton: {
      root: {
        color: palette.black,
        fill: palette.black,
        padding: "6px",
        label: {
          fill: palette.black,
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: "10px",
        padding: "14px !important",
      },
    },
    MuiTableRow: {
      hover: {
        cursor: "pointer",
      },
      root: {
        verticalAlign: "baseline",
      },
    },
    MuiTableCell: {
      head: {
        fontSize: "0.75em",
      },
    },
    MuiBadge: {
      anchorOriginBottomLeftRectangle: {
        left: "8px",
        bottom: "8px",
        transform: "none",
        transformOrigin: "none",
        borderRadius: "4px",
      },
      colorPrimary: {
        backgroundColor: palette.yellow + "bb",
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: palette.darkBlue,
      },
      message: {},
    },
    MuiChip: {
      sizeSmall: {
        padding: "1px 6px",
      },
      labelSmall: {
        fontWeight: 450,
      },
    },
    MuiAlert: {
      standardError: {
        backgroundColor: "rgba(231, 111, 81, 0.48)",
        color: "rgba(60, 60, 60, 1.0)",
        "& $icon": {
          color: "rgba(60, 60, 60, 1.0)",
        },
      },
    },
  },
  breakpoints: {
    //TODO - xs/sm equal to 1 is a hack to get mobile version displayed for width < md. Suggest values are revisited. MUI defaults are in the comments.
    values: {
      xs: 1,
      sm: 1, //600
      md: 850, //960
      lg: 1024, //1280
      xl: 1430, //1920
    },
  },
});

export const useInfoBoxStyles = makeStyles(() =>
  createStyles({
    infoBox: {
      alignItems: "center",
    },
  })
);
