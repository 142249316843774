import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { BreadcrumbContext } from "../../../routing/idfyRouter";
import { createStyles, TableCell, TableRow, Theme, useMediaQuery } from "@material-ui/core";
import { idfyTheme } from "../../../util/IdfyTheme";
import { useTranslation } from "react-i18next";
import { routes } from "../../../routing/routes";
import { AgriLogo } from "../../pageFragments/util/AgriLogo";
import { AssetStatusCell } from "../../pageFragments/util/StatusChip";
import { makeStyles } from "@material-ui/core/styles";
import { isBlankOrEmpty } from "../../../util/stringUtil";
import { ContractStatusIcon } from "../../contracts/ContractStatus";
import { CustomerCell } from "../../customers/CustomerCell";
import { assetsPagedQuery_assetsPaged_edges_node } from "../api/__generated__/assetsPagedQuery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      padding: `${theme.spacing(4)}px 0`,
      verticalAlign: "middle",
    },
    assetThumbnail: {
      width: 80,
      height: 80,
      margin: theme.spacing(1),
      borderRadius: "50%",
    },
    assetThumbnailIcon: {
      boxSizing: "border-box",
      width: 80,
      height: 80,
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      borderRadius: "50%",
      border: "1px solid grey",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&> svg": {
        fill: "grey",
        width: 60,
        height: 60,
      },
    },
    condensed: {
      fontFamily: "Roboto Condensed",
      fontSize: "0.9em",
    },
    serialNumberUndefined: {
      color: "red",
    },
  })
);

export const AssetTableRow: React.FunctionComponent<{ asset: assetsPagedQuery_assetsPaged_edges_node }> = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const utilContext = useContext(BreadcrumbContext);
  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <TableRow
      onClick={() => {
        utilContext.breadcrumbs.push({
          url: history.location.pathname || "errorPage",
          title: t("pages.assets.header"),
        });
        history.push(routes.assets + routes.details(props.asset.id) + routes.infoSuffix);
      }}
      className={classes.tableRow}
      hover={!mobile}
    >
      <TableCell>
        {props.asset.image ? (
          <img
            alt="Asset Thumbnail"
            className={classes.assetThumbnail}
            src={`data:${props.asset.image.type};base64,${props.asset.image.data}`}
          />
        ) : (
          <div className={classes.assetThumbnailIcon}>
            <AgriLogo className={""} />
          </div>
        )}
      </TableCell>
      <TableCell>
        <b>
          {props.asset.manufacturer?.name} {props.asset.name}
        </b>
        <br />
        <span style={{ fontSize: "0.9em" }}>{props.asset.blueprint?.name}</span>
      </TableCell>
      <TableCell>
        {isBlankOrEmpty(props.asset.serialNumber) ? (
          <span className={`${classes.serialNumberUndefined} ${classes.condensed}`}>
            {t("pages.assets.table.asset-row.serial-number-undefined")}
          </span>
        ) : (
          <span className={classes.condensed}>{props.asset.serialNumber}</span>
        )}
      </TableCell>
      <TableCell>
        {props.asset.currentContract && <ContractStatusIcon contract={props.asset.currentContract} />}
      </TableCell>
      <CustomerCell customer={props.asset.currentContract?.customer} />
      <TableCell>
        <span>
          <AssetStatusCell asset={props.asset} mobile={false} />
        </span>
      </TableCell>
    </TableRow>
  );
};

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-types
function AssetTableRowLoading(props: {}, ref: React.ForwardedRef<never>) {
  const classes = useStyles();
  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));

  return (
    <TableRow className={classes.tableRow} hover={!mobile} key={"sentry"} ref={ref}>
      <TableCell colSpan={6}>Loading</TableCell>
    </TableRow>
  );
}

export const ReferencedAssetTableRowLoading = React.forwardRef(AssetTableRowLoading);
