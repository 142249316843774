import React from "react";
import { FormModal } from "../../components/FormModal";
import { ControllerTextField } from "../../components/inputs/ControllerTextField";
import { usePrefixedTranslation } from "../../util/usePrefixedTranslation";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { SupplierInput } from "../../__generated__/globalTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FIELD_VALIDATION_TYPE_STRING } from "../../util/constants";
import { useMutation } from "@apollo/client";
import { createSupplierMutation } from "./api/__generated__/createSupplierMutation";
import { CREATE_SUPPLIER_MUTATION, SUPPLIERS_QUERY } from "./api/suppplierQueriesAndMutations";

export const CreateSupplierModal: React.FunctionComponent<{
  open: boolean;
  handleClose: (supplierCreated: boolean) => void;
}> = (props) => {
  const { pt } = usePrefixedTranslation("pages.suppliers.modals.create-supplier.");
  const { t } = useTranslation();

  const schema = yup.object().shape({
    name: FIELD_VALIDATION_TYPE_STRING(t),
  });
  const { handleSubmit, errors, control } = useForm<SupplierInput>({
    resolver: yupResolver(schema),
  });

  const [createSupplier] = useMutation<createSupplierMutation>(CREATE_SUPPLIER_MUTATION, {
    refetchQueries: [{ query: SUPPLIERS_QUERY }],
  });

  async function onSubmit(values: SupplierInput) {
    await createSupplier({
      variables: {
        input: {
          name: values.name,
          streetAddress: values.streetAddress,
          zip: values.zip,
          city: values.city,
        },
      },
    });
    props.handleClose(true);
  }
  return (
    <FormModal
      open={props.open}
      onClose={() => props.handleClose(false)}
      cancelButtonText={pt("buttons.cancel")}
      submitButtonText={pt("buttons.create")}
      header={pt("header")}
      onSubmit={handleSubmit(onSubmit)}
    >
      <ControllerTextField
        control={control}
        fullWidth
        variant="outlined"
        size="small"
        name="name"
        label={pt("supplier")}
        color="secondary"
        helperText={errors.name?.message}
        error={!!errors.name}
        trimOnBlur
        required
      />
      <p />
      <ControllerTextField
        control={control}
        fullWidth
        variant="outlined"
        size="small"
        name="streetAddress"
        label={pt("streetAddress")}
        color="secondary"
        helperText={errors.name?.message}
        error={!!errors.name}
        trimOnBlur
        required
      />
      <p />
      <ControllerTextField
        control={control}
        fullWidth
        variant="outlined"
        size="small"
        name="zip"
        label={pt("zip")}
        color="secondary"
        helperText={errors.name?.message}
        error={!!errors.name}
        trimOnBlur
        required
      />
      <p />
      <ControllerTextField
        control={control}
        fullWidth
        variant="outlined"
        size="small"
        name="city"
        label={pt("city")}
        color="secondary"
        helperText={errors.name?.message}
        error={!!errors.name}
        trimOnBlur
        required
      />
    </FormModal>
  );
};
