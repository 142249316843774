import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { string } from "yup";
import { SubAssetInput } from "../../../__generated__/globalTypes";
import { useTranslation } from "react-i18next";
import {
  AssetOrSubAssetAlertReason,
  DuplicateAssetOrSubAssetAlert,
} from "../../../components/DuplicateAssetOrSubAssetAlert";
import { FIELD_VALIDATION_TYPE_STRING } from "../../../util/constants";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useApolloClient, useQuery } from "@apollo/client";
import { ASSETS_AND_SUB_ASSETS_BY_MANUFACTURER_AND_SERIAL_NUMBER_QUERY } from "../api/assetQueriesAndMutations";
import { MANUFACTURERS_QUERY } from "../../manufacturers/api/manufacturerQueriesAndMutations";
import { PopUpDialog } from "../../pageFragments/util/PopUpDialog";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBoth,
  assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBothVariables,
} from "../api/__generated__/assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBoth";
import { manufacturersQuery } from "../../manufacturers/api/__generated__/manufacturersQuery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      maxWidth: 300,
    },
    duplicateAssetContainer: {
      marginBottom: theme.spacing(3),
    },
  })
);

export const AddSubAssetDialog: React.FunctionComponent<{
  addSubAssetDialogOpen: boolean;
  handleClose: () => void;
  handleSubmit: (name: string, serial: string, manufacturerId: string) => void;
  initialAssetManufacturerId: string;
  serialNumberOfAsset: string;
  previousSubAssets: SubAssetInput[];
}> = (props) => {
  const { t } = useTranslation();

  const [manufacturerAndSerialInUse, setManufacturerAndSerialInUse] = useState({ inUse: false, assetId: "" });
  const [duplicateReason, setDuplicateReason] = useState<AssetOrSubAssetAlertReason | undefined>(undefined);
  const schema = yup.object().shape({
    name: FIELD_VALIDATION_TYPE_STRING(t),
    serialNumber: string().notRequired().trim(t("validations.trim")),
    manufacturerId: FIELD_VALIDATION_TYPE_STRING(t),
  });

  const { control, errors, handleSubmit, reset, watch, setError, clearErrors } = useForm<{
    name: string;
    serialNumber: string;
    manufacturerId: string;
  }>({
    resolver: yupResolver(schema),
    defaultValues: { name: "", serialNumber: "", manufacturerId: props.initialAssetManufacturerId },
  });

  const client = useApolloClient();
  const assetsAndSubAssetsByManufacturerAndSerialNumber = async (manufacturerId: string, serialNumber: string) => {
    return client.query<
      assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBoth,
      assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBothVariables
    >({
      query: ASSETS_AND_SUB_ASSETS_BY_MANUFACTURER_AND_SERIAL_NUMBER_QUERY,
      variables: { manufacturerId: manufacturerId, serialNumber: serialNumber },
    });
  };

  const manufacturerId = watch("manufacturerId");
  const serialNumber = watch("serialNumber");

  useEffect(() => {
    const previousAssetsFilter = props.previousSubAssets.filter((subAsset) => {
      return subAsset.serialNumber === serialNumber && subAsset.manufacturerId === manufacturerId;
    });
    if (manufacturerId && serialNumber) {
      assetsAndSubAssetsByManufacturerAndSerialNumber(manufacturerId, serialNumber).then((result) => {
        if (result.data.assetsByManufacturerAndSerialNumber.length > 0) {
          setManufacturerAndSerialInUse({
            inUse: true,
            assetId: result.data.assetsByManufacturerAndSerialNumber[0].id,
          });
          setError("manufacturerId", {});
          setError("serialNumber", {});
          setDuplicateReason("SerialNumberUsedInExistingAsset");
        } else if (result.data.subAssetsByManufacturerAndSerialNumber.length > 0) {
          setManufacturerAndSerialInUse({
            inUse: true,
            assetId: result.data.subAssetsByManufacturerAndSerialNumber[0].assetId,
          });

          setError("manufacturerId", {});
          setError("serialNumber", {});
          setDuplicateReason("SerialNumberUsedInExistingSubAsset");
        } else if (previousAssetsFilter.length > 0) {
          setManufacturerAndSerialInUse({
            inUse: true,
            assetId: "",
          });
          setDuplicateReason("SerialNumberUsedInExistingSubAsset");
        } else if (serialNumber === props.serialNumberOfAsset && manufacturerId === props.initialAssetManufacturerId) {
          setManufacturerAndSerialInUse({
            inUse: true,
            assetId: "0",
          });
          setError("manufacturerId", {});
          setError("serialNumber", {});
          setDuplicateReason("SerialNumberUsedInCurrentAsset");
        } else {
          setManufacturerAndSerialInUse({ inUse: false, assetId: "-1" });
          clearErrors("manufacturerId");
          clearErrors("serialNumber");
        }
      });
    }
  }, [manufacturerId, serialNumber]);

  useEffect(() => {
    setManufacturerAndSerialInUse({ inUse: false, assetId: "0" });
  }, [props.addSubAssetDialogOpen]);

  const classes = useStyles();
  const manufacturerData = useQuery<manufacturersQuery>(MANUFACTURERS_QUERY).data;

  useEffect(() => {
    reset({
      name: "",
      serialNumber: "",
      manufacturerId: props.initialAssetManufacturerId,
    });
  }, [manufacturerData]);

  return (
    <PopUpDialog
      open={props.addSubAssetDialogOpen}
      onClose={props.handleClose}
      handleConfirm={handleSubmit((data) => props.handleSubmit(data.name, data.serialNumber, data.manufacturerId))}
      header={t("pages.assets.modals.create-asset.sub-assets.add-sub-asset")}
      confirm={t("pages.assets.modals.create-asset.sub-assets.buttons.save")}
      deny={t("pages.assets.modals.create-asset.sub-assets.buttons.cancel")}
      confirmButtonDisabled={manufacturerAndSerialInUse.inUse}
      handleDeny={() => {
        reset();
        props.handleClose();
      }}
    >
      <form className={classes.form}>
        {manufacturerAndSerialInUse.inUse && (
          <Grid item xs={12} className={classes.duplicateAssetContainer}>
            {duplicateReason && (
              <DuplicateAssetOrSubAssetAlert reason={duplicateReason} assetId={manufacturerAndSerialInUse.assetId} />
            )}
          </Grid>
        )}
        <Grid container spacing={2} alignItems={"flex-start"}>
          <Grid item xs={12}>
            <Controller
              name={"name"}
              label={t("pages.assets.modals.create-asset.sub-assets.name")}
              control={control}
              as={TextField}
              size={"small"}
              fullWidth
              variant={"outlined"}
              error={!!errors.name}
              color={"secondary"}
              helperText={errors.name?.message}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={"serialNumber"}
              label={t("pages.assets.modals.create-asset.sub-assets.serial-number")}
              control={control}
              as={TextField}
              size={"small"}
              fullWidth
              variant={"outlined"}
              error={!!errors.serialNumber}
              color={"secondary"}
              helperText={errors.serialNumber?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              size="small"
              fullWidth
              variant={"outlined"}
              color={"secondary"}
              error={!!errors.manufacturerId}
            >
              <InputLabel required>{t("pages.assets.modals.create-asset.sub-assets.manufacturer")}</InputLabel>
              <Controller
                control={control}
                as={Select}
                fullWidth
                name={"manufacturerId"}
                label={t("pages.assets.modals.create-asset.sub-assets.manufacturer")}
              >
                {manufacturerData?.manufacturers.map((manufacturer, i) => (
                  <MenuItem value={manufacturer.id} key={i}>
                    <div>
                      <b>{manufacturer.name}</b>
                    </div>
                  </MenuItem>
                ))}
              </Controller>
              <FormHelperText>{errors.manufacturerId?.message}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </PopUpDialog>
  );
};
