import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { uaeAssetInfoOffsiteLocationStyles } from "../style/AssetInfoOffsiteLocationStyles";
import { assetDetailQuery_asset } from "../../../api/__generated__/assetDetailQuery";
import { FormGridContainer, FormGridItem } from "../../../../../components/forms/FormGrid";
import { FormTextField } from "../../../../../components/forms/FormTextField";
import { DetailsSectionHeader } from "../../../../../components/DetailsSectionHeader";

export const AssetInfoOffsiteLocationFields: React.FunctionComponent<{
  disabled: boolean;
  asset: assetDetailQuery_asset;
}> = (props) => {
  const { t } = useTranslation();

  const form = useFormContext();
  const styles = uaeAssetInfoOffsiteLocationStyles();

  if (!props.asset.currentContract) {
    return <React.Fragment></React.Fragment>;
  }
  const customerAddress = `${props.asset.currentContract?.customer.streetAddress}, ${props.asset.currentContract?.customer.zip} ${props.asset.currentContract?.customer.city}`;

  const isOffsiteLocation = form.watch("isOffsiteLocation");

  return (
    <React.Fragment>
      <DetailsSectionHeader header={t("pages.asset-details.information.object-location")} />
      <FormGridContainer>
        <FormGridItem>
          <Controller
            control={form.control}
            name={"isOffsiteLocation"}
            required
            render={({ onChange, value, ref }) => (
              <FormControl
                component="fieldset"
                disabled={props.disabled}
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                error={!!form.errors.isOffsiteLocation}
              >
                <RadioGroup row className={styles.radioGroup}>
                  <FormControlLabel
                    value="false"
                    control={<Radio checked={value === false} onChange={() => onChange(false)} />}
                    label={t("pages.assets.modals.create-asset.customer-address")}
                    inputRef={ref}
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio checked={value} onChange={() => onChange(true)} />}
                    label={t("pages.assets.modals.create-asset.offsite-location")}
                    inputRef={ref}
                  />
                </RadioGroup>
                <FormHelperText>{form.errors.isOffsiteLocation?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </FormGridItem>
        <FormGridItem>
          {!isOffsiteLocation && (
            <Typography variant={"body1"} className={styles.customerAddress}>
              {t("pages.asset-details.information.address")}: {customerAddress}
            </Typography>
          )}
        </FormGridItem>
        {isOffsiteLocation && (
          <>
            <FormGridItem>
              <FormTextField
                name={"offsiteLocation.name"}
                label={t("pages.assets.modals.create-asset.fields.offsite-location.name")}
                required
                disabled={props.disabled}
              />
            </FormGridItem>
            <FormGridItem>
              <FormTextField
                name="offsiteLocation.streetAddress"
                label={t("pages.assets.modals.create-asset.fields.offsite-location.street-address")}
                required
                disabled={props.disabled}
              />
            </FormGridItem>
            <FormGridItem>
              <FormTextField
                name="offsiteLocation.zip"
                label={t("pages.assets.modals.create-asset.fields.offsite-location.zip")}
                required
                disabled={props.disabled}
              />
            </FormGridItem>
            <FormGridItem>
              <FormTextField
                disabled={props.disabled}
                name="offsiteLocation.city"
                label={t("pages.assets.modals.create-asset.fields.offsite-location.city")}
                trimOnBlur
                required
              />
            </FormGridItem>
          </>
        )}
      </FormGridContainer>
    </React.Fragment>
  );
};
