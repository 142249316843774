import React from "react";

import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  accordion: {
    marginBottom: theme.spacing(5),
    border: "none",
    boxShadow: "none",
  },
  summary: {
    borderBottom: "1px solid #ddd",
    flexDirection: "row-reverse",
    padding: 0,
  },
  accordionDetails: {
    display: "block",
    padding: 24,
  },
  expandIcon: {
    margin: 0,
    padding: 0,
  },
}));

export const AccordionDivider: React.FunctionComponent<{
  forceExpanded?: boolean;
  title: string;
}> = (props) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion className={classes.accordion} expanded={props.forceExpanded ? true : expanded} onChange={handleChange}>
      <AccordionSummary
        classes={{ expandIcon: classes.expandIcon }}
        className={classes.summary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>{props.children}</AccordionDetails>
    </Accordion>
  );
};
