import classNames from "classnames";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { alpha, Button, Collapse, Grid, MenuItem, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { InspectionStatus } from "../../../../__generated__/globalTypes";
import { formatDateString } from "../../../../util/dateTime/formatDate";
import { blueprintInfoQuery_blueprint } from "../../../blueprints/blueprintsDetails/__generated__/blueprintInfoQuery";
import { BlueprintModal } from "../../../blueprints/blueprintsDetails/blueprintModal/BlueprintModal";
import { PopUpDialog } from "../../../pageFragments/util/PopUpDialog";
import {
  DueDateStatus,
  getDueDateStatus,
  inspectionStatusText,
  StatusChip,
} from "../../../pageFragments/util/StatusChip";
import { InspectionModal } from "./normalInspection/InspectionModal";
import {
  inspectionQuery_asset,
  inspectionQuery_asset_inspections,
  inspectionQuery_asset_subAssets,
} from "./api/__generated__/inspectionQuery";
import { ContextMenu } from "../../../../components/buttons/ContextMenu";
import { RevokeInspectionDialog } from "./RevokeInspectionDialog";
import { useMutation } from "@apollo/client";
import {
  updateInspectionMutation,
  updateInspectionMutationVariables,
} from "./api/__generated__/updateInspectionMutation";
import { UPDATE_INSPECTION_MUTATION } from "./api/inspectionQueriesAndMutations";
import { Alert } from "@material-ui/lab";
import { ExtendDueDateDialog } from "./ExtendDueDateDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inspectionPreview: {
      width: "100%",
      boxSizing: "border-box",
      padding: theme.spacing(2),
      background: theme.palette.background.default,
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      cursor: "pointer",
      alignItems: "center",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    image: {
      width: `calc( 33% - ${theme.spacing(1)}px )`,
      height: `calc( 33% - ${theme.spacing(1)}px )`,
      objectFit: "cover",
      marginRight: theme.spacing(1),
    },
    button: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    dropDownDisplay: {
      display: "flex",
      alignItems: "center",
    },
    inspectionName: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
    icon: {
      marginRight: "5px",
    },
    iconWarning: {
      color: theme.palette.warning.main,
    },
    iconError: {
      color: theme.palette.error.main,
    },
    rejectionReason: {
      background: alpha(theme.palette.error.light, 0.3),
      backgroundOpacity: 20,
      padding: theme.spacing(2),
      width: "100%",
      boxSizing: "border-box",
      whiteSpace: "pre-line",
      borderRadius: theme.spacing(1),
      color: "#a23419",
    },
  })
);

export const InspectionPreview: React.FunctionComponent<{
  inspection: blueprintInfoQuery_blueprint | inspectionQuery_asset_inspections;
  isInitial: boolean;
  startOpen: boolean;
  asset?: inspectionQuery_asset;
  subAssets?: inspectionQuery_asset_subAssets[];
  onInspectionSubmit: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const status = props.inspection.status;
  const canBeOpened = status !== InspectionStatus.PENDING;

  const thumbnails =
    props.inspection.__typename === "Inspection"
      ? props.inspection.featureInspections.map((f) => f.images[0])
      : props.inspection.featureBlueprints.map((f) => f.images[0]);

  const buttonText =
    status === InspectionStatus.WAITING_FOR_APPROVAL
      ? t("pages.asset-details.inspection-overview.preview.buttons.check")
      : t("pages.asset-details.inspection-overview.preview.buttons.view");

  const classes = useStyles();
  const [open, setOpen] = useState(props.startOpen);
  const [modalOpen, setModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [revokeDialogOpen, setRevokeDialogOpen] = useState(false);
  const [extendDueDateDialogOpen, setExtendDueDateDialogOpen] = useState(false);
  const [updateInspection] = useMutation<updateInspectionMutation, updateInspectionMutationVariables>(
    UPDATE_INSPECTION_MUTATION
  );

  let daysTillDue = 0;

  if (props.inspection.__typename === "Inspection") {
    daysTillDue = Math.floor((new Date(props.inspection.dueDate || 0).getTime() - Date.now()) / (1000 * 60 * 60 * 24));
  }

  const dueDateStatus = getDueDateStatus(props.inspection.status, daysTillDue);
  const iconClassNames = classNames(
    classes.icon,
    dueDateStatus === DueDateStatus.WARNING && classes.iconWarning,
    dueDateStatus === DueDateStatus.ERROR && classes.iconError
  );

  function handleClose(triggerRefetchInspections: boolean) {
    setModalOpen(false);
    if (triggerRefetchInspections) {
      props.onInspectionSubmit();
    }
  }

  function handleCancelModalClose() {
    setCancelModalOpen(false);
  }

  async function handleRevoke(internalNote?: string): Promise<void> {
    console.info("Revoke inspection " + props.inspection.id, internalNote);
    if (!props.inspection.id) {
      return;
    }
    await updateInspection({
      variables: {
        input: {
          inspectionId: props.inspection.id,
          status: InspectionStatus.REVOKED,
          messageToCustomer: internalNote,
          rejectedFeatureInspections: [],
        },
      },
    });
    setRevokeDialogOpen(false);
    props.onInspectionSubmit();
  }

  async function handleExtendDueDate(dueDate?: Date, messageToCustomer?: string): Promise<void> {
    console.info("Extend due date inspection " + props.inspection.id, dueDate, messageToCustomer);
    if (!dueDate) {
      handleExtendDueDateDialogClose();
    }
    if (!props.inspection.id) {
      return;
    }
    await updateInspection({
      variables: {
        input: {
          inspectionId: props.inspection.id,
          status: InspectionStatus.PENDING,
          messageToCustomer: messageToCustomer,
          dueDate: dueDate!.toISOString(),
          rejectedFeatureInspections: [],
        },
      },
    });
    setExtendDueDateDialogOpen(false);
    props.onInspectionSubmit();
  }

  function handleRevokeDialogClose() {
    setRevokeDialogOpen(false);
  }

  function handleRevokeDialogOpen() {
    setRevokeDialogOpen(true);
  }

  function handleExtendDueDateDialogClose() {
    setExtendDueDateDialogOpen(false);
  }

  function handleExtendDueDateDialogOpen() {
    setExtendDueDateDialogOpen(true);
  }

  return (
    <Paper elevation={3} className={classes.inspectionPreview}>
      <div className={classes.header} onClick={() => setOpen(!open)}>
        {props.inspection.__typename === "Inspection" ? (
          <div className={classes.inspectionName}>
            <Typography variant={"h3"}>
              {t(`pages.asset-details.inspection-overview.preview.${props.inspection.inspectionType.toLowerCase()}`)}{" "}
              {status === InspectionStatus.WAITING_FOR_APPROVAL ||
                status === InspectionStatus.APPROVED ||
                status === InspectionStatus.REJECTED
                ? t("pages.asset-details.inspection-overview.preview.from")
                : t("pages.asset-details.inspection-overview.preview.created-at")}{" "}
              {formatDateString(props.inspection.latestSubmissionDate || props.inspection.creationDate)}{" "}
            </Typography>
            {props.inspection.status !== InspectionStatus.APPROVED ? (
              <>
                <Typography
                  variant={"h6"}
                  style={{
                    width: "100%",
                    textAlign: "left",
                    fontWeight: 400,
                    verticalAlign: "middle",
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  {(dueDateStatus === DueDateStatus.WARNING || dueDateStatus === DueDateStatus.ERROR) && (
                    <ErrorIcon fontSize={"small"} className={iconClassNames} />
                  )}
                  {inspectionStatusText(props.inspection, t)}
                </Typography>
              </>
            ) : null}
          </div>
        ) : (
          <span>{t("pages.asset-details.inspection-overview.preview.blueprint-creation")}</span>
        )}

        <div className={classes.dropDownDisplay}>
          <StatusChip status={status} />
          {props.inspection.status == InspectionStatus.PENDING && (
            <ContextMenu>
              <MenuItem onClick={handleRevokeDialogOpen}>
                {t("pages.asset-details.inspection-overview.buttons.revoke-inspection")}
              </MenuItem>
              <MenuItem onClick={handleExtendDueDateDialogOpen}>
                {t("pages.asset-details.inspection-overview.buttons.extend-due-date")}
              </MenuItem>
            </ContextMenu>
          )}
          {canBeOpened && (open ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
        </div>
      </div>
      <Collapse in={canBeOpened ? open : false}>
        <Grid container alignItems={"flex-end"} alignContent={"flex-start"}>
          <Grid item xs={12}>
            {props.inspection.status === InspectionStatus.REJECTED && props.inspection.rejectionReason && (
              <p className={classes.rejectionReason}>{props.inspection.rejectionReason}</p>
            )}
            {props.inspection.status === InspectionStatus.REVOKED && props.inspection.messageToCustomer && (
              <Alert severity="info" color={"warning"}>
                {props.inspection.messageToCustomer}
              </Alert>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={8} style={{ textAlign: "left" }}>
            {thumbnails
              .slice(0, 3)
              .filter((image) => !!image)
              .map((image, i) => (
                <img key={i} className={classes.image} src={`data:${image?.type};base64,${image?.data}`} alt="" />
              ))}
          </Grid>
          {props.inspection.status !== InspectionStatus.REVOKED && (
            <Grid item xs={12} md={6} lg={4}>
              <Button color="primary" className={classes.button} onClick={() => setModalOpen(true)}>
                {buttonText}
              </Button>
            </Grid>
          )}
        </Grid>
      </Collapse>
      {props.isInitial && props.inspection.__typename === "Blueprint" ? (
        <BlueprintModal
          modalOpen={modalOpen}
          handleClose={(triggerRefetch) => handleClose(triggerRefetch)}
          blueprint={props.inspection}
        />
      ) : (
        props.inspection.__typename === "Inspection" &&
        modalOpen &&
        props.asset && (
          <InspectionModal
            modalOpen={modalOpen}
            handleClose={(triggerRefetch) => handleClose(triggerRefetch)}
            inspection={props.inspection} // hier
            asset={props.asset}
          />
        )
      )}

      <PopUpDialog
        open={cancelModalOpen}
        onClose={handleCancelModalClose}
        handleConfirm={() => {
          handleCancelModalClose();
          setModalOpen(false);
        }}
        handleDeny={handleCancelModalClose}
        header={t("pages.asset-details.inspection-overview.preview.dialog.header")}
        confirm={t("pages.asset-details.inspection-overview.preview.dialog.confirm")}
        deny={t("pages.asset-details.inspection-overview.preview.dialog.deny")}
      />
      <RevokeInspectionDialog open={revokeDialogOpen} onClose={handleRevokeDialogClose} onConfirm={handleRevoke} />
      <ExtendDueDateDialog
        open={extendDueDateDialogOpen}
        onClose={handleExtendDueDateDialogClose}
        onConfirm={handleExtendDueDate}
      />
    </Paper>
  );
};
