import { addMethod, string } from "yup";

export function extendYup(): void {
  addMethod(string, "emptyStringToNull", function () {
    return this.transform((value) => {
      // console.info("Transforming " + value);
      const result = value === "" || !value ? null : value;
      // console.info("returning " + result);
      return result;
    });
  });
}
