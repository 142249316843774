import React, { ReactNode, useEffect, useState } from "react";
import { useCurrentAccount } from "./util/auth/useCurrentAccount";
import { useAuth } from "oidc-react";
import { Button, LinearProgress, Modal, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { idfyTheme } from "./util/IdfyTheme";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalWrapper: {
      overflow: "hidden",
      width: "50%",
      height: "50%",
      display: "flex",
    },
    modalBody: {
      position: "absolute",
      top: "50%",
      left: "50%",
      height: "30%",
      width: "30%",
      transform: "translate(-50%,-50%)",
      boxSizing: "border-box",
      padding: theme.spacing(4),
      borderRadius: "10px",
      outline: "none",
      display: "flex",
      flexDirection: "column",
      background: idfyTheme.palette.background.default,
    },
    header: {
      display: "grid",
      width: "100%",
      flexWrap: "nowrap",
      alignItems: "center",
      gridTemplateColumns: "1fr 1fr 1fr",
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        gridTemplateRows: "1fr 1fr 1fr",
        gridTemplateColumns: "auto",
        justifyContent: "center",
      },
    },
    content: {
      display: "flex",
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingLeft: 1,
    },
  })
);
export const RolesCheck: React.FunctionComponent = (props: { children?: ReactNode }) => {
  const account = useCurrentAccount();
  const auth = useAuth();
  const classes = useStyles();
  const { t } = useTranslation();
  const [isCustomer, setIsCustomer] = useState(false);

  useEffect(() => {
    if (account?.roles[0] === "CUSTOMER") {
      setIsCustomer(true);
    }
  }, []);

  return (
    <React.Fragment>
      {props.children}
      <Modal open={isCustomer} onClose={() => null}>
        <Paper className={classes.modalBody} elevation={2}>
          <div className={classes.header}>
            <Typography variant={"h2"}>{t("pages.logout-modal.Logout")}</Typography>
          </div>
          <LinearProgress variant={"determinate"} value={0} />
          <div className={classes.content}>
            <Typography variant="h3">{t("pages.logout-modal.text-field")}</Typography>
          </div>
          <Button color="primary" variant="contained" onClick={() => auth?.userManager?.signoutRedirect()}>
            {t("pages.logout-modal.Logout")}
          </Button>
        </Paper>
      </Modal>
    </React.Fragment>
  );
};
