import { Contract } from "./api/__generated__/Contract";
import { ContractStatus } from "../../__generated__/globalTypes";

export function getStatusColorClassName(
  contract: Contract,
  classes: Record<"active" | "extended" | "not_yet_active" | "inactive" | "expired" | "unknown", string>
): string {
  switch (contract.status) {
    case ContractStatus.ACTIVE:
      return classes.active;
    case ContractStatus.EXTENDED:
      return classes.extended;
    case ContractStatus.NOT_YET_ACTIVE:
      return classes.not_yet_active;
    case ContractStatus.EXPIRED:
      return classes.expired;
    case ContractStatus.INACTIVE:
      return classes.inactive;
    case ContractStatus.UNKNOWN:
      return classes.unknown;
  }
  return classes.unknown;
}
