import React, { FC } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { MultilineText } from "../../../../../components/MultilineText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textContainer: {
      backgroundColor: theme.palette.grey["100"],
      padding: theme.spacing(2),
      borderRadius: 8,
    },
  })
);

export interface EventTextBoxProps {
  title?: string;
  text: string;
}

export const EventTextBox: FC<EventTextBoxProps> = (props) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.textContainer}>
        {props.title && (
          <Typography variant="h6" gutterBottom>
            {props.title}
          </Typography>
        )}
        <Typography variant="body2">
          <MultilineText text={props.text} />
        </Typography>
      </div>
    </div>
  );
};
