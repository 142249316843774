import { gql } from "@apollo/client";

export const BLUEPRINT_NAMES_QUERY = gql`
  query blueprintNamesQuery {
    blueprints {
      assetBlueprints {
        id
        name
        status
      }
    }
  }
`;

export const BLUEPRINT_STATUS_QUERY = gql`
  query blueprintStatusQuery($id: ID!) {
    blueprint(id: $id) {
      status
    }
  }
`;
