import React, { ReactNode } from "react";
import { useParams } from "react-router-dom";

import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { SubPageButton } from "../pages/pageFragments/util/SubPageButton";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overview: {
      width: "100%",
      minHeight: "100%",
      boxSizing: "border-box",
      gridColumn: "1 / span 1",
      borderRight: "1px solid #888",
      paddingRight: theme.spacing(1.5),
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        justifyContent: "left",
        flexWrap: "no-wrap",
        height: "fit-content",
        border: "none",
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
      },
    },
    details: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    accountCircle: {
      width: "80%",
      height: "auto",
      fill: "grey",
    },
    menuWrapper: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        margin: 0,
        overflowX: "auto",
      },
    },
    truncate: {
      maxWidth: 250 - theme.spacing(3), // Subtract the padding form the menu item width
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })
);

export interface DetailsMenuSubPage {
  label: string;
  icon: ReactNode;
  path: string;
  badge?: boolean;
  extra?: string;
}

export interface DetailsMenuProps {
  subPages: DetailsMenuSubPage[];
  icon: (className: string) => ReactNode;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  subPageActiveCheck: (subPage: DetailsMenuSubPage) => boolean;
  routeFunction: (id: string) => string;
}

export const DetailsMenu: React.FunctionComponent<DetailsMenuProps> = (props: DetailsMenuProps) => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();

  return (
    <div className={classes.overview}>
      <div className={classes.details}>
        {props.icon(classes.accountCircle)}
        <Typography className={classes.truncate} variant="h3">
          {props.title}
        </Typography>
        {props.subtitle && typeof props.subtitle === "string" && (
          <Typography className={classes.truncate} variant="body1">
            {props.subtitle}
          </Typography>
        )}
        {props.subtitle && typeof props.subtitle !== "string" && props.subtitle}
      </div>
      <div className={classes.menuWrapper}>
        {props.subPages.map((subPage) => (
          <SubPageButton
            key={subPage.path}
            label={subPage.label}
            icon={subPage.icon}
            id={id}
            routeFunction={props.routeFunction}
            subPath={subPage.path}
            badge={subPage.badge}
            active={props.subPageActiveCheck(subPage)}
            extra={subPage.extra}
          />
        ))}
      </div>
    </div>
  );
};
