import { useMutation, useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

import React, { useContext, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { createStyles, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SnackContext } from "../../../routing/idfyRouter";
import { routes } from "../../../routing/routes";
import { idfyTheme } from "../../../util/IdfyTheme";
import { InfoPageHeader } from "../../pageFragments/util/InfoPageHeader";
import { PopUpDialog } from "../../pageFragments/util/PopUpDialog";
import {
  ASSET_NUMBER_PER_CUSTOMER_QUERY,
  DELETE_CUSTOMER_MUTATION,
  UPDATE_CUSTOMER_MUTATION,
} from "../api/customerQueriesAndMutations";
import { Customer, customerSchema } from "../Customer";
import { updateCustomerMutation } from "../api/__generated__/updateCustomerMutation";
import { deleteCustomerMutation } from "../api/__generated__/deleteCustomerMutation";
import { assetNumberQuery } from "../api/__generated__/assetNumberQuery";
import { CustomerInput } from "../../../__generated__/globalTypes";
import { CustomerForm } from "../CustomerForm";

const useStyles = makeStyles(() =>
  createStyles({
    formGrid: {
      boxSizing: "border-box",
      paddingTop: ".5em",
    },
    gridItem: {
      marginTop: ".2em",
    },
    deleteButton: {
      background: idfyTheme.palette.error.main,
      color: idfyTheme.palette.background.default,
      "&:hover": {
        background: "#FF7B59",
      },
    },
  })
);

export const CustomerInfo: React.FunctionComponent<{
  initialValues: Customer;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [updateCustomer] = useMutation<updateCustomerMutation>(UPDATE_CUSTOMER_MUTATION);
  const [deleteCustomer] = useMutation<deleteCustomerMutation>(DELETE_CUSTOMER_MUTATION);

  const assetNumber = useQuery<assetNumberQuery>(ASSET_NUMBER_PER_CUSTOMER_QUERY, {
    variables: { id: props.initialValues.id },
  }).data;

  const [notDeletableModalOpen, setNotDeletableModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const initialValues = useMemo<CustomerInput>(
    () => ({ ...props.initialValues } as CustomerInput),
    [props.initialValues]
  );

  const methods = useForm<CustomerInput>({
    defaultValues: initialValues,
    resolver: yupResolver(customerSchema(t)),
  });

  const { handleSubmit, reset } = methods;

  function onSubmit(values: CustomerInput) {
    console.info(values);
    updateCustomer({
      variables: {
        id: props.initialValues.id,
        customer: values,
      },
    }).then((res) => {
      setDisabled(true);
      snackContext.setSnackOpen(!res.errors);
      snackContext.setSnackText(t("pages.customer-details.information.alerts.customer-updated"));
    });
  }

  function handleDelete() {
    if (assetNumber?.assets.length === 0) setDeleteModalOpen(true);
    else setNotDeletableModalOpen(true);
  }

  const snackContext = useContext(SnackContext);

  function onDelete() {
    void deleteCustomer({
      variables: { id: props.initialValues.id },
    }).then((res) => {
      snackContext.setSnackOpen(!res.errors);
      snackContext.setSnackText(t("pages.customer-details.information.alerts.customer-deleted"));
      history.push(routes.customers);
    });
  }

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <InfoPageHeader
            setDisabled={setDisabled}
            disabled={disabled}
            header={t("pages.customer-details.information.edit-buttons.header")}
            handleDelete={handleDelete}
            deleteText={t("pages.customer-details.information.edit-buttons.delete-customer")}
            onCancel={() => reset(initialValues)}
          />
          <CustomerForm disabled={disabled} emailChangeDisabled={true} />
          <PopUpDialog
            open={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            handleConfirm={onDelete}
            handleDeny={() => setDeleteModalOpen(false)}
            header={t("pages.customer-details.dialogs.delete.header")}
            confirm={t("pages.customer-details.dialogs.delete.confirm")}
            deny={t("pages.customer-details.dialogs.delete.deny")}
            classes={{ confirmButton: classes.deleteButton }}
          >
            <Typography variant={"body2"}>
              {t("pages.customer-details.dialogs.delete.message", {
                name: props.initialValues.lastName + ", " + props.initialValues.firstName,
                "serial-number": props.initialValues.company,
              })}
            </Typography>
          </PopUpDialog>
          <PopUpDialog
            open={notDeletableModalOpen}
            onClose={() => setNotDeletableModalOpen(false)}
            handleConfirm={() => setNotDeletableModalOpen(false)}
            header={t("pages.customer-details.dialogs.not-deletable.header")}
            confirm={t("pages.customer-details.dialogs.delete.ok")}
          >
            <Typography variant={"body2"}>
              <Trans
                t={t}
                i18nKey="pages.customer-details.dialogs.not-deletable.message"
                values={{
                  name: props.initialValues.lastName + ", " + props.initialValues.firstName,
                  "serial-number": props.initialValues.company,
                  "asset-count": assetNumber?.assets.length,
                }}
                components={{ b: <b /> }}
              />
            </Typography>
          </PopUpDialog>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
