import React, { ReactElement } from "react";
import { useQuery } from "@apollo/client";
import { ASSETS_BY_CONTRACT_ID_QUERY } from "../../assets/api/assetQueriesAndMutations";
import { Contract } from "../api/__generated__/Contract";
import { CustomerAssetTable } from "../../customers/customerDetails/CustomerAssetTable";
import { useTranslation } from "react-i18next";
import { assetsByContractIdQuery } from "../../assets/api/__generated__/assetsByContractIdQuery";

export function ContractAssets(props: { contract: Contract }): ReactElement {
  const { data, refetch: refetchAssets } = useQuery<assetsByContractIdQuery>(ASSETS_BY_CONTRACT_ID_QUERY, {
    variables: { id: props.contract.id },
  });
  const { t } = useTranslation();
  return (
    <CustomerAssetTable
      assets={data?.assets}
      breadcrumbTitle={t("pages.contracts.contractBreadcrumb") + props.contract.contractNumber}
      refetch={() => refetchAssets().then(() => true)}
      customerId={props.contract.customer.id}
      title={t("pages.contracts.contract-assets")}
    />
  );
}
