import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createStyles, TableCell, TableRow, Theme, useMediaQuery } from "@material-ui/core";
import { idfyTheme } from "../../../util/IdfyTheme";
import { routes } from "../../../routing/routes";
import { AssetStatusCell } from "../../pageFragments/util/StatusChip";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { isBlankOrEmpty } from "../../../util/stringUtil";
import { assetsPagedQuery_assetsPaged_edges_node } from "../api/__generated__/assetsPagedQuery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileTableRow: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(2),
      borderBottom: "1px solid" + idfyTheme.palette.secondary.main + "30",
      textAlign: "left",
      width: "100%",
      boxSizing: "border-box",
      "& td": {
        border: "none",
        flexGrow: 1,
        padding: 0,
      },
    },
    mobileRowHeader: {
      width: "100%",
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-between",
    },
    serialNumberUndefined: {
      color: "red",
    },
  })
);

export const MobileAssetTableRow: React.FunctionComponent<{ asset: assetsPagedQuery_assetsPaged_edges_node }> = (
  props
) => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();

  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));
  const serialNumberString = isBlankOrEmpty(props.asset.serialNumber)
    ? t("pages.assets.table.asset-row.serial-number-undefined")
    : props.asset.serialNumber;
  const serialNumberClass = isBlankOrEmpty(props.asset.serialNumber) ? `${classes.serialNumberUndefined}` : "";

  return (
    <TableRow
      onClick={() => history.push(routes.assets + routes.details(props.asset.id) + routes.infoSuffix)}
      className={classes.mobileTableRow}
      hover={!mobile}
    >
      <TableCell>
        <span className={classes.mobileRowHeader}>
          <b>{props.asset.name}</b>
          <AssetStatusCell asset={props.asset} mobile={true} />
        </span>
        {t("pages.assets.table.header-row.serial-number")}:{" "}
        <span className={serialNumberClass}>{serialNumberString}</span>
        <br />
        {t("pages.assets.table.header-row.contract-number")}: {props.asset.contractNumber}
        <br />
        {t("pages.assets.table.header-row.customer")}:{" "}
        {props.asset.currentContract && (
          <>{props.asset.currentContract.customer.lastName + ", " + props.asset.currentContract.customer.firstName}</>
        )}
        <br />
      </TableCell>
    </TableRow>
  );
};

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-types
function MobileAssetTableRowLoading(props: {}, ref: React.ForwardedRef<never>) {
  const classes = useStyles();

  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));

  return (
    <TableRow className={classes.mobileTableRow} hover={!mobile} key={"sentry"} ref={ref}>
      <TableCell>
        <span className={classes.mobileRowHeader}>Loading</span>
      </TableCell>
    </TableRow>
  );
}

export const ReferencedMobileAssetTableRowLoading = React.forwardRef(MobileAssetTableRowLoading);
