import React, { FC } from "react";

import { createStyles, Paper, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      maxHeight: "90%",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        overflow: "scroll",
      },
      display: "flex",
      alignItems: "stretch",
      flexDirection: "column",
    },
  })
);

export interface TableWrapperPaperProps {
  fullHeight?: boolean;
}

export const TableWrapperPaper: FC<TableWrapperPaperProps> = (props) => {
  const classes = useStyles();

  return (
    <Paper style={props.fullHeight ? { height: "100%" } : {}} className={classes.paper}>
      {props.children}
    </Paper>
  );
};
