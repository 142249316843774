import { gql, useQuery } from "@apollo/client";

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Route } from "react-router-dom";

import { BreadcrumbContext } from "../../../routing/idfyRouter";
import { routes } from "../../../routing/routes";
import { DetailsPage } from "../../pageFragments/DetailsPage";
import { userInfoQuery } from "./__generated__/userInfoQuery";
import { UserInfo } from "./UserInfo";
import { UserMenu } from "./UserMenu";

export const USER_INFO_QUERY = gql`
  query userInfoQuery($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      telephoneNumber
      roles
      team
    }
  }
`;

export const UserDetails: React.FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const utilContext = useContext(BreadcrumbContext);

  if (utilContext.breadcrumbs.length === 0) {
    utilContext.breadcrumbs.push({
      url: routes.administration,
      title: t("pages.administration.header"),
    });
  }

  const { data } = useQuery<userInfoQuery>(USER_INFO_QUERY, {
    variables: { id: id },
    fetchPolicy: "network-only",
  });

  return (
    <DetailsPage headerText={t("pages.user-details.header")} menu={<UserMenu id={id} user={data?.user} />}>
      <Route path={routes.administration + routes.details(":id") + routes.infoSuffix}>
        {data?.user && <UserInfo user={data.user} />}
      </Route>
    </DetailsPage>
  );
};
