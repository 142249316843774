import React from "react";
import { useTranslation } from "react-i18next";

import { Chip, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { FeatureType } from "../../../../../../__generated__/globalTypes";
import { IdfyDataChip, IdfyTagChip } from "../../../../../../components/IdfyTagChip";
import { MultilineText } from "../../../../../../components/MultilineText";
import { getFeatureTypeIcon } from "../../../../../../util/iconUtil";
import { formatThousands, getTranslatedFeatureType } from "../../../../../../util/stringUtil";
import { FeatureImageSlider } from "../FeatureImageSlider";
import { InspectionOverviewElement } from "./InspectionOverviewElement";
import { inspectionQuery_asset_inspections_featureInspections } from "../../api/__generated__/inspectionQuery";

const useStyles = makeStyles((theme) =>
  createStyles({
    chipContainer: {
      display: "flex",
      justifyContent: "center",
    },
    inspectionValueChip: {
      marginLeft: theme.spacing(1),
    },
    featureTypeChip: {
      marginBottom: theme.spacing(2),
    },
  })
);

export interface InspectionOverviewElementProps {
  featureInspection: inspectionQuery_asset_inspections_featureInspections;
  tag: string | null;
  tagsMatch: boolean;
  eval?: { approved: boolean; reason: string };
}

const I18N_PREFIX = "pages.asset-details.inspection-overview.modals.normal-inspection.overview.";

export const InspectionOverviewFeatureElement: React.FunctionComponent<InspectionOverviewElementProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <InspectionOverviewElement
      imageColumn={
        <>
          <div>
            <FeatureImageSlider
              images={props.featureInspection.images}
              modalTitle={props.featureInspection.featureBlueprint.title}
            />
          </div>
          {props.tag && (
            <div className={classes.chipContainer}>
              <IdfyTagChip tag={props.tag} showWarning={!props.tagsMatch} />
            </div>
          )}
          {props.featureInspection.featureBlueprint.type === FeatureType.METER && (
            <div className={classes.chipContainer}>
              <IdfyDataChip
                tag={formatThousands(props.featureInspection.meter?.hours)}
                className={classes.inspectionValueChip}
              />
              <IdfyDataChip
                tag={formatThousands(props.featureInspection.meter?.kilometers)}
                className={classes.inspectionValueChip}
              />
            </div>
          )}
        </>
      }
      informationColumn={
        <>
          <Typography variant="h1" gutterBottom>
            {props.featureInspection.featureBlueprint.title}
          </Typography>
          <Chip
            size="small"
            label={getTranslatedFeatureType(props.featureInspection.featureBlueprint.type, t)}
            icon={getFeatureTypeIcon(props.featureInspection.featureBlueprint.type)}
            className={classes.featureTypeChip}
          />
          <Typography variant="body2">
            <MultilineText text={props.featureInspection.featureBlueprint.description} />
          </Typography>
        </>
      }
      icon={props.eval ? (props.eval.approved ? "check" : "clear") : undefined}
      additionalInformationColumn={
        props.eval?.reason && (
          <div>
            <Typography variant="h2" gutterBottom>
              {t(`${I18N_PREFIX}problem`)}
            </Typography>
            <Typography variant="body2">{props.eval?.reason}</Typography>
          </div>
        )
      }
    />
  );
};
