import { gql } from "@apollo/client";
import { contractFragment } from "../../contracts/api/contractFragments";
import { customerFragment } from "../../customers/api/customerFragment";
import { supplierFragment } from "../../suppliers/api/supplierFragment";
import { manufacturerFragment } from "../../manufacturers/api/manufacturerFragment";
import { inspectionFragment } from "../assetDetails/inspection/api/inspectionFragments";
import { bluePrintFragment } from "../../blueprints/api/blueprintFragments";

export const assetWebImage = gql`
  fragment AssetWebImage on Asset {
    image(scale: { width: 250, height: 250, mode: FILL }) {
      type
      data
    }
  }
`;

export const offsiteLocationFragment = gql`
  fragment OffsiteLocation on OffsiteLocation {
    name
    streetAddress
    zip
    city
  }
`;

export const assetFragment = gql`
  ${contractFragment}
  ${customerFragment}
  ${supplierFragment}
  ${bluePrintFragment}
  ${inspectionFragment}
  ${assetWebImage}
  fragment AssetData on Asset {
    id
    name
    serialNumber
    contractNumber
    isNew
    isAlreadyInStock
    contractDate
    contractEndOfTerm
    assetStatus
    supplier {
      ...SupplierData
    }
    inspections {
      ...Inspection
    }
    subAssets {
      id
    }
    ...AssetWebImage
    blueprint {
      ...Blueprint
    }
    currentContract {
      ...Contract
    }
  }
`;

export const smallAssetFragment = gql`
  ${contractFragment}
  ${manufacturerFragment}
  ${inspectionFragment}
  ${assetWebImage}
  fragment SmallAsset on Asset {
    id
    name
    serialNumber
    assetStatus
    manufacturer {
      ...Manufacturer
    }
    blueprint {
      status
    }
    ...AssetWebImage
    inspections {
      ...Inspection
    }
    currentContract {
      ...Contract
    }
  }
`;

export const subAssetFragment = gql`
  ${manufacturerFragment}
  fragment SubAsset on SubAsset {
    id
    name
    serialNumber
    manufacturer {
      ...Manufacturer
    }
    blueprintFeatures {
      ...FeatureBlueprintWithImages
    }
  }
`;

export const smallSubAssetFragment = gql`
  ${manufacturerFragment}
  fragment SmallSubAsset on SubAsset {
    id
    name
    serialNumber
    manufacturer {
      ...Manufacturer
    }
  }
`;
