import { gql } from "@apollo/client";
import { supplierFragment } from "./supplierFragment";

export const SUPPLIERS_QUERY = gql`
  ${supplierFragment}
  query suppliersQuery {
    suppliers {
      ...SupplierData
    }
  }
`;

export const CREATE_SUPPLIER_MUTATION = gql`
  ${supplierFragment}
  mutation createSupplierMutation($input: SupplierInput!) {
    addSupplier(input: $input) {
      supplier {
        ...SupplierData
      }
    }
  }
`;

export const DELETE_SUPPLIER_MUTATION = gql`
  mutation deleteSupplierMutation($id: ID!) {
    deleteSupplier(id: $id) {
      id
      deletionSuccessful
    }
  }
`;
