import clsx from "clsx";

import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { AppBar, createStyles, IconButton, Theme, Toolbar, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";

import { BreadcrumbContext } from "../../../routing/idfyRouter";
import { APPBAR_HEIGHT, COLLAPSED_DRAWER_WIDTH, DRAWER_WIDTH } from "../../../util/constants";
import { idfyTheme } from "../../../util/IdfyTheme";
import { styleLeftRight } from "../../../util/styleFunctions";
import { MAX_CONTENT_WIDTH } from "../BasePage";
import { UserButton } from "./UserButton";
import { User } from "../../../util/auth/useCurrentAccount";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      boxShadow: "none",
      height: APPBAR_HEIGHT,
      background: idfyTheme.palette.background.default,
      zIndex: 10,
      maxWidth: MAX_CONTENT_WIDTH + COLLAPSED_DRAWER_WIDTH + theme.spacing(8) * 4,
      left: "50%",
      transform: "translateX(-50%)",
      top: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        top: 0,
      },
    },
    toolbar: {
      background: idfyTheme.palette.background.default,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      height: APPBAR_HEIGHT,
      marginLeft: COLLAPSED_DRAWER_WIDTH + theme.spacing(8),
      [theme.breakpoints.up("xl")]: {
        marginLeft: `calc(${DRAWER_WIDTH} + ${theme.spacing(8)}px)`,
      },
      marginRight: theme.spacing(8),
      padding: 0,
      [theme.breakpoints.down("sm")]: {
        background: idfyTheme.palette.primary.main,
        margin: 0,
        padding: styleLeftRight(idfyTheme.spacing(3)),
        color: idfyTheme.palette.background.paper,
        justifyContent: "space-between",
      },
    },
    header: {
      flexGrow: 1,
      textAlign: "center",
    },
    notificationButton: {
      border: "1px #333 solid",
    },
    hoverPointer: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    arrowBack: {
      color: idfyTheme.typography.h6.color,
    },
    linkWrapper: {
      userSelect: "none",
      display: "flex",
      alignItems: "center",
    },
  })
);

export const IdfyAppBar: React.FunctionComponent<{
  header: string;
  user?: User;
  link?: boolean;
  toggleDrawer: () => void;
  drawerOpen: boolean;
}> = (props) => {
  const classes = useStyles();

  const history = useHistory();

  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));

  const utilContext = useContext(BreadcrumbContext);

  const backObject = utilContext.breadcrumbs[utilContext.breadcrumbs.length - 1];

  return (
    <AppBar className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        {mobile ? (
          <IconButton onClick={props.toggleDrawer}>
            <MenuIcon />
          </IconButton>
        ) : (
          <React.Fragment>
            {props.link ? (
              <div
                onClick={() => {
                  history.push(backObject?.url || "errorPage");
                  utilContext.breadcrumbs.pop();
                }}
                className={clsx(classes.linkWrapper, classes.hoverPointer)}
              >
                <ArrowBackIcon className={classes.arrowBack} />
                <Typography style={idfyTheme.typography.h6}>{backObject?.title || "error"}</Typography>
              </div>
            ) : null}
            <Typography variant={"h1"} className={classes.header}>
              {props.header}
            </Typography>
          </React.Fragment>
        )}
        {(!mobile || props.drawerOpen) && <UserButton />}
      </Toolbar>
    </AppBar>
  );
};
