import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache, ServerError } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useAuth } from "oidc-react";

import React, { ReactNode } from "react";
import { relayStylePagination } from "@apollo/client/utilities";

export interface AuthenticatedApolloProviderProps {
  children?: ReactNode;
}

/*
 * We have the access token defined outside and then update it
 * whenever the component is re-rendered. This is a bit of a hack, but
 * we cannot call the useAuth() hook inside the authLink.
 */
let accessToken: string | undefined = undefined;

const httpLink = new HttpLink({
  uri: window.location.origin + "/api/graphql",
});

const authLink = setContext(() => {
  if (accessToken) {
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  } else {
    return {};
  }
});

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    if ((networkError as ServerError).statusCode !== undefined) {
      if ((networkError as ServerError).statusCode === 401) {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    }
  }
});

const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          contracts: relayStylePagination(),
          assetsPaged: relayStylePagination(),
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    mutate: {
      fetchPolicy: "no-cache",
    },
  },
});

export const UnAuthenticatedApolloProvider: React.FunctionComponent<AuthenticatedApolloProviderProps> = (props) => {
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

export const AuthenticatedApolloProvider: React.FunctionComponent<AuthenticatedApolloProviderProps> = (props) => {
  const auth = useAuth();
  accessToken = auth?.userData?.expired ? undefined : auth?.userData?.access_token;

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
