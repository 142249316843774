import React, { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  debounce,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { CreateAssetInfoSupplierFields } from "./CreateAssetInfoSupplierFields";
import { Contract } from "../../../../contracts/api/__generated__/Contract";
import { format } from "date-fns";
import { Delete } from "@material-ui/icons";
import { useLazyQuery } from "@apollo/client";
import { contractQueryByContractNumber } from "../../../../contracts/api/__generated__/contractQueryByContractNumber";
import { CONTRACT_QUERY_BY_CONTRACT_NUMBER } from "../../../../contracts/api/contractQueriesAndMutations";
import { isBlankOrEmpty } from "../../../../../util/stringUtil";
import deLocale from "date-fns/locale/de";

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    formGrid: {
      boxSizing: "border-box",
    },
    contractDate: {
      height: "65px",
      marginTop: "2px",
    },
    contractEndOfTerm: {
      marginTop: "-2px",
      marginBottom: "4px",
    },
    icon: {
      alignSelf: "center",
    },
    contractMatchIcon: {
      alignSelf: "center",
    },
    contractMatchButtonContainer: {
      display: "flex",
      flexDirection: "row-reverse",
    },
  })
);

export const CreateAssetInfoContractFields: React.FunctionComponent<{
  isNewDisabled: boolean;
  displayUseExistingContractPromptCallback: (display: boolean) => void;
  customerId?: string;
}> = (props) => {
  const methods = useFormContext();
  const classes = useStyles();

  const { t } = useTranslation();

  const [existingContract, setExistingContract] = useState<Contract | null>(null);
  const [displayUseExistingContractPrompt, setDisplayUseExistingContractPrompt] = useState<boolean>(false);

  const contractNumber = methods.watch("contractNumber");
  const isContractEndOfTermUnknownWatch = methods.watch("isContractEndOfTermUnknown");
  const useExistingContractWatch = methods.watch("useExistingContract");

  useEffect(() => {
    // We have no customerId or useExistingContract field => we must register it manually
    methods.register("customerId");
    methods.register("useExistingContract");
  }, []);

  useEffect(() => {
    if (isContractEndOfTermUnknownWatch) {
      methods.setValue("contractEndOfTerm", null);
    }
  }, [isContractEndOfTermUnknownWatch]);

  const [getMatchingContract, { data: matchingContracts }] = useLazyQuery<contractQueryByContractNumber>(
    CONTRACT_QUERY_BY_CONTRACT_NUMBER
  );

  const handleContractNumberChange = useMemo(
    () =>
      debounce((contractNumber: string) => {
        getMatchingContract({
          variables: {
            contractNumber: contractNumber,
            customerId: isBlankOrEmpty(props.customerId) ? null : props.customerId,
          },
        });
      }, 300),
    []
  );

  useEffect(() => {
    handleContractNumberChange(contractNumber);
  }, [contractNumber]);

  useEffect(() => {
    const matchingContract = matchingContracts === undefined ? null : matchingContracts.contractByContractNumber;
    console.log("Found matching contract:", matchingContract && matchingContract.contractNumber == contractNumber);
    setExistingContract(matchingContract);
  }, [matchingContracts]);

  useEffect(() => {
    const isPromptDisplay = existingContract != null && !useExistingContractWatch;
    setDisplayUseExistingContractPrompt(isPromptDisplay);
    props.displayUseExistingContractPromptCallback(isPromptDisplay);
  }, [existingContract, useExistingContractWatch]);

  const handleUseExistingContract = () => {
    methods.setValue("contractDate", existingContract?.startDate, { shouldValidate: true });
    methods.setValue("isContractEndOfTermUnknown", existingContract?.endDate === null, { shouldValidate: false });
    methods.setValue("contractEndOfTerm", existingContract?.endDate, { shouldValidate: true });
    methods.setValue("useExistingContract", true, { shouldValidate: false });
    methods.setValue("customerId", existingContract?.customer.id, { shouldValidate: false });
  };

  const handleEditContract = () => {
    setExistingContract(null);
    methods.setValue("useExistingContract", false, { shouldValidate: false });
    methods.setValue("contractNumber", "", { shouldValidate: true });
    methods.setValue("contractDate", null, { shouldValidate: true });
    methods.setValue("isContractEndOfTermUnknown", false, { shouldValidate: false });
    methods.setValue("contractEndOfTerm", null, { shouldValidate: true });
    if (isBlankOrEmpty(props.customerId)) methods.setValue("customerId", "", { shouldValidate: false });
    else methods.setValue("customerId", props.customerId, { shouldValidate: false });
  };

  return (
    <Grid container spacing={3} justifyContent={"space-around"} className={classes.formGrid}>
      <Grid item xs={12}>
        <Typography variant={"h3"}>{t("pages.assets.modals.create-asset.contract-information")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={methods.control}
          as={TextField}
          fullWidth
          variant="outlined"
          size="small"
          name="contractNumber"
          label={t("pages.assets.modals.create-asset.fields.contract-number")}
          required
          color="secondary"
          helperText={methods.errors.contractNumber?.message}
          error={!!methods.errors.contractNumber}
          disabled={useExistingContractWatch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton disabled={!useExistingContractWatch} onClick={handleEditContract}>
                  <Delete />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {displayUseExistingContractPrompt && (
          <Alert severity="error" classes={{ icon: classes.contractMatchIcon }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {t("pages.assets.modals.create-asset.fields.useExistingContract.prompt")}:
              </Grid>
              <Grid item xs={12} xl={4}>
                {t("pages.assets.modals.create-asset.fields.contract-number")}:
              </Grid>
              <Grid item xs={12} xl={8}>
                {existingContract?.contractNumber}
              </Grid>
              <Grid item xs={12} xl={4}>
                {t("pages.assets.modals.create-asset.fields.contractDate")}:
              </Grid>
              <Grid item xs={12} xl={8}>
                {existingContract?.startDate && format(new Date(existingContract.startDate!), "dd.MM.yyyy")}
              </Grid>
              <Grid item xs={12} xl={4}>
                {t("pages.assets.modals.create-asset.fields.contractEndOfTerm")}:
              </Grid>
              <Grid item xs={12} xl={8}>
                {existingContract?.endDate && format(new Date(existingContract.endDate!), "dd.MM.yyyy")}
              </Grid>
              <Grid item xs={12} xl={4}>
                {t("pages.assets.modals.create-asset.fields.customer")}:
              </Grid>
              <Grid item xs={12} xl={8}>
                {existingContract?.customer.company}
              </Grid>
              <Grid item xs={12}>
                <div className={classes.contractMatchButtonContainer}>
                  <Button variant={"outlined"} color={"inherit"} onClick={handleUseExistingContract}>
                    {t("pages.assets.modals.create-asset.fields.useExistingContract.button")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Alert>
        )}
      </Grid>
      <Grid
        container
        item
        xs={12}
        className={classes.contractDate}
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
          <Controller
            name="contractDate"
            control={methods.control}
            defaultValue={null}
            render={({ onChange, value }) => {
              return (
                <KeyboardDatePicker
                  label={t("pages.assets.modals.create-asset.fields.contractDate")}
                  variant="inline"
                  fullWidth
                  autoOk
                  format="dd.MM.yyyy"
                  value={value}
                  onChange={onChange}
                  inputVariant="outlined"
                  size={"small"}
                  color="secondary"
                  required
                  error={!!methods.errors.contractDate}
                  helperText={methods.errors.contractDate?.message}
                  disabled={useExistingContractWatch}
                />
              );
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} className={classes.contractEndOfTerm}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
          <Controller
            required
            name="contractEndOfTerm"
            control={methods.control}
            defaultValue={null}
            render={({ onChange, value }) => {
              return (
                <KeyboardDatePicker
                  label={t("pages.assets.modals.create-asset.fields.contractEndOfTerm")}
                  variant="inline"
                  fullWidth
                  autoOk
                  format="dd.MM.yyyy"
                  value={value}
                  onChange={onChange}
                  inputVariant="outlined"
                  size={"small"}
                  color="secondary"
                  required
                  disabled={isContractEndOfTermUnknownWatch || useExistingContractWatch}
                  error={!!methods.errors.contractEndOfTerm}
                  helperText={methods.errors.contractEndOfTerm?.message}
                />
              );
            }}
          />
        </MuiPickersUtilsProvider>
        <Box display="flex" justifyContent="flex-end">
          <Controller
            control={methods.control}
            defaultValue={false}
            variant={"outlined"}
            size={"small"}
            color={"secondary"}
            name={"isContractEndOfTermUnknown"}
            required
            render={({ onChange, value, ref }) => (
              <FormControlLabel
                control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
                label={t("pages.assets.modals.create-asset.fields.isContractEndOfTermUnknown.label")}
                inputRef={ref}
                disabled={useExistingContractWatch}
              />
            )}
          />
        </Box>
        {isContractEndOfTermUnknownWatch && (
          <Alert severity="warning" classes={{ icon: classes.icon }}>
            <Trans i18nKey="pages.assets.modals.create-asset.fields.isContractEndOfTermUnknown.warning" />
          </Alert>
        )}
      </Grid>
      <CreateAssetInfoSupplierFields isNewDisabled={props.isNewDisabled} />
      <Grid item xs={12}>
        <Controller
          control={methods.control}
          as={TextField}
          fullWidth
          variant="outlined"
          size="small"
          name="supplierInvoiceNumber"
          label={t("pages.assets.modals.create-asset.fields.supplier-invoice-number")}
          required={!props.isNewDisabled}
          color="secondary"
          helperText={methods.errors.supplierInvoiceNumber?.message}
          error={!!methods.errors.supplierInvoiceNumber}
        />
      </Grid>
      <Grid item xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Controller
            name="invoiceDate"
            control={methods.control}
            defaultValue={null}
            render={({ onChange, value }) => {
              return (
                <KeyboardDatePicker
                  label={t("pages.assets.modals.create-asset.fields.invoice-date")}
                  variant="inline"
                  fullWidth
                  autoOk
                  format="dd.MM.yyyy"
                  value={value}
                  onChange={onChange}
                  inputVariant="outlined"
                  size={"small"}
                  color="secondary"
                  required={!props.isNewDisabled}
                  error={!!methods.errors.invoiceDate}
                  helperText={methods.errors.invoiceDate?.message}
                />
              );
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};
