import { useQuery } from "@apollo/client";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { NoDataPlaceholder } from "../../../../components/NoDataPlaceholder";
import { idfyTheme } from "../../../../util/IdfyTheme";
import { LibraryAdd } from "@material-ui/icons";
import { assetDetailQuery_asset } from "../../api/__generated__/assetDetailQuery";
import { UpdateSubAssetDialog } from "./UpdateSubAssetDialog";
import { SUB_ASSET_IMAGE_QUERY } from "../../api/assetQueriesAndMutations";
import { subAssetImageQuery } from "../../api/__generated__/subAssetImageQuery";
import { useCurrentAccount } from "../../../../util/auth/useCurrentAccount";
import { isAllowed } from "../../../../components/access/AccessManager";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableHeader: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      align: "center",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    subAssetPicture: {
      height: 80,
      width: 80,
      borderRadius: "50%",
      objectFit: "cover",
    },
    subAssetIcon: {
      height: 20,
      width: 20,
      padding: theme.spacing(1),
      fill: "grey",
    },
    tableRow: {
      verticalAlign: "middle",
    },
  })
);

export interface SubAsset {
  id: string;
  name: string;
  serialNumber: string | null;
  manufacturerId: string | null;
}

export const SubAssetTable: React.FunctionComponent<{ id: string; asset: assetDetailQuery_asset }> = (props) => {
  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentSelectedSubAsset, setCurrentSelectedSubAsset] = useState<SubAsset | undefined>(undefined);

  const classes = useStyles();
  const currentAccount = useCurrentAccount();

  const { data, error, refetch } = useQuery<subAssetImageQuery>(SUB_ASSET_IMAGE_QUERY, {
    variables: { id: props.id },
    fetchPolicy: "no-cache",
  });

  if (data && data.asset.subAssets.length <= 0) {
    return (
      <NoDataPlaceholder
        title={t("pages.asset-details.sub-assets.no-data-placeholder.title")}
        subTitle={t("pages.asset-details.sub-assets.no-data-placeholder.subtitle")}
      />
    );
  }

  return (
    <React.Fragment>
      <div className={classes.tableHeader}>
        <Typography variant={"h3"} align={"left"} style={{ marginRight: idfyTheme.spacing(2) }}>
          {t("pages.asset-details.sub-assets.header")}
        </Typography>
      </div>
      <TableContainer>
        <Table>
          {!mobile && (
            <TableHead>
              <TableRow>
                {!mobile && <TableCell />}
                <TableCell>{t("pages.asset-details.sub-assets.table.header-row.name")}</TableCell>
                <TableCell>{t("pages.asset-details.sub-assets.table.header-row.serial-number")}</TableCell>
                <TableCell>{t("pages.asset-details.sub-assets.table.header-row.manufacturer")}</TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {data && data.asset ? (
              data.asset.subAssets.map((subAsset) => (
                <TableRow
                  key={subAsset.id}
                  hover
                  className={classes.tableRow}
                  onClick={() => {
                    setCurrentSelectedSubAsset({
                      id: subAsset.id,
                      name: subAsset.name,
                      serialNumber: subAsset.serialNumber,
                      manufacturerId: subAsset.manufacturer?.id || null,
                    });
                    setModalOpen(true);
                  }}
                >
                  {!mobile && (
                    <TableCell>
                      {subAsset.image ? (
                        <img
                          className={classes.subAssetPicture}
                          alt="Zubehörteil-Foto"
                          src={`data:${subAsset.image.type};base64,${subAsset.image.data}`}
                        />
                      ) : (
                        <LibraryAdd className={classes.subAssetIcon} />
                      )}
                    </TableCell>
                  )}
                  <TableCell>{subAsset.name}</TableCell>
                  <TableCell>{subAsset.serialNumber}</TableCell>
                  <TableCell>{subAsset.manufacturer?.name}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3}>{error?.message}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <UpdateSubAssetDialog
        open={modalOpen && isAllowed("pages.asset-details.sub-assets.update-dialog", currentAccount)}
        onClose={(refetchSubAssets) => {
          setCurrentSelectedSubAsset(undefined);
          setModalOpen(false);
          if (refetchSubAssets) {
            refetch();
          }
        }}
        subAsset={currentSelectedSubAsset}
      />
    </React.Fragment>
  );
};
