import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formGrid: {
      boxSizing: "border-box",
      paddingTop: theme.spacing(3),
    },
  })
);

export const CreateAssetInfoCreationFields: React.FunctionComponent<{
  isNewDisabled: boolean;
}> = (props) => {
  const methods = useFormContext();
  const classes = useStyles();

  const { t } = useTranslation();
  return (
    <Grid container spacing={3} justifyContent={"space-around"} className={classes.formGrid}>
      <Grid item xs={12}>
        <Typography variant={"h3"}>{t("pages.assets.modals.create-asset.object-creation")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={methods.control}
          name={"isAlreadyInStock"}
          required
          render={({ onChange, value, ref }) => (
            <FormControl
              component="fieldset"
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              error={!!methods.errors.isAlreadyInStock}
            >
              <FormLabel required={true} style={{ color: "#999999" }}>
                {t("pages.assets.modals.create-asset.fields.isAlreadyInStock.title")}
              </FormLabel>
              <RadioGroup row>
                <FormControlLabel
                  value="false"
                  control={<Radio checked={value === false} onChange={() => onChange(false)} />}
                  label={t("pages.assets.modals.create-asset.fields.isAlreadyInStock.false")}
                  inputRef={ref}
                />
                <FormControlLabel
                  value="true"
                  control={<Radio checked={value} onChange={() => onChange(true)} />}
                  label={t("pages.assets.modals.create-asset.fields.isAlreadyInStock.true")}
                  inputRef={ref}
                />
              </RadioGroup>
              <FormHelperText>{methods.errors.isAlreadyInStock?.message}</FormHelperText>
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={methods.control}
          name={"isNew"}
          defaultValue={true}
          required
          render={({ onChange, value, ref }) => (
            <FormControl component="fieldset">
              <FormLabel required={true} style={{ color: "#999999" }}>
                {t("pages.assets.modals.create-asset.fields.objectCondition")}
              </FormLabel>
              <RadioGroup row>
                <FormControlLabel
                  value="new"
                  control={<Radio checked={value} onChange={() => onChange(true)} disabled={props.isNewDisabled} />}
                  label={t("pages.assets.modals.create-asset.fields.new")}
                  inputRef={ref}
                />
                <FormControlLabel
                  value="old"
                  control={<Radio checked={!value} onChange={() => onChange(false)} disabled={props.isNewDisabled} />}
                  label={t("pages.assets.modals.create-asset.fields.used")}
                  inputRef={ref}
                />
              </RadioGroup>
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  );
};
