import React, { ReactNode } from "react";

import { createStyles, Paper, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MENU_WIDTH } from "../../util/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      height: "100%",
      padding: theme.spacing(3),
      boxSizing: "border-box",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1),
        overflowY: "auto",
        flexDirection: "column",
      },
    },
    informationWrapper: {
      height: "100%",
      width: "100%",
      overflowY: "auto",
      overflowX: "auto",
      boxSizing: "border-box",
      padding: theme.spacing(5),
      paddingTop: 0,
      [theme.breakpoints.down("sm")]: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        overflowX: "hidden",
      },
    },
    contentItem: {
      height: "100%",
      width: `calc(100% - ${MENU_WIDTH}px)`,
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        width: "100%",
      },
    },
    menuItem: {
      overflowY: "auto",
      height: "100%",
      minWidth: MENU_WIDTH,
      maxWidth: MENU_WIDTH,
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        minWidth: "100%",
        maxWidth: "100%",
      },
    },
  })
);

export interface DetailsCardProps {
  menu: ReactNode;
  children: ReactNode;
}

export const DetailsCard: React.FunctionComponent<DetailsCardProps> = (props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper + " no-disabled-border"}>
      <div className={classes.menuItem}>{props.menu}</div>
      <div className={classes.contentItem}>
        <div className={classes.informationWrapper}>{props.children}</div>
      </div>
    </Paper>
  );
};
