import React, { createContext, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import { AdministrationPage } from "../pages/administration/AdministrationPage";
import { UserDetails } from "../pages/administration/userDetails/UserDetails";
import { AssetDetails } from "../pages/assets/assetDetails/AssetDetails";
import { AssetsPage } from "../pages/assets/AssetsPage";
import { BlueprintDetails } from "../pages/blueprints/blueprintsDetails/BlueprintDetails";
import { BlueprintsPage } from "../pages/blueprints/BlueprintsPage";
import { CustomerDetails } from "../pages/customers/customerDetails/CustomerDetails";
import { CustomersPage } from "../pages/customers/CustomersPage";
import { IdfyDrawer } from "../pages/pageFragments/IdfyDrawer";
import { IdfySnackbar } from "../pages/pageFragments/util/Snackbar";
import { GraphiQLPage } from "./GraphiQLPage";
import { routes } from "./routes";
import { ManufacturersPage } from "../pages/manufacturers/ManufacturersPage";
import { SuppliersPage } from "../pages/suppliers/SuppliersPage";
import { ContractsPage } from "../pages/contracts/ContractsPage";
import { ContractDetails } from "../pages/contracts/details/ContractDetails";

export const SnackContext = createContext<{
  snackOpen: boolean;
  setSnackOpen: (b: boolean) => void;
  snackText: string;
  setSnackText: (s: string) => void;
}>({
  snackOpen: false,
  setSnackOpen: () => null,
  snackText: "",
  setSnackText: () => null,
});

export const DrawerContext = createContext<{
  drawerOpen: boolean;
  setDrawerOpen: (b: boolean) => void;
}>({ drawerOpen: false, setDrawerOpen: () => null });

export const BreadcrumbContext = createContext<{
  breadcrumbs: Array<{ url: string; title: string }>;
}>({
  breadcrumbs: [],
});

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "100%",
    },
  })
);

const IdfyRouter: React.FunctionComponent = () => {
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackText, setSnackText] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);

  const classes = useStyles();

  return (
    <SnackContext.Provider
      value={{
        snackOpen: snackOpen,
        setSnackOpen: setSnackOpen,
        snackText: snackText,
        setSnackText: setSnackText,
      }}
    >
      <BreadcrumbContext.Provider
        value={{
          breadcrumbs: [],
        }}
      >
        <DrawerContext.Provider
          value={{
            drawerOpen: drawerOpen,
            setDrawerOpen: setDrawerOpen,
          }}
        >
          <IdfySnackbar />
          <BrowserRouter>
            <div className={classes.root}>
              <IdfyDrawer drawerOpen={drawerOpen} setDrawerOpen={(value: boolean) => setDrawerOpen(value)} />
              <Switch>
                <Route exact path={routes.assets}>
                  <AssetsPage />
                </Route>
                <Route exact path={routes.assets + routes.details(":id") + "/:suffix"}>
                  <AssetDetails />
                </Route>
                <Route exact path={routes.contracts}>
                  <ContractsPage />
                </Route>
                <Route exact path={routes.contracts + routes.details(":id") + "/:suffix"}>
                  <ContractDetails />
                </Route>
                <Route exact path={routes.graphiql}>
                  <GraphiQLPage />
                </Route>
                <Route exact path={routes.customers}>
                  <CustomersPage />
                </Route>
                <Route exact path={routes.customers + routes.details(":id") + "/:suffix"}>
                  <CustomerDetails />
                </Route>
                <Route exact path={routes.blueprints}>
                  <BlueprintsPage />
                </Route>
                <Route exact path={routes.blueprints + routes.details(":id") + "/:suffix"}>
                  <BlueprintDetails />
                </Route>
                <Route exact path={routes.manufacturers}>
                  <ManufacturersPage />
                </Route>
                <Route exact path={routes.suppliers}>
                  <SuppliersPage />
                </Route>
                <Route exact path={routes.administration}>
                  <AdministrationPage />
                </Route>
                <Route exact path={routes.administration + routes.details(":id") + "/:suffix"}>
                  <UserDetails />
                </Route>
                <Route exact path={routes.successfulLogin}>
                  <Redirect to={routes.assets} />
                </Route>
                <Redirect to={routes.assets} />
              </Switch>
            </div>
          </BrowserRouter>
        </DrawerContext.Provider>
      </BreadcrumbContext.Provider>
    </SnackContext.Provider>
  );
};

export default IdfyRouter;
