import classNames from "classnames";

import React, { ReactNode } from "react";

import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ErrorIcon from "@material-ui/icons/Error";

const useStyles = makeStyles((theme) =>
  createStyles({
    iconsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    icon: {
      fontSize: 32,
      padding: 8,
      borderRadius: 9999,
    },
    checkIcon: {
      backgroundColor: theme.palette.primary.main,
    },
    problemIcon: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    helpIcon: {
      color: theme.palette.secondary.main,
      fontSize: 56,
      padding: 0,
      borderRadius: 9999,
    },
    errorIcon: {
      fontSize: 56,
      padding: 0,
      borderRadius: 9999,
    },
  })
);

export type InspectionOverviewIconType = "clear" | "check" | "unevaluated" | "error" | undefined;

export interface InspectionOverviewElementProps {
  imageColumn: ReactNode;
  informationColumn: ReactNode;
  icon: InspectionOverviewIconType;
  problem?: string;
  additionalInformationColumn?: ReactNode;
  acceptanceDeclaration?: {
    checklist: ReactNode;
    note: ReactNode;
  };
}

export const InspectionOverviewElement: React.FunctionComponent<InspectionOverviewElementProps> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          {props.imageColumn}
        </Grid>
        <Grid item xs={12} lg={4}>
          {props.informationColumn}
        </Grid>
        <Grid item xs={12} lg={1} className={classes.iconsContainer}>
          {props.icon === "clear" && (
            <ClearIcon color="secondary" className={classNames(classes.icon, classes.problemIcon)} />
          )}
          {props.icon === "check" && (
            <CheckIcon color="secondary" className={classNames(classes.icon, classes.checkIcon)} />
          )}
          {props.icon === "unevaluated" && <HelpOutlineIcon color="primary" className={classes.helpIcon} />}
          {props.icon === "error" && <ErrorIcon color="secondary" className={classes.errorIcon} />}
        </Grid>
        <Grid item xs={12} lg={4}>
          {props.additionalInformationColumn}
        </Grid>
      </Grid>
      {props.acceptanceDeclaration && (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3} /> {/*Placeholder*/}
          <Grid item xs={12} lg={4}>
            {props.acceptanceDeclaration.checklist}
          </Grid>
          <Grid item xs={12} lg={5}>
            {props.acceptanceDeclaration.note}
          </Grid>
        </Grid>
      )}
    </>
  );
};
