import { ApolloClient, ApolloQueryResult, gql } from "@apollo/client";
import {
  getAcceptanceDeclarationPdfQuery,
  getAcceptanceDeclarationPdfQueryVariables,
} from "./__generated__/getAcceptanceDeclarationPdfQuery";

export const GET_ACCEPTANCE_DECLARATION_PDF_QUERY = gql`
  query getAcceptanceDeclarationPdfQuery($arguments: DeclarationPdfArguments!) {
    getAcceptanceDeclarationPdf(arguments: $arguments) {
      base64
    }
  }
`;

export async function getAcceptanceDeclarationPdf(
  assetId: string,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  client: ApolloClient<any>
): Promise<ApolloQueryResult<getAcceptanceDeclarationPdfQuery>> {
  return client.query<getAcceptanceDeclarationPdfQuery, getAcceptanceDeclarationPdfQueryVariables>({
    query: GET_ACCEPTANCE_DECLARATION_PDF_QUERY,
    variables: { arguments: { assetId: assetId } },
  });
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export async function downloadDeclaration(assetId: string, client: ApolloClient<any>, linkName: string): Promise<void> {
  const fetchResult = await getAcceptanceDeclarationPdf(assetId, client);
  const base64String = fetchResult.data.getAcceptanceDeclarationPdf.base64;
  const a = document.createElement("a");
  a.href = "data:application/pdf;base64," + base64String;
  a.download = linkName;
  a.click();
}
