import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Button, ClickAwayListener, IconButton, Menu } from "@material-ui/core";
import React, { PropsWithChildren, ReactElement, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useContextMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginBottom: 0,
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginBottom: theme.spacing(1),
        marginLeft: 0,
      },
    },
    menuItem: {
      fontWeight: "bold",
      fontSize: "14px",
    },
  })
);

/**
 * Shows a "..." Button or a button with the given label to open up a context menu with the children as menu items
 */
export function ContextMenu(props: PropsWithChildren<{ label?: string }>): ReactElement {
  const classes = useContextMenuStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  function handleMenuClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleMenuClose}>
        {props.label ? (
          <Button
            color={"secondary"}
            variant={"outlined"}
            aria-controls={"context-menu"}
            aria-haspopup={"true"}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            className={classes.button}
          >
            {props.label}
          </Button>
        ) : (
          <IconButton className={classes.button} color="secondary" onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreHorizIcon />
          </IconButton>
        )}
      </ClickAwayListener>
      <Menu anchorEl={anchorEl} keepMounted={true} open={!!anchorEl} onClose={handleMenuClose}>
        {props.children}
      </Menu>
    </>
  );
}
