import React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ImageLocation } from "../util/graphql/__generated__/ImageLocation";

export const useStyles = makeStyles((_: Theme) =>
  createStyles({
    iframe: {
      position: "absolute",
      zIndex: 1,
      bottom: 44,
      right: 12,
      width: "25%",
      height: "25%",
      opacity: 0.6,
      borderRadius: 10,
      transition: "200ms ease-out",
      "&:hover": {
        opacity: 1,
        transition: "200ms ease-out",
        width: "35%",
        height: "35%",
      },
    },
  })
);

export interface LocationIFrameProps {
  location: ImageLocation | null;
  className?: string;
}

export const LocationIFrame: React.FunctionComponent<LocationIFrameProps> = (props) => {
  const classes = useStyles();

  if (!props.location) {
    return null;
  }

  const markerLatitude = props.location.latitude;
  const markerLongitude = props.location.longitude;
  const LATITUDE_OFFSET = 4.58;
  const LONGITUDE_OFFSET = 1.83;

  // Open Street Map uses a bounding box to define the shown map area
  // We use the given coordinates to calculate the two bounding points
  const upperLeftLongitude = markerLongitude - LATITUDE_OFFSET;
  const UpperLeftLatitude = markerLatitude - LONGITUDE_OFFSET;
  const lowerRightLongitude = markerLongitude + LATITUDE_OFFSET;
  const lowerRightLatitude = markerLatitude + LONGITUDE_OFFSET;

  return (
    <iframe
      title={"Open Street Map Location"}
      className={classes.iframe}
      frameBorder="0"
      scrolling="no"
      src={`https://www.openstreetmap.org/export/embed.html?bbox=${upperLeftLongitude}%2C${UpperLeftLatitude}%2C${lowerRightLongitude}%2C${lowerRightLatitude}&amp;layer=mapnik&marker=${markerLatitude}%2C${markerLongitude}`}
    />
  );
};
