import addDays from "date-fns/addDays";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Grid, TextField, useMediaQuery } from "@material-ui/core";

import { idfyTheme } from "../../../util/IdfyTheme";
import { PopUpDialog } from "../../pageFragments/util/PopUpDialog";
import { DateWithFixedDatePeriodCombo } from "../../../components/inputs/DateWithFixedDatePeriodCombo";
import { Alert } from "@material-ui/lab";

export const InitiateInspectionDialog: React.FunctionComponent<{
  open: boolean;
  onClose: () => void;
  onConfirm: (messageToCustomer: string, date: Date | null) => Promise<void>;
  withoutContract: boolean;
}> = (props) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));
  const [messageToCustomer, setMessageToCustomer] = useState("");
  const [inspectionDate, setInspectionDate] = useState<Date | null>(addDays(new Date(), 14));

  const handleConfirm = async () => {
    await props.onConfirm(messageToCustomer, inspectionDate);
  };

  const I18N_PREFIX = `pages.asset-details.inspection-overview.create-new-inspection-dialog.`;

  return (
    <>
      {!props.withoutContract && (
        <PopUpDialog
          open={props.open}
          onClose={props.onClose}
          handleConfirm={handleConfirm}
          handleDeny={props.onClose}
          header={t(`${I18N_PREFIX}header`)}
          confirm={t(`${I18N_PREFIX}confirm`)}
          deny={t(`${I18N_PREFIX}deny`)}
        >
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
            style={mobile ? undefined : { width: "600px" }}
          >
            <DateWithFixedDatePeriodCombo
              value={inspectionDate}
              setValue={setInspectionDate}
              fixedDateSelectionLabel={t(`${I18N_PREFIX}deadline`)}
              fixedDateValue={14}
              fixedDateLabel={t(`${I18N_PREFIX}14-days`)}
            />
            <Grid item xs={12}>
              <TextField
                onChange={(e) => setMessageToCustomer(e.target.value)}
                color="secondary"
                variant="outlined"
                label={t(`${I18N_PREFIX}message-for-customer`)}
                fullWidth
                style={!mobile ? { width: "100%" } : undefined}
                multiline
                minRows={8}
                maxRows={8}
              />
            </Grid>
          </Grid>
        </PopUpDialog>
      )}
      {props.withoutContract && (
        <PopUpDialog
          open={props.open}
          onClose={props.onClose}
          handleConfirm={props.onClose}
          header={t(`${I18N_PREFIX}header`)}
          confirm={t(`${I18N_PREFIX}deny`)}
        >
          <Grid container>
            <Grid item xs={12}>
              <Alert severity="error">{t(`${I18N_PREFIX}no-contract-error`)}</Alert>
            </Grid>
          </Grid>
        </PopUpDialog>
      )}
    </>
  );
};
