import { gql, useMutation } from "@apollo/client";
import DateFnsUtils from "@date-io/date-fns";
import { de } from "date-fns/locale";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, TextField } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { PopUpDialog } from "../../pageFragments/util/PopUpDialog";
import { emptyToNull } from "../../../util/stringUtil";
import { SmallAsset } from "../../assets/api/__generated__/SmallAsset";
import { initiateInspectionMutation } from "../../assets/assetDetails/inspection/api/__generated__/initiateInspectionMutation";

const INITIATE_MULTIPLE_INSPECTIONS = gql`
  mutation initiateMultipleInspectionsMutation($input: SetMultipleInspectionsInput!) {
    setMultipleInspections(input: $input) {
      inspections {
        id
        status
        asset {
          id
          assetStatus
        }
      }
    }
  }
`;

export const CreateInspections: React.FunctionComponent<{
  customerId: string;
  createInspectionsDialogOpen: boolean;
  setCreateInspectionsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  assets: SmallAsset[];
}> = (props) => {
  const { t } = useTranslation();

  const [initiateInspections] = useMutation<initiateInspectionMutation>(INITIATE_MULTIPLE_INSPECTIONS);

  const [assetIdList, setAssetIdList] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [inspectionDate, setInspectionDate] = useState<Date | null>(new Date(new Date().getTime() + 14 * 8.64e7));
  const [fixedDate, setFixedDate] = useState(true);
  const [messageToCustomer, setMessageToCustomer] = useState("");

  const assetsThatCanInitiateInspections = props.assets.filter((asset) => asset.assetStatus == "APPROVED");

  return (
    <PopUpDialog
      open={props.createInspectionsDialogOpen}
      onClose={() => props.setCreateInspectionsDialogOpen(false)}
      handleConfirm={async () => {
        await initiateInspections({
          variables: {
            input: {
              customerId: props.customerId,
              assetIdList: assetIdList,
              dueDate: inspectionDate?.toISOString() || "",
              messageToCustomer: emptyToNull(messageToCustomer),
            },
          },
        }).then((res) => {
          if (!res.errors) props.setCreateInspectionsDialogOpen(false);
        });
      }}
      header={t("pages.customer-details.create-inspections.initiate-inspection-for-multiple-assets")}
      confirm={t("pages.customer-details.create-inspections.initiate")}
      deny={t("pages.customer-details.create-inspections.cancel")}
      handleDeny={() => {
        props.setCreateInspectionsDialogOpen(false);
        setAssetIdList([]);
      }}
      confirmButtonDisabled={assetIdList.length === 0}
      fullWidth={true}
    >
      <FormControl variant={"outlined"} fullWidth>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel id="12345647896543213456789">
              {t("pages.customer-details.create-inspections.assets")}
            </InputLabel>
            <Select
              label={t("pages.customer-details.create-inspections.assets")}
              labelId="12345647896543213456789"
              multiple
              fullWidth
              variant={"outlined"}
              value={assetIdList}
              onChange={(event) => {
                if ((event.target.value as string[]).find((value) => value === "-1") != null) {
                  setSelectAll(true);
                  setAssetIdList(assetsThatCanInitiateInspections.map((asset) => asset.id));
                } else {
                  setSelectAll(false);
                  setAssetIdList(event.target.value as string[]);
                }
              }}
              renderValue={(selected: unknown) =>
                (selected as string[]).map((id) => props.assets.find((asset) => asset.id === id)!.name).join(", ")
              }
            >
              {assetsThatCanInitiateInspections.length > 1 && (
                <MenuItem key={"-1"} value={"-1"}>
                  <Checkbox checked={selectAll} />
                  <ListItemText primary={t("pages.customer-details.create-inspections.select-all")} />
                </MenuItem>
              )}
              {assetsThatCanInitiateInspections.map((asset) => (
                <MenuItem key={asset.id} value={asset.id}>
                  <Checkbox checked={assetIdList.find((it) => it === asset.id) !== undefined} />
                  <ListItemText primary={asset.name} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
              <DatePicker
                value={inspectionDate}
                minDate={new Date()}
                onChange={(date) => setInspectionDate(date)}
                fullWidth
                format={t("pages.asset-details.inspection-overview.create-new-inspection-dialog.date-format")}
                inputVariant={"outlined"}
                color={"secondary"}
                disabled={fixedDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" color={"secondary"} fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                {t("pages.asset-details.inspection-overview.create-new-inspection-dialog.deadline")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                label={t("pages.asset-details.inspection-overview.create-new-inspection-dialog.deadline")}
                defaultValue={14}
                onChange={(e) => {
                  setInspectionDate(new Date(new Date().getTime() + Number(e.target.value) * 8.64e7));
                  if (e.target.value === 14) {
                    setFixedDate(true);
                  } else {
                    setFixedDate(false);
                  }
                }}
              >
                <MenuItem value={0}>
                  {t("pages.asset-details.inspection-overview.create-new-inspection-dialog.self-select-date")}
                </MenuItem>
                <MenuItem value={14}>
                  {t("pages.asset-details.inspection-overview.create-new-inspection-dialog.14-days")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => setMessageToCustomer(e.target.value)}
              color="secondary"
              variant="outlined"
              label={t("pages.asset-details.inspection-overview.create-new-inspection-dialog.message-for-customer")}
              fullWidth
              multiline
              rows={8}
            />
          </Grid>
        </Grid>
      </FormControl>
    </PopUpDialog>
  );
};
