import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import addDays from "date-fns/addDays";
import React, { ReactElement, useState } from "react";
import { FormGridItem } from "../forms/FormGrid";
import { useTranslation } from "react-i18next";
import { getDateFnsLocale } from "../../i18nInit";

export function DateWithFixedDatePeriodCombo(props: {
  value: Date | null;
  setValue: (value: Date | null) => void;
  fixedDateSelectionLabel: string;
  fixedDateValue: number;
  fixedDateLabel: string;
  dateFormat?: string;
}): ReactElement {
  const { t, i18n } = useTranslation();

  const dateFormat = props.dateFormat ? props.dateFormat : t("dates.date-long");
  const [hasFixedDate, setHasFixedDate] = useState(true);

  return (
    <>
      <FormGridItem>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getDateFnsLocale(i18n.language)}>
          <DatePicker
            value={props.value}
            minDate={new Date()}
            onChange={(date) => props.setValue(date)}
            fullWidth
            format={dateFormat}
            inputVariant="outlined"
            color="secondary"
            disabled={hasFixedDate}
          />
        </MuiPickersUtilsProvider>
      </FormGridItem>
      <FormGridItem>
        <FormControl variant="outlined" color="secondary" fullWidth>
          <InputLabel id="fixed-date-select">{props.fixedDateSelectionLabel}</InputLabel>
          <Select
            labelId="fixed-date-select"
            defaultValue={props.fixedDateValue}
            onChange={(e) => {
              props.setValue(addDays(new Date(), Number(e.target.value)));
              setHasFixedDate(e.target.value !== 0);
            }}
          >
            <MenuItem value={0}>{t("dates.self-select-date")}</MenuItem>
            <MenuItem value={props.fixedDateValue}>{props.fixedDateLabel}</MenuItem>
          </Select>
        </FormControl>
      </FormGridItem>
    </>
  );
}
