import React, { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";
import { ControllerTextField } from "../inputs/ControllerTextField";
import { retrieveError } from "./FormErrorInfoText";

export type FormTextFieldProps = {
  name: string;
  trimOnBlur?: boolean;
} & TextFieldProps;

/**
 * A simple controller text field that hides the ugly render method (which is required for stuff like `onBlur()`) and
 * provides some useful utilities like `trimOnBlur` and not showing popup when required.
 */
export function FormTextField(props: FormTextFieldProps): ReactElement {
  const { required, label, ...rest } = props;
  const { control, errors } = useFormContext();
  const displayLabel = required ? label + " *" : label;

  return (
    <ControllerTextField
      control={control}
      fullWidth
      variant="outlined"
      size="small"
      color="secondary"
      helperText={retrieveError(props.name, errors)}
      error={!!retrieveError(props.name, errors)}
      label={displayLabel}
      {...rest}
    />
  );
}
