import React, { FC } from "react";

import { assetEventsQuery_assetEvents } from "./__generated__/assetEventsQuery";
import { AssetCreatedEventEntry } from "./entries/AssetCreatedEventEntry";
import { InspectionApprovedEventEntry } from "./entries/InspectionApprovedEventEntry";
import { InspectionRejectedEventEntry } from "./entries/InspectionRejectedEventEntry";
import { InspectionRequestedEventEntry } from "./entries/InspectionRequestedEventEntry";
import { InspectionSubmittedEventEntry } from "./entries/InspectionSubmittedEventEntry";
import { NoteAddedEventEntry } from "./entries/NoteAddedEventEntry";
import { InspectionEventEntry } from "./entries/InspectionEventEntry";

export interface AssetEventSelectorProps {
  event: assetEventsQuery_assetEvents;
}

/**
 * A simple helper class, that maps the asset events from the GraphQL-Query
 * to their correct React component.
 */
export const AssetEventSelector: FC<AssetEventSelectorProps> = (props) => {
  const { event } = props;
  const time = new Date(event.timestamp);

  switch (event.__typename) {
    case "AssetCreatedEvent":
      return (
        <AssetCreatedEventEntry
          time={time}
          dueDate={new Date(event.dueDate)}
          user={event.user}
          messageToCustomer={event.messageToCustomer}
          isNewAsset={event.asset.isNew}
          isAlreadyInStock={event.asset.isAlreadyInStock}
        />
      );
    case "InspectionSubmittedEvent":
      return (
        <InspectionSubmittedEventEntry
          time={time}
          inspectionType={event.inspection.inspectionType}
          asset={event.asset}
          customer={event.customer}
        />
      );
    case "InspectionApprovedEvent":
      return (
        <InspectionApprovedEventEntry time={time} inspectionType={event.inspection.inspectionType} user={event.user} />
      );
    case "InspectionRejectedEvent":
      return (
        <InspectionRejectedEventEntry
          time={time}
          dueDate={new Date(event.dueDate)}
          rejectionReason={event.rejectionReason}
          inspectionType={event.inspection.inspectionType}
          user={event.user}
        />
      );
    case "InspectionRequestedEvent":
      return (
        <InspectionRequestedEventEntry
          time={time}
          inspectionType={event.inspection.inspectionType}
          messageToCustomer={event.messageToCustomer}
          user={event.user}
          dueDate={new Date(event.dueDate)}
        />
      );
    case "InspectionEvent":
      return (
        <InspectionEventEntry
          type={event.type}
          time={time}
          inspectionType={event.inspection.inspectionType}
          messageToCustomer={event.messageToCustomer}
          user={event.user}
        />
      );
    case "NoteAddedEvent":
      return <NoteAddedEventEntry time={time} note={event.note} user={event.user} />;
    default:
      return null;
  }
};
