import React, { ReactNode } from "react";

import { Button, MenuItem } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { DetailsSectionHeader } from "../../../components/DetailsSectionHeader";
import { ContextMenu } from "../../../components/buttons/ContextMenu";
import { isAllowed } from "../../../components/access/AccessManager";
import { useCurrentAccount } from "../../../util/auth/useCurrentAccount";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
      },
    },
    button: {
      marginBottom: 0,
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginBottom: theme.spacing(1),
        marginLeft: 0,
      },
    },
  })
);

export const InfoPageHeader: React.FunctionComponent<{
  /**
   * If no deleteText is set, the delete button will not be rendered
   */
  deleteText?: string;
  setDisabled: (value: boolean) => void;
  disabled: boolean;
  header: string;
  handleDelete?: () => void;
  onCancel: () => void;
  saveButtonDisabled?: boolean;
  additionalContent?: ReactNode;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentAccount = useCurrentAccount();

  return (
    <div className={classes.root}>
      <DetailsSectionHeader header={props.header} />
      <div className={classes.buttonContainer}>
        {props.disabled ? (
          <Button color="secondary" type="button" disabled={!isAllowed("pages.customer-details.information.edit", currentAccount)} onClick={() => props.setDisabled(false)}>
            {t("common.edit")}
          </Button>
        ) : (
          <>
            {props.additionalContent && <>{props.additionalContent}</>}
            {props.deleteText && (
              <ContextMenu>
                <MenuItem onClick={props.handleDelete}>{props.deleteText}</MenuItem>
              </ContextMenu>
            )}
            <Button
              color="secondary"
              variant="outlined"
              className={classes.button}
              onClick={() => {
                props.onCancel();
                props.setDisabled(true);
              }}
            >
              {t("common.cancel")}
            </Button>
            <Button
              className={classes.button}
              color="primary"
              key="submitbutton"
              type="submit"
              disabled={props.saveButtonDisabled}
            >
              {t("common.save")}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
