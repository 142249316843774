import { AcceptanceDeclaration } from "../../../../../api/__generated__/AcceptanceDeclaration";
import { inspectionQuery_asset } from "../../../api/__generated__/inspectionQuery";

export function acceptanceDeclarationComplete(
  declaration: AcceptanceDeclaration,
  asset: inspectionQuery_asset
): boolean {
  if (
    declaration.isDeliveryComplete &&
    declaration.hasInstructionManual &&
    declaration.isFreeOfDefects &&
    declaration.isInvoiceCorrect &&
    declaration.isCheckedAndAccepted
  ) {
    if (asset.isNew) {
      return !!declaration.isBrandNewAndWorking;
    }
    return true;
  }
  return false;
}
