import { gql } from "@apollo/client";

export const userFragment = gql`
  fragment UserData on User {
    id
    firstName
    lastName
    email
    telephoneNumber
    roles
    team
  }
`;
