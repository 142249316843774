import { gql } from "@apollo/client";
import { imageLocationFragment } from "../../../util/graphql/commonFragments";

export const featureBlueprintWebImageFragment = gql`
  ${imageLocationFragment}
  fragment FeatureBlueprintWebImage on FeatureBlueprint {
    images(scale: { width: 1000, height: 1000, mode: FIT }) {
      data
      type
      creationDate
      location {
        ...ImageLocation
      }
      appCreationDateTime
    }
  }
`;

export const bluePrintFragment = gql`
  fragment Blueprint on Blueprint {
    id
    status
    name
  }
`;

export const featureBlueprintFragment = gql`
  fragment FeatureBlueprint on FeatureBlueprint {
    id
    title
    description
    type
  }
`;

export const featureBlueprintWithImagesFragment = gql`
  ${featureBlueprintWebImageFragment}
  fragment FeatureBlueprintWithImages on FeatureBlueprint {
    id
    title
    description
    type
    ...FeatureBlueprintWebImage
  }
`;
