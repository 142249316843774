import React from "react";
import { CircularProgress, createStyles } from "@material-ui/core";
import { BasePage } from "../pages/pageFragments/BasePage";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    loadingContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
    },
    loadingIndicator: {
      margin: "auto",
    },
  })
);

/**
 * Renders a base page with a loading indicator.
 * Useful when awaiting data.
 */
export const BasePageWithLoadingIndicator: React.FunctionComponent<{ headerText: string }> = (props) => {
  const classes = useStyles();

  return (
    <BasePage headerText={props.headerText}>
      <div className={classes.loadingContainer}>
        <CircularProgress className={classes.loadingIndicator} color={"primary"} />
      </div>
    </BasePage>
  );
};
