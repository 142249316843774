import { gql, useQuery } from "@apollo/client";

import React, { FC, useState } from "react";

import { Button, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { usePrefixedTranslation } from "../../../../util/usePrefixedTranslation";
import { addAssetNoteMutation_addAssetNote } from "./__generated__/addAssetNoteMutation";
import {
  assetEventsQuery,
  assetEventsQuery_assetEvents,
  assetEventsQueryVariables,
} from "./__generated__/assetEventsQuery";
import { AssetEventSelector } from "./AssetEventSelector";
import { AssetProtocolEntrySkeleton } from "./AssetProtocolEntrySkeleton";
import { NoteDraftEntry } from "./NoteDraftEntry";
import { acceptanceDeclarationFragment } from "../../api/acceptanceDeclarationFragment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    titleContainer: {
      display: "flex",
    },
    title: {
      marginRight: theme.spacing(2),
    },
    entryContainer: {
      marginTop: theme.spacing(4),
      display: "grid",
      gridRowGap: theme.spacing(2),
    },
  })
);

const ASSET_EVENTS_QUERY = gql`
  ${acceptanceDeclarationFragment}
  query assetEventsQuery($assetId: ID!) {
    assetEvents(filter: { assetId: $assetId }) {
      __typename
      timestamp
      ... on AssetCreatedEvent {
        dueDate
        messageToCustomer
        user {
          firstName
          lastName
        }
        asset {
          isNew
          isAlreadyInStock
        }
      }
      ... on InspectionSubmittedEvent {
        inspection {
          inspectionType
        }
        asset {
          id
          acceptanceDeclarations {
            ...AcceptanceDeclaration
          }
        }
        customer {
          company
        }
      }
      ... on InspectionApprovedEvent {
        inspection {
          inspectionType
        }
        user {
          firstName
          lastName
        }
      }
      ... on InspectionRejectedEvent {
        inspection {
          inspectionType
        }
        user {
          firstName
          lastName
        }
        rejectionReason
        dueDate
      }
      ... on InspectionRequestedEvent {
        inspection {
          inspectionType
        }
        messageToCustomer
        user {
          firstName
          lastName
        }
        dueDate
      }
      ... on InspectionEvent {
        type
        inspection {
          inspectionType
        }
        asset {
          isNew
          isAlreadyInStock
        }
        messageToCustomer
        user {
          firstName
          lastName
        }
      }
      ... on NoteAddedEvent {
        note
        user {
          firstName
          lastName
        }
      }
    }
  }
`;

export interface AssetProtocolProps {
  assetId: string;
}

export const AssetProtocol: FC<AssetProtocolProps> = (props) => {
  const { pt } = usePrefixedTranslation("pages.asset-details.protocol.");
  const classes = useStyles();

  const [noteDraftOpen, setNoteDraftOpen] = useState(false);

  const { data } = useQuery<assetEventsQuery, assetEventsQueryVariables>(ASSET_EVENTS_QUERY, {
    variables: { assetId: props.assetId },
    fetchPolicy: "no-cache",
  });
  const [newlyAddedEvents, setNewlyAddedEvents] = useState<assetEventsQuery_assetEvents[]>([]);

  const combinedEvents = data == null ? null : [...newlyAddedEvents, ...data.assetEvents];

  const handleNoteDraftSave = (assetNoteEvent: addAssetNoteMutation_addAssetNote): void => {
    setNewlyAddedEvents((prev) => [assetNoteEvent, ...prev]);
    setNoteDraftOpen(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant="h3" className={classes.title}>
          {pt("header")}
        </Typography>
        <Button color="secondary" disabled={noteDraftOpen} onClick={() => setNoteDraftOpen(true)}>
          {pt("add-note")}
        </Button>
      </div>
      <div className={classes.entryContainer}>
        {noteDraftOpen && <NoteDraftEntry assetId={props.assetId} onSave={handleNoteDraftSave} />}
        {combinedEvents == null && [1, 2, 3, 4].map((seed) => <AssetProtocolEntrySkeleton key={seed} seed={seed} />)}
        {combinedEvents?.map((event, index) => (
          <AssetEventSelector key={index} event={event} />
        ))}
      </div>
    </div>
  );
};
