import React, { useEffect, useState } from "react";

import { Fab } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ArrowLeftIcon } from "@material-ui/pickers/_shared/icons/ArrowLeftIcon";
import { ArrowRightIcon } from "@material-ui/pickers/_shared/icons/ArrowRightIcon";

import { ImageModal } from "../../../../pageFragments/util/ImageModal";
import { LocationIFrame } from "../../../../../components/LocationIFrame";
import { ImageLocation } from "../../../../../util/graphql/__generated__/ImageLocation";
import { Image } from "../../../../../util/graphql/__generated__/Image";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "relative",
      minHeight: 0,
      height: "100%",
    },
    arrowRight: {
      position: "absolute",
      top: "50%",
      left: "100%",
      transform: "translate(-120%,-50%)",
    },
    arrowLeft: {
      position: "absolute",
      top: "50%",
      transform: "translate(20%,-50%)",
    },
    image: {
      flexGrow: 1,
      width: "100%",
      objectFit: "contain",
      height: "calc(100% - 32px)",
      [theme.breakpoints.down(1600)]: {
        height: "calc(100% - 64px)",
      },
      backgroundColor: "#eeeeee",
      "&:hover ": {
        cursor: "pointer",
      },
    },
  })
);

export const FeatureImageSlider: React.FunctionComponent<{
  images: Image[];
  modalTitle: string;
  footer?: (image: Image) => React.ReactNode;
  displayLocation?: boolean;
  locationOfImage?: (location: ImageLocation | null) => void;
}> = (props) => {
  const classes = useStyles();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setCurrentIndex(0);
    props.locationOfImage && props.locationOfImage(props.images[currentIndex]?.location);
  }, [props.images, setCurrentIndex]);

  const handleClose = (newIndex: number) => {
    setCurrentIndex(newIndex);
    setModalOpen(false);
  };

  return (
    <div className={classes.root}>
      {props.images.length > 1 && (
        <>
          <Fab
            className={classes.arrowLeft}
            size="small"
            onClick={() => {
              setCurrentIndex((currentIndex - 1 + props.images.length) % props.images.length);
              props.locationOfImage && props.locationOfImage(props.images[currentIndex]?.location);
            }}
          >
            <ArrowLeftIcon />
          </Fab>
          <Fab
            className={classes.arrowRight}
            size="small"
            onClick={() => {
              setCurrentIndex((currentIndex + 1) % props.images.length);
              props.locationOfImage && props.locationOfImage(props.images[currentIndex]?.location);
            }}
          >
            <ArrowRightIcon />
          </Fab>
        </>
      )}
      <img
        alt=""
        className={classes.image}
        src={`data:${props.images[currentIndex]?.type};base64,${props.images[currentIndex]?.data}`}
        onClick={() => setModalOpen(true)}
      />
      {props.displayLocation && <LocationIFrame location={props.images[currentIndex]?.location} />}
      {/*Necessary assertions because of rerender before useEffect hook is executed*/}
      {props.footer && currentIndex < props.images.length && props.footer(props.images[currentIndex])}
      <ImageModal
        images={props.images}
        open={modalOpen}
        handleClose={handleClose}
        header={props.modalTitle}
        currentIndex={currentIndex}
      />
    </div>
  );
};
