import React from "react";
import { AcceptanceDownloadLink } from "../../acceptanceDownload/AcceptanceDownloadLink";
import { usePrefixedTranslation } from "../../../../../util/usePrefixedTranslation";
import { assetDetailQuery_asset } from "../../../api/__generated__/assetDetailQuery";
import { DetailsSectionHeader } from "../../../../../components/DetailsSectionHeader";
import { alignWithFormFieldsStyle } from "../../../../../components/alignmentStyles/AlignWithFormFieldsStyle";

export const AssetInfoDownloads: React.FunctionComponent<{
  asset: assetDetailQuery_asset;
}> = (props) => {
  const { pt } = usePrefixedTranslation("pages.asset-details.information.");
  return (
    <React.Fragment>
      <DetailsSectionHeader header={pt("documents")} />
      <AcceptanceDownloadLink
        styleClasses={alignWithFormFieldsStyle().alignWithInputFields}
        assetId={props.asset.id}
        acceptanceDeclarations={props.asset.acceptanceDeclarations}
        companyName={props.asset.currentContract?.customer.company || "unbekannt"}
      />
    </React.Fragment>
  );
};
