import { gql, useQuery } from "@apollo/client";

import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { TableHeader } from "../../components/TableHeader";
import { BreadcrumbContext, SnackContext } from "../../routing/idfyRouter";
import { routes } from "../../routing/routes";
import { BasePage } from "../pageFragments/BasePage";
import { usersQuery, usersQuery_users } from "./__generated__/usersQuery";
import { CreateUserModal } from "./CreateUserModal";
import { UserTable } from "./UserTable";

export const USERS_QUERY = gql`
  query usersQuery {
    users {
      id
      email
      firstName
      lastName
      roles
    }
  }
`;

export const AdministrationPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const snackContext = useContext(SnackContext);

  const history = useHistory();
  const utilContext = useContext(BreadcrumbContext);

  utilContext.breadcrumbs = [];

  const { data } = useQuery<usersQuery>(USERS_QUERY, {
    fetchPolicy: "network-only",
  });

  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);

  const handleUserClick = (user: usersQuery_users) => {
    utilContext.breadcrumbs.push({
      url: history.location.pathname || "errorPage",
      title: t("pages.administration.header"),
    });
    history.push(routes.administration + routes.details(user.id) + routes.infoSuffix);
  };

  const handleUserCreate = () => {
    setCreateUserModalOpen(false);
    snackContext.setSnackOpen(true);
    snackContext.setSnackText(t("pages.administration.alerts.user-created"));
  };

  return (
    <BasePage headerText={t("pages.administration.header")}>
      <TableHeader
        title={t("pages.administration.table.title")}
        buttonText={t("pages.administration.buttons.new")}
        onButtonClick={() => setCreateUserModalOpen(true)}
        enabled={true}
      />
      <UserTable users={data?.users ?? []} onUserClick={handleUserClick} />
      <CreateUserModal
        open={createUserModalOpen}
        onClose={() => setCreateUserModalOpen(false)}
        onUserCreate={handleUserCreate}
      />
    </BasePage>
  );
};
