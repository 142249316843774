import React from "react";
import { Alert } from "@material-ui/lab";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { assetDetailQuery_asset } from "../../../api/__generated__/assetDetailQuery";
import { FormattedSupplierInfo } from "./CreateAssetInfoSupplierFields";
import { Link } from "react-router-dom";
import { routes } from "../../../../../routing/routes";

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    icon: {
      alignSelf: "center",
    },
    assetValue: {
      fontWeight: "bold",
    },
  })
);

export const FoundAssetOutOfContract: React.FunctionComponent<{
  foundAsset: assetDetailQuery_asset;
  useOutOfContractAsset: () => void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Alert severity="error" classes={{ icon: classes.icon }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {t("pages.assets.modals.create-asset.fields.useOutOfContractAsset.prompt")}
        </Grid>
        <Grid item xs={12} xl={4}>
          {t("pages.assets.modals.create-asset.manufacturer")}:
        </Grid>
        <Grid item xs={12} xl={8} className={classes.assetValue}>
          {props.foundAsset.manufacturer?.name}
        </Grid>
        <Grid item xs={12} xl={4}>
          {t("pages.assets.modals.create-asset.fields.name")}:
        </Grid>
        <Grid item xs={12} xl={8} className={classes.assetValue}>
          {props.foundAsset.name}
        </Grid>
        <Grid item xs={12} xl={4}>
          {t("pages.assets.modals.create-asset.fields.serial-number")}:
        </Grid>
        <Grid item xs={12} xl={8} className={classes.assetValue}>
          {props.foundAsset.serialNumber}
        </Grid>
        <Grid item xs={12} xl={4}>
          {t("pages.assets.modals.create-asset.fields.blueprint")}:
        </Grid>
        <Grid item xs={12} xl={8} className={classes.assetValue}>
          {props.foundAsset.blueprint?.name}
        </Grid>
        {props.foundAsset.supplier && (
          <>
            <Grid item xs={12} xl={4}>
              {t("pages.assets.modals.create-asset.fields.supplier.name")}:
            </Grid>
            <Grid item xs={12} xl={8}>
              <FormattedSupplierInfo supplier={props.foundAsset.supplier} />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Box justifyContent="space-around" alignItems="center" display="flex">
            <Link to={routes.assets + routes.details(props.foundAsset.id) + "/info"} target="_blank">
              {t("duplicateAssetAlert.openObject")}
            </Link>
            <Button variant={"outlined"} color={"inherit"} onClick={props.useOutOfContractAsset}>
              {t("pages.assets.modals.create-asset.fields.useOutOfContractAsset.button")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Alert>
  );
};
