import React, { FC } from "react";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import PersonIcon from "@material-ui/icons/Person";
import { usePrefixedTranslation } from "../../../../../util/usePrefixedTranslation";
import { AssetProtocolEntry } from "../AssetProtocolEntry";
import { EventTextBox } from "./EventTextBox";

export interface NoteAddedEventEntryProps {
  time: Date;
  note: string;
  user: {
    firstName: string;
    lastName: string;
  };
}

export const NoteAddedEventEntry: FC<NoteAddedEventEntryProps> = (props) => {
  const { pt } = usePrefixedTranslation("pages.asset-details.protocol.note-added.");

  return (
    <AssetProtocolEntry
      icon={<CreateOutlinedIcon />}
      title={pt("title")}
      time={props.time}
      fields={[
        {
          text: `${props.user.lastName}, ${props.user.firstName}`,
          tooltip: pt("tooltip-user"),
          icon: <PersonIcon />,
        },
      ]}
      body={<EventTextBox text={props.note} />}
    />
  );
};
