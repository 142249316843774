import classNames from "classnames";

import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Tooltip, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";

import logo from "../util/idf-logo-low-res.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 1000,
      boxSizing: "border-box",
      height: 24,

      position: "relative",
      display: "flex",
      alignItems: "center",
    },
    circle: {
      position: "absolute",
      left: -2,
      top: -2,
      bottom: 0,
      borderRadius: 1000,
      height: 24,
      width: 24,
    },
    text: {
      marginTop: 1,
      marginRight: theme.spacing(1),
      marginLeft: 28,
    },
    dataText: {
      marginTop: 1,
      marginRight: 28,
      marginLeft: theme.spacing(1),
    },
    dataCircle: {
      background: theme.palette.primary.main,
      position: "absolute",
      right: -2,
      top: -2,
      bottom: 0,
      borderRadius: 1000,
      height: 24,
      width: 24,
      fontSize: 12,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

export interface IdfyTagChipProps {
  tag: string;
  className?: string;
  /**
   * If true, the IDfy logo gets replaced with a red exclamation mark.
   * This can be used for the inspection if the tag differs from the
   * previous inspection.
   */
  showWarning?: boolean;
}

/**
 * A simple chip that is rendered like the IDfy tag that's put on the objects.
 */
export const IdfyTagChip: FC<IdfyTagChipProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Tooltip title={props.showWarning ? t("feature-inspection.tag-do-not-match").toString() : ""}>
      <div className={classNames(classes.root, props.className)}>
        {props.showWarning && <ErrorIcon color="error" className={classes.circle} />}
        {!props.showWarning && <img className={classes.circle} src={logo} alt="IDfy Logo" />}
        <Typography variant="button" className={classes.text}>
          {props.tag}
        </Typography>
      </div>
    </Tooltip>
  );
};

export enum ChipDataType {
  KILOMETERS,
  HOURS,
}

export interface IdfyDataChipProps {
  tag: string;
  className?: string;
  /**
   * If true, the IDfy logo gets replaced with a red exclamation mark.
   * This can be used for the inspection if the tag differs from the
   * previous inspection.
   */
  type?: ChipDataType;
}

export const IdfyDataChip: FC<IdfyDataChipProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, props.className)}>
      <Typography variant="button" className={classes.dataText}>
        {props.tag}
      </Typography>
      <span className={classes.dataCircle}>{props.type == ChipDataType.HOURS ? "h" : "km"}</span>
    </div>
  );
};
