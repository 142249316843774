import { useMutation } from "@apollo/client";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, LinearProgress, Modal, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

import {
  FeatureType,
  InspectionMandatoryAssetFieldsInput,
  InspectionStatus,
} from "../../../../../__generated__/globalTypes";
import { formatDateString, formatDateToIsoDate } from "../../../../../util/dateTime/formatDate";
import { idfyTheme } from "../../../../../util/IdfyTheme";
import { MAX_CONTENT_WIDTH } from "../../../../pageFragments/BasePage";
import { DenyInspectionDialog } from "./DenyInspectionDialog";
import { FeatureInspectionComponent, FeatureInspectionData } from "./FeatureInspectionComponent";
import { InspectionModalNavigation } from "./InspectionModalNavigation";
import { InspectionOverview } from "./overview/InspectionOverview";
import { emptyToNull, isBlankOrEmpty } from "../../../../../util/stringUtil";
import { PopUpDialog } from "../../../../pageFragments/util/PopUpDialog";
import InfoIcon from "@material-ui/icons/Info";
import { AddContractEndOfTermDialog } from "./AddContractEndOfTermDialog";
import { inspectionQuery_asset, inspectionQuery_asset_inspections } from "../api/__generated__/inspectionQuery";
import {
  SET_INSPECTION_MANDATORY_ASSET_FIELDS_MUTATION,
  UPDATE_INSPECTION_MUTATION,
} from "../api/inspectionQueriesAndMutations";
import { updateInspectionMutation } from "../api/__generated__/updateInspectionMutation";
import { setInspectionMandatoryFieldsMutation } from "../api/__generated__/setInspectionMandatoryFieldsMutation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalBody: {
      position: "absolute",
      top: "50%",
      left: "50%",
      maxHeight: "90%",
      width: "90%",
      transform: "translate(-50%,-50%)",
      boxSizing: "border-box",
      padding: theme.spacing(4),
      borderRadius: "10px",
      outline: "none",
      display: "flex",
      flexDirection: "column",
      background: idfyTheme.palette.background.default,
      maxWidth: MAX_CONTENT_WIDTH,
    },
    grid: {
      maxHeight: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    header: {
      display: "grid",
      width: "100%",
      flexWrap: "nowrap",
      alignItems: "center",
      gridTemplateColumns: "1fr 1fr 1fr",
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        gridTemplateRows: "auto auto auto",
        gridTemplateColumns: "auto",
        justifyContent: "center",
      },
    },
    headerButtons: {
      display: "flex",
      justifyContent: "flex-end",
      "& > *": {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
          marginLeft: 0,
          marginTop: theme.spacing(1),
        },
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    modalWrapper: {
      overflow: "hidden",
      width: "100%",
      height: "100%",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        overflow: "hidden auto",
      },
    },
  })
);

interface InspectionMandatoryAssetFormFields extends InspectionMandatoryAssetFieldsInput {
  isDirty: boolean;
}

export interface FeatureEvaluation {
  approved: boolean;
  title: string;
  reason: string;
  featureInspectionId: string;
}

function loadFeatures(
  inspection: inspectionQuery_asset_inspections,
  asset: inspectionQuery_asset,
  mandatoryAssetFields: InspectionMandatoryAssetFormFields,
  serialNumberUndefinedText: string
): FeatureInspectionData[] {
  const tempFeatureArray: FeatureInspectionData[] = inspection.featureInspections.map((feature) => ({
    manufacturerName: asset.manufacturer?.name,
    assetName: asset.name,
    featureInspection: feature,
    assetSerialNumber: asset.serialNumber || mandatoryAssetFields.serialNumber,
    type: asset.blueprint?.name,
    isSubAsset: false,
  }));

  inspection.subInspections?.forEach((inspection) =>
    inspection.featureInspections.forEach((feature) =>
      tempFeatureArray.push({
        manufacturerName: inspection.subAsset.manufacturer?.name,
        assetName: inspection.subAsset.name,
        featureInspection: feature,
        assetSerialNumber: inspection.subAsset.serialNumber || serialNumberUndefinedText,
        type: "Zubehörteil",
        isSubAsset: true,
      })
    )
  );
  return tempFeatureArray;
}

export const InspectionModal: React.FunctionComponent<{
  modalOpen: boolean;
  handleClose: (triggerRefetch: boolean) => void;
  inspection: inspectionQuery_asset_inspections;
  asset: inspectionQuery_asset;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const I18NEXT_PREFIX = "pages.asset-details.inspection-overview.modals.normal-inspection.";

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [mandatoryInspectionFields, setMandatoryInspectionFields] = useState<InspectionMandatoryAssetFormFields>({
    assetId: props.asset.id,
    serialNumber: isBlankOrEmpty(props.asset.serialNumber) ? null : props.asset.serialNumber,
    contractEndOfTerm: isBlankOrEmpty(props.inspection.contract.endDate) ? "" : props.inspection.contract.endDate!,
    isDirty: false,
  });

  const editable = props.inspection.status === InspectionStatus.WAITING_FOR_APPROVAL;

  function getInitialFeatureEvaluations(): FeatureEvaluation[] {
    if (props.inspection.status === InspectionStatus.WAITING_FOR_APPROVAL) {
      return [] as FeatureEvaluation[];
    } else {
      // Feature evaluations for feature inspections
      const featureInspectionEvaluations = props.inspection.featureInspections.map((featureInspection) => ({
        featureInspectionId: featureInspection.id,
        approved: featureInspection.rejectionReason === null,
        title: "", // Not needed here
        reason: featureInspection.rejectionReason,
      }));
      // Append feature evaluations feature inspections of sub inspections
      props.inspection.subInspections?.forEach((inspection) =>
        inspection.featureInspections.forEach((feature) =>
          featureInspectionEvaluations.push({
            featureInspectionId: feature.id,
            approved: feature.rejectionReason === null,
            title: "", // Not needed here
            reason: feature.rejectionReason,
          })
        )
      );
      return featureInspectionEvaluations as FeatureEvaluation[];
    }
  }

  /**
   * Feature evaluations are either used
   * - to set the status for each feature when evaluation an inspection or
   * - to display the status for each feature of rejected inspections.
   */
  const [featureEvaluations, setFeatureEvaluations] = useState<FeatureEvaluation[]>(getInitialFeatureEvaluations());

  // This is horrible code and I'm deeply sorry if any poor soul (probably myself) ever has to
  // touch this code again. It violates every good practice regarding data-flow and having a SSOT.
  // However, this component was already a mess when I've implemented this and it's impossible to
  // add this (simple) feature any other way without major refactoring.
  // I'm sorry!
  //
  // But let me try to make your life at least a bit easier and let me explain what this is and how
  // it works: We want to highlight non-matching tags in the UI, both in the feature inspection and
  // the final overview. Because we don't have access to the matching features in this parent component,
  // we receive the information about non-matching tags via a callback in the FeatureInspection component.
  // We then add this information to this array and pass it to the InspectionOverview component.
  // This is very similar to the message array (which is equally bad) ... So at least it's consistent? ¯\_(ツ)_/¯
  const [notMatchingTagFeatureInspectionIds, setNotMatchingTagFeatureInspectionIds] = useState<string[]>([]);

  const [featureArray, setFeatureArray] = useState<FeatureInspectionData[]>(
    loadFeatures(props.inspection, props.asset, mandatoryInspectionFields, `${I18NEXT_PREFIX}serialNumberUndefined`)
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentFeatureInspectionId, setCurrentFeatureInspectionId] = useState<string | undefined>(undefined);

  useEffect(() => {
    setCurrentFeatureInspectionId(featureArray[currentIndex]?.featureInspection.id);
  }, [featureArray, currentIndex]);

  const [progressIndex, setProgressIndex] = useState(editable ? 0 : featureArray.length);

  const [denyInspectionOpen, setDenyInspectionOpen] = useState(false);
  const [denyFeatureOpen, setDenyFeatureOpen] = useState(false);
  const [disableHandleFeatureCheck, setDisableHandleFeatureCheck] = useState(false);

  const [featureEvalOpen, setFeatureEvalOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [addContractEndOfTermOpen, setAddContractEndOfTermOpen] = useState(false);

  const [updateInspection] = useMutation<updateInspectionMutation>(UPDATE_INSPECTION_MUTATION);
  const [setInspectionMandatoryAssetFields] = useMutation<setInspectionMandatoryFieldsMutation>(
    SET_INSPECTION_MANDATORY_ASSET_FIELDS_MUTATION
  );

  const displaySerialNumberButton = (): boolean => {
    return (
      !featureArray[currentIndex].isSubAsset &&
      featureArray[currentIndex].featureInspection.featureBlueprint.type == FeatureType.TAG &&
      isBlankOrEmpty(mandatoryInspectionFields.serialNumber)
    );
  };

  function handleFeatureCheck() {
    if (!displaySerialNumberButton()) {
      if (currentIndex === progressIndex) {
        setProgressIndex((prev) => prev + 1);
        setCurrentIndex((prev) => prev + 1);
      }
      setFeatureEvaluations([
        ...featureEvaluations,
        {
          reason: "",
          approved: true,
          title: "",
          featureInspectionId: featureArray[currentIndex].featureInspection.id,
        },
      ]);
    }
  }

  function handleButtonClickWhenDisabled() {
    setDisableHandleFeatureCheck(true);
  }

  const handleDenyFeature = (reason: string) => {
    setFeatureEvaluations([
      ...featureEvaluations,
      {
        reason: reason,
        approved: false,
        title: featureArray[currentIndex].featureInspection.featureBlueprint.title,
        featureInspectionId: featureArray[currentIndex].featureInspection.id,
      },
    ]);
  };

  function handleFeatureDeny(reason: string) {
    handleDenyFeature(reason);
    if (currentIndex === progressIndex) {
      setProgressIndex((prev) => prev + 1);
      setCurrentIndex((prev) => prev + 1);
    }
  }

  async function approveInspection(inspectionId: string): Promise<void> {
    await updateInspection({
      variables: {
        input: {
          inspectionId: inspectionId,
          status: InspectionStatus.APPROVED,
          messageToCustomer: null,
          rejectedFeatureInspections: [],
        },
      },
    });
  }

  async function rejectInspection(
    inspectionId: string,
    messageToCustomer: string,
    dueDate: Date,
    features: FeatureEvaluation[]
  ): Promise<void> {
    await updateInspection({
      variables: {
        input: {
          inspectionId: inspectionId,
          status: InspectionStatus.REJECTED,
          messageToCustomer: emptyToNull(messageToCustomer),
          dueDate: dueDate.toISOString(),
          rejectedFeatureInspections: features
            .filter((featureEvaluation) => !featureEvaluation.approved)
            .map((featureEvaluation) => ({
              featureInspectionId: featureEvaluation.featureInspectionId,
              rejectionReason: featureEvaluation.reason,
            })),
        },
      },
    });
  }

  async function updateMandatoryAssetFieldsForInspection(
    mandatoryInspectionFields: InspectionMandatoryAssetFormFields
  ): Promise<void> {
    await setInspectionMandatoryAssetFields({
      variables: {
        input: {
          assetId: mandatoryInspectionFields.assetId,
          serialNumber: mandatoryInspectionFields.serialNumber,
          contractEndOfTerm: mandatoryInspectionFields.contractEndOfTerm,
        },
      },
    });
  }

  const handleSubmit = async () => {
    if (isBlankOrEmpty(mandatoryInspectionFields.contractEndOfTerm)) {
      setAddContractEndOfTermOpen(true);
    } else {
      await completeSubmission();
    }
  };

  const completeSubmission = async () => {
    if (featureEvaluations.filter((m) => !m.approved).length > 0) {
      setDenyInspectionOpen(true);
    } else {
      setIsSubmitting(true);
      if (mandatoryInspectionFields.isDirty) {
        updateMandatoryAssetFieldsForInspection(mandatoryInspectionFields).then(() => {
          approveInspection(props.inspection.id!).then(() => {
            setIsClosing(true);
          });
        });
      } else {
        approveInspection(props.inspection.id!).then(() => {
          setIsClosing(true);
        });
      }
      setIsSubmitting(false);
    }
  };

  const handleDenyInspection = async (messageToCustomer: string, dueDate: Date) => {
    if (mandatoryInspectionFields.isDirty) {
      updateMandatoryAssetFieldsForInspection(mandatoryInspectionFields).then(() => {
        rejectInspection(props.inspection.id!, messageToCustomer, dueDate, featureEvaluations).then(() => {
          setIsClosing(true);
        });
      });
    } else {
      rejectInspection(props.inspection.id!, messageToCustomer, dueDate, featureEvaluations).then(() => {
        setIsClosing(true);
      });
    }
    setDenyInspectionOpen(false);
  };

  const handleDenyInspectionOnClose = () => {
    setDenyInspectionOpen(false);
  };

  const handleAddContractEndOfTermOnSubmit = async (contractEndOfTerm: Date) => {
    // Don't use the set method for mandatoryInspectionFields because we don't want
    // to re-render.
    mandatoryInspectionFields.contractEndOfTerm = formatDateToIsoDate(contractEndOfTerm);
    mandatoryInspectionFields.isDirty = true;
    setAddContractEndOfTermOpen(false);
    await completeSubmission();
  };

  const handleAddContractEndOfTermOnClose = () => {
    setAddContractEndOfTermOpen(false);
  };

  useEffect(() => {
    if (isClosing) {
      props.handleClose(true);
    }
  }, [isClosing]);

  useEffect(() => {
    setFeatureArray(
      loadFeatures(
        props.inspection,
        props.asset,
        mandatoryInspectionFields,
        t(`${I18NEXT_PREFIX}serialNumberUndefined`)
      )
    );
  }, [mandatoryInspectionFields]);

  const handleAddNewSerialNumber = (serialNumber: string) => {
    setMandatoryInspectionFields((prevState) => ({
      ...prevState,
      serialNumber: serialNumber,
      isDirty: true,
    }));
  };

  const getEvaluationForId = (featureInspectionId: string | undefined): FeatureEvaluation | undefined => {
    return featureEvaluations.find(
      (featureEvaluation) => featureEvaluation.featureInspectionId === featureInspectionId
    );
  };

  const resetEval = (featureInspectionId: string | undefined) => {
    setFeatureEvaluations(
      featureEvaluations.filter((featureEvaluation) => featureEvaluation.featureInspectionId != featureInspectionId)
    );
    setFeatureEvalOpen(false);
  };

  const headerText = `${t(`${I18NEXT_PREFIX}header`)} vom ${formatDateString(
    props.inspection.latestSubmissionDate || ""
  )}`;

  const generateButtons = () => {
    if (
      currentIndex < featureArray.length &&
      (currentIndex == progressIndex ||
        (currentIndex < progressIndex && !getEvaluationForId(currentFeatureInspectionId)))
    ) {
      return (
        <>
          <Button
            startIcon={<ClearIcon />}
            onClick={() => setDenyFeatureOpen(true)}
            disabled={isSubmitting}
            color="secondary"
          >
            {t(`${I18NEXT_PREFIX}feature-overview.report-problem`)}
          </Button>
          <Button
            startIcon={<CheckIcon />}
            onClick={
              isBlankOrEmpty(mandatoryInspectionFields.serialNumber)
                ? handleButtonClickWhenDisabled
                : handleFeatureCheck
            }
            disabled={
              isSubmitting || (isBlankOrEmpty(mandatoryInspectionFields.serialNumber) && disableHandleFeatureCheck)
            }
            color="primary"
          >
            {t(`${I18NEXT_PREFIX}feature-overview.ok`)}
          </Button>
        </>
      );
    } else {
      return (
        <Button startIcon={<InfoIcon />} onClick={() => setFeatureEvalOpen(true)} variant="outlined" color="secondary">
          {getEvaluationForId(currentFeatureInspectionId)?.approved
            ? t(`${I18NEXT_PREFIX}feature-evaluation.accepted`)
            : t(`${I18NEXT_PREFIX}feature-evaluation.rejected`)}
        </Button>
      );
    }
  };

  return (
    <Modal open={props.modalOpen} onClose={() => props.handleClose(false)}>
      <div className={classes.modalBody}>
        <div className={classes.modalWrapper}>
          <div className={classes.grid}>
            <div className={classes.header}>
              <Typography variant="h3">{headerText}</Typography>
              <InspectionModalNavigation
                hasPrevious={currentIndex > 0}
                hasNext={currentIndex < featureArray.length && currentIndex !== progressIndex}
                text={
                  currentIndex === featureArray.length
                    ? t(`${I18NEXT_PREFIX}overview.title`)
                    : `${t(`${I18NEXT_PREFIX}feature`)} ${currentIndex + 1}/${featureArray.length}`
                }
                onNext={() => setCurrentIndex(currentIndex + 1)}
                onPrevious={() => setCurrentIndex(currentIndex - 1)}
              />
              <div className={classes.headerButtons}>
                <Button
                  onClick={() => props.handleClose(false)}
                  variant="outlined"
                  color="secondary"
                  disabled={isSubmitting}
                >
                  {editable ? t(`${I18NEXT_PREFIX}buttons.cancel`) : t(`${I18NEXT_PREFIX}buttons.close`)}
                </Button>
                {editable && currentIndex === featureArray.length && (
                  <Button
                    onClick={async () => {
                      await handleSubmit();
                    }}
                    disabled={isSubmitting}
                    color="primary"
                  >
                    {t(`${I18NEXT_PREFIX}buttons.finish`)}
                  </Button>
                )}
                {currentIndex < featureArray.length && generateButtons()}
              </div>
            </div>
            <LinearProgress variant="determinate" value={Math.min((progressIndex / featureArray.length) * 100, 100)} />
            {currentIndex < featureArray.length ? (
              <FeatureInspectionComponent
                active={progressIndex === currentIndex}
                data={featureArray[currentIndex]}
                asset={props.asset}
                inspection={props.inspection}
                contract={props.inspection.contract}
                onDeny={(reason: string) => handleFeatureDeny(reason)}
                denyMessage={getEvaluationForId(currentFeatureInspectionId)?.reason}
                onTagsDoNotMatch={() =>
                  // Horrible idea. See related useState-Hook for more information.
                  setNotMatchingTagFeatureInspectionIds((prev) =>
                    prev.includes(featureArray[currentIndex].featureInspection.id)
                      ? prev
                      : [...prev, featureArray[currentIndex].featureInspection.id]
                  )
                }
                denyFeatureOpen={denyFeatureOpen}
                onDenyFeatureClose={() => setDenyFeatureOpen(false)}
                onAddSerialNumber={(serialNumber: string) => handleAddNewSerialNumber(serialNumber)}
                displayAddSerialNumberButton={displaySerialNumberButton()}
                shakeTheSerialNrButton={disableHandleFeatureCheck}
              />
            ) : (
              <InspectionOverview
                features={featureArray.map((feature) => ({
                  ...feature,
                  tag: feature.featureInspection.tag,
                  message: getEvaluationForId(feature.featureInspection.id),
                  tagsMatch: !notMatchingTagFeatureInspectionIds.includes(feature.featureInspection.id),
                }))}
                inspection={props.inspection}
                asset={props.asset}
              />
            )}
          </div>
        </div>
        <DenyInspectionDialog
          open={denyInspectionOpen}
          onClose={handleDenyInspectionOnClose}
          onConfirm={handleDenyInspection}
        />
        <PopUpDialog
          open={featureEvalOpen}
          onClose={() => setFeatureEvalOpen(false)}
          handleConfirm={() => setFeatureEvalOpen(false)}
          denyButtonDisabled={props.inspection.status !== InspectionStatus.WAITING_FOR_APPROVAL}
          header={
            getEvaluationForId(currentFeatureInspectionId)?.approved
              ? t(`${I18NEXT_PREFIX}feature-evaluation.accepted`)
              : t(`${I18NEXT_PREFIX}feature-evaluation.rejected`)
          }
          confirm={t(`${I18NEXT_PREFIX}feature-evaluation.close`)}
          handleDeny={() => resetEval(currentFeatureInspectionId)}
          deny={t(`${I18NEXT_PREFIX}feature-evaluation.reset-evaluation`)}
        >
          {getEvaluationForId(currentFeatureInspectionId)?.approved
            ? t(`${I18NEXT_PREFIX}feature-evaluation.this-feature-was-approved`)
            : t(`${I18NEXT_PREFIX}feature-evaluation.rejected-because`, {
              reason: getEvaluationForId(currentFeatureInspectionId)?.reason,
            })}
        </PopUpDialog>
        <AddContractEndOfTermDialog
          open={addContractEndOfTermOpen}
          handleClose={handleAddContractEndOfTermOnClose}
          handleSubmit={handleAddContractEndOfTermOnSubmit}
          contractNumber={props.inspection.contract.contractNumber}
          contractDate={props.inspection.contract.startDate}
        />
      </div>
    </Modal>
  );
};
