import { useApolloClient, useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

import React, { useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Box, Typography } from "@material-ui/core";

import { SnackContext } from "../../../../routing/idfyRouter";
import { routes } from "../../../../routing/routes";
import { InfoPageHeader } from "../../../pageFragments/util/InfoPageHeader";
import { PopUpDialog } from "../../../pageFragments/util/PopUpDialog";
import { usePrefixedTranslation } from "../../../../util/usePrefixedTranslation";
import { assetInfoValidationSchema } from "./AssetInfoValidationSchema";
import { AssetInfoFormData } from "./AssetInfoFormData";
import { DELETE_ASSET_MUTATION, deleteAsset, UPDATE_ASSET_MUTATION, updateAsset } from "./AssetInfoApi";
import { AssetInfoBaseFields } from "./form-fields/AssetInfoBaseFields";
import { AssetInfoSupplierField } from "./form-fields/AssetInfoSupplierField";
import { AssetInfoDownloads } from "./form-fields/AssetInfoDownloads";
import { useAssetInfoStyles } from "./style/AssetInfoStyles";
import { updateAssetMutation } from "./__generated__/updateAssetMutation";
import { AssetInfoOffsiteLocationFields } from "./form-fields/AssetInfoOffsiteLocationFields";
import { isBlankOrEmpty } from "../../../../util/stringUtil";
import { AssetOrSubAssetAlertReason } from "../../../../components/DuplicateAssetOrSubAssetAlert";
import { assetDetailQuery_asset } from "../../api/__generated__/assetDetailQuery";
import { deleteAssetMutation } from "./__generated__/deleteAssetMutation";
import {
  assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBoth,
  assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBothVariables,
} from "../../api/__generated__/assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBoth";
import { ASSETS_AND_SUB_ASSETS_BY_MANUFACTURER_AND_SERIAL_NUMBER_QUERY } from "../../api/assetQueriesAndMutations";
import { AssetInfoContractTable } from "./form-fields/AssetInfoContractTable";
import { AccordionDivider } from "../../../../components/accordion/AccordionDivider";
import { CustomerDetailsSectionTable } from "../../../customers/CustomerDetailsSectionTable";

export const AssetInfo: React.FunctionComponent<{
  asset: assetDetailQuery_asset;
  onRefetch: () => void;
}> = (props) => {
  const classes = useAssetInfoStyles();
  const { t } = useTranslation();
  const { pt } = usePrefixedTranslation("pages.asset-details.information.");
  const history = useHistory();
  const snackContext = useContext(SnackContext);

  // Indicates whether another asset with same manufacturer and ID exists
  const [manufacturerAndSerialInUse, setManufacturerAndSerialInUse] = useState({ inUse: false, assetId: "-1" });
  const [duplicateReason, setDuplicateReason] = useState<AssetOrSubAssetAlertReason | undefined>(undefined);

  const [disabled, setDisabled] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const initialValues = useMemo(() => {
    return {
      manufacturerId: props.asset.manufacturer?.id,
      name: props.asset.name,
      isSerialNumberUnknown: isBlankOrEmpty(props.asset.serialNumber),
      serialNumber: props.asset.serialNumber,
      contractNumber: props.asset.contractNumber,
      contractDate: props.asset.contractDate,
      isContractEndOfTermUnknown: isBlankOrEmpty(props.asset.contractEndOfTerm),
      contractEndOfTerm: props.asset.contractEndOfTerm,
      supplierId: props.asset.supplier?.id,
      isOffsiteLocation: props.asset.offsiteLocation !== null,
      offsiteLocation: props.asset.offsiteLocation || { name: "", streetAddress: "", zip: "", city: "" },
      supplierInvoiceNumber: props.asset.supplierInvoiceNumber || "",
      invoiceDate: props.asset.invoiceDate || null,
    };
  }, [props.asset]);

  const [updateAssetMutationCall] = useMutation<updateAssetMutation>(UPDATE_ASSET_MUTATION);

  async function onSubmit(values: AssetInfoFormData) {
    const result = await updateAsset(values, props.asset.id, updateAssetMutationCall);
    snackContext.setSnackOpen(!result.errors);
    snackContext.setSnackText(pt("asset-changed"));
    setDisabled(true);
    props.onRefetch();
  }

  const [deleteAssetMutationCall] = useMutation<deleteAssetMutation>(DELETE_ASSET_MUTATION);

  async function onDelete() {
    const result = await deleteAsset(props.asset.id, deleteAssetMutationCall);
    snackContext.setSnackOpen(!result.errors);
    snackContext.setSnackText(pt("asset-deleted"));
    history.push(routes.assets);
  }

  const client = useApolloClient();
  const assetsAndSubAssetsByManufacturerAndSerialNumber = async (manufacturerId: string, serialNumber: string) => {
    return client.query<
      assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBoth,
      assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBothVariables
    >({
      query: ASSETS_AND_SUB_ASSETS_BY_MANUFACTURER_AND_SERIAL_NUMBER_QUERY,
      variables: { manufacturerId: manufacturerId, serialNumber: serialNumber },
    });
  };

  const form = useForm<AssetInfoFormData>({
    defaultValues: initialValues,
    resolver: yupResolver(assetInfoValidationSchema(t, pt, props.asset.isAlreadyInStock)),
  });

  const manufacturerId = form.watch("manufacturerId");
  const serialNumber = form.watch("serialNumber");
  const isSerialNumberUnknownWatch = form.watch("isSerialNumberUnknown");
  const isContractEndOfTermUnknownWatch = form.watch("isContractEndOfTermUnknown");

  useEffect(() => {
    if (manufacturerId && serialNumber) {
      assetsAndSubAssetsByManufacturerAndSerialNumber(manufacturerId, serialNumber).then((result) => {
        const otherAssetsWithSameManufacturerAndSerialNumber = result.data?.assetsByManufacturerAndSerialNumber.filter(
          (asset) => {
            return asset.id !== props.asset.id;
          }
        );
        if (otherAssetsWithSameManufacturerAndSerialNumber.length > 0) {
          setManufacturerAndSerialInUse({
            inUse: true,
            assetId: result.data.assetsByManufacturerAndSerialNumber[0].id,
          });
          form.setError("manufacturerId", {});
          form.setError("serialNumber", {});
          setDuplicateReason("SerialNumberUsedInExistingAsset");
        } else if (result.data.subAssetsByManufacturerAndSerialNumber.length > 0) {
          setManufacturerAndSerialInUse({
            inUse: true,
            assetId: result.data?.subAssetsByManufacturerAndSerialNumber[0]?.assetId,
          });

          form.setError("manufacturerId", {});
          form.setError("serialNumber", {});
          setDuplicateReason("SerialNumberUsedInExistingSubAsset");
        } else {
          setManufacturerAndSerialInUse({ inUse: false, assetId: "-1" });
          form.clearErrors("manufacturerId");
          form.clearErrors("serialNumber");
        }
      });
    }
  }, [manufacturerId, serialNumber]);

  const [isSerialNumberDisabled, setIsSerialNumberDisabled] = useState(false);
  useEffect(() => {
    if (isSerialNumberUnknownWatch) {
      form.setValue("serialNumber", "");
      setIsSerialNumberDisabled(true);
    } else {
      setIsSerialNumberDisabled(false);
    }
  }, [isSerialNumberUnknownWatch]);

  const [isContractEndOfTermDisabled, setIsContractEndOfTermDisabled] = useState(false);
  useEffect(() => {
    if (isContractEndOfTermUnknownWatch) {
      form.setValue("contractEndOfTerm", null);
      setIsContractEndOfTermDisabled(true);
    } else {
      setIsContractEndOfTermDisabled(false);
    }
  }, [isContractEndOfTermUnknownWatch]);

  useEffect(() => {
    form.register("contractDate");
    form.register("contractEndOfTerm");
    form.register("contractNumber");
  }, [form.register]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit((data) => onSubmit(data))} noValidate>
        <InfoPageHeader
          setDisabled={setDisabled}
          disabled={disabled}
          header={pt("header")}
          handleDelete={() => setDeleteModalOpen(true)}
          deleteText={pt("delete")}
          onCancel={() => {
            setManufacturerAndSerialInUse({ inUse: false, assetId: "-1" });
            form.reset(initialValues);
          }}
          saveButtonDisabled={manufacturerAndSerialInUse.inUse}
        />
        <AssetInfoBaseFields
          manufacturerAndSerialInUse={manufacturerAndSerialInUse}
          disabled={disabled}
          isSerialNumberDisabled={isSerialNumberDisabled}
          isContractEndOfTermDisabled={isContractEndOfTermDisabled}
          duplicateReason={duplicateReason}
        />
        <Box width={"100%"} height={"10vh"} />
        <AssetInfoContractTable
          contract={props.asset.currentContract}
          breadcrumbTitle={t("pages.asset-details.customer.asset") + props.asset.name}
        />
        <AccordionDivider forceExpanded={!disabled} title={pt("documents-and-supplier")}>
          {props.asset.acceptanceDeclarations.length > 0 && <AssetInfoDownloads asset={props.asset} />}
          <AssetInfoSupplierField disabled={disabled} isAlreadyInStock={props.asset.isAlreadyInStock} />
        </AccordionDivider>
        <CustomerDetailsSectionTable
          customer={props.asset.currentContract?.customer}
          breadcrumbTitle={t("pages.asset-details.customer.asset") + props.asset.name}
        />
        {props.asset.currentContract && (
          <AccordionDivider forceExpanded={!disabled} title={pt("object-location")}>
            <AssetInfoOffsiteLocationFields disabled={disabled} asset={props.asset} />
          </AccordionDivider>
        )}
      </form>

      <PopUpDialog
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        handleConfirm={onDelete}
        handleDeny={() => setDeleteModalOpen(false)}
        header={pt("delete-dialog.header")}
        confirm={pt("delete-dialog.confirm")}
        deny={pt("delete-dialog.deny")}
        classes={{ confirmButton: classes.confirmButton }}
      >
        <Typography variant={"body2"}>
          {pt("delete-dialog.message", {
            name: props.asset.name,
            "serial-number": props.asset.serialNumber,
          })}
        </Typography>
      </PopUpDialog>
    </FormProvider>
  );
};
