import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Button, CircularProgress, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { idfyTheme } from "../../../util/IdfyTheme";
import { BreadcrumbContext, SnackContext } from "../../../routing/idfyRouter";
import { emptyToNull } from "../../../util/stringUtil";
import { BlueprintStatus, InspectionStatus } from "../../../__generated__/globalTypes";
import { InspectionPreview } from "./inspection/InspectionPreview";
import { routes } from "../../../routing/routes";
import { InitiateInspectionDialog } from "./InitiateInspectionDialog";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { INITIATE_INSPECTION_MUTATION, INSPECTION_QUERY } from "./inspection/api/inspectionQueriesAndMutations";
import { inspectionQuery } from "./inspection/api/__generated__/inspectionQuery";
import { AssetData } from "../api/__generated__/AssetData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inspectionWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    inspectionPreview: {
      width: "100%",
    },
    mobileButton: {
      width: "100%",
      marginBottom: theme.spacing(4),
    },
    informationHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(2),
      },
    },
    progressContainer: {
      width: "100%",
      textAlign: "center",
    },
    progress: {
      position: "absolute",
      top: "50%",
    },
  })
);

function mayCreateNewInspection(data: { asset: AssetData | undefined }): boolean {
  if (!data?.asset?.inspections || data?.asset?.inspections.length === 0) {
    return true;
  }
  const lastInspectionStatus = data.asset.inspections[data.asset.inspections.length - 1].status;
  return !(
    lastInspectionStatus === InspectionStatus.PENDING || lastInspectionStatus === InspectionStatus.WAITING_FOR_APPROVAL
  );
}

export const InspectionOverview: React.FunctionComponent<{ id: string; onRefetch: () => void }> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { data, loading, refetch } = useQuery<inspectionQuery>(INSPECTION_QUERY, { variables: { id: props.id } });

  const [initiateInspection] = useMutation(INITIATE_INSPECTION_MUTATION);

  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));

  const [initiateInspectionDialogOpen, setInitiateInspectionDialogOpen] = useState(false);

  const snackContext = useContext(SnackContext);
  const breadcrumbContext = useContext(BreadcrumbContext);

  const handleConfirm = async (messageToCustomer: string, date: Date | null) => {
    await initiateInspection({
      variables: {
        assetId: props.id,
        messageToCustomer: emptyToNull(messageToCustomer),
        date: date!.toISOString(),
      },
    });

    await refetch();
    snackContext.setSnackOpen(true);
    snackContext.setSnackText(t("pages.asset-details.inspection-overview.create-new-inspection-dialog.snack"));
    setInitiateInspectionDialogOpen(false);
  };

  const handleOnInspectionSubmit = async () => {
    // Load the data in this page.
    await refetch();
    // Call out to the parent page so that the sidebar can be updated.
    props.onRefetch();
  };

  return (
    <React.Fragment>
      {data && mobile ? (
        <Button
          color="secondary"
          className={classes.mobileButton}
          onClick={() => setInitiateInspectionDialogOpen(true)}
          disabled={!mayCreateNewInspection(data)}
        >
          {t("pages.asset-details.inspection-overview.buttons.create-new-inspection")}
        </Button>
      ) : (
        data && (
          <div className={classes.informationHeader}>
            <Typography variant={"h3"} style={{ marginRight: idfyTheme.spacing(2) }}>
              {t("pages.asset-details.inspection-overview.header")}
            </Typography>
            <Button
              color={"primary"}
              onClick={() => setInitiateInspectionDialogOpen(true)}
              disabled={!mayCreateNewInspection(data)}
            >
              {t("pages.asset-details.inspection-overview.buttons.create-new-inspection")}
            </Button>
          </div>
        )
      )}
      <div className={classes.inspectionWrapper}>
        {data && data.asset ? (
          data.asset.blueprint && (
            <Grid container spacing={2}>
              {data.asset.blueprint.status === BlueprintStatus.APPROVED
                ? data.asset.inspections
                    .slice(0)
                    .reverse()
                    .map((inspection, i) => (
                      <Grid item xs={12} key={i}>
                        <InspectionPreview
                          inspection={inspection}
                          key={i}
                          isInitial={i === (data?.asset?.inspections.length || 0) - 1}
                          startOpen={i === 0}
                          asset={data?.asset}
                          onInspectionSubmit={handleOnInspectionSubmit}
                        />
                      </Grid>
                    ))
                : data &&
                  data.asset &&
                  data.asset.blueprint && (
                    <>
                      <Typography variant={"body1"} align={"center"} style={{ width: "100%", margin: "16px 0" }}>
                        {t("pages.asset-details.inspection-overview.blueprint-not-created-yet")}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Button
                          color={"primary"}
                          onClick={() => {
                            breadcrumbContext.breadcrumbs.push({
                              url: history.location.pathname || "errorPage",
                              title: `Objekt: ${data?.asset?.name || ""}`,
                            });
                            history.push(
                              routes.blueprints + routes.details(data?.asset?.blueprint?.id || "") + routes.infoSuffix
                            );
                          }}
                        >
                          {t("pages.asset-details.inspection-overview.buttons.to-blueprint")}
                        </Button>
                      </div>
                    </>
                  )}
            </Grid>
          )
        ) : loading ? (
          <div className={classes.progressContainer}>
            <CircularProgress className={classes.progress} />
          </div>
        ) : null}
      </div>
      <InitiateInspectionDialog
        withoutContract={data?.asset.currentContract == null}
        open={initiateInspectionDialogOpen}
        onClose={() => setInitiateInspectionDialogOpen(false)}
        onConfirm={handleConfirm}
      />
    </React.Fragment>
  );
};
