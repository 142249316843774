import { TFunction } from "i18next";
import { SearchSelectOption } from "../SearchSelectField";
import * as yup from "yup";
import { AccountDto } from "../../util/auth/useCurrentAccount";
import { Team } from '../../__generated__/globalTypes';
import { SchemaOf } from "yup";

export enum TeamFilter {
  NORTH = "NORTH",
  SOUTH = "SOUTH",
  ALL = "ALL",
  ASSET_MONITORING = "ASSET_MONITORING"
}

const TEAM_FILTERS: TeamFilter[] = Object.values(TeamFilter);

export function getTeamsSchema(includeAllTeam: boolean): SchemaOf<TeamFilter> {
  return yup.mixed().oneOf(Object.values(getTeams(includeAllTeam)));
}

export function getTeams(includeAllTeam: boolean): TeamFilter[] {
  return includeAllTeam ? TEAM_FILTERS : TEAM_FILTERS.filter((team) => team !== TeamFilter.ALL);
}

function getTeamOptionsLabel(key: TeamFilter, t: TFunction): string {
  return t("Team.title") + ": " + t("Team." + TeamFilter[key]);
}

export function getTeamFilterOptions(t: TFunction): SearchSelectOption[] {
  return Object.values(TeamFilter).map((key) => ({
    value: key,
    label: getTeamOptionsLabel(key, t),
  }));
}

export function getTeamFilterForUser(account?: AccountDto): TeamFilter {
  switch (account?.user?.team) {
    case "NORTH":
      return TeamFilter.NORTH
    case "SOUTH": 
      return TeamFilter.SOUTH
    case "ASSET_MONITORING": 
      return TeamFilter.ASSET_MONITORING
    default:
      return TeamFilter.ALL;
  }
}

export function convertTeamFilterToTeam(teamFilter: TeamFilter): Team {
  switch (teamFilter) {
    case "NORTH":
      return Team.NORTH
    case "SOUTH": 
      return Team.SOUTH
    case "ASSET_MONITORING": 
      return Team.ASSET_MONITORING
    default:
      return Team.ALL;
  }
}
