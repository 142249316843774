import React, { ReactElement, useState } from "react";
import { PopUpDialog } from "../../../pageFragments/util/PopUpDialog";
import { usePrefixedTranslation } from "../../../../util/usePrefixedTranslation";
import { TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Trans } from "react-i18next";
import { useInfoBoxStyles } from "../../../../util/IdfyTheme";

export function RevokeInspectionDialog(props: {
  onConfirm: (internalNote: string | undefined) => void;
  onClose: () => void;
  open: boolean;
}): ReactElement {
  const { pt } = usePrefixedTranslation("pages.asset-details.inspection-overview.revoke-inspection-dialog.");
  const classes = useInfoBoxStyles();
  const [internalNote, setInternalNote] = useState<string>();

  return (
    <PopUpDialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      handleConfirm={() => {
        props.onConfirm(internalNote);
      }}
      header={pt("header")}
      confirm={pt("confirm")}
      deny={pt("deny")}
      handleDeny={() => {
        props.onClose();
      }}
    >
      <Alert severity="info" color={"warning"} className={classes.infoBox}>
        <Trans t={pt} i18nKey="info-text" />
      </Alert>
      <TextField
        variant={"outlined"}
        multiline
        fullWidth={true}
        value={internalNote}
        onChange={(text) => setInternalNote(text.target.value)}
        minRows={4}
        maxRows={4}
        aria-label="internal note"
        placeholder={pt("internal-note-label")}
        style={{ marginTop: 24 }}
      />
    </PopUpDialog>
  );
}
