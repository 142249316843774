import React, { ReactElement } from "react";
import { IDFyColumn, IDFyTable } from "../../components/table/IDFyTable";
import { Account } from "./api/__generated__/Account";

const columns = [
  {
    name: "firstName",
    titleKey: "pages.customer-details.information.fields.firstName",
    render: (rowData) => rowData.firstName,
  },
  {
    name: "firstName",
    titleKey: "pages.customer-details.information.fields.lastName",
    render: (rowData) => rowData.lastName,
  },
  {
    name: "email",
    titleKey: "pages.customer-details.information.fields.email",
    render: (rowData) => rowData.email,
  },
  {
    name: "role",
    titleKey: "pages.customer-details.information.fields.role",
    render: (rowData) => rowData.role,
  },
] as IDFyColumn<Account>[];

export function SubAccountTable(props: { accounts: Account[] }): ReactElement {
  return <IDFyTable columns={columns} data={props.accounts} />;
}
