import React, { FC } from "react";

import PersonIcon from "@material-ui/icons/Person";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";

import { InspectionType } from "../../../../../__generated__/globalTypes";
import { usePrefixedTranslation } from "../../../../../util/usePrefixedTranslation";
import { AssetProtocolEntry } from "../AssetProtocolEntry";

export interface InspectionApprovedEventEntryProps {
  time: Date;
  inspectionType: InspectionType;
  user: {
    firstName: string;
    lastName: string;
  };
}

export const InspectionApprovedEventEntry: FC<InspectionApprovedEventEntryProps> = (props) => {
  const { pt } = usePrefixedTranslation("pages.asset-details.protocol.");

  const type = pt("inspection-type." + props.inspectionType);

  return (
    <AssetProtocolEntry
      icon={<ThumbUpAltOutlinedIcon />}
      title={pt("inspection-approved.title", { type })}
      time={props.time}
      fields={[
        {
          text: `${props.user.lastName}, ${props.user.firstName}`,
          tooltip: pt("inspection-approved.tooltip-user", { type }),
          icon: <PersonIcon />,
        },
      ]}
    />
  );
};
