import React from "react";
import { useTranslation } from "react-i18next";

import { createStyles, Divider, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import LogoutIcon from "../../../components/icons/Logout";

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      marginTop: 8,
    },
    listIcon: {
      fill: "#555",
    },
    listText: {
      marginLeft: -16,
    },
  })
);

export interface UserPopupButtonsProps {
  onHelpClick: () => void;
  onLogoutClick: () => void;
}

export const UserPopupButtons: React.FunctionComponent<UserPopupButtonsProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <List className={classes.list}>
      <Divider />
      <ListItem button onClick={props.onLogoutClick}>
        <ListItemIcon>
          <LogoutIcon className={classes.listIcon} />
        </ListItemIcon>
        <ListItemText className={classes.listText} primary={t("appbar.user-button.popup.logout")} />
      </ListItem>
      <Divider />
    </List>
  );
};
