import React from "react";
import { useTranslation } from "react-i18next";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import InfoIcon from "@material-ui/icons/Info";
import StarsIcon from "@material-ui/icons/Stars";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import { DetailsMenu, DetailsMenuSubPage } from "../../../components/DetailsMenu";
import { routes } from "../../../routing/routes";
import { Customer } from "../Customer";
import PersonIcon from "@material-ui/icons/Person";

export const CustomerMenu: React.FunctionComponent<{
  customer: Customer;
}> = (props) => {
  const { t } = useTranslation();

  const buttons: DetailsMenuSubPage[] = [
    {
      label: t("pages.customer-details.menu.buttons.information"),
      icon: InfoIcon,
      path: routes.infoSuffix,
    },
    {
      label: t("pages.customer-details.menu.buttons.objects"),
      icon: StarsIcon,
      path: routes.assetsSuffix,
    },
    {
      label: t("pages.customer-details.menu.buttons.contracts"),
      icon: DescriptionOutlinedIcon,
      path: routes.contractsSuffix,
    },
    {
      label: t("pages.customer-details.menu.buttons.sub-accounts"),
      icon: PersonIcon,
      path: routes.subAccountsSuffix,
    },
  ];

  if (!props.customer.registrationComplete) {
    buttons.push({
      label: t("pages.customer-details.menu.buttons.registration"),
      icon: HowToRegIcon,
      path: routes.registrationSuffix,
    });
  }

  return (
    <DetailsMenu
      title={props.customer.company}
      subtitle={t("pages.customer-details.menu.customer-number") + props.customer.customerNumber}
      icon={(className) => <AccountCircleIcon className={className} />}
      subPages={buttons}
      subPageActiveCheck={(button) =>
        window.location.pathname === routes.customers + routes.details(props.customer.id) + button.path
      }
      routeFunction={(id) => routes.customers + routes.details(id)}
    />
  );
};
