import React from "react";

import { createSvgIcon } from "@material-ui/core";

export default createSvgIcon(
  <>
    <defs>
      <clipPath id="b">
        <rect width="24" height="24" />
      </clipPath>
    </defs>
    <g id="a" clipPath="url(#b)">
      <path d="M0,0H24V24H0Z" fill="none" />
      <path
        d="M15.335,19.169H6.347a5.687,5.687,0,0,0,1.025-1.917h7.963V3.834H1.917v6H1.9a5.712,5.712,0,0,0-1.9.322V3.834A1.833,1.833,0,0,1,.153,3.1a1.936,1.936,0,0,1,1.38-1.14,1.638,1.638,0,0,1,.383-.039H5.923a2.864,2.864,0,0,1,5.405,0h4.006a1.919,1.919,0,0,1,1.917,1.917V17.252A1.919,1.919,0,0,1,15.335,19.169ZM8.625,1.678a.719.719,0,0,0,0,1.437.719.719,0,0,0,0-1.437Zm1.917,13.658H7.62a5.694,5.694,0,0,0-.411-1.917h3.332v1.916ZM13.418,11.5H5.936a5.764,5.764,0,0,0-2.1-1.33V9.585h9.584V11.5Zm0-3.834H3.834V5.751h9.584V7.667Z"
        transform="translate(5.998 0.751)"
      />
      <path
        d="M8.782,2a6.79,6.79,0,1,0,6.8,6.79A6.786,6.786,0,0,0,8.782,2Zm2.879,10.864L8.789,11.132,5.917,12.864,6.678,9.6,4.145,7.4l3.34-.285,1.3-3.083,1.3,3.076,3.34.285L10.9,9.591Z"
        transform="translate(-0.954 7.589)"
      />
    </g>
  </>,
  "BlueprintIcon"
);
