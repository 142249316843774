import DateFnsUtils from "@date-io/date-fns";
import addDays from "date-fns/addDays";
import { de } from "date-fns/locale";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { PopUpDialog } from "../../../../pageFragments/util/PopUpDialog";

export interface DenyInspectionDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (message: string, dueDate: Date) => void;
}

export const DenyInspectionDialog: React.FunctionComponent<DenyInspectionDialogProps> = (props) => {
  const { t } = useTranslation();

  const [messageToCustomer, setMessageToCustomer] = useState<string>("");

  const [useFixedDueDate, setUseFixedDueDate] = useState(true);
  const [dueDate, setDueDate] = useState<Date>(addDays(new Date(), 14));

  const I18NEXT_DENY_PREFIX = "pages.asset-details.inspection-overview.modals.normal-inspection.";
  const I18NEXT_CREATE_PREFIX = "pages.asset-details.inspection-overview.create-new-inspection-dialog.";

  return (
    <PopUpDialog
      open={props.open}
      onClose={props.onClose}
      handleConfirm={() => props.onConfirm(messageToCustomer, dueDate)}
      header={t(`${I18NEXT_DENY_PREFIX}deny-inspection-dialog.header`)}
      confirm={t(`${I18NEXT_DENY_PREFIX}deny-inspection-dialog.deny-inspection`)}
    >
      <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
        <Grid item xs={12} md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
            <DatePicker
              value={dueDate}
              minDate={new Date()}
              onChange={(date) => setDueDate(date ?? new Date())}
              fullWidth
              format={t(`${I18NEXT_CREATE_PREFIX}date-format`)}
              inputVariant="outlined"
              color="secondary"
              disabled={useFixedDueDate}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" color="secondary" fullWidth>
            <InputLabel id="demo-simple-select-helper-label">{t(`${I18NEXT_CREATE_PREFIX}deadline`)}</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              label={t(`${I18NEXT_CREATE_PREFIX}deadline`)}
              defaultValue={14}
              onChange={(e) => {
                setDueDate(addDays(new Date(), 14));
                if (e.target.value === 14) {
                  setUseFixedDueDate(true);
                } else {
                  setUseFixedDueDate(false);
                }
              }}
            >
              <MenuItem value={0}>{t(`${I18NEXT_CREATE_PREFIX}self-select-date`)}</MenuItem>
              <MenuItem value={14}>{t(`${I18NEXT_CREATE_PREFIX}14-days`)}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={(e) => setMessageToCustomer(e.target.value)}
            value={messageToCustomer}
            color="secondary"
            variant="outlined"
            label={t(`${I18NEXT_DENY_PREFIX}deny-inspection-dialog.text-field`)}
            fullWidth
            multiline
            rows={8}
          />
        </Grid>
      </Grid>
    </PopUpDialog>
  );
};
