import React from "react";
import { Alert } from "@material-ui/lab";
import { usePrefixedTranslation } from "../util/usePrefixedTranslation";
import { Link } from "react-router-dom";
import { routes } from "../routing/routes";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    icon: {
      alignSelf: "center",
    },
  })
);

export type AssetOrSubAssetAlertReason =
  | "SerialNumberUsedInExistingAsset"
  | "SerialNumberUsedInExistingSubAsset"
  | "SerialNumberUsedInCurrentAsset";

export const DuplicateAssetOrSubAssetAlert: React.FunctionComponent<{
  assetId?: string;
  reason: AssetOrSubAssetAlertReason;
}> = (props) => {
  const classes = useStyles();
  const { pt } = usePrefixedTranslation("duplicateAssetAlert.");

  function getAlertText(): string {
    if (props.reason === "SerialNumberUsedInExistingAsset") {
      return pt("manufacturerAndSerialAlreadyInUseAsset");
    } else if (props.reason === "SerialNumberUsedInExistingSubAsset") {
      return pt("manufacturerAndSerialAlreadyInUseSubAsset");
    } else if (props.reason === "SerialNumberUsedInCurrentAsset") {
      return pt("subAssetSerialNrCantBeSameAsAsset");
    } else {
      return "ERROR";
    }
  }

  return (
    <Alert severity="error" classes={{ icon: classes.icon }}>
      {getAlertText()}
      {props.assetId && props.reason === "SerialNumberUsedInExistingAsset" ? (
        <Link to={routes.assets + routes.details(props.assetId) + "/info"} target="_blank">
          {pt("openObject")}
        </Link>
      ) : props.assetId && props.reason === "SerialNumberUsedInExistingSubAsset" ? (
        <Link to={routes.assets + routes.details(props.assetId) + "/subassets"} target="_blank">
          {pt("openObject")}
        </Link>
      ) : (
        <></>
      )}
    </Alert>
  );
};
