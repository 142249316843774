import React, { ReactNode } from "react";

import { Button, createStyles, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: "65px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
      "_::-webkit-full-page-media, _:future, :root .safari_only": {
        maxHeight: "-webkit-fit-content",
        height: "-webkit-fill-available",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        display: "block",
        height: "unset",
      },
    },
    titleButtonContainer: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      [theme.breakpoints.down("sm")]: {
        fontSize: 22,
        fontWeight: 700,
        margin: 0,
      },
    },
    button: {
      marginLeft: theme.spacing(2),
    },
  })
);

export interface TableHeaderProps {
  title: string;
  buttonText?: string; // Button will be hidden when no button text is set
  onButtonClick?: () => void;
  additionalContent?: ReactNode;
  enabled: boolean;
}

export const TableHeader: React.FunctionComponent<TableHeaderProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.titleButtonContainer}>
        <Typography variant={"h3"} className={classes.title}>
          {props.title}
        </Typography>
        {props.buttonText && (
          <Button color="secondary" className={classes.button} onClick={props.onButtonClick} disabled={!props.enabled}>
            {props.buttonText}
          </Button>
        )}
      </div>
      {props.additionalContent && <>{props.additionalContent}</>}
    </div>
  );
};
