import { useQuery } from "@apollo/client";
import DateFnsUtils from "@date-io/date-fns";
import { de } from "date-fns/locale";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Info } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { BlueprintStatus } from "../../../__generated__/globalTypes";
import { idfyTheme } from "../../../util/IdfyTheme";
import { emptyToNull } from "../../../util/stringUtil";
import { CreateAssetInput, CreateAssetInputFormFields } from "./CreateAssetInput";
import { BLUEPRINT_STATUS_QUERY } from "../../blueprints/api/blueprintQueriesAndMutations";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formGrid: {
      boxSizing: "border-box",
      paddingTop: theme.spacing(3),
    },
    infoBox: {
      color: "#4c7f93",
      backgroundColor: "rgb(232, 244, 253)",
    },
    informationTextContainer: {
      display: "flex",
      width: `calc(100% - ${idfyTheme.spacing(3) * 2}px)`,
      margin: idfyTheme.spacing(3),
      padding: idfyTheme.spacing(3),
      backgroundColor: idfyTheme.palette.info.light,
      borderRadius: idfyTheme.spacing(3),
      alignItems: "center",
    },
    informationText: {
      marginLeft: idfyTheme.spacing(3),
    },
  })
);

export const CreateAssetDueDate: React.FunctionComponent<{
  assetInput: CreateAssetInput;
  setAssetInput: React.Dispatch<React.SetStateAction<CreateAssetInputFormFields>>;
  setFormSubmit: (fun: () => () => boolean) => void;
}> = (props) => {
  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const classes = useStyles();

  const [fixedDate, setFixedDate] = useState<boolean>(true);
  const [inspectionDate, setInspectionDate] = useState<Date | null>(new Date(new Date().getTime() + 14 * 8.64e7));
  const [messageToCustomer, setMessageToCustomer] = useState("");

  const setFormSubmit = props.setFormSubmit;
  const setAssetInput = props.setAssetInput;

  useEffect(() => {
    setFormSubmit(() => () => {
      setAssetInput((prevState) => ({
        ...prevState,
        inspection: {
          ...prevState.inspection,
          dueDate: inspectionDate?.toISOString() || "",
          messageToCustomer: emptyToNull(messageToCustomer),
        },
      }));
      return true;
    });
  }, [inspectionDate, setFormSubmit, setAssetInput, messageToCustomer]);

  const { data } = useQuery<{ blueprint: { status: BlueprintStatus } }>(BLUEPRINT_STATUS_QUERY, {
    variables: { id: props.assetInput.asset.blueprintId },
  });

  return props.assetInput.asset.blueprintId !== null ? (
    data && data.blueprint.status === BlueprintStatus.APPROVED.toString() ? (
      <Grid container spacing={3} justifyContent={"space-around"} className={classes.formGrid}>
        <Grid item xs={12}>
          <Typography variant={"h3"}>
            {t("pages.asset-details.inspection-overview.create-new-inspection-dialog.inspection-date")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h3"}>
            <Alert severity="info" color="info" className={classes.infoBox}>
              <strong>{t("pages.asset-details.inspection-overview.create-new-inspection-dialog.info-text")}</strong>
            </Alert>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
            <DatePicker
              value={inspectionDate}
              minDate={new Date()}
              onChange={(date) => setInspectionDate(date)}
              fullWidth
              format={t("pages.asset-details.inspection-overview.create-new-inspection-dialog.date-format")}
              inputVariant={"outlined"}
              color={"secondary"}
              disabled={fixedDate}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" color={"secondary"} fullWidth>
            <InputLabel id="demo-simple-select-helper-label">
              {t("pages.asset-details.inspection-overview.create-new-inspection-dialog.deadline")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              label={t("pages.asset-details.inspection-overview.create-new-inspection-dialog.deadline")}
              defaultValue={14}
              onChange={(e) => {
                setInspectionDate(new Date(new Date().getTime() + Number(e.target.value) * 8.64e7));
                if (e.target.value === 14) {
                  setFixedDate(true);
                } else {
                  setFixedDate(false);
                }
              }}
            >
              <MenuItem value={0}>
                {t("pages.asset-details.inspection-overview.create-new-inspection-dialog.self-select-date")}
              </MenuItem>
              <MenuItem value={14}>
                {t("pages.asset-details.inspection-overview.create-new-inspection-dialog.14-days")}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={(e) => setMessageToCustomer(e.target.value)}
            color={"secondary"}
            variant={"outlined"}
            label={t("pages.asset-details.inspection-overview.create-new-inspection-dialog.message-for-customer")}
            fullWidth
            style={!mobile ? { width: "100%" } : undefined}
            multiline
            rows={8}
          />
        </Grid>
      </Grid>
    ) : (
      <div className={classes.informationTextContainer}>
        <Info />
        <Typography variant={"h6"} className={classes.informationText}>
          {t("pages.assets.modals.create-asset.informational-texts.no-blueprint-created-yet")}
        </Typography>
      </div>
    )
  ) : (
    <div className={classes.informationTextContainer}>
      <Info />
      <Typography variant={"h6"} className={classes.informationText}>
        {t("pages.assets.modals.create-asset.informational-texts.no-blueprint-existing")}
      </Typography>
    </div>
  );
};
