import React, { FunctionComponent } from "react";

import { Divider } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { InspectionOverviewFeatureElement } from "./InspectionOverviewFeatureElement";
import { InspectionOverviewAcceptanceDeclarationElement } from "./acceptance-declaration/InspectionOverviewAcceptanceDeclarationElement";
import {
  inspectionQuery_asset,
  inspectionQuery_asset_inspections,
  inspectionQuery_asset_inspections_featureInspections,
} from "../../api/__generated__/inspectionQuery";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      minHeight: 0,
      overflowY: "auto",
    },
    featureContainer: {
      margin: theme.spacing(4),
    },
    divider: {
      marginTop: theme.spacing(2),
    },
  })
);

export const InspectionOverview: FunctionComponent<{
  features?: {
    featureInspection: inspectionQuery_asset_inspections_featureInspections;
    tag: string | null;
    tagsMatch: boolean;
    message?: { approved: boolean; reason: string };
  }[];
  inspection: inspectionQuery_asset_inspections;
  asset: inspectionQuery_asset;
}> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {props.features?.map((feature) => (
        <div key={feature.featureInspection.id} className={classes.featureContainer}>
          <InspectionOverviewFeatureElement
            featureInspection={feature.featureInspection}
            tag={feature.tag}
            tagsMatch={feature.tagsMatch}
            eval={feature.message}
          />
          <Divider className={classes.divider} />
        </div>
      ))}
      <div className={classes.featureContainer}>
        <InspectionOverviewAcceptanceDeclarationElement inspection={props.inspection} asset={props.asset} />
      </div>
    </div>
  );
};
