import React, { useState } from "react";

import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  })
);

export type ModifyMenuItem = {
  enabled: boolean
  buttonText: string;
  handleModification: () => void;
}


export const ContextModifyMenu: React.FunctionComponent<{
  menuItems: ModifyMenuItem[]

}> = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <div>
      <IconButton className={classes.button} color="secondary" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "center", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {props.menuItems.map((menuItem) => (
          <MenuItem
            disabled={!menuItem.enabled}
            key={menuItem.buttonText}
            onClick={() => {
              setAnchorEl(null);
              menuItem.handleModification();
            }}
          >
            {menuItem.buttonText}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
