import { gql, useQuery } from "@apollo/client";

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  CircularProgress,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { BlueprintStatus } from "../../../__generated__/globalTypes";
import { NoDataPlaceholder } from "../../../components/NoDataPlaceholder";
import { BreadcrumbContext } from "../../../routing/idfyRouter";
import { routes } from "../../../routing/routes";
import { assetFragment } from "../../assets/api/assetFragments";
import { idfyTheme } from "../../../util/IdfyTheme";
import { StatusChip } from "../../pageFragments/util/StatusChip";
import { assetsByBlueprintIdQuery, assetsByBlueprintIdQuery_assets } from "./__generated__/assetsByBlueprintIdQuery";
import { isBlankOrEmpty } from "../../../util/stringUtil";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    //overflow hidden to enable tableContainer to calculate a pixel value from 100%
    paper: {
      flexGrow: 1,
      overflow: "hidden",
    },
    tableRow: {
      padding: `${theme.spacing(4)}px 0`,
    },
    mobileTableRow: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      padding: `${theme.spacing(2)}px 0`,
      borderBottom: "1px solid" + idfyTheme.palette.secondary.main + "30",
      textAlign: "left",
      width: "100%",
      boxSizing: "border-box",
      "& td": {
        border: "none",
        flexGrow: 1,
        padding: 0,
      },
    },
    tableHeader: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      align: "center",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    noDataPlaceholder: {
      marginTop: 128,
      [theme.breakpoints.down("md")]: {
        marginTop: 64,
      },
    },
    loadingProgress: {
      marginTop: 64,
    },
    serialNumberUndefined: {
      color: "red",
    },
  })
);

const ASSET_BY_BLUEPRINT = gql`
  ${assetFragment}
  query assetsByBlueprintIdQuery($id: ID!) {
    assets(filter: { blueprintId: $id }) {
      ...AssetData
    }
  }
`;

export const BlueprintAssets: React.FunctionComponent<{
  blueprintId: string;
  blueprintName: string;
}> = (props) => {
  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));

  const classes = useStyles();
  const { t } = useTranslation();

  const { data } = useQuery<assetsByBlueprintIdQuery>(ASSET_BY_BLUEPRINT, {
    variables: { id: props.blueprintId },
  });

  if (!data) {
    return <CircularProgress className={classes.loadingProgress} />;
  }

  return (
    <React.Fragment>
      <div className={classes.tableHeader}>
        <Typography variant={"h3"} align={"left"} style={{ marginRight: idfyTheme.spacing(2) }}>
          {t("pages.blueprint-details.assets.assets-with-this-blueprint")}
        </Typography>
      </div>
      <TableContainer>
        {data.assets.length <= 0 && (
          <NoDataPlaceholder
            className={classes.noDataPlaceholder}
            title={t("pages.blueprint-details.assets.no-data-placeholder.title")}
            subTitle={t("pages.blueprint-details.assets.no-data-placeholder.subtitle")}
          />
        )}
        {data.assets.length > 0 && (
          <Table>
            {mobile ? null : (
              <TableHead>
                <TableRow>
                  <TableCell>{t("pages.blueprint-details.assets.name")}</TableCell>
                  <TableCell>{t("pages.blueprint-details.assets.serial-number")}</TableCell>
                  <TableCell>{t("pages.blueprint-details.assets.contract-number")}</TableCell>
                  <TableCell>{t("pages.blueprint-details.assets.customer")}</TableCell>
                  <TableCell>{t("pages.blueprint-details.assets.status")}</TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {data &&
                data.assets.map((data, i) =>
                  mobile ? (
                    <MobileBlueprintAssetRow asset={data} key={i} />
                  ) : (
                    <BlueprintAssetRow key={i} asset={data} blueprintName={props.blueprintName} />
                  )
                )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </React.Fragment>
  );
};

const BlueprintAssetRow: React.FunctionComponent<{
  asset: assetsByBlueprintIdQuery_assets;
  blueprintName: string;
}> = (props) => {
  const history = useHistory();

  const classes = useStyles();

  const utilContext = useContext(BreadcrumbContext);

  const { t } = useTranslation();

  const serialNumberString = isBlankOrEmpty(props.asset.serialNumber)
    ? t("pages.blueprint-details.assets.serial-number-undefined")
    : props.asset.serialNumber;
  const serialNumberClass = isBlankOrEmpty(props.asset.serialNumber) ? `${classes.serialNumberUndefined}` : "";

  return (
    <TableRow
      onClick={() => {
        utilContext.breadcrumbs.push({
          title: t("pages.blueprint-details.assets.breadcrumb") + ": " + props.blueprintName,
          url: history.location.pathname,
        });

        history.push(routes.assets + routes.details(props.asset.id) + routes.infoSuffix);
      }}
      className={classes.tableRow}
      hover
    >
      <TableCell>
        <b>{props.asset.name}</b>
      </TableCell>
      <TableCell>{props.asset.contractNumber}</TableCell>
      <TableCell>
        <span className={serialNumberClass}>{serialNumberString}</span>
      </TableCell>
      <TableCell>
        {props.asset.currentContract && (
          <>{props.asset.currentContract.customer.lastName + ", " + props.asset.currentContract.customer.firstName}</>
        )}
        <br />
        <span style={{ fontSize: "0.9em" }}>
          Kundennr: {props.asset.currentContract && <>{props.asset.currentContract.customer.customerNumber}</>}
        </span>
      </TableCell>
      <TableCell>
        <StatusChip status={props.asset.inspections.slice().pop()?.status || BlueprintStatus.APPROVED} />
      </TableCell>
    </TableRow>
  );
};

const MobileBlueprintAssetRow: React.FunctionComponent<{
  asset: assetsByBlueprintIdQuery_assets;
}> = (props) => {
  const history = useHistory();

  const classes = useStyles();
  const { t } = useTranslation();

  const serialNumberString = isBlankOrEmpty(props.asset.serialNumber)
    ? t("pages.blueprint-details.assets.serial-number-undefined")
    : props.asset.serialNumber;
  const serialNumberClass = isBlankOrEmpty(props.asset.serialNumber) ? `${classes.serialNumberUndefined}` : "";

  return (
    <TableRow
      onClick={() => history.push(routes.assets + routes.details(props.asset.id) + routes.infoSuffix)}
      className={classes.mobileTableRow}
    >
      <div>
        <b>{props.asset.name}</b>
        <br />
        {t("pages.blueprint-details.assets.contract-number")}: {props.asset.contractNumber}
        <br />
        {t("pages.blueprint-details.assets.serial-number")}:{" "}
        <span className={serialNumberClass}>{serialNumberString}</span>
        <br />
      </div>
      <StatusChip status={props.asset.inspections.slice().pop()?.status || BlueprintStatus.APPROVED} />
    </TableRow>
  );
};
