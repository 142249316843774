import React, { ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { usePrefixedTranslation } from "../../util/usePrefixedTranslation";
import { NoDataPlaceholder } from "../../components/NoDataPlaceholder";
import { contractsQuery, contractsQueryVariables } from "./api/__generated__/contractsQuery";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { CONTRACTS_QUERY } from "./api/contractQueriesAndMutations";
import { Contract } from "./api/__generated__/Contract";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { routes } from "../../routing/routes";
import { BreadcrumbContext } from "../../routing/idfyRouter";
import { useHistory } from "react-router-dom";
import { ContractFilter } from "../../__generated__/globalTypes";
import { IDFyColumn, IDFyTable } from "../../components/table/IDFyTable";
import { CustomerCellContent } from "../customers/CustomerCell";
import { formatDateString } from "../../util/dateTime/formatDate";
import { ContractStatusChip } from "./ContractStatusChip";

const allColumns = [
  {
    name: "contractNumber",
    titleKey: "pages.contracts.table.contractNumber",
    render: (rowData) => rowData.contractNumber,
  },
  {
    name: "customer",
    titleKey: "pages.contracts.table.customer",
    // eslint-disable-next-line react/display-name
    render: (rowData) => <CustomerCellContent customer={rowData.customer} />,
  },
  {
    name: "startDate",
    titleKey: "pages.contracts.table.startDate",
    render: (rowData) => formatDateString(rowData.startDate, "-"),
  },
  {
    name: "endDate",
    titleKey: "pages.contracts.table.endDate",
    render: (rowData) => formatDateString(rowData.endDate, "-"),
  },
  {
    name: "status",
    titleKey: "pages.contracts.table.status",
    // eslint-disable-next-line react/display-name
    render: (rowData) => <ContractStatusChip contract={rowData} />,
  },
] as IDFyColumn<Contract>[];

export function ContractTable(props: {
  filter: ContractFilter;
  sort?: string;
  breadcrumbTitle?: string;
  hideColumns?: string[];
}): ReactElement {
  const utilContext = useContext(BreadcrumbContext);
  const history = useHistory();
  const { t } = useTranslation();
  const { pt } = usePrefixedTranslation("pages.contracts.table.");
  const pageSize = 10;
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [runningLazyQuery, setRunningLazyQuery] = useState<boolean>(false);
  const hideColumns = props.hideColumns || [];
  const columns = allColumns.filter((col) => !hideColumns.includes(col.name));

  const [getContracts, { data, loading, error, fetchMore }] = useLazyQuery<contractsQuery, contractsQueryVariables>(
    CONTRACTS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      variables: {
        first: pageSize,
      },
    }
  );

  useEffect(() => {
    setContracts([]);
    setRunningLazyQuery(true);
    // console.info("Running getContracts: ", props.filter, "Loading:", loading, "runningLazyQuery", runningLazyQuery);
    getContracts({
      variables: {
        filter: props.filter,
        sort: props.sort,
      },
    });
  }, [props.filter, props.sort]);

  const fetchNext = useCallback(() => {
    // console.info("Fetch next called", data);
    if (fetchMore) {
      fetchMore({
        variables: {
          after: data?.contracts.pageInfo.endCursor || undefined,
        },
      });
    }
  }, [data]);

  const hasNextPage = data?.contracts?.pageInfo?.hasNextPage || false;

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    onLoadMore: fetchNext,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    if (!data || (loading && runningLazyQuery)) {
      // console.info("Running useEffect::[data] Exiting...", !data, loading, runningLazyQuery);
      return;
    }
    // console.info("Running useEffect::[data] Adding...", data?.contracts?.pageInfo, data?.contracts?.edges);
    const newContracts = data?.contracts?.edges?.map((edge) => edge.node) || [];
    setContracts(newContracts);
    setRunningLazyQuery(false);
  }, [data, loading]);

  if (!data || data.contracts.totalCount === 0) {
    return <NoDataPlaceholder title={pt("no-data-placeholder.title")} subTitle={pt("no-data-placeholder.subtitle")} />;
  }

  function onRowClick(rowData: Contract) {
    props.breadcrumbTitle
      ? utilContext.breadcrumbs.push({
          title: props.breadcrumbTitle,
          url: history.location.pathname,
        })
      : utilContext.breadcrumbs.push({
          url: history.location.pathname || "errorPage",
          title: t("pages.contracts.header"),
        });
    history.push(routes.contracts + routes.details(rowData.id) + routes.infoSuffix);
  }

  return (
    <IDFyTable
      columns={columns}
      data={contracts}
      onRowClick={onRowClick}
      loadingRowRef={loading || hasNextPage ? sentryRef : undefined}
    />
  );
}
