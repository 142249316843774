import React from "react";
import { useTranslation } from "react-i18next";

import { Chip, Tooltip } from "@material-ui/core";
import LocationOffIcon from "@material-ui/icons/LocationOff";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { ImageLocation } from "../util/graphql/__generated__/ImageLocation";

export interface LocationChipProps {
  location: ImageLocation | null;
  className?: string;
}

export const LocationChip: React.FunctionComponent<LocationChipProps> = (props) => {
  const { t } = useTranslation();

  function handleChipClick() {
    if (props.location) {
      window.open(
        `https://www.openstreetmap.org/?mlat=${props.location.latitude}&mlon=${props.location.longitude}&zoom=8`,
        "_blank"
      );
    }
  }

  return (
    <Tooltip title={t("location-chip.tool-tip").toString()}>
      <Chip
        size="small"
        label={props.location ? t("location-chip.display-location") : t("location-chip.location-not-available")}
        icon={props.location ? <LocationOnIcon /> : <LocationOffIcon />}
        disabled={!props.location}
        className={props.className}
        onClick={() => handleChipClick()}
      />
    </Tooltip>
  );
};
