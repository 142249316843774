import React, { FC } from "react";

import SmartphoneIcon from "@material-ui/icons/Smartphone";

import { InspectionType } from "../../../../../__generated__/globalTypes";
import { usePrefixedTranslation } from "../../../../../util/usePrefixedTranslation";
import { AssetProtocolEntry } from "../AssetProtocolEntry";
import { AcceptanceDownloadLink } from "../../acceptanceDownload/AcceptanceDownloadLink";
import {
  assetEventsQuery_assetEvents_InspectionSubmittedEvent_asset,
  assetEventsQuery_assetEvents_InspectionSubmittedEvent_customer,
} from "../__generated__/assetEventsQuery";

export interface InspectionSubmittedEventEntryProps {
  time: Date;
  inspectionType: InspectionType;
  asset: assetEventsQuery_assetEvents_InspectionSubmittedEvent_asset;
  customer: assetEventsQuery_assetEvents_InspectionSubmittedEvent_customer;
}

export const InspectionSubmittedEventEntry: FC<InspectionSubmittedEventEntryProps> = (props) => {
  const { pt } = usePrefixedTranslation("pages.asset-details.protocol.");

  return (
    <AssetProtocolEntry
      icon={<SmartphoneIcon />}
      title={pt("inspection-submitted.title", {
        type: pt("inspection-type." + props.inspectionType),
      })}
      time={props.time}
      fields={[]}
      body={
        props.inspectionType == InspectionType.INITIAL ? (
          <AcceptanceDownloadLink
            assetId={props.asset.id}
            acceptanceDeclarations={props.asset.acceptanceDeclarations}
            companyName={props.customer.company}
          />
        ) : null
      }
    />
  );
};
