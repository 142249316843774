import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  AssetOrSubAssetAlertReason,
  DuplicateAssetOrSubAssetAlert,
} from "../../../../../components/DuplicateAssetOrSubAssetAlert";
import { blueprintNamesQuery } from "../../../../blueprints/api/__generated__/blueprintNamesQuery";
import { manufacturersQuery } from "../../../../manufacturers/api/__generated__/manufacturersQuery";
import { Alert } from "@material-ui/lab";
import { StatusChip } from "../../../../pageFragments/util/StatusChip";
import { isBlankOrEmpty } from "../../../../../util/stringUtil";
import { FoundAssetOutOfContract } from "./FoundAssetOutOfContract";
import { assetDetailQuery_asset } from "../../../api/__generated__/assetDetailQuery";
import { getDefaultCreateAssetInputFormFields } from "../../CreateAssetInput";

const useStyles = makeStyles(() =>
  createStyles({
    formGrid: {
      boxSizing: "border-box",
    },
    icon: {
      alignSelf: "center",
    },
    resetAssetButtonContainer: {
      display: "flex",
      flexDirection: "row-reverse",
    },
  })
);

export interface MatchedAsset {
  inUse: boolean;
  assetId: string;
  outOfContract: boolean;
}

function getDefaultBlueprintId(bluePrintData: blueprintNamesQuery | undefined): string {
  if (!bluePrintData) return "";
  const blueprintIds = bluePrintData.blueprints.assetBlueprints.map((bp) => +bp.id);
  const minBlueprintId = Math.min(...blueprintIds);
  return minBlueprintId.toString();
}

export const CreateAssetInfoObjectFields: React.FunctionComponent<{
  manufacturerAndSerialInUse: MatchedAsset;
  duplicateReason: AssetOrSubAssetAlertReason | undefined;
  blueprintData: blueprintNamesQuery | undefined;
  manufacturersData: manufacturersQuery | undefined;
  isSerialNumberUnknown: boolean;
  isSerialNumberDisabled: boolean;
  foundAsset: assetDetailQuery_asset | undefined;
  useExistingAsset: (useAsset: boolean) => void;
}> = (props) => {
  const methods = useFormContext();
  const classes = useStyles();
  const { t } = useTranslation();

  const useOutOfContractAssetWatch = methods.watch("useOutOfContractAsset");

  useEffect(() => {
    // We have no useExistingContract field => we must register it manually
    methods.register("useOutOfContractAsset");
    // Same for asset ID.
    methods.register("assetId");
  }, []);

  useEffect(() => {
    // Set default value for blueprint
    // We want to set the main blueprint which is generated initially in backend. Hence, we search for the minimum ID
    if (props.blueprintData) {
      console.log("blueprintId", methods.getValues("blueprintId"));
      if (isBlankOrEmpty(methods.getValues("blueprintId"))) {
        console.log("Setting blueprintId");
        methods.setValue("blueprintId", getDefaultBlueprintId(props.blueprintData), { shouldValidate: true });
      }
    }
  }, [props.blueprintData, methods.setValue]);

  const useOutOfContractAssetHandler = () => {
    console.log("useOutOfContractAssetHandler::useOutOfContractAsset", methods.getValues("useOutOfContractAsset"));
    methods.setValue("manufacturerId", props.foundAsset?.manufacturer?.id, { shouldValidate: true });
    methods.setValue("name", props.foundAsset?.name, { shouldValidate: true });
    methods.setValue("serialNumber", props.foundAsset?.serialNumber, { shouldValidate: true });
    methods.setValue("isSerialNumberUnknown", false);
    methods.setValue("blueprintId", props.foundAsset?.blueprint?.id, { shouldValidate: true });
    methods.setValue("useOutOfContractAsset", true, { shouldValidate: false });
    methods.setValue("assetId", props.foundAsset?.id, { shouldValidate: false });
    methods.setValue("isNew", false, { shouldValidate: true });
    props.useExistingAsset(true);
  };

  const resetAssetDataHandler = () => {
    const defaults = getDefaultCreateAssetInputFormFields(undefined);
    methods.setValue("useOutOfContractAsset", false, { shouldValidate: false });
    methods.setValue("manufacturerId", defaults.asset.manufacturerId, { shouldValidate: true });
    methods.setValue("name", defaults.asset.name, { shouldValidate: true });
    methods.setValue("serialNumber", defaults.asset.serialNumber, { shouldValidate: true });
    methods.setValue("isSerialNumberUnknown", defaults.isSerialNumberUnknown);
    methods.setValue("blueprintId", getDefaultBlueprintId(props.blueprintData));
    methods.setValue("isNew", defaults.asset.isNew);
    methods.setValue("assetId", defaults.assetId, { shouldValidate: false });
    methods.setValue("isAlreadyInStock", undefined, { shouldValidate: true });
    props.useExistingAsset(false);
    console.log("resetAssetDataHandler::useOutOfContractAsset", methods.getValues("useOutOfContractAsset"));
  };

  return (
    <Grid container spacing={3} justifyContent={"space-around"} className={classes.formGrid}>
      <Grid item xs={12}>
        <Typography variant={"h3"}>{t("pages.assets.modals.create-asset.object-information")}</Typography>
      </Grid>
      {props.manufacturerAndSerialInUse.inUse && (
        <Grid item xs={12}>
          {!props.manufacturerAndSerialInUse.outOfContract && props.duplicateReason && (
            <DuplicateAssetOrSubAssetAlert
              assetId={props.manufacturerAndSerialInUse.assetId}
              reason={props.duplicateReason}
            />
          )}
          {props.manufacturerAndSerialInUse.outOfContract && props.foundAsset && !useOutOfContractAssetWatch && (
            <FoundAssetOutOfContract
              foundAsset={props.foundAsset}
              useOutOfContractAsset={useOutOfContractAssetHandler}
            />
          )}
        </Grid>
      )}
      {useOutOfContractAssetWatch && (
        <Grid item xs={12}>
          <Alert severity="error" classes={{ icon: classes.icon }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                Es wird ein bestehendes Objekt verwendet.
              </Grid>
              <Grid item xs={12}>
                <div className={classes.resetAssetButtonContainer}>
                  <Button variant={"outlined"} color={"inherit"} onClick={resetAssetDataHandler}>
                    {t("pages.assets.modals.create-asset.fields.useExistingContract.reset")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl
          size="small"
          fullWidth
          variant={"outlined"}
          color={"secondary"}
          error={!!methods.errors.manufacturerId}
        >
          <InputLabel required>{t("pages.assets.modals.create-asset.manufacturer")}</InputLabel>
          <Controller
            control={methods.control}
            as={Select}
            fullWidth
            name={"manufacturerId"}
            label={t("pages.assets.modals.create-asset.fields.blueprint")}
            disabled={useOutOfContractAssetWatch}
          >
            {props.manufacturersData?.manufacturers.map((manufacturer, i) => (
              <MenuItem value={manufacturer.id} key={i}>
                <Typography variant={"h6"} color={"inherit"}>
                  {manufacturer.name}
                </Typography>
              </MenuItem>
            ))}
          </Controller>
          <FormHelperText>{methods.errors.manufacturerId?.message}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={methods.control}
          as={TextField}
          fullWidth
          variant="outlined"
          size="small"
          name="name"
          label={t("pages.assets.modals.create-asset.fields.name")}
          required
          color="secondary"
          helperText={methods.errors.name?.message}
          error={!!methods.errors.name}
          disabled={useOutOfContractAssetWatch}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={methods.control}
          as={TextField}
          fullWidth
          variant="outlined"
          size="small"
          name="serialNumber"
          label={t("pages.assets.modals.create-asset.fields.serial-number")}
          required
          color="secondary"
          helperText={methods.errors.serialNumber?.message}
          error={!!methods.errors.serialNumber}
          disabled={props.isSerialNumberDisabled || useOutOfContractAssetWatch}
        />
        <Box display="flex" justifyContent="flex-end">
          <Controller
            control={methods.control}
            defaultValue={false}
            variant={"outlined"}
            size={"small"}
            color={"secondary"}
            name={"isSerialNumberUnknown"}
            required
            render={({ onChange, value, ref }) => (
              <FormControlLabel
                control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
                label={t("pages.assets.modals.create-asset.fields.isSerialNumberUnknown.label")}
                inputRef={ref}
                disabled={useOutOfContractAssetWatch}
              />
            )}
          />
        </Box>
        {props.isSerialNumberUnknown && (
          <Alert severity="warning" classes={{ icon: classes.icon }}>
            <Trans i18nKey="pages.assets.modals.create-asset.fields.isSerialNumberUnknown.warning" />
          </Alert>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormControl
          size={"small"}
          fullWidth
          variant={"outlined"}
          color={"secondary"}
          error={!!methods.errors.blueprintId}
        >
          <InputLabel required>{t("pages.assets.modals.create-asset.blueprint-type")}</InputLabel>
          <Controller
            control={methods.control}
            as={Select}
            fullWidth
            name={"blueprintId"}
            label={t("pages.assets.modals.create-asset.fields.blueprint")}
            disabled={useOutOfContractAssetWatch}
          >
            {props.blueprintData?.blueprints.assetBlueprints.map((blueprint, i) => (
              <MenuItem value={blueprint.id} key={i}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography variant={"h6"} color={"inherit"}>
                    {blueprint.name}
                  </Typography>
                  <StatusChip status={blueprint.status} disabled />
                </div>
              </MenuItem>
            ))}
          </Controller>
          <FormHelperText>{methods.errors.blueprintId?.message}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};
