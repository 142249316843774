import classNames from "classnames";

import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Badge, Chip, IconButton, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import { ArrowLeftIcon } from "@material-ui/pickers/_shared/icons/ArrowLeftIcon";
import { ArrowRightIcon } from "@material-ui/pickers/_shared/icons/ArrowRightIcon";

import { FeatureType } from "../../../../../__generated__/globalTypes";
import { ChipDataType, IdfyDataChip, IdfyTagChip } from "../../../../../components/IdfyTagChip";
import { LocationChip } from "../../../../../components/LocationChip";
import { MultilineText } from "../../../../../components/MultilineText";
import { formatDateString } from "../../../../../util/dateTime/formatDate";
import { getFeatureTypeIcon } from "../../../../../util/iconUtil";
import { idfyTheme } from "../../../../../util/IdfyTheme";
import { formatThousands, getTranslatedFeatureType } from "../../../../../util/stringUtil";
import { FeatureImageSlider } from "./FeatureImageSlider";
import { ImageLocation } from "../../../../../util/graphql/__generated__/ImageLocation";
import { getMatchingFeaturesQuery } from "../api/__generated__/getMatchingFeaturesQuery";
import { Image } from "../../../../../util/graphql/__generated__/Image";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    featureComparison: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "99%",
      gridColumn: "2 /span 1",
      gridRow: "1 /span 2",
      [theme.breakpoints.down("sm")]: {
        gridColumn: "1 /span 1",
        gridRow: "4 /span 1",
        paddingTop: theme.spacing(2),
        marginTop: theme.spacing(2),
        borderTop: "1px solid #ddd",
      },
    },
    headline: {
      display: "flex",
      alignItems: "center",
      marginTop: theme.spacing(4),
    },
    typeChip: {
      marginLeft: theme.spacing(1),
    },
    description: {
      marginTop: theme.spacing(2),
    },
    featureCounter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "nowrap",
    },
    inspectionValueChip: {
      float: "right",
      marginLeft: theme.spacing(1),
    },
    bodyPadding: {
      paddingBottom: theme.spacing(3),
    },
  })
);

export const FeatureComparison: React.FunctionComponent<{
  featureId: string;
  tag: string | null;
  title: string;
  type: FeatureType;
  matchingFeatureInspections: getMatchingFeaturesQuery | undefined;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [imageLocation, setImageLocation] = useState<ImageLocation | null>(null);

  const I18_PREFIX = "pages.asset-details.inspection-overview.modals.normal-inspection.feature-comparison.";

  const featuresAndBlueprint = useMemo<
    Array<{
      images: Image[];
      title: string;
      tagText: string | null;
      date: string | null;
      meterKilometers: number | null;
      meterHours: number | null;
      location: ImageLocation | null;
      isFeatureBlueprint: boolean;
    }>
  >(() => {
    if (!props.matchingFeatureInspections) {
      return [];
    }

    const featureList = [];

    // Add matching feature inspections which were handled previously
    props.matchingFeatureInspections.getMatchingFeatureInspections.featureInspections
      .filter((matchingFeature) => matchingFeature.featureInspection.id !== props.featureId)
      .sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
      .map((matchingFeature, index, total) => ({
        images: matchingFeature.featureInspection.images,
        title: `${t(`${I18_PREFIX}${index === total.length - 1 ? "initial-" : ""}inspection-from`)} ${formatDateString(
          matchingFeature.date
        )}`,
        tagText: matchingFeature.featureInspection.tag,
        meterKilometers: matchingFeature.featureInspection.meter?.kilometers,
        meterHours: matchingFeature.featureInspection.meter?.hours,
        date: formatDateString(matchingFeature.date),
        location: matchingFeature.featureInspection.location,
        isFeatureBlueprint: false,
      }))
      .forEach((feature) => featureList.push(feature));

    // Add the feature blueprint
    featureList.push({
      images: props.matchingFeatureInspections.getMatchingFeatureInspections.featureBlueprint.images,
      title: t(`${I18_PREFIX}object-type`),
      tagText: null,
      meterKilometers: null,
      meterHours: null,
      location: null,
      date: null,
      isFeatureBlueprint: true,
    });

    return featureList;
  }, [props.matchingFeatureInspections, props.featureId, t]);

  const [currentIndex, setCurrentIndex] = useState(0);

  if (!props.matchingFeatureInspections) {
    return null;
  }

  return (
    <div className={classes.featureComparison}>
      <div>
        <div className={classes.headline}>
          <Typography variant="h3">{props.title}</Typography>
          <Chip
            size="small"
            label={getTranslatedFeatureType(props.type, t)}
            icon={getFeatureTypeIcon(props.type)}
            className={classes.typeChip}
          />
        </div>
        <Typography className={classes.description} variant="body1">
          <MultilineText
            text={props.matchingFeatureInspections.getMatchingFeatureInspections.featureBlueprint.description}
          />
        </Typography>
      </div>
      <div className={classNames({ [classes.bodyPadding]: !props.tag })}>
        <Typography variant="h4" style={{ padding: "8px 0" }}>
          {t(`${I18_PREFIX}inspection-for-comparison`)}
        </Typography>
        <div className={classes.featureCounter}>
          <IconButton
            size="small"
            disabled={featuresAndBlueprint.length <= 1}
            onClick={() =>
              setCurrentIndex((currentIndex - 1 + featuresAndBlueprint.length) % featuresAndBlueprint.length)
            }
          >
            <ArrowLeftIcon />
          </IconButton>
          {featuresAndBlueprint[currentIndex].title}
          <IconButton
            size="small"
            disabled={featuresAndBlueprint.length <= 1}
            onClick={() => setCurrentIndex((currentIndex + 1) % featuresAndBlueprint.length)}
          >
            <ArrowRightIcon />
          </IconButton>
        </div>
        <Badge
          color="primary"
          badgeContent={
            <>
              <PhotoLibraryIcon fontSize="inherit" style={{ marginRight: idfyTheme.spacing(0.5) }} />{" "}
              {featuresAndBlueprint[currentIndex].images.length}
            </>
          }
          style={{ width: "100%" }}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <FeatureImageSlider
            images={featuresAndBlueprint[currentIndex]?.images}
            modalTitle={`${featuresAndBlueprint[currentIndex]?.title} - ${props.matchingFeatureInspections.getMatchingFeatureInspections.featureBlueprint.title}`}
            locationOfImage={(location) => setImageLocation(location)}
          />
        </Badge>
        {!featuresAndBlueprint[currentIndex].isFeatureBlueprint && (
          <LocationChip location={imageLocation || featuresAndBlueprint[currentIndex].location} />
        )}
        {props.type === FeatureType.TAG && !featuresAndBlueprint[currentIndex].isFeatureBlueprint && (
          <IdfyTagChip
            tag={featuresAndBlueprint[currentIndex].tagText ?? "-"}
            className={classes.inspectionValueChip}
          />
        )}
        {props.type === FeatureType.METER && !featuresAndBlueprint[currentIndex].isFeatureBlueprint && (
          <>
            <IdfyDataChip
              tag={formatThousands(featuresAndBlueprint[currentIndex].meterKilometers)}
              className={classes.inspectionValueChip}
              type={ChipDataType.KILOMETERS}
            />
            <IdfyDataChip
              tag={formatThousands(featuresAndBlueprint[currentIndex].meterHours)}
              className={classes.inspectionValueChip}
              type={ChipDataType.HOURS}
            />
          </>
        )}
      </div>
    </div>
  );
};
