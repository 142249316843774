import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CheckItem } from "./CheckItem";
import { format } from "date-fns";
import { AcceptanceDeclaration } from "../../../../../api/__generated__/AcceptanceDeclaration";

const useStyles = makeStyles((_) =>
  createStyles({
    confirmation: {
      marginTop: "1rem",
    },
    checkPointContainer: {
      marginTop: "1rem",
      marginBottom: "1rem",
      marginRight: "3rem",
    },
    acceptedArea: {
      marginTop: "1rem",
      marginLeft: "2.5rem",
    },
  })
);

export const AcceptanceDeclarationChecklist: React.FunctionComponent<{
  declaration: AcceptanceDeclaration;
  latestSubmissionDate: string | null;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { declaration } = props;

  const I18N_PREFIX = "pages.asset-details.inspection-overview.modals.normal-inspection.acceptance-declaration.";

  return (
    <div>
      <Typography variant="h2" gutterBottom>
        {t(`${I18N_PREFIX}protocol`)}
      </Typography>
      <Typography variant="body2" className={classes.confirmation}>
        {t(`${I18N_PREFIX}confirmation-of-customer`)}
      </Typography>
      <div className={classes.checkPointContainer}>
        <CheckItem
          isSuccessful={declaration.isDeliveryComplete}
          text={<Trans i18nKey={`${I18N_PREFIX}isDeliveryComplete`} components={{ bold: <b /> }} />}
        />
        {declaration.isBrandNewAndWorking != null && (
          <CheckItem
            isSuccessful={declaration.isBrandNewAndWorking}
            text={<Trans i18nKey={`${I18N_PREFIX}isBrandNewAndWorking`} components={{ bold: <b /> }} />}
          />
        )}
        <CheckItem
          isSuccessful={declaration.hasInstructionManual}
          text={<Trans i18nKey={`${I18N_PREFIX}hasInstructionManual`} components={{ bold: <b /> }} />}
        />
        <CheckItem
          isSuccessful={declaration.isFreeOfDefects}
          text={<Trans i18nKey={`${I18N_PREFIX}isFreeOfDefects`} components={{ bold: <b /> }} />}
        />
        <CheckItem
          isSuccessful={declaration.isInvoiceCorrect}
          text={<Trans i18nKey={`${I18N_PREFIX}isInvoiceCorrect`} components={{ bold: <b /> }} />}
        />
        <CheckItem
          isSuccessful={declaration.isCheckedAndAccepted}
          text={<Trans i18nKey={`${I18N_PREFIX}isCheckedAndAccepted`} components={{ bold: <b /> }} />}
        />
        <Typography variant="body2" className={classes.acceptedArea}>
          {props.latestSubmissionDate
            ? format(new Date(props.latestSubmissionDate), t("dates.date-time-with-seconds"))
            : t(`${I18N_PREFIX}latestSubmissionDateMissing`)}
          <br />
          <br />
          <Trans i18nKey={`${I18N_PREFIX}accepted`} components={{ bold: <b /> }} />
        </Typography>
      </div>
    </div>
  );
};
