import { useQuery } from "@apollo/client";

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Route, useParams } from "react-router";

import { CircularProgress } from "@material-ui/core";
import { BreadcrumbContext } from "../../../routing/idfyRouter";
import { routes } from "../../../routing/routes";
import { DetailsPage } from "../../pageFragments/DetailsPage";
import { AssetInfo } from "./asset-info/AssetInfo";
import { AssetMenu } from "./AssetMenu";
import { AssetProtocol } from "./protocol/AssetProtocol";
import { SubAssetTable } from "./subAssets/SubAssetTable";
import { InspectionOverview } from "./InspectionOverview";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ASSET_DETAIL_QUERY } from "../api/assetQueriesAndMutations";
import { assetDetailQuery } from "../api/__generated__/assetDetailQuery";

const useStyles = makeStyles((_) =>
  createStyles({
    progressContainer: {
      width: "100%",
      textAlign: "center",
    },
    progress: {
      position: "absolute",
      top: "50%",
    },
  })
);

export const AssetDetails: React.FunctionComponent = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { id } = useParams();
  const { t } = useTranslation();

  const utilContext = useContext(BreadcrumbContext);
  const classes = useStyles();

  if (utilContext.breadcrumbs.length === 0) {
    utilContext.breadcrumbs.push({
      url: routes.assets,
      title: t("pages.asset-details.assets"),
    });
  }

  const { data, refetch } = useQuery<assetDetailQuery>(ASSET_DETAIL_QUERY, {
    variables: { id: id as string },
  });

  const handleOnRefetch = async () => {
    await refetch();
  };

  return (
    <DetailsPage
      headerText={t("pages.asset-details.asset-details")}
      menu={
        data && data.asset ? (
          <AssetMenu
            manufacturer={data.asset.manufacturer?.name}
            name={data.asset.name}
            serialNumber={data.asset.serialNumber}
            blueprint={data.asset.blueprint?.name}
            status={data.asset.inspections.slice().pop()?.status}
            id={id as string}
            subAssetsCount={data.asset.subAssets?.length | 0}
            image={data.asset.image}
          />
        ) : null
      }
    >
      {data && data.asset ? (
        <>
          <Route path={routes.assets + routes.details(":id") + routes.infoSuffix}>
            <AssetInfo asset={data.asset} onRefetch={handleOnRefetch} />
          </Route>
          <Route path={routes.assets + routes.details(":id") + routes.inspectionSuffix}>
            <InspectionOverview id={id as string} onRefetch={handleOnRefetch} />
          </Route>
          <Route path={routes.assets + routes.details(":id") + routes.subAssetSuffix}>
            <SubAssetTable id={id as string} asset={data.asset} />
          </Route>
          <Route path={routes.assets + routes.details(":id") + routes.protocolSuffix}>
            <AssetProtocol assetId={id as string} />
          </Route>
        </>
      ) : (
        <div className={classes.progressContainer}>
          <CircularProgress className={classes.progress} />
        </div>
      )}
    </DetailsPage>
  );
};
