import { TFunction } from "i18next";
import { SearchSelectOption } from "../../components/SearchSelectField";
import { ContractStatus } from "../../__generated__/globalTypes";

export function getContractStatusFilterOptions(t: TFunction): SearchSelectOption[] {
  return [
    {
      value: undefined,
      label: t("pages.contracts.status") + " : " + t("pages.contracts.ContractStatus.ALL"),
    },
    ...Object.values(ContractStatus).map((key) => ({
      value: key,
      label: t("pages.contracts.ContractStatus." + key),
    })),
  ];
}
