import React from "react";
import { usePrefixedTranslation } from "../../../util/usePrefixedTranslation";
import { FormModal } from "../../../components/FormModal";
import * as yup from "yup";
import { FIELD_VALIDATION_TYPE_STRING } from "../../../util/constants";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ManufacturerInput } from "../../../__generated__/globalTypes";
import { ControllerTextField } from "../../../components/inputs/ControllerTextField";
import { useMutation } from "@apollo/client";
import { UPDATE_MANUFACTURER_MUTATION, MANUFACTURERS_QUERY } from "../api/manufacturerQueriesAndMutations";
import { updateManufacturerMutation } from "../api/__generated__/updateManufacturerMutation";

export const EditManufacturerModal: React.FunctionComponent<{
  open: boolean;
  id: string;
  currentName: string;
  handleClose: (manufacturerUpdated: boolean) => void;
}> = (props) => {
  const { pt } = usePrefixedTranslation("pages.manufacturers.modals.edit-manufacturer.");
  const { t } = useTranslation();

  const schema = yup.object().shape({
    name: FIELD_VALIDATION_TYPE_STRING(t),
  });

  const { handleSubmit, control } = useForm<ManufacturerInput>({
    resolver: yupResolver(schema),
  });

  const [updateManufacturer] = useMutation<updateManufacturerMutation>(UPDATE_MANUFACTURER_MUTATION, {
    refetchQueries: [{ query: MANUFACTURERS_QUERY }],
  });

  async function onSubmit(values: ManufacturerInput) {
    await updateManufacturer({
      variables: {
        input: {
          manufacturerId: props.id,
          manufacturer: values
        },
      },
    });

    props.handleClose(true);
  }

  return (
    <FormModal
      open={props.open}
      onClose={() => props.handleClose(false)}
      cancelButtonText={pt("buttons.cancel")}
      submitButtonText={pt("buttons.save")}
      header={pt("header")}
      onSubmit={handleSubmit(onSubmit)}
    >
      <ControllerTextField
        control={control}
        fullWidth
        variant="outlined"
        size="small"
        name="name"
        label={pt("manufacturer")}
        defaultValue={props.currentName}
        color="secondary"
        trimOnBlur
      ></ControllerTextField>
    </FormModal>
  );
};
