import React from "react";
import { useTranslation } from "react-i18next";
import { usePrefixedTranslation } from "../../../../../util/usePrefixedTranslation";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { SupplierField } from "../../../../suppliers/SupplierField";
import { FormGridContainer, FormGridItem } from "../../../../../components/forms/FormGrid";
import { FormTextField } from "../../../../../components/forms/FormTextField";
import { FormDateField } from "../../../../../components/forms/FormDateField";
import { Grid } from "@material-ui/core";
import { DetailsSectionHeader } from "../../../../../components/DetailsSectionHeader";
import deLocale from "date-fns/locale/de";

export const AssetInfoSupplierField: React.FunctionComponent<{
  disabled: boolean;
  isAlreadyInStock: boolean | null;
}> = (props) => {
  const { t } = useTranslation();
  const { pt } = usePrefixedTranslation("pages.asset-details.information.");

  return (
    <React.Fragment>
      <DetailsSectionHeader header={t("pages.suppliers.header")} />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
        <FormGridContainer>
          <FormGridItem>
            <SupplierField
              name={"supplierId"}
              label={t("pages.assets.modals.create-asset.supplier")}
              disabled={props.disabled}
              required={!props.isAlreadyInStock}
            />
          </FormGridItem>
          <Grid item xs={12}>
            <FormGridContainer>
              <FormGridItem>
                <FormTextField
                  name="supplierInvoiceNumber"
                  required={!props.isAlreadyInStock}
                  disabled={props.disabled}
                  label={pt("fields.supplier-invoice-number")}
                  trimOnBlur
                />
              </FormGridItem>
              <FormGridItem>
                <FormDateField
                  name="invoiceDate"
                  required={!props.isAlreadyInStock}
                  disabled={props.disabled}
                  label={t("pages.assets.modals.create-asset.fields.invoice-date")}
                />
              </FormGridItem>
            </FormGridContainer>
          </Grid>
        </FormGridContainer>
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );
};
