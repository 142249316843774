import * as yup from "yup";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { manufacturersQuery } from "../../../manufacturers/api/__generated__/manufacturersQuery";
import { MANUFACTURERS_QUERY } from "../../../manufacturers/api/manufacturerQueriesAndMutations";
import React, { useEffect, useState } from "react";
import {
  AssetOrSubAssetAlertReason,
  DuplicateAssetOrSubAssetAlert,
} from "../../../../components/DuplicateAssetOrSubAssetAlert";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBoth,
  assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBothVariables,
} from "../../api/__generated__/assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBoth";
import { ASSETS_AND_SUB_ASSETS_BY_MANUFACTURER_AND_SERIAL_NUMBER_QUERY } from "../../api/assetQueriesAndMutations";
import { updateSubAssetMutation } from "./__generated__/updateSubAssetMutation";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { SubAsset } from "./SubAssetTable";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { MAX_CONTENT_WIDTH } from "../../../pageFragments/BasePage";
import { idfyTheme } from "../../../../util/IdfyTheme";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalBody: {
      maxWidth: MAX_CONTENT_WIDTH,
      position: "absolute",
      top: "50%",
      left: "50%",
      minHeight: "40%",
      width: "40%",
      transform: "translate(-50%,-50%)",
      boxSizing: "border-box",
      padding: theme.spacing(4),
      borderRadius: "10px",
      outline: "none",
      display: "flex",
      flexDirection: "column",
      background: idfyTheme.palette.background.default,
    },
  })
);

export const UpdateSubAssetDialog: React.FunctionComponent<{
  open: boolean;
  onClose: (refetchSubAssets: boolean) => void;
  subAsset?: SubAsset;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const schema = yup.object({
    name: yup.string().required(),
    serialNumber: yup.string(),
    manufacturerId: yup.string().required(),
  });
  const manufacturersData = useQuery<manufacturersQuery>(MANUFACTURERS_QUERY).data;
  const [manufacturerAndSerialInUse, setManufacturerAndSerialInUse] = useState({ inUse: false, assetId: "-1" });
  const [duplicateReason, setDuplicateReason] = useState<AssetOrSubAssetAlertReason | undefined>(undefined);

  const methods = useForm<{
    name: string;
    serialNumber: string;
    manufacturerId: string;
  }>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      name: props.subAsset?.name,
      serialNumber: props.subAsset?.serialNumber || "",
      manufacturerId: props.subAsset?.manufacturerId || "",
    },
  });

  const client = useApolloClient();

  const assetsAndSubAssetsByManufacturerAndSerialNumber = async (manufacturerId: string, serialNumber: string) => {
    return client.query<
      assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBoth,
      assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBothVariables
    >({
      query: ASSETS_AND_SUB_ASSETS_BY_MANUFACTURER_AND_SERIAL_NUMBER_QUERY,
      variables: { manufacturerId: manufacturerId, serialNumber: serialNumber },
    });
  };

  useEffect(() => {
    methods.reset({
      name: props.subAsset?.name,
      serialNumber: props.subAsset?.serialNumber || "",
      manufacturerId: props.subAsset?.manufacturerId || "",
    });
  }, [props.subAsset]);

  const manufacturerId = methods.watch("manufacturerId");
  const serialNumber = methods.watch("serialNumber");

  useEffect(() => {
    if (manufacturerId && serialNumber) {
      assetsAndSubAssetsByManufacturerAndSerialNumber(manufacturerId, serialNumber).then((result) => {
        if (
          result.data.assetsByManufacturerAndSerialNumber.length > 0 &&
          props.subAsset?.serialNumber !== serialNumber
        ) {
          setManufacturerAndSerialInUse({
            inUse: true,
            assetId: result.data.assetsByManufacturerAndSerialNumber[0].id,
          });
          methods.setError("manufacturerId", {});
          methods.setError("serialNumber", {});
          setDuplicateReason("SerialNumberUsedInExistingAsset");
        } else if (
          result.data.subAssetsByManufacturerAndSerialNumber.length > 0 &&
          props.subAsset?.serialNumber !== serialNumber
        ) {
          setManufacturerAndSerialInUse({
            inUse: true,
            assetId: result.data?.subAssetsByManufacturerAndSerialNumber[0]?.assetId,
          });

          methods.setError("manufacturerId", {});
          methods.setError("serialNumber", {});
          setDuplicateReason("SerialNumberUsedInExistingSubAsset");
        } else {
          setManufacturerAndSerialInUse({ inUse: false, assetId: "-1" });
          methods.clearErrors("manufacturerId");
          methods.clearErrors("serialNumber");
        }
      });
    }
  }, [manufacturerId, serialNumber]);

  const UPDATE_SUB_ASSET_MUTATION = gql`
    mutation updateSubAssetMutation($input: UpdateSubAssetInput!) {
      updateSubAsset(input: $input) {
        subAsset {
          id
          name
          serialNumber
          manufacturer {
            id
            name
          }
        }
      }
    }
  `;

  const [updateSubAssetMutationCall] = useMutation<updateSubAssetMutation>(UPDATE_SUB_ASSET_MUTATION);

  async function onSubmit(data: { name: string; serialNumber: string | null; manufacturerId: string }) {
    await updateSubAssetMutationCall({
      variables: {
        input: {
          subAssetId: props.subAsset?.id,
          subAsset: { name: data.name, serialNumber: data.serialNumber, manufacturerId: data.manufacturerId },
        },
      },
    });
    props.onClose(true);
  }

  useEffect(() => {
    setManufacturerAndSerialInUse({ inUse: false, assetId: "0" });
  }, [props.open]);

  return (
    <Modal open={props.open} onClose={() => props.onClose(false)}>
      <form onSubmit={methods.handleSubmit((data) => onSubmit(data))}>
        <Paper className={classes.modalBody} elevation={2}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <p>{t("pages.asset-details.sub-assets.update-dialog.header")}</p>
            </Grid>
            {manufacturerAndSerialInUse.inUse && (
              <Grid item xs={12}>
                {duplicateReason && (
                  <DuplicateAssetOrSubAssetAlert
                    reason={duplicateReason}
                    assetId={manufacturerAndSerialInUse.assetId}
                  />
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl
                size="small"
                fullWidth
                variant={"outlined"}
                color={"secondary"}
                error={!!methods.errors.manufacturerId}
              >
                <InputLabel required>{t("pages.asset-details.sub-assets.update-dialog.manufacturer")}</InputLabel>
                <Controller
                  control={methods.control}
                  as={Select}
                  fullWidth
                  name={"manufacturerId"}
                  label={t("pages.assets.modals.create-asset.sub-assets.manufacturer")}
                >
                  {manufacturersData?.manufacturers.map((manufacturer, i) => (
                    <MenuItem value={manufacturer.id} key={i}>
                      <Typography variant={"h6"} color={"inherit"}>
                        {manufacturer.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Controller>
                <FormHelperText>{methods.errors.manufacturerId?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={methods.control}
                as={TextField}
                fullWidth
                variant="outlined"
                size="small"
                name="name"
                label={t("pages.asset-details.sub-assets.update-dialog.name")}
                color="secondary"
                helperText={methods.errors.name?.message}
                error={!!methods.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={methods.control}
                as={TextField}
                fullWidth
                variant="outlined"
                size="small"
                name="serialNumber"
                label={t("pages.asset-details.sub-assets.update-dialog.serial-number")}
                color="secondary"
                helperText={methods.errors.serialNumber?.message}
                error={!!methods.errors.serialNumber}
              />
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
              <div>
                <Button variant={"outlined"} onClick={() => props.onClose(false)}>
                  {t("pages.asset-details.sub-assets.update-dialog.denyButton")}
                </Button>
                <Button
                  style={{ marginLeft: "10px" }}
                  color="primary"
                  type="submit"
                  disabled={manufacturerAndSerialInUse.inUse}
                >
                  {t("pages.asset-details.sub-assets.update-dialog.saveButton")}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Modal>
  );
};
