import React from "react";

import { createStyles, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { idfyTheme } from "../../util/IdfyTheme";
import { BasePage } from "./BasePage";
import { DetailsCard, DetailsCardProps } from "./DetailsCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      background: idfyTheme.palette.background.paper,
    },
  })
);

export type DetailsPageProps = {
  headerText: string;
} & DetailsCardProps;

export const DetailsPage: React.FunctionComponent<DetailsPageProps> = (props) => {
  const classes = useStyles();
  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"), { noSsr: true });

  return (
    <BasePage headerText={props.headerText} backLink={true}>
      {mobile && (
        <Typography variant="h1" className={classes.mobileHeader}>
          {props.headerText}
        </Typography>
      )}
      <DetailsCard menu={props.menu}>{props.children}</DetailsCard>
    </BasePage>
  );
};
