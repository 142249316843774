import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Grid, InputProps, Popover, TextField, Theme, Typography } from "@material-ui/core";
import { FiberManualRecord, InfoOutlined } from "@material-ui/icons";
import { palette } from "../../util/IdfyTheme";
import { emptyToNull, isBlankOrEmpty } from "../../util/stringUtil";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";
import { Contract } from "./api/__generated__/Contract";
import { formatDateShortString } from "../../util/dateTime/formatDate";
import { ContractStatusChip } from "./ContractStatusChip";
import { getStatusColorClassName } from "./GetStatusColorClassName";
import { useTranslation } from "react-i18next";

const useStatusColorStyles = makeStyles(() =>
  createStyles({
    active: {
      color: palette.green,
      borderColor: palette.green,
    },
    extended: {
      color: palette.green,
      borderColor: palette.green,
    },
    not_yet_active: {
      color: palette.mediumGrey,
      borderColor: palette.mediumGrey,
    },
    expired: {
      color: palette.red,
      borderColor: palette.red,
    },
    inactive: {
      color: palette.mediumGrey,
      borderColor: palette.mediumGrey,
    },
    unknown: {
      color: palette.yellow,
      borderColor: palette.yellow,
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(2),
      minWidth: "200px",
      maxWidth: "300px",
    },
    contractInformationMinimised: {
      display: "flex",
      alignItems: "center",
    },
    contractInformationExpanded: {
      display: "flex",
      alignItems: "center",
    },
    condensed: {
      fontFamily: "Roboto Condensed",
      fontSize: "0.9em",
    },
    icon: {
      fontSize: 24,
      fill: theme.palette.secondary.main,
      marginRight: "5px",
    },
    textFieldFont: {
      fontWeight: "bold",
    },
  })
);

export function ContractStatusIcon(props: { contract: Contract }): ReactElement {
  const { contract } = props;
  const classes = useStyles();
  const statusColorStyles = useStatusColorStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const statusColorClassName = getStatusColorClassName(contract, statusColorStyles);

  const unknown = t("common.unknown");

  return (
    <div id="divPopOverContainer">
      <span className={classes.contractInformationMinimised}>
        <FiberManualRecord
          fontSize={"small"}
          className={statusColorClassName}
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
        <span className={classes.condensed}>{emptyToNull(contract.contractNumber) || unknown}</span>
      </span>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{ paper: classes.paper }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <span className={classes.contractInformationExpanded}>
              <InfoOutlined className={classes.icon} />
              <Typography variant="h5">{t("pages.contracts.statusPopoverTitle")}</Typography>
            </span>
          </Grid>
          <Grid item xs={6}>
            <span className={classes.contractInformationExpanded}>
              <ContractStatusChip contract={contract} />
            </span>
          </Grid>
          <Grid item xs={12}>
            <ContractTextField
              label={t("pages.contracts.fields.contractNumber")}
              value={emptyToNull(contract.contractNumber) || unknown}
            />
          </Grid>
          <Grid item xs={6}>
            <ContractTextField
              label={t("pages.contracts.fields.startDate")}
              value={formatDateShortString(contract.startDate, unknown)}
            />
          </Grid>
          <Grid item xs={6}>
            <ContractTextField
              label={t("pages.contracts.fields.endDate")}
              value={formatDateShortString(contract.endDate, unknown)}
            />
          </Grid>
          {!isBlankOrEmpty(contract.extendedEndDate) && (
            <Grid item xs={6}>
              <ContractTextField
                label={t("pages.contracts.fields.extendedEndDate")}
                value={formatDateShortString(contract.extendedEndDate)}
              />
            </Grid>
          )}
        </Grid>
      </Popover>
    </div>
  );
}

function ContractTextField(props: TextFieldProps) {
  const classes = useStyles();

  return (
    <TextField
      aria-readonly={"true"}
      variant={"standard"}
      size={"small"}
      color={"secondary"}
      fullWidth
      InputProps={
        {
          disableUnderline: true,
          classes: {
            input: classes.textFieldFont,
          },
        } as Partial<InputProps>
      }
      {...props}
    />
  );
}
