import { gql } from "@apollo/client";
import { contractFragment } from "./contractFragments";
import { pageInfoFragment } from "../../../util/graphql/pagingFragments";

export const CONTRACTS_QUERY = gql`
  ${contractFragment}
  ${pageInfoFragment}
  query contractsQuery($first: Int, $after: String, $sort: String, $filter: ContractFilter) {
    contracts(first: $first, after: $after, sort: $sort, filter: $filter) {
      edges {
        node {
          ...Contract
        }
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
`;

export const CONTRACT_QUERY = gql`
  ${contractFragment}
  query contractQuery($id: ID!) {
    contract(id: $id) {
      ...Contract
    }
  }
`;

export const UPDATE_CONTRACT_MUTATION = gql`
  ${contractFragment}
  mutation updateContractMutation($id: ID!, $input: ContractInput) {
    updateContract(id: $id, input: $input) {
      ...Contract
    }
  }
`;

export const CONTRACT_QUERY_BY_CONTRACT_NUMBER = gql`
  ${contractFragment}
  query contractQueryByContractNumber($contractNumber: String!, $customerId: String) {
    contractByContractNumber(contractNumber: $contractNumber, customerId: $customerId) {
      ...Contract
    }
  }
`;
