import { format } from "date-fns";

import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import PersonIcon from "@material-ui/icons/Person";
import ThumbDownAltOutlinedIcon from "@material-ui/icons/ThumbDownAltOutlined";

import { InspectionType } from "../../../../../__generated__/globalTypes";
import PendingActionsIcon from "../../../../../components/icons/PendingActions";
import { usePrefixedTranslation } from "../../../../../util/usePrefixedTranslation";
import { AssetProtocolEntry } from "../AssetProtocolEntry";
import { EventTextBox } from "./EventTextBox";

export interface InspectionRejectedEventEntryProps {
  time: Date;
  dueDate: Date;
  inspectionType: InspectionType;
  rejectionReason: string | null;
  user: {
    firstName: string;
    lastName: string;
  };
}

export const InspectionRejectedEventEntry: FC<InspectionRejectedEventEntryProps> = (props) => {
  const { t } = useTranslation();
  const { pt } = usePrefixedTranslation("pages.asset-details.protocol.");

  const type = pt("inspection-type." + props.inspectionType);

  return (
    <AssetProtocolEntry
      icon={<ThumbDownAltOutlinedIcon />}
      title={pt("inspection-rejected.title", { type })}
      time={props.time}
      fields={[
        {
          text: pt("inspection-rejected.due-date", { dueDate: format(props.dueDate, t("dates.date")) }),
          icon: <PendingActionsIcon />,
        },
        {
          text: `${props.user.lastName}, ${props.user.firstName}`,
          tooltip: pt("inspection-rejected.tooltip-user", { type }),
          icon: <PersonIcon />,
        },
      ]}
      body={
        props.rejectionReason ? (
          <EventTextBox title={pt("inspection-rejected.rejection-reason")} text={props.rejectionReason} />
        ) : undefined
      }
    />
  );
};
