import i18n from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import I18NextXhrBackend from "i18next-xhr-backend";
import * as dateFnsLocales from "date-fns/locale";

import { initReactI18next } from "react-i18next";

export enum Languages {
  de = "de",
}

export function getDateFnsLocale(language: string): Locale {
  if (language === Languages.de) {
    return dateFnsLocales.de;
  } else {
    // Fallback for now :-)
    return dateFnsLocales.de;
  }
}

i18n
  .use(I18NextXhrBackend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: Languages.de,
    debug: true,
    whitelist: Object.keys(Languages),

    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator"],
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      caches: ["localStorage", "cookie"],
      excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)
      checkWhitelist: true,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })
  .catch(console.error);
