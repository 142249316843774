import React from "react";
import { ReactElement, useEffect, useState } from "react";
import { ContractTable } from "../../contracts/ContractTable";
import { ContractFilter } from "../../../__generated__/globalTypes";

/**
 * We need this wrapper around the original contracts table to avoid continuous re-renders because of changing filter
 */
export function CustomerContractsTable(props: { customerId: string; breadcrumbTitle: string }): ReactElement {
  const [filter, setFilter] = useState({ customerId: props.customerId } as ContractFilter);
  useEffect(() => {
    setFilter({ customerId: props.customerId } as ContractFilter);
  }, [props.customerId]);

  return (
    <ContractTable filter={filter} breadcrumbTitle={props.breadcrumbTitle} hideColumns={["customer"]} sort={"status"} />
  );
}
