import React, { useEffect, useState } from "react";

import { PopUpDialog } from "../../../../pageFragments/util/PopUpDialog";
import * as yup from "yup";
import { FIELD_VALIDATION_TYPE_STRING } from "../../../../../util/constants";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, TextField } from "@material-ui/core";
import {
  AssetOrSubAssetAlertReason,
  DuplicateAssetOrSubAssetAlert,
} from "../../../../../components/DuplicateAssetOrSubAssetAlert";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import { ASSETS_AND_SUB_ASSETS_BY_MANUFACTURER_AND_SERIAL_NUMBER_QUERY } from "../../../api/assetQueriesAndMutations";
import {
  assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBoth,
  assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBothVariables,
} from "../../../api/__generated__/assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBoth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formGrid: {
      boxSizing: "border-box",
      paddingTop: theme.spacing(3),
    },
    deleteCell: {
      width: 60,
    },
    form: {
      maxWidth: 300,
    },
    duplicateAssetContainer: {
      marginBottom: theme.spacing(3),
    },
  })
);

export interface AddSerialNumberManufacturer {
  id: string;
  name: string;
}

export interface AddSerialNumberProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (serialNumber: string) => void;
  name: string;
  manufacturer: AddSerialNumberManufacturer | null;
  contractNumber: string;
}

export const AddSerialNumberDialog: React.FunctionComponent<AddSerialNumberProps> = (props) => {
  const { t } = useTranslation();
  const i18nPrefix = "pages.asset-details.inspection-overview.modals.normal-inspection.add-serial-number-dialog.";

  const schema = yup.object().shape({
    serialNumber: FIELD_VALIDATION_TYPE_STRING(t),
  });

  const { control, errors, handleSubmit, reset, watch, setError, clearErrors } = useForm<{ serialNumber: string }>({
    resolver: yupResolver(schema),
    defaultValues: { serialNumber: "" },
  });

  const classes = useStyles();

  const [manufacturerAndSerialNumberInUse, setManufacturerAndSerialNumberInUse] = useState({
    inUse: false,
    assetId: "-1",
  });
  const [duplicateReason, setDuplicateReason] = useState<AssetOrSubAssetAlertReason | undefined>(undefined);

  const client = useApolloClient();
  const assetsAndSubAssetsByManufacturerAndSerialNumber = async (manufacturerId: string, serialNumber: string) => {
    return client.query<
      assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBoth,
      assetsAndSubAssetsByManufacturerAndSerialNumberQueryForBothVariables
    >({
      query: ASSETS_AND_SUB_ASSETS_BY_MANUFACTURER_AND_SERIAL_NUMBER_QUERY,
      variables: { manufacturerId: manufacturerId, serialNumber: serialNumber },
    });
  };

  const serialNumber = watch("serialNumber");
  const manufacturerId = props.manufacturer?.id;
  useEffect(() => {
    if (serialNumber && manufacturerId) {
      assetsAndSubAssetsByManufacturerAndSerialNumber(manufacturerId, serialNumber).then((result) => {
        if (result.data.assetsByManufacturerAndSerialNumber.length > 0) {
          setManufacturerAndSerialNumberInUse({
            inUse: true,
            assetId: result.data.assetsByManufacturerAndSerialNumber[0].id,
          });
          setError("serialNumber", {});
          setDuplicateReason("SerialNumberUsedInExistingAsset");
        } else if (result.data.subAssetsByManufacturerAndSerialNumber.length > 0) {
          setManufacturerAndSerialNumberInUse({
            inUse: true,
            assetId: result.data?.subAssetsByManufacturerAndSerialNumber[0]?.id,
          });
          setError("serialNumber", {});
          setDuplicateReason("SerialNumberUsedInExistingSubAsset");
        } else {
          setManufacturerAndSerialNumberInUse({ inUse: false, assetId: "-1" });
          clearErrors("serialNumber");
        }
      });
    }
  }, [serialNumber]);

  return (
    <PopUpDialog
      open={props.open}
      onClose={props.handleClose}
      handleConfirm={handleSubmit((data) => props.handleSubmit(data.serialNumber))}
      header={t(`${i18nPrefix}header`)}
      confirm={t(`${i18nPrefix}save`)}
      deny={t(`${i18nPrefix}cancel`)}
      confirmButtonDisabled={manufacturerAndSerialNumberInUse.inUse}
      handleDeny={() => {
        reset();
        props.handleClose();
      }}
    >
      <form className={classes.form}>
        {manufacturerAndSerialNumberInUse.inUse && (
          <Grid item xs={12} className={classes.duplicateAssetContainer}>
            {duplicateReason && (
              <DuplicateAssetOrSubAssetAlert
                reason={duplicateReason}
                assetId={manufacturerAndSerialNumberInUse.assetId}
              />
            )}
          </Grid>
        )}
        <Grid container spacing={2} alignItems={"flex-start"}>
          <Grid item xs={12}>
            <TextField
              disabled={true}
              value={props.manufacturer?.name}
              label={t(`${i18nPrefix}manufacturer`)}
              size={"small"}
              fullWidth
              variant={"outlined"}
              color={"secondary"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={true}
              value={props.name}
              label={t(`${i18nPrefix}name`)}
              size={"small"}
              fullWidth
              variant={"outlined"}
              color={"secondary"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={true}
              value={props.contractNumber}
              label={t(`${i18nPrefix}contract-number`)}
              size={"small"}
              fullWidth
              variant={"outlined"}
              color={"secondary"}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={"serialNumber"}
              label={t(`${i18nPrefix}serial-number`)}
              control={control}
              as={TextField}
              size={"small"}
              fullWidth
              variant={"outlined"}
              error={!!errors.serialNumber}
              color={"secondary"}
              helperText={errors.serialNumber?.message}
            />
          </Grid>
        </Grid>
      </form>
    </PopUpDialog>
  );
};
