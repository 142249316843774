import React from "react";

export interface MultilineTextProps {
  text?: string | null;
}

/**
 * Renders the given text with <br/> for every linebreak (\n).
 */
const _MultilineText = (props: MultilineTextProps) => {
  if (!props.text) {
    return null;
  }

  const lines = props.text.split("\n");

  return (
    <>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index < lines.length - 1 ? <br /> : undefined}
        </React.Fragment>
      ))}
    </>
  );
};

export const MultilineText = React.memo(_MultilineText);
