import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { idfyTheme, palette } from "../../../util/IdfyTheme";
import { NoDataPlaceholder } from "../../../components/NoDataPlaceholder";
import { makeStyles } from "@material-ui/core/styles";
import { MobileAssetTableRow, ReferencedMobileAssetTableRowLoading } from "./MobileAssetTableRow";
import { AssetTableRow, ReferencedAssetTableRowLoading } from "./AssetTableRow";
import {
  assetsPagedQuery,
  assetsPagedQuery_assetsPaged_edges,
  assetsPagedQuery_assetsPaged_edges_node,
  assetsPagedQueryVariables,
} from "../api/__generated__/assetsPagedQuery";
import { usePrefixedTranslation } from "../../../util/usePrefixedTranslation";
import { useLazyQuery } from "@apollo/client";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { ASSETS_QUERY } from "../api/assetQueriesAndMutations";
import { AssetFilter, AssetStatusGroup, ContractStatus, Team } from "../../../__generated__/globalTypes";
import { TeamFilter } from "../../../components/team/TeamFilter";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      overflow: "auto",
      webkitOverflowScrolling: "touch",
      maxHeight: "-webkit-fill-available",
      alignItems: "middle",
    },
    tableHeadRow: {
      "& th": {
        background: theme.palette.background.paper,
        color: palette.mediumGrey,
        textTransform: "uppercase",
      },
    },
  })
);

function getFilter(
  searchText: string,
  assetStatusFilter: string,
  contractStatusFilter: ContractStatus | undefined,
  teamFilter: string
): AssetFilter | undefined {
  if (!Object.keys(AssetStatusGroup).includes(assetStatusFilter)) return undefined;
  if (!Object.keys(Team).includes(teamFilter)) return undefined;
  return {
    searchText: searchText,
    statusGroup: assetStatusFilter as AssetStatusGroup,
    contractStatus: contractStatusFilter,
    team: teamFilter as Team,
  };
}

export function AssetTable(props: {
  searchAssetStatusQuery: string;
  searchContractStatusQuery: ContractStatus | undefined;
  searchTeamFilter: TeamFilter;
  searchText: string;
  toggleRefresh: boolean;
}): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pt } = usePrefixedTranslation("pages.assets.");
  let i = 0;
  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));

  const [pageSize] = useState(10);
  const [assets, setAssets] = useState<assetsPagedQuery_assetsPaged_edges_node[]>([]);
  const [runningLazyQuery, setRunningLazyQuery] = useState<boolean>(false);

  const [getAssets, { data, loading, error, fetchMore }] = useLazyQuery<assetsPagedQuery, assetsPagedQueryVariables>(
    ASSETS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      variables: {
        first: pageSize,
      },
    }
  );

  useEffect(() => {
    setAssets([]);
    setRunningLazyQuery(true);
    console.info(
      "Running getAssets: ",
      getFilter(
        props.searchText,
        props.searchAssetStatusQuery,
        props.searchContractStatusQuery,
        props.searchTeamFilter
      ),
      "Loading:",
      loading,
      "runningLazyQuery",
      runningLazyQuery
    );
    getAssets({
      variables: {
        filter: getFilter(
          props.searchText,
          props.searchAssetStatusQuery,
          props.searchContractStatusQuery,
          props.searchTeamFilter
        ),
      },
    });
  }, [
    props.searchAssetStatusQuery,
    props.searchContractStatusQuery,
    props.searchText,
    props.searchTeamFilter,
    props.toggleRefresh,
  ]);

  const fetchNext = useCallback(() => {
    console.info("Fetch next called", data);
    if (fetchMore) {
      fetchMore({
        variables: {
          after: data?.assetsPaged.pageInfo.endCursor || undefined,
        },
      });
    }
  }, [data]);

  const hasNextPage = data?.assetsPaged?.pageInfo?.hasNextPage || false;

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    onLoadMore: fetchNext,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    if (!data || (loading && runningLazyQuery)) {
      console.info("Running useEffect::[data] Exiting...", !data, loading, runningLazyQuery);
      return;
    }
    console.info("Running useEffect::[data] Adding...", data?.assetsPaged?.pageInfo, data?.assetsPaged?.edges);
    const newAssets = data?.assetsPaged?.edges?.map((edge: assetsPagedQuery_assetsPaged_edges) => edge.node) || [];
    setAssets(newAssets);
    setRunningLazyQuery(false);
  }, [data, loading]);

  if (!data || data.assetsPaged.totalCount === 0) {
    return <NoDataPlaceholder title={pt("no-data-placeholder.title")} subTitle={pt("no-data-placeholder.subtitle")} />;
  }

  return (
    <React.Fragment>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          {mobile ? null : (
            <TableHead>
              <TableRow className={classes.tableHeadRow}>
                <TableCell />
                <TableCell>{t("pages.assets.table.header-row.name")}</TableCell>
                <TableCell>{t("pages.assets.table.header-row.serial-number")}</TableCell>
                <TableCell>{t("pages.assets.table.header-row.contract")}</TableCell>
                <TableCell>{t("pages.assets.table.header-row.customer-name")}</TableCell>
                <TableCell>{t("pages.assets.table.header-row.status")}</TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {assets.map((data) =>
              mobile ? <MobileAssetTableRow asset={data} key={i++} /> : <AssetTableRow key={i++} asset={data} />
            )}
            {(loading || hasNextPage) &&
              (mobile ? (
                <ReferencedMobileAssetTableRowLoading ref={sentryRef} />
              ) : (
                <ReferencedAssetTableRowLoading ref={sentryRef} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
