import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { usePrefixedTranslation } from "../../../util/usePrefixedTranslation";

export const SubAssetConfirmDialog: React.FunctionComponent<{
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onDeny: () => void;
}> = (props) => {
  const { pt } = usePrefixedTranslation("pages.assets.modals.create-asset.");

  return (
    <Dialog open={props.open} onClose={() => props.onClose()} maxWidth={"md"}>
      <DialogTitle disableTypography>
        <Typography variant={"h3"}>{pt("unsaved-sub-assets")}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>{pt("unsaved-sub-assets-explanation")}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color={"primary"} onClick={() => props.onDeny()}>
          {pt("buttons.edit-sub-assets")}
        </Button>
        <Button
          color={"secondary"}
          variant="outlined"
          onClick={() => {
            props.onConfirm();
          }}
        >
          {pt("buttons.discard-changes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
