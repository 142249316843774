import React, { ReactNode } from "react";

import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import classNames from "classnames";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) =>
  createStyles({
    checkItemContainer: {
      marginTop: "1rem",
      marginBottom: "1rem",
      display: "flex",
      alignItems: "center",
    },
    icon: {
      marginRight: "1rem",
    },
    isSuccessful: {
      color: theme.palette.success.dark,
    },
    isNotSuccessful: {
      color: theme.palette.error.main,
    },
  })
);

export const CheckItem: React.FunctionComponent<{
  isSuccessful: boolean;
  text: ReactNode;
}> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.checkItemContainer}>
      <div>
        {props.isSuccessful && <CheckCircleIcon className={classNames(classes.icon, classes.isSuccessful)} />}
        {!props.isSuccessful && <CancelIcon className={classNames(classes.icon, classes.isNotSuccessful)} />}
      </div>
      <div>
        <Typography variant="body2">{props.text}</Typography>
      </div>
    </div>
  );
};
