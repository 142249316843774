import React, { ReactNode, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";

export const PopUpDialog: React.FunctionComponent<{
  children?: ReactNode;
  open: boolean;
  onClose: () => void;
  // When returning a promise, the dialog actions will be disabled
  // until the promise is resolved
  handleConfirm: () => Promise<void> | void;
  handleDeny?: () => void;
  header: string;
  confirm: string;
  deny?: string;
  classes?: { confirmButton: string };
  confirmButtonDisabled?: boolean;
  denyButtonDisabled?: boolean;
  fullWidth?: boolean;
}> = (props) => {
  const [disabled, setDisabled] = useState(false);
  const handleConfirmClick = async () => {
    setDisabled(true);
    await props.handleConfirm();
    setDisabled(false);
  };

  return (
    <Dialog open={props.open} onClose={() => !disabled && props.onClose()} maxWidth={"md"} fullWidth={props.fullWidth}>
      <DialogTitle disableTypography>
        <Typography variant={"h3"}>{props.header}</Typography>
      </DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        {props.deny && props.handleDeny && (
          <Button
            color={"secondary"}
            variant="outlined"
            onClick={props.handleDeny}
            disabled={disabled || props.denyButtonDisabled}
          >
            {props.deny}
          </Button>
        )}
        <Button
          disabled={disabled || props.confirmButtonDisabled}
          color={"primary"}
          onClick={handleConfirmClick}
          className={props.classes?.confirmButton}
        >
          {props.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
