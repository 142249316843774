import React from "react";

import { CircularProgress } from "@material-ui/core";

export const LoadingPage: React.FunctionComponent = () => (
  <div
    style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    }}
  >
    <CircularProgress color={"primary"} />
  </div>
);
