import { customerByIdQuery_customer } from "./api/__generated__/customerByIdQuery";
import * as yup from "yup";
import { TFunction } from "i18next";
import { CustomerInput, Team } from "../../__generated__/globalTypes";
import { fieldValidationOptionalEmail, fieldValidationString, fieldValidationOptionalString } from "../../util/yup/validations";
import { getTeamsSchema } from "../../components/team/TeamFilter";

export type Customer = Omit<customerByIdQuery_customer, "__typename">;

export function customerSchema(t: TFunction): yup.SchemaOf<CustomerInput> {
  return yup.object().shape({
    firstName: fieldValidationString(t),
    lastName: fieldValidationString(t),
    customerNumber: fieldValidationString(t),
    company: fieldValidationString(t),
    email: fieldValidationOptionalEmail(t),
    streetAddress: fieldValidationString(t),
    zip: fieldValidationString(t),
    city: fieldValidationString(t),
    telephoneNumber: fieldValidationOptionalString(t).nullable(),
    responsibleTeam: getTeamsSchema(false).default(Team.ASSET_MONITORING)
  });
}
