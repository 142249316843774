import { useQuery } from "@apollo/client";

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Route, useParams } from "react-router";

import { CircularProgress } from "@material-ui/core";

import { BreadcrumbContext } from "../../../routing/idfyRouter";
import { routes } from "../../../routing/routes";
import { DetailsPage } from "../../pageFragments/DetailsPage";
import { assetsByCustomerIdQuery } from "../../assets/api/__generated__/assetsByCustomerIdQuery";
import { CustomerAssetTable } from "./CustomerAssetTable";
import { CustomerInfo } from "./CustomerInfo";
import { CustomerMenu } from "./CustomerMenu";
import { CUSTOMER_DETAIL_QUERY } from "../api/customerQueriesAndMutations";
import { CustomerRegistration } from "./CustomerRegistration";
import { ASSETS_BY_CUSTOMER_ID_QUERY } from "../../assets/api/assetQueriesAndMutations";
import { CustomerContractsTable } from "./CustomerContractsTable";
import { SubAccountTable } from "../SubAccountTable";
import { customerByIdQuery } from "../api/__generated__/customerByIdQuery";

export const CustomerDetails: React.FunctionComponent = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { id } = useParams();
  const { t } = useTranslation();

  const utilContext = useContext(BreadcrumbContext);

  if (utilContext.breadcrumbs.length === 0) {
    utilContext.breadcrumbs.push({
      url: routes.customers,
      title: t("pages.customer-details.customers"),
    });
  }

  const { data } = useQuery<customerByIdQuery>(CUSTOMER_DETAIL_QUERY, {
    variables: { id: id as string },
  });

  const { data: assetArray, refetch } = useQuery<assetsByCustomerIdQuery>(ASSETS_BY_CUSTOMER_ID_QUERY, {
    variables: { id: id as string },
  });

  return (
    <DetailsPage
      headerText={t("pages.customer-details.customers-lessees")}
      menu={data && data.customer ? <CustomerMenu customer={data.customer} /> : null}
    >
      {data && data.customer ? (
        <>
          <Route path={routes.customers + routes.details(":id") + routes.infoSuffix}>
            <CustomerInfo initialValues={data.customer} />
          </Route>
          <Route path={routes.customers + routes.details(":id") + routes.assetsSuffix}>
            <CustomerAssetTable
              assets={assetArray?.assets}
              breadcrumbTitle={
                t("pages.customer-details.breadcrumb") + data.customer.lastName + ", " + data.customer.firstName
              }
              refetch={() => refetch().then(() => true)}
              customerId={data.customer.id}
              title={t("pages.customer-details.customer-assets")}
            />
          </Route>
          <Route path={routes.customers + routes.details(":id") + routes.contractsSuffix}>
            <CustomerContractsTable
              customerId={data.customer.id}
              breadcrumbTitle={t("pages.customer-details.breadcrumb") + data.customer.company}
            />
          </Route>
          <Route path={routes.customers + routes.details(":id") + routes.subAccountsSuffix}>
            <SubAccountTable accounts={data.customer.accounts} />
          </Route>
          <Route path={routes.customers + routes.details(":id") + routes.registrationSuffix}>
            <CustomerRegistration customer={data.customer} />
          </Route>
        </>
      ) : (
        <CircularProgress color={"primary"} />
      )}
    </DetailsPage>
  );
};
