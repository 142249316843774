import { TFunction } from "i18next";

import { FeatureType } from "../__generated__/globalTypes";

export function getTranslatedFeatureType(type: FeatureType, t: TFunction): string | null {
  if (type === FeatureType.TAG) return t("feature-type.tag-feature");
  if (type === FeatureType.IMAGE) return t("feature-type.image-feature");
  if (type === FeatureType.METER) return t("feature-type.meter-feature");
  return null;
}

export function formatThousands(n: number | undefined | null): string {
  if (n !== undefined && n !== null) {
    return n.toLocaleString("de", { maximumFractionDigits: 0 });
  } else {
    return "-";
  }
}

export function emptyToNull(str?: string | null): string | null {
  if (str === undefined) {
    return null;
  }
  return str === "" ? null : str;
}

export function isBlankOrEmpty(str?: string | null): boolean {
  return str === undefined || str === null || str.trim() === "";
}
