import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  Button,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { NoDataPlaceholder } from "../../../components/NoDataPlaceholder";
import { BreadcrumbContext, SnackContext } from "../../../routing/idfyRouter";
import { routes } from "../../../routing/routes";
import { idfyTheme } from "../../../util/IdfyTheme";
import { AssetStatusCell } from "../../pageFragments/util/StatusChip";
import { CreateInspections } from "./CreateInspections";
import { CreateAssetModal } from "../../assets/createAsset/CreateAssetModal";
import AddIcon from "@material-ui/icons/Add";
import { isBlankOrEmpty } from "../../../util/stringUtil";
import { AgriLogo } from "../../pageFragments/util/AgriLogo";
import { SmallAsset } from "../../assets/api/__generated__/SmallAsset";
import { isAllowed } from "../../../components/access/AccessManager";
import { useCurrentAccount } from "../../../util/auth/useCurrentAccount";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    //overflow hidden to enable tableContainer to calculate a pixel value from 100%
    paper: {
      flexGrow: 1,
      overflow: "hidden",
    },
    tableRow: {
      padding: `${theme.spacing(4)}px 0`,
      verticalAlign: "middle",
    },
    mobileTableRow: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      padding: `${theme.spacing(2)}px 0`,
      borderBottom: "1px solid" + idfyTheme.palette.secondary.main + "30",
      textAlign: "left",
      width: "100%",
      boxSizing: "border-box",
      "& td": {
        border: "none",
        flexGrow: 1,
        padding: 0,
      },
    },
    tableHeader: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      align: "center",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    initiateButton: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginLeft: theme.spacing(2),
    },
    addButton: {
      marginTop: theme.spacing(2),
    },
    serialNumberUndefined: {
      color: "red",
    },
    assetThumbnail: {
      width: 80,
      height: 80,
      margin: theme.spacing(1),
      borderRadius: "50%",
    },
    assetThumbnailIcon: {
      boxSizing: "border-box",
      width: 80,
      height: 80,
      margin: theme.spacing(1),
      borderRadius: "50%",
      border: "1px solid grey",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&> svg": {
        fill: "grey",
        width: 60,
        height: 60,
      },
    },
  })
);

export const CustomerAssetTable: React.FunctionComponent<{
  assets?: SmallAsset[];
  breadcrumbTitle: string;
  customerId: string;
  refetch: () => Promise<boolean>;
  title: string;
}> = (props) => {
  const { customerId } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const mobile = useMediaQuery(idfyTheme.breakpoints.down("sm"));
  const snackContext = useContext(SnackContext);
  const currentAccount = useCurrentAccount();

  const [createInspectionsDialogOpen, setCreateInspectionsDialogOpen] = useState(false);
  const [newModalOpen, setNewModalOpen] = useState(false);

  const assetsThatCanInitiateInspections = props.assets?.filter((asset) => asset.assetStatus == "APPROVED");
  return (
    <React.Fragment>
      {props.assets && props.assets.length > 0 && assetsThatCanInitiateInspections ? (
        <>
          <div className={classes.tableHeader}>
            <Typography variant={"h3"} align={"left"} style={{ marginRight: idfyTheme.spacing(2) }}>
              {props.title}
            </Typography>
            <Button color={"secondary"} startIcon={<AddIcon />} disabled={!isAllowed("pages.customer-details.assets.add-asset", currentAccount)} onClick={() => setNewModalOpen(true)}>
              {t("pages.customer-details.assets.add-asset")}
            </Button>
            <Button
              color={"primary"}
              onClick={() => setCreateInspectionsDialogOpen(true)}
              disabled={(assetsThatCanInitiateInspections.length || 0) <= 0}
              className={classes.initiateButton}
            >
              {t("pages.customer-details.assets.initiate-inspections")}
            </Button>
            <CreateInspections
              customerId={customerId}
              createInspectionsDialogOpen={createInspectionsDialogOpen}
              setCreateInspectionsDialogOpen={setCreateInspectionsDialogOpen}
              assets={props.assets}
            />
          </div>
          <TableContainer>
            <Table>
              {mobile ? null : (
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>{t("pages.customer-details.assets.manufacturer")}</TableCell>
                    <TableCell>{t("pages.customer-details.assets.name")}</TableCell>
                    <TableCell>{t("pages.customer-details.assets.serial-number")}</TableCell>
                    <TableCell>{t("pages.customer-details.assets.contract-number")}</TableCell>
                    <TableCell>{t("pages.customer-details.assets.status")}</TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {props.assets?.map((data, i) =>
                  mobile ? (
                    <MobileCustomerAssetRow asset={data} key={i} />
                  ) : (
                    <CustomerAssetRow key={i} asset={data} breadcrumbTitle={props.breadcrumbTitle} />
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <NoDataPlaceholder
          title={t("pages.customer-details.assets.no-data-placeholder.title")}
          subTitle={t("pages.customer-details.assets.no-data-placeholder.subtitle")}
        >
          <Button
            color={"secondary"}
            startIcon={<AddIcon />}
            onClick={() => {
              setNewModalOpen(true);
            }}
            className={classes.addButton}
          >
            {t("pages.customer-details.assets.add-asset")}
          </Button>
        </NoDataPlaceholder>
      )}
      <CreateAssetModal
        newModalOpen={newModalOpen}
        handleClose={(success: boolean) => {
          setNewModalOpen(false);
          void props.refetch().then(() => {
            snackContext.setSnackOpen(success);
            snackContext.setSnackText(t("pages.assets.alerts.object-created"));
          });
        }}
        customerId={customerId}
      />
    </React.Fragment>
  );
};

const CustomerAssetRow: React.FunctionComponent<{
  asset: SmallAsset;
  breadcrumbTitle: string;
}> = (props) => {
  const history = useHistory();

  const classes = useStyles();
  const { t } = useTranslation();

  const serialNumberString = isBlankOrEmpty(props.asset.serialNumber)
    ? t("pages.customer-details.assets.serial-number-undefined")
    : props.asset.serialNumber;
  const serialNumberClass = isBlankOrEmpty(props.asset.serialNumber) ? `${classes.serialNumberUndefined}` : "";

  const utilContext = useContext(BreadcrumbContext);

  return (
    <TableRow
      onClick={() => {
        utilContext.breadcrumbs.push({
          title: props.breadcrumbTitle,
          url: history.location.pathname,
        });

        history.push(`${routes.assets}${routes.details(props.asset.id)}${routes.infoSuffix}`);
      }}
      className={classes.tableRow}
      hover
    >
      <TableCell>
        {props.asset.image ? (
          <img
            alt="Asset Thumbnail"
            className={classes.assetThumbnail}
            src={`data:${props.asset.image.type};base64,${props.asset.image.data}`}
          />
        ) : (
          <div className={classes.assetThumbnailIcon}>
            <AgriLogo className={""} />
          </div>
        )}
      </TableCell>
      <TableCell>
        <b>{props.asset.manufacturer?.name}</b>
      </TableCell>
      <TableCell>
        <b>{props.asset.name}</b>
      </TableCell>
      <TableCell>
        <span className={serialNumberClass}>{serialNumberString}</span>
      </TableCell>
      <TableCell>{props.asset.currentContract?.contractNumber}</TableCell>
      <TableCell>
        <AssetStatusCell asset={props.asset} mobile={true} />
      </TableCell>
    </TableRow>
  );
};

const MobileCustomerAssetRow: React.FunctionComponent<{
  asset: SmallAsset;
}> = (props) => {
  const history = useHistory();

  const classes = useStyles();
  const { t } = useTranslation();

  const serialNumberString = isBlankOrEmpty(props.asset.serialNumber)
    ? t("pages.customer-details.assets.serial-number-undefined")
    : props.asset.serialNumber;
  const serialNumberClass = isBlankOrEmpty(props.asset.serialNumber) ? `${classes.serialNumberUndefined}` : "";

  return (
    <TableRow
      onClick={() => history.push(routes.assets + routes.details(props.asset.id) + routes.infoSuffix)}
      className={classes.mobileTableRow}
    >
      <div>
        <b>{props.asset.name}</b>
        <br />
        {t("pages.customer-details.assets.serial-number")}:{" "}
        <span className={serialNumberClass}>{serialNumberString}</span>
        <br />
        {t("pages.customer-details.assets.contract-number")}: {props.asset.currentContract?.contractNumber}
        <br />
      </div>
      <AssetStatusCell asset={props.asset} mobile={true} />
    </TableRow>
  );
};
