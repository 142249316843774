import { TFunction } from "i18next";
import { RequiredStringSchema } from "yup/lib/string";
import { Maybe } from "graphql/jsutils/Maybe";
import * as yup from "yup";
import { StringSchema } from "yup";
import { formatDateToIsoDate } from "../dateTime/formatDate";
import { EntityRef } from "../../__generated__/globalTypes";

/**
 * YUP validation that can be used for required string fields
 *
 * Re result string is trimmed
 */
export function fieldValidationString(
  t: TFunction,
  minMessage?: string,
  requiredMessage?: string
): RequiredStringSchema<string | undefined> {
  return yup
    .string()
    .trim()
    .min(1, minMessage ? minMessage : t("validations.min"))
    .max(255, t("validations.max"))
    .typeError(t("validations.type"))
    .required(requiredMessage ? requiredMessage : t("validations.required"));
}

/**
 * YUP validation that can be used for required string fields
 *
 * Re result string is trimmed
 */
export function fieldValidationOptionalString(t: TFunction): StringSchema<string | undefined> {
  return yup.string().trim().max(255, t("validations.max")).emptyStringToNull();
}

export function fieldValidationOptionalEmail(t: TFunction): StringSchema<string | undefined> {
  return yup.string().email(t("validations.email"));
}

/**
 * YUP validation that can be used for optional DateFields which are of type string
 *
 * The result date format is Iso Date (without time)
 */
export function fieldValidationOptionalDateString(t: TFunction): StringSchema<Maybe<string>> {
  return yup.string().trim(t("validations.trim")).transform(transformDateAndDateString).emptyStringToNull().nullable();
}

/**
 * YUP validation that can be used for DateFields which are of type string
 *
 * The result date format is Iso Date (without time)
 */
export function fieldValidationDateString(t: TFunction, requiredMessage?: string): RequiredStringSchema<Maybe<string>> {
  return yup
    .string()
    .trim(t("validations.trim"))
    .transform(transformDateAndDateString)
    .emptyStringToNull()
    .nullable()
    .required(requiredMessage ? requiredMessage : t("validations.required"));
}

export function fieldValidationEntityRef(t: TFunction): yup.SchemaOf<EntityRef> {
  return yup
    .object({
      id: yup.string().defined(t("validations.required")),
    })
    .transform((value) => {
      return { id: value.id };
    });
}

function transformDateAndDateString(value: Maybe<string>) {
  console.info("Transforming ", value);
  if (!value) {
    // console.info("Just returning...")
    return value;
  }
  const date = new Date(value);
  return formatDateToIsoDate(date);
}
