export const routes = {
  successfulLogin: "/login-success",
  home: "/",
  assets: "/assets",
  customers: "/customers",
  contracts: "/contracts",
  graphiql: "/graphiql",
  details: (id: string): string => "/details/" + id,
  infoSuffix: "/info",
  subAssetSuffix: "/subassets",
  inspectionSuffix: "/inspections",
  protocolSuffix: "/protocol",
  customerSuffix: "/customers",
  assetsSuffix: "/assets",
  contractsSuffix: "/contracts",
  subAccountsSuffix: "/sub-accounts",
  registrationSuffix: "/registration",
  blueprints: "/blueprints",
  manufacturers: "/manufacturers",
  suppliers: "/suppliers",
  administration: "/admin",
};
