import { gql } from "@apollo/client";

export const supplierFragment = gql`
  fragment SupplierData on Supplier {
    city
    id
    name
    streetAddress
    zip
  }
`;
