import * as Sentry from "@sentry/browser";

const SENTRY_DNS_URL = "https://5e67d319d5bc4fffb1b2a284ce1d2a6d@o59532.ingest.sentry.io/5441060";

/**
 * Configures the Sentry JS client (https://github.com/getsentry/raven-js).
 */
export function initSentry(): void {
  if (process.env.NODE_ENV === "development") {
    console.log("Skipping sentry init, NODE_ENV: " + process.env.NODE_ENV);
    // Sentry in local development
    return;
  }

  // Installs an `window.onerror` listener
  Sentry.init({
    dsn: SENTRY_DNS_URL,
    release: process.env.REACT_APP_GIT_TAG,
    environment: window.location.hostname,
  });
  Sentry.configureScope((scope) => {
    scope.setTag("git_tag", process.env.REACT_APP_GIT_TAG || "undefined");
    scope.setTag("git_commit", process.env.REACT_APP_GIT_COMMIT || "undefined");
  });
}
