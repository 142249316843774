import { gql, useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import React, { useContext, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Checkbox, createStyles, FormControlLabel, FormGroup, Grid, Typography, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Role, Team } from "../../../__generated__/globalTypes";
import { ControllerTextField } from "../../../components/inputs/ControllerTextField";
import { SnackContext } from "../../../routing/idfyRouter";
import { routes } from "../../../routing/routes";
import { FIELD_VALIDATION_TYPE_EMAIL, FIELD_VALIDATION_TYPE_STRING } from "../../../util/constants";
import { idfyTheme } from "../../../util/IdfyTheme";
import { userFragment } from "../../../util/graphql/userFragment";
import { InfoPageHeader } from "../../pageFragments/util/InfoPageHeader";
import { PopUpDialog } from "../../pageFragments/util/PopUpDialog";
import { deleteUserMutation, deleteUserMutationVariables } from "./__generated__/deleteUserMutation";
import { updateUserMutation } from "./__generated__/updateUserMutation";
import { userInfoQuery_user } from "./__generated__/userInfoQuery";
import { refreshCurrentAccount, useCurrentAccount } from "../../../util/auth/useCurrentAccount";
import { getTeamsSchema } from "../../../components/team/TeamFilter";
import { TeamField } from "../../../components/team/TeamField";
import { RoleError } from "../../../components/error/RoleError";

const useStyles = makeStyles((theme) =>
  createStyles({
    formGrid: {
      boxSizing: "border-box",
      paddingTop: ".5em",
    },
    gridItem: {
      marginTop: ".2em",
    },
    deleteButton: {
      background: idfyTheme.palette.error.main,
      color: idfyTheme.palette.background.default,
      "&:hover": {
        background: "#ff7b59",
      },
    },
    rolesLabel: {
      textAlign: "start",
      marginBottom: theme.spacing(1),
    },
  })
);

const UPDATE_USER_MUTATION = gql`
  ${userFragment}
  mutation updateUserMutation($id: ID!, $user: UserInput!) {
    updateUser(input: { userId: $id, user: $user }) {
      user {
        ...UserData
      }
    }
  }
`;

const DELETE_USER_MUTATION = gql`
  mutation deleteUserMutation($id: ID!) {
    deleteUser(input: { userId: $id }) {
      userId
    }
  }
`;

export interface UserInfoProps {
  user: userInfoQuery_user;
}

export const SELECTABLE_ROLES: Role[] = [Role.ADMIN, Role.EMPLOYEE, Role.INSPECTOR];

export const UserInfo: React.FunctionComponent<UserInfoProps> = (props) => {
  const classes = useStyles();
  const snackContext = useContext(SnackContext);
  const { t } = useTranslation();
  const history = useHistory();
  const user = useCurrentAccount();
  const refresh = refreshCurrentAccount();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [updateUser] = useMutation<updateUserMutation>(UPDATE_USER_MUTATION);
  const [deleteUser] = useMutation<deleteUserMutation, deleteUserMutationVariables>(DELETE_USER_MUTATION);

  const initialValues: {
    firstName: string;
    lastName: string;
    email: string;
    telephoneNumber: string;
    company: string;
    team: string;
    roles: Role[];
  } = useMemo(
    () => ({
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      email: props.user.email,
      telephoneNumber: props.user.telephoneNumber,
      company: "Deutsche Leasing",
      ...(SELECTABLE_ROLES.reduce((prev, curr) => ({ ...prev, [curr]: props.user.roles.includes(curr) }), {}) as {
        ADMIN: boolean;
        EMPLOYEE: boolean;
        INSPECTOR: boolean;
      }),
      team: props.user.team,
      roles: props.user.roles,
    }),
    [props.user]
  );

  const schema = yup.object().shape({
    firstName: FIELD_VALIDATION_TYPE_STRING(t),
    lastName: FIELD_VALIDATION_TYPE_STRING(t),
    email: FIELD_VALIDATION_TYPE_EMAIL(t),
    telephoneNumber: FIELD_VALIDATION_TYPE_STRING(t),
    team: getTeamsSchema(true).default(Team.ASSET_MONITORING),
  });

  const form = useForm<typeof initialValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, errors, reset, control, setError } = form;

  const handleOnSubmit = (values: typeof initialValues) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    const roles = SELECTABLE_ROLES.filter((role) => (values as any)[role]);

    if (roles.length < 1) {
      setError("roles", {
        message: t("validations.required"),
      });
      return;
    }

    void updateUser({
      variables: {
        id: props.user.id,
        user: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          telephoneNumber: values.telephoneNumber,
          roles: roles,
          team: values.team,
        },
      },
    }).then((res) => {
      setDisabled(true);
      snackContext.setSnackOpen(!res.errors);
      snackContext.setSnackText(t("pages.user-details.information.alert.user-updated"));
      if (res.data?.updateUser.user?.id === user?.user?.id) {
        refresh();
      }
    });
  };

  const handleDelete = () => setDeleteModalOpen(true);

  const handleDeleteConfirm = () => {
    void deleteUser({
      variables: { id: props.user.id },
    }).then((res) => {
      snackContext.setSnackOpen(!res.errors);
      snackContext.setSnackText(t("pages.user-details.information.alert.user-deleted"));
      history.push(routes.administration);
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <InfoPageHeader
          setDisabled={setDisabled}
          disabled={disabled}
          header={t("pages.user-details.information.edit-buttons.header")}
          handleDelete={handleDelete}
          deleteText={t("pages.user-details.information.edit-buttons.delete-user")}
          onCancel={() => reset(initialValues)}
        />
        <Grid container spacing={4} className={classes.formGrid}>
          <Grid item xs={12} lg={6} className={classes.gridItem}>
            <ControllerTextField
              control={control}
              disabled={disabled}
              fullWidth
              variant="outlined"
              size="small"
              name="firstName"
              label={t("pages.user-details.information.field.firstName")}
              color="secondary"
              helperText={errors.firstName?.message}
              error={!!errors.firstName}
              trimOnBlur
            />
          </Grid>
          <Grid item xs={12} lg={6} className={classes.gridItem}>
            <ControllerTextField
              control={control}
              disabled={disabled}
              fullWidth
              variant="outlined"
              size="small"
              name="lastName"
              label={t("pages.user-details.information.field.lastName")}
              color="secondary"
              helperText={errors.lastName?.message}
              error={!!errors.lastName}
              trimOnBlur
            />
          </Grid>
          <Grid item xs={12} lg={6} className={classes.gridItem}>
            <ControllerTextField
              control={control}
              disabled={true}
              fullWidth
              variant="outlined"
              size="small"
              name="company"
              label={t("pages.user-details.information.field.company")}
              color="secondary"
              helperText={errors.company?.message}
              error={!!errors.company}
              trimOnBlur
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} lg={6} className={classes.gridItem}>
            <ControllerTextField
              control={control}
              disabled={true}
              fullWidth
              variant="outlined"
              size="small"
              name="email"
              label={t("pages.user-details.information.field.email")}
              color="secondary"
              helperText={errors.email?.message}
              error={!!errors.email}
              trimOnBlur
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={4} className={classes.gridItem}>
            <ControllerTextField
              control={control}
              disabled={disabled}
              fullWidth
              variant="outlined"
              size="small"
              name="telephoneNumber"
              label={t("pages.user-details.information.field.telephone")}
              color="secondary"
              helperText={errors.telephoneNumber?.message}
              error={!!errors.telephoneNumber}
              trimOnBlur
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h3" className={classes.rolesLabel}>
              {t("pages.user-details.information.field.roles")}
            </Typography>
            <FormHelperText>{(errors.roles && errors.roles as RoleError)?.message}</FormHelperText>
            <FormGroup>
              {SELECTABLE_ROLES.map((role) => (
                <Controller
                  key={role}
                  name={role as never}
                  control={control}
                  render={
                    /* @ts-ignore */
                    ({ onChange, onBlur, value, _, ref }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={disabled}
                            onBlur={onBlur}
                            onChange={(e) => onChange(e.target.checked)}
                            checked={value as boolean}
                            inputRef={ref as unknown as null}
                          />
                        }
                        label={t(`role.${role}`)}
                      />
                    )
                  }
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <TeamField name={"team"} label={t("Team.title")} includeAllTeam={true} disabled={disabled} />
          </Grid>
        </Grid>
        <PopUpDialog
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          handleConfirm={handleDeleteConfirm}
          handleDeny={() => setDeleteModalOpen(false)}
          header={t("pages.user-details.information.dialog.delete.header")}
          confirm={t("pages.user-details.information.dialog.delete.confirm")}
          deny={t("pages.user-details.information.dialog.delete.deny")}
          classes={{ confirmButton: classes.deleteButton }}
        >
          <Typography variant={"body2"}>
            {t("pages.user-details.information.dialog.delete.message", {
              name: props.user.lastName + ", " + props.user.firstName,
            })}
          </Typography>
        </PopUpDialog>
      </form>
    </FormProvider>
  );
};
