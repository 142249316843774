import { gql } from "@apollo/client";

export const imageLocationFragment = gql`
  fragment ImageLocation on Location {
    latitude
    longitude
  }
`;

export const imageFragment = gql`
  ${imageLocationFragment}
  fragment Image on Image {
    data
    type
    creationDate
    appCreationDateTime
    location {
      ...ImageLocation
    }
  }
`;
