import React, { FC } from "react";

import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import PersonIcon from "@material-ui/icons/Person";

import { AssetEventType, InspectionType } from "../../../../../__generated__/globalTypes";
import { usePrefixedTranslation } from "../../../../../util/usePrefixedTranslation";
import { AssetProtocolEntry } from "../AssetProtocolEntry";
import { EventTextBox } from "./EventTextBox";

export interface InspectionEventEntryProps {
  type: AssetEventType;
  time: Date;
  messageToCustomer: string | null;
  inspectionType: InspectionType;
  user: {
    firstName: string;
    lastName: string;
  };
}

export const InspectionEventEntry: FC<InspectionEventEntryProps> = (props) => {
  const { pt } = usePrefixedTranslation("pages.asset-details.protocol.");

  const type = pt("inspection-type." + props.inspectionType);
  const action = pt("event-action." + props.type);

  return (
    <AssetProtocolEntry
      icon={<AssignmentOutlinedIcon />}
      title={pt("inspection.title", { type, action })}
      time={props.time}
      fields={[
        {
          text: `${props.user.lastName}, ${props.user.firstName}`,
          tooltip: pt("inspection.tooltip-user", { type, action }),
          icon: <PersonIcon />,
        },
      ]}
      body={
        props.messageToCustomer ? (
          <EventTextBox title={pt("inspection.message-to-customer")} text={props.messageToCustomer} />
        ) : undefined
      }
    />
  );
};
