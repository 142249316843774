import { gql } from "@apollo/client";
import { customerFragment } from "../../customers/api/customerFragment";

export const contractFragment = gql`
  ${customerFragment}
  fragment Contract on Contract {
    id
    contractNumber
    customer {
      ...CustomerData
    }
    startDate
    endDate
    extensionPeriod
    extendedEndDate
    status
  }
`;
