import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { idfyTheme } from "../../../../../util/IdfyTheme";

export const useAssetInfoStyles = makeStyles((theme: Theme) =>
  createStyles({
    informationHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    buttonWrapper: {
      display: "grid",
      alignItems: "center",
      gridColumnGap: theme.spacing(2),
      gridAutoFlow: "column",
    },
    formGrid: {
      boxSizing: "border-box",
      paddingTop: ".5em",
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(2),
      },
    },
    confirmButton: {
      background: idfyTheme.palette.error.main,
      color: idfyTheme.palette.background.paper,
      "&hover": {
        background: "#FF7B59",
      },
    },
  })
);
