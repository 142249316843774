import React, { ReactElement } from "react";
import { Box } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { CUSTOMER_REGISTRATION_QUERY } from "../api/customerQueriesAndMutations";
import {
  customerRegistrationQuery,
  customerRegistrationQueryVariables,
} from "../api/__generated__/customerRegistrationQuery";
import { Customer } from "../Customer";

export function CustomerRegistration(props: { customer: Customer }): ReactElement {
  const customerRegistration = useQuery<customerRegistrationQuery, customerRegistrationQueryVariables>(
    CUSTOMER_REGISTRATION_QUERY,
    {
      variables: { input: { customerId: props.customer.id } },
    }
  ).data?.customerRegistration;

  if (!customerRegistration) {
    return <></>;
  }
  return (
    <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <img
        src={"/api/qr-codes?code=" + encodeURI(customerRegistration.registrationLink)}
        alt={"qr code"}
        width={250}
        height={250}
      />
      {customerRegistration.registrationCode}
    </Box>
  );
}
