import { useQuery } from "@apollo/client";

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Route, useParams } from "react-router";

import { CircularProgress } from "@material-ui/core";

import { BreadcrumbContext } from "../../../routing/idfyRouter";
import { routes } from "../../../routing/routes";
import { DetailsPage } from "../../pageFragments/DetailsPage";
import { ContractMenu } from "./ContractMenu";
import { contractQuery } from "../api/__generated__/contractQuery";
import { CONTRACT_QUERY } from "../api/contractQueriesAndMutations";
import { ContractInfo } from "./ContractInfo";
import { ContractAssets } from "./ContractAssets";

export const ContractDetails: React.FunctionComponent = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { id } = useParams();
  const { t } = useTranslation();

  const utilContext = useContext(BreadcrumbContext);

  if (utilContext.breadcrumbs.length === 0) {
    utilContext.breadcrumbs.push({
      url: routes.contracts,
      title: t("drawer.contracts"),
    });
  }

  const { data, refetch: updateContractDetails } = useQuery<contractQuery>(CONTRACT_QUERY, {
    variables: { id: id as string },
  });

  const handleOnUpdateContractDetails = async () => {
    await updateContractDetails();
  };

  return (
    <DetailsPage
      headerText={t("pages.contracts.header")}
      menu={data && data.contract ? <ContractMenu contract={data.contract} /> : null}
    >
      {data && data.contract ? (
        <>
          <Route path={routes.contracts + routes.details(":id") + routes.infoSuffix}>
            <ContractInfo contract={data.contract} onUpdateContractDetails={handleOnUpdateContractDetails} />
          </Route>
          <Route path={routes.contracts + routes.details(":id") + routes.assetsSuffix}>
            <ContractAssets contract={data.contract} />
          </Route>
          <Route path={routes.contracts + routes.details(":id") + routes.protocolSuffix}>
            <div>coming soon...</div>
          </Route>
        </>
      ) : (
        <CircularProgress color={"primary"} />
      )}
    </DetailsPage>
  );
};
