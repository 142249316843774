import { Controller, useFormContext } from "react-hook-form";
import * as React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { SelectProps } from "@material-ui/core/Select/Select";
import { retrieveError } from "./FormErrorInfoText";
import { ReactElement } from "react";

const EMPTY_VALUE = "__empty__";

export function FormSelectField<LIST_TYPE>(
  props: {
    name: string;
    label?: string;
    list: LIST_TYPE[];
    getListLabel?: (entry: LIST_TYPE) => string;
    getListKey?: (entry: LIST_TYPE) => string | number;
    noSelectionText?: string;
    allowEmpty?: boolean;
  } & Partial<Omit<SelectProps, "onChange" | "onBlur" | "value" | "name" | "error">>
): ReactElement {
  const {
    name,
    label,
    list,
    getListLabel,
    getListKey,
    noSelectionText,
    allowEmpty,
    required,
    ...selectAndFormControlProps
  } = props;
  const { variant, ...rest } = selectAndFormControlProps;
  const { control, errors } = useFormContext();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getListKeyOrDefault(value: any) {
    if (value === EMPTY_VALUE) {
      return value;
    }
    let result: string | number = "";
    if (value) {
      if (getListKey) {
        result = getListKey(value);
      } else {
        result = value.toString();
      }
    }
    if (!result) {
      result = "";
    }
    return result;
  }

  function getListLabelOrDefault(value: LIST_TYPE) {
    if (!value || list.length === 0) {
      return "";
    }
    if (!getListLabel) {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      return (value as any).toString();
    }
    return getListLabel(value);
  }

  return (
    <Controller
      name={name}
      control={control}
      render={(renderProps) => {
        return (
          <FormControl
            size="small"
            error={!!retrieveError(props.name, errors)}
            fullWidth={true}
            variant={variant || "outlined"}
          >
            {label && <InputLabel id={"select_" + name + "_label"}>{label + (required ? " *" : "")}</InputLabel>}
            <Select
              id={"select_" + name}
              labelId={"select_" + name + "_label"}
              label={label}
              name={renderProps.name}
              onChange={(event) => {
                const e = event as React.ChangeEvent<HTMLSelectElement>;
                if (e.target.value === EMPTY_VALUE) {
                  renderProps.onChange(undefined);
                } else {
                  const value = e.target.value;
                  if (value) {
                    renderProps.onChange(value);
                  }
                }
              }}
              onBlur={renderProps.onBlur}
              value={renderProps.value}
              error={!!retrieveError(props.name, errors)}
              {...rest}
            >
              <MenuItem value={EMPTY_VALUE} disabled={!allowEmpty} key={EMPTY_VALUE}>
                {noSelectionText || "---"}
              </MenuItem>
              {list.map((item) => (
                <MenuItem
                  id={"option_" + getListKeyOrDefault(item)}
                  value={getListKeyOrDefault(item)}
                  key={getListKeyOrDefault(item)}
                >
                  {getListLabelOrDefault(item)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{retrieveError(props.name, errors)}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}
