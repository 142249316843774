import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "../../routing/routes";
import { Customer } from "./Customer";
import { IDFyFormColumn, IDFyFormTable } from "../../components/table/IDFyFormTable";
import { BreadcrumbContext } from "../../routing/idfyRouter";
import { useHistory } from "react-router-dom";

export const CustomerDetailsSectionTable: React.FunctionComponent<{
  customer: Customer | undefined;
  breadcrumbTitle: string;
}> = (props) => {
  const { t } = useTranslation();
  const { customer } = props;

  const langCustomerTablePath = "pages.customers.table.";
  const utilContext = useContext(BreadcrumbContext);
  const history = useHistory();

  const allColumns = [
    {
      name: "company",
      titleKey: `${langCustomerTablePath}company`,
      render: (rowData) => rowData.company,
      renderMobile: (rowData) => t(`${langCustomerTablePath}company`) + ": " + rowData.company,
    },
    {
      name: "contactPerson",
      titleKey: `${langCustomerTablePath}contactPerson`,
      render: (rowData) => `${rowData.firstName} ${rowData.lastName}`,
      renderMobile: (rowData) =>
        t(`${langCustomerTablePath}contactPerson`) + ": " + `${rowData.firstName} ${rowData.lastName}`,
    },
    {
      name: "email",
      titleKey: `${langCustomerTablePath}email`,
      render: (rowData) => rowData.email,
      renderMobile: (rowData) => t(`${langCustomerTablePath}email`) + ": " + rowData.email,
    },
  ] as IDFyFormColumn<Customer>[];

  function onRowClick(rowData: Customer) {
    utilContext.breadcrumbs.push({
      url: history.location.pathname || "errorPage",
      title: props.breadcrumbTitle,
    });
    history.push(routes.customers + routes.details(rowData.id) + routes.infoSuffix);
  }

  return (
    <IDFyFormTable
      title={t("pages.customers.table.customer")}
      columns={allColumns}
      data={customer ? [customer] : undefined}
      onRowClick={onRowClick}
    />
  );
};
