import { TFunction, TFunctionKeys, TOptions } from "i18next";

import { useTranslation } from "react-i18next";

/**
 * A wrapper for the useTranslation hook that allows you to provide a prefix.
 */
export const usePrefixedTranslation = (prefix: string): { pt: TFunction } => {
  const original = useTranslation();

  return {
    pt: (key: TFunctionKeys, defaultValue?: string, options?: TOptions) => {
      if (Array.isArray(key)) {
        return original.t(
          key.map((key) => prefix + key),
          defaultValue,
          options
        );
      } else {
        return original.t(prefix + key, defaultValue, options);
      }
    },
  };
};
