import React, { ReactElement } from "react";
import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

export function DetailsSectionHeader(props: { header: string }): ReactElement {
  const classes = useStyles();
  return (
    <Typography variant="h3" className={classes.title}>
      {props.header}
    </Typography>
  );
}
